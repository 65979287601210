import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation, checkEmailValidation, checkPhoneNumberValidation } from "services/validation-service";
import { FormHelperText, MenuItem } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Autocomplete } from "@mui/material";
import { CustomButton } from "components/Common/CustomButton";
import ChangePasswordDialog from 'components/dialoges/ChangePasswordDialog';
import CustomerChangePasswordDialog from "components/dialoges/CustomerChangePasswordDialog";
//import roles from "services/roles";

const useStyles = makeStyles(componentStyles);

function UserProfile() {

  //Use properties
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  //Then inside your component
  const { id } = useParams();
  const PageTitle = id ? 'Update User' : 'Add User';

  // Usef defined states
  const [userModel, setUserModel] = useState({
    Name: '',
    Email: '',
    PhoneNumber: '',
    Username: '',
    Password: '',
    ConfirmPassword: '',
    Status: 'Active',
    Role: 'SUPER_ADMIN',
    Machines: [],
    WorkCenters: [],
    //Code: ''
  });

  // Usef defined states
  const [userValidateModel, setUserValidateModel] = useState({
    Name: { message: '', touched: false },
    Email: { message: '', touched: false },
    PhoneNumber: { message: '', touched: false },
    Username: { message: '', touched: false },
    Password: { message: '', touched: false },
    ConfirmPassword: { message: '', touched: false },
    Status: { message: '', touched: true },
    Role: { message: '', touched: true },
    //Code: { message: '', touched: true },
    IsValidationError: false
  });

  const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
  const [machineList, setMachineList] = useState([]);
  const [workCenterList, setWorkCenterList] = useState([]);

  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });

  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false); // loading page state value

  useEffect(() => {
    const fetchData = async() => {
      await getMachineList();
      await getWorkCenterList();
    }

    const getUserData = async () => {
      let res = await dataService('get', `UserGet/${id}`, '');
      if (res.status === 200) {
        const {UserData,  OperatorMachineData, MasterOperatorWorkCenterData} = res?.data;
        let OperatorMachineList = [];
        if(OperatorMachineData)
          OperatorMachineList = OperatorMachineData.reduce((acc, e) => { acc.push({ MachineId: e.MachineId, WorkCenterId: e.WorkCenterId, WorkCenterName: e.WorkCenterName, Name: e.Name, Default: e.Default }); return acc }, []);
  
        let MasterOperatorWorkCenterList = [];
        if(MasterOperatorWorkCenterData)
          MasterOperatorWorkCenterList = MasterOperatorWorkCenterData.reduce((acc, e) => { acc.push({ WorkCenterId: e.WorkCenterId, Name: e.Name }); return acc }, []);
          
        if (UserData) {
          setUserModel({
            ...userModel,
            Name: UserData?.Name,
            Email: UserData?.Email,
            PhoneNumber: UserData?.PhoneNumber,
            Username: UserData?.Username,
            Status: UserData?.Status,
            Role: UserData?.Role,
            Code: UserData?.Code === null ? '' : UserData?.Code,
            Machines: OperatorMachineList,
            WorkCenters: MasterOperatorWorkCenterList
          });
        }
  
      }
    }

    fetchData()
    if (id) {
      getUserData();
    }
  }, [id])

 

  const getMachineList = async () => {
    let res = await dataService('get', 'MachineList', `searchText=&sortBy=Name&sortOrder=asc&page=1&offset=100`);
    if (res.status === 200) {
      if (res?.data?.length > 0) {
        let mList = res?.data.reduce((acc, e) => { acc.push({ MachineId: e.MachineId, WorkCenterId: e.WorkCenterId, WorkCenterName: e.WorkCenterName, Name: e.Name, Default: e.Default }); return acc }, []);

        setMachineList(mList);
      }

    }
  }

  const getWorkCenterList = async () => {
    let res = await dataService('get', 'WorkCenterList', `searchText=&sortBy=Name&sortOrder=asc&page=1&offset=100`);
    if (res.status === 200) {
      if (res?.data?.length > 0) {
        let mList = res?.data.reduce((acc, e) => { acc.push({ WorkCenterId: e.WorkCenterId, Name: e.Name }); return acc }, []);
        setWorkCenterList(mList);
      }

    }
  }


  

  const handleChange = (id, value) => {
    
    switch (id) {
      case 'Name':
        setUserModel({ ...userModel, Name: value });
        setUserValidateModel({ ...userValidateModel, Name: { message: checkEmptyValidation(value, 'Name'), touched: true } });
        break;
      case 'Email':
        setUserModel({ ...userModel, Email: value });
        setUserValidateModel({ ...userValidateModel, Email: { message: checkEmailValidation(value, 'Email'), touched: true } });
        break;
      case 'PhoneNumber':
        setUserModel({ ...userModel, PhoneNumber: value });
        setUserValidateModel({ ...userValidateModel, PhoneNumber: { message: checkPhoneNumberValidation(value, 'Phone Number'), touched: true } });
        break;
      case 'Username':
        setUserModel({ ...userModel, Username: value });
        setUserValidateModel({ ...userValidateModel, Username: { message: checkEmptyValidation(value, 'Username'), touched: true } });
        break;
      case 'Password':
        setUserModel({ ...userModel, Password: value });
        setUserValidateModel({ ...userValidateModel, Password: { message: checkEmptyValidation(value, 'Password'), touched: true } });
        break;
      case 'ConfirmPassword':
        setUserModel({ ...userModel, ConfirmPassword: value });
        setUserValidateModel({ ...userValidateModel, ConfirmPassword: { message: checkEmptyValidation(value, 'Confirm Password'), touched: true } });
        break;
      case 'Role':
        setUserModel({ ...userModel, Role: value });
        setUserValidateModel({ ...userValidateModel, Role: { message: checkEmptyValidation(value, 'Role'), touched: true } });
        break;
      case 'Status':
        setUserModel({ ...userModel, Status: value });
        setUserValidateModel({ ...userValidateModel, Status: { message: checkEmptyValidation(value, 'Status'), touched: true } });
        break;
      // case 'Code':
      //   setUserModel({ ...userModel, Code: value });
      //   setUserValidateModel({ ...userValidateModel, Code:  { message: userModel.Role === 'OPERATOR' ? checkEmptyValidation(userModel.Code, 'Code') : '', touched: userModel.Role === 'OPERATOR' ? true : false} });
      //   break;
      case 'Machines':
        setUserModel({ ...userModel, Machines: value });
        ///setUserValidateModel({ ...userValidateModel, Machines:  {message: checkEmptyValidation(value, 'Status'), touched: true} });
        break;
      case 'WorkCenters':
        setUserModel({ ...userModel, WorkCenters: value });
        ///setUserValidateModel({ ...userValidateModel, Machines:  {message: checkEmptyValidation(value, 'Status'), touched: true} });
        break;
        default:
          break;
      
    }
  };

  const handleSubmitUser = async () => {


    if (!checkValidations()) {

      setShowSubmitLoading(true);
      let reqData = {...userModel};
      if(userModel.Role === 'OPERATOR' || userModel.Role === 'MASTER_OPERATOR'){
        let mList = reqData?.Machines?.reduce((acc,e)=>{acc.push({MachineId: e.MachineId});return acc},[]);
        reqData.Machines = mList;
      }
      if(userModel.Role === 'MASTER_OPERATOR'){
        let wList = reqData?.WorkCenters?.reduce((acc,e)=>{acc.push({WorkCenterId: e.WorkCenterId});return acc},[]);
        reqData.WorkCenters = wList;
      }

      let res = await dataService('post', `UserSave/${id ? id : 0}`, reqData);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      setShowSubmitLoading(false);
      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialoge({ show: true, type: 'success', message: id ? 'User updated successfully!' : 'User registered successfully!' });
        if (!id) {
          setTimeout(() => {
            history.push('users')
          }, 500);
        }

      } else {
        //setOpen(res.message);
        setMessageDialoge({ show: true, type: 'error', message: res.message });
      }
    }
  }


  const checkValidations = () => {

    let validateErrors = {
      Name: { message: checkEmptyValidation(userModel.Name, 'Name'), touched: true },
      Email: { message: checkEmailValidation(userModel.Email, 'Email'), touched: true },
      PhoneNumber: { message: checkPhoneNumberValidation(userModel.PhoneNumber, 'Phone Number'), touched: true },
      Username: { message: checkEmptyValidation(userModel.Username, 'Username'), touched: true },
      Password: { message: id ? '' : checkEmptyValidation(userModel.Password, 'Password'), touched: id ? false : true },
      ConfirmPassword: { message: id ? '' : checkEmptyValidation(userModel.ConfirmPassword, 'Confirm Password'), touched: id ? false : true },
      Status: { message: checkEmptyValidation(userModel.Status, 'Status'), touched: true },
      Role: { message: checkEmptyValidation(userModel.Role, 'Role'), touched: true },
      // Code: {message: userModel.Role === 'OPERATOR' ? checkEmptyValidation(userModel.Code, 'Code') : '', touched: userModel.Role === 'OPERATOR' ? true : false}
    }
    if (validateErrors.Name.message !== '' ||
      validateErrors.Email.message !== '' ||
      validateErrors.PhoneNumber.message !== '' ||
      validateErrors.Username.message !== '' ||
      (id && validateErrors.Password.message !== '') ||
      (id && validateErrors.ConfirmPassword.message !== '') ||
      validateErrors.Status.message !== '' ||
      validateErrors.Role.message !== '' 
      //(userModel.Role === 'OPERATOR' && validateErrors.Code.message !== '')
    ) {
      validateErrors.IsValidationError = true;
    }
    else {
      validateErrors.IsValidationError = false;
    }
    setUserValidateModel(validateErrors);
    return validateErrors.IsValidationError;

  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      // paddingTop="10rem"
      // paddingBottom="3rem"
      >
        <Grid container >
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {PageTitle}
                      </Box>
                    </Grid>
                    {id &&
                      <Grid item xs="auto">
                        <Box
                          justifyContent="flex-end"
                          display="flex"
                          flexWrap="wrap"
                        >
                          <CustomButton size="small" label="Change Password" onClick={() => { setOpenChangePasswordDialog(true) }} />
                        </Box>
                      </Grid>
                    }
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                </Grid>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  User Information
                </Box>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={userModel.Name}
                          onChange={e => handleChange('Name', e.target.value)}
                          error={userValidateModel.Name.message !== '' && userValidateModel.Name.touched}
                          helperText={userValidateModel.Name.message !== '' && userValidateModel.Name.touched ? userValidateModel.Name.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={userModel.Email}
                          onChange={e => handleChange('Email', e.target.value)}
                          error={userValidateModel.Email.message !== '' && userValidateModel.Email.touched}
                          helperText={userValidateModel.Email.message !== '' && userValidateModel.Email.touched ? userValidateModel.Email.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="email"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Phone Number</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          value={userModel.PhoneNumber}
                          autoComplete="off"
                          onChange={e => handleChange('PhoneNumber', e.target.value)}
                          error={userValidateModel.PhoneNumber.message !== '' && userValidateModel.PhoneNumber.touched}
                          helperText={userValidateModel.PhoneNumber.message !== '' && userValidateModel.PhoneNumber.touched ? userValidateModel.PhoneNumber.message : ''}
                          className={classes.errorText}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Username</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          value={userModel.Username}
                          autoComplete="off"
                          onChange={e => handleChange('Username', e.target.value)}
                          error={userValidateModel.Username.message !== '' && userValidateModel.Username.touched}
                          helperText={userValidateModel.Username.message !== '' && userValidateModel.Username.touched ? userValidateModel.Username.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {!id &&
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Password</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            type="password"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            value={userModel.Password}
                            onChange={e => handleChange('Password', e.target.value)}
                            error={userValidateModel.Password.message !== '' && userValidateModel.Password.touched}
                            helperText={userValidateModel.Password.message !== '' && userValidateModel.Password.touched ? userValidateModel.Password.message : ''}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Confirm Password</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            type="password"
                            autoComplete="off"
                            value={userModel.ConfirmPassword}
                            onChange={e => handleChange('ConfirmPassword', e.target.value)}
                            error={userValidateModel.ConfirmPassword.message !== '' && userValidateModel.ConfirmPassword.touched}
                            helperText={userValidateModel.ConfirmPassword.message !== '' && userValidateModel.ConfirmPassword.touched ? userValidateModel.ConfirmPassword.message : ''}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              components={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  }
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Role</FormLabel>

                        <Select value={userModel.Role} onChange={e => handleChange('Role', e.target.value)}>
                          
                          <MenuItem value={'SUPER_ADMIN'}>SUPER_ADMIN</MenuItem>
                          <MenuItem value={'OPERATOR'}>OPERATOR</MenuItem>
                          <MenuItem value={'MASTER_OPERATOR'}>MASTER_OPERATOR</MenuItem>
                        </Select>
                        <FormHelperText>{userValidateModel.Role.message !== '' && userValidateModel.Role.touched ? userValidateModel.Role.message : ''}</FormHelperText>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Status {userModel.Role}</FormLabel>

                        <Select value={userModel.Status} onChange={e => handleChange('Status', e.target.value)}>
                          <MenuItem value={'Active'}>Active</MenuItem>
                          <MenuItem value={'InActive'}>In Active</MenuItem>
                        </Select>
                      </FormGroup>
                      <FormHelperText>{userValidateModel.Status.message !== '' && userValidateModel.Status.touched ? userValidateModel.Status.message : ''}</FormHelperText>
                    </Grid>
                  </Grid>
                  {(userModel.Role === 'OPERATOR' || userModel.Role === 'MASTER_OPERATOR') && 
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Machine</FormLabel>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            value={userModel.Machines}
                            options={machineList}
                            groupBy={(option) => option.WorkCenterName}
                            getOptionLabel={(option) => option.Name}
                            onChange={(event, newValue) => {
                              handleChange('Machines', newValue)
                            }}
                            // getOptionLabel={(option) => option.Name}
                            //defaultValue={[top100Films[13]]}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Machines"
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  }
                  {userModel.Role === 'MASTER_OPERATOR' &&
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Work Centers</FormLabel>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            value={userModel.WorkCenters}
                            options={workCenterList}
                            getOptionLabel={(option) => option.Name}
                            onChange={(event, newValue) => {
                              handleChange('WorkCenters', newValue)
                            }}
                            //defaultValue={[top100Films[13]]}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select WorkCenters"
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    }
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitUser} />
                      </Box>
                    </Grid>
                  </Grid>
                </div>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      { openChangePasswordDialog && ( id ?
        <ChangePasswordDialog title="Change Password 1" open={openChangePasswordDialog} setOpen={setOpenChangePasswordDialog} UserId={id} userType="other" /> : 
        <CustomerChangePasswordDialog title="Change Password" open={openChangePasswordDialog} setOpen={setOpenChangePasswordDialog} userType="other" /> )
      }
    </>
  );
}

export default UserProfile;
