import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { Worker } from '@react-pdf-viewer/core';

import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin, } from '@react-pdf-viewer/get-file';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useMediaQuery, useTheme } from '@mui/material';


function PDFViewerDialog({ viewer, handleClose }) {

    const defaultLayoutPluginInstance = defaultLayoutPlugin()

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file) => {
            return viewer?.item?.Title;
        },
    });

    const theme = useTheme();

    const { DownloadButton } = getFilePluginInstance;

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iMDScreen = useMediaQuery(theme.breakpoints.down('md'));

    console.log("item", viewer)
    return (
        <Dialog
            fullWidth={true}
            fullScreen={(isSmallScreen || iMDScreen) ? true : false}
            maxWidth={(isSmallScreen || iMDScreen) && 'xl'}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-3 pb-2 text-xl">
                    <div className="text-xl sm:text-2xl">{viewer?.item?.Title}</div>
                </DialogTitle>
                <div className='flex flex-row items-center'>
                    <div className='mt-1.5'>
                    <DownloadButton />
                        {/* <img
                            width="40"
                            alt='download-cloud-icon'
                            src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/download-cloud-icon.png`}
                        /> */}
                    </div>
                    <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-800 " onClick={handleClose} />
                </div>
            </div>
            <DialogContent >
                <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        {isSmallScreen ?
                            <Viewer
                                fileUrl={viewer?.file}
                                plugins={(isSmallScreen || iMDScreen) ? [getFilePluginInstance] : [defaultLayoutPluginInstance]}
                                initialRotation={0}
                            /> :
                            <Viewer
                                defaultScale={0.5}  
                                fileUrl={viewer?.file}
                                plugins={(isSmallScreen || iMDScreen) ? [] : [defaultLayoutPluginInstance, getFilePluginInstance]}
                                initialRotation={0}
                            />}
                    </Worker>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default PDFViewerDialog;
