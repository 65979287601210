import { Tooltip, Typography } from "@mui/material";

export const getIconFromMimeTypes = (mimeType) => {

    var icon_classes = {
        // Media
        image: "png-icon.png",
        audio: "audio-icon.png",
        video: "video-icon.png",
        // Documents
        "application/pdf": "pdf-icon.png",
        "application/msword": "word-icon.png",
        "application/vnd.ms-word": "word-icon.png",
        "application/vnd.oasis.opendocument.text": "word-icon.png",
        "application/vnd.openxmlformats-officedocument.wordprocessingml":
            "word-icon.png",
        "application/vnd.ms-excel": "xls-icon.png",
        "application/vnd.openxmlformats-officedocument.spreadsheetml":
            "xls-icon.png",
        "application/vnd.oasis.opendocument.spreadsheet": "xls-icon.png",
        "text/csv": "xls-icon.png",
        "text/plain": "txt-icon.png",
    };


    for (var key in icon_classes) {
        if (icon_classes.hasOwnProperty(key)) {
            if (mimeType.search(key) === 0) {
                // Found it
                return icon_classes[key];
            }
        } else {
            return "file-icon.png";
        }
    }
    return "file-icon.png";

}

export const getFileIcon = (mimeType) => {
    return <img width="40" alt='file' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/${getIconFromMimeTypes(mimeType)}`} />
}

export const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

export const renderTitleDetails = (title, length, style) => {
    const isLongName = title && title.length > length;
  
    const renderTitle = (name) => (
      <span
        style={{
          ...style
        }}
      >
        {name}
      </span>
    );
  
    return (
      <>
        {isLongName ? (
          <Tooltip
            title={
              <Typography sx={theme => ({color : theme.palette.mode === "dark" ? "black" : "white", fontSize : 12})}>
                {title}
              </Typography>
            }
            sx={{ color: "red" }}
            placement="top"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10]
                    }
                  }
                ]
              }
            }}
          >
            {renderTitle(truncateText(title, length))}
          </Tooltip>
        ) : (
          renderTitle(title)
        )}
      </>
    );
  };
  

  export const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export const useQRCodeStyling = (options) => {
  if (typeof window !== "undefined") {
    const QRCodeStylingLib = require("qr-code-styling")
    const qrCodeStyling = new QRCodeStylingLib(options)
    return qrCodeStyling
  }
  return null
}