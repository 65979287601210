/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';

import { dataService } from 'services/data-service';
// import moment from 'moment';
import SalesReportTable from './components/SalesReportTable';
import { currentUser } from 'services/user-service';

import OrderHeader from 'components/Headers/OrderHeader';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

const useStyles = makeStyles(componentStyles);

const ProductionReportsDaily = () => {
  const classes = useStyles();
  const history = useHistory();
  const { month, year, workCenertId } = useParams();

  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(year);
  const selectedOrderFlow = useSelector(state => state?.selectedOrderFlow);

  const columns = Object.keys(completedOrderList?.[0] || []).map(columnId => ({
    id: columnId,
    numeric: false,
    disablePadding: true,
    label: columnId,
    dataType: 'component',
    Component: ({ row }) => (
      <Typography onClick={() => {
        if(columnId === "Date") {
          history.push(
            `/admin/report/productions-report-daily-detail/${selectedYear}/${month}/${workCenertId}/${row[columnId]?.value}/0`    
          )
        }
        else {
          history.push({
            pathname :  `/admin/report/productions-report-daily-detail/${selectedYear}/${month}/${workCenertId}/${row[columnId].date}/${row[columnId].id}`,
            state : {workCenterName : columnId}
          }
           
          )
        }
       
      }}>
        {row[columnId].value}
      </Typography>
    ),  
  })); 


  
  useEffect(() => {
    fetchData()
  }, [selectedYear, selectedOrderFlow?.selected_flow?.product?.ProductId]);

  const fetchData = async() => {
    let curUser = currentUser();
    if (curUser?.UserId !== 1 && curUser?.UserId !== 3) {
      history.push(`/admin`);
      return false;
    }

    setIsLoading(true);
    let reqBody = generateQueryPostData();
    let res = await dataService('post', 'ProcutionReports', reqBody);
   
    const convertedData = res?.data?.dataReport.map(item => {
      const convertedItem = { Date: item.Date };
      for (const key in item) {
        if (key !== 'Date') {
          const id = parseInt(key.match(/\d+/)[0]);
          convertedItem[key.split('{')[0].trim()] = { title: key.split('{')[0].trim(), value: item[key], id, date :item.Date  };
        }
      }
      return convertedItem;
    });

    const formattedData = convertedData?.map((item) => {
      const formattedItem = { ...item };
      formattedItem.Date = {
          title: "Date",
          value: item.Date,
          id: null  
      };
      return formattedItem;
  });
    setCompletedOrderList(formattedData)
    

    setIsLoading(false);
  }

  
  const generateQueryPostData = () => {
    let reqBody = {
      Year: selectedYear,
      ReportType: 'DAILY',
      Month: month,
      ProductId : selectedOrderFlow?.selected_flow?.product?.ProductId,
      WorkCenterId : workCenertId ?? 0
    };
    return reqBody;
  };

  const totalQuantity = completedOrderList.reduce((sum, item) => {
    const orderKey = Object.keys(item).find(key => key !== "Date");
    return sum + item[orderKey].value;
}, 0);

  
  return (
    <>
    <OrderHeader isHideHeader={true}/>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <SalesReportTable
          title={null}
          columns={columns}
          rows={completedOrderList}
          actions={[]}
          loading={isLoading}
          selectedYear={selectedYear}
          selectedMonth={month}
          setSelectedYear={setSelectedYear}
          reportType={'DAILY'}
          isHideTotal={true}
          productionReportQuantityTotal={workCenertId !== "0" ? true : false}
          totalProductionQuantity={totalQuantity}
          isProductionReport={true}
        />
         <div>
        </div>

      </Container>
    </>
  );
};

export default ProductionReportsDaily;
