import React, { useEffect, useState } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
//import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
//import ViewTable from "components/Tables/ViewTable";
import CommonTable from "components/Tables/CommonTable";
import { OrderFlowIcon } from "components/Icons/CustomIcons";
import { FileCopy } from "@mui/icons-material";
import OrderFlowListDialog from "components/dialoges/OrderFlowListDialog";
import ViewOrderDialog from "components/dialoges/ViewOrderDialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { GoodsEntryIcon } from "components/Icons/CustomIcons";
import ViewOrdersGEDialog from "components/dialoges/ViewOrdersGEDialog";

const useStyles = makeStyles(componentStyles);

const ScrappedOrders = () => {
  const classes = useStyles();
  const [runningOrderList, setRunningOrderList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('ScrappedDate');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const [openShowFlowListView, setOpenShowFlowListView] = useState(null);
  const [flowStepListOrder, setFlowStepListOrder] = useState(null);

  const [openViewOrder, setOpenViewOrder] = useState(false);
  const [viewOrderId, setViewOrderId] = useState(null);

  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const [goodsDetailDialogData, setGoodsDetailDialogData] = useState(false);
  const [openGoodsDetailDialog, setOpenGoodsDetailDialog] = useState(false);

  const columns = [
    { id: 'OrderNumber', numeric: false, disablePadding: true, label: 'Order Number', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    { id: 'FileName', numeric: false, disablePadding: false, label: 'File Name', dataType: 'string' },
    { id: 'WorkCenterName', numeric: false, disablePadding: false, label: 'Status', dataType: 'string' },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'ScrappedQuantity', numeric: false, disablePadding: false, label: 'Scrapped Quantity', dataType: 'string' },
    { id: 'ScrappedReason', numeric: false, disablePadding: false, label: 'Reason', dataType: 'string' },
    { id: 'ScrappedDate', numeric: false, disablePadding: false, label: 'Scrapped Date', dataType: 'date' }
  ];

  useEffect(() => {
    const fetchData = async() => {
      if (localStorage.getItem('products')) {
        let productsAr = JSON.parse(localStorage.getItem('products'));
        setProductList(productsAr);
      }
      else {
        let res = await getProductList();
        setProductList(res?.data);
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchDataOrder = async() => {
      setIsLoading(true);
      //let res = await dataService('get', 'ScrappedOrderList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}&ProductId=${selectedOrderFlow?.selected_flow?.product?.ProductId}`);
      let res = await dataService('get', 'ScrappedOrderList', `productId=${selectedProductId || 0}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setIsLoading(false);
      setRunningOrderList(res?.data);
      setTotalRecord(res?.totalRecord);
    }
    
    fetchDataOrder()
  }, [page, searchText, order, orderBy, offset, selectedProductId])

  const getProductList = async () => {
    let res = await dataService('get', 'ProductList', null);
    if (res?.data) {
      localStorage.setItem('products', JSON.stringify(res?.data));
      return res;
    }
  }

  const FlowSetpListAction = (OrderDetail) => {
    setOpenShowFlowListView(true);
    setFlowStepListOrder(OrderDetail);
  };

  const makeCopyAction = (OrderDetail) => {
    history.push(`/admin/order-copy/${OrderDetail.OrderId}`)
  }

  const viewAction = (OrderDetail) => {
    setOpenViewOrder(true);
    setViewOrderId(OrderDetail.OrderId);
  };

  const viewGoodsDetailAction = (row) => {
    setGoodsDetailDialogData(row)
    setOpenGoodsDetailDialog(true);
  };

  let actionList = [
    { label: 'View Order', icon: VisibilityIcon, iconColor: 'purple-500', action: viewAction },
    { label: 'FlowSteps', icon: OrderFlowIcon, iconColor: 'yellow-500', action: FlowSetpListAction },
    { label: 'Make Copy', icon: FileCopy, iconColor: 'pink-500', action: makeCopyAction },
    { label: 'Goods Detail', icon: GoodsEntryIcon, iconColor: 'white', action: viewGoodsDetailAction },
  ]

  const handleSelect = async (ProductId) => {
    setSelectedProductId(parseInt(ProductId));
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >

        <RadioGroup row aria-label="position" className="mb-0 text-black mr-auto ml-0" onChange={(e) => handleSelect(e?.target?.value)} name="position" value={selectedProductId}>
          <FormControlLabel value={0} className="z-10" control={<Radio color="default" />} label="All" />
          {productList?.map((product, index) => {
            return <FormControlLabel className="z-10" key={index} value={product?.ProductId} control={<Radio color="default" />} label={product?.Name} />
          })}
        </RadioGroup>

        <CommonTable
          title="Scrapped Orders"
          columns={columns}
          rows={runningOrderList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          setOffset={setOffset}
          offset={offset}
          setSearchText={setSearchText}
          isSearchable={true}
          loading={isLoading}
          actions={actionList}
        />

        {/* <CommonTable 
            view="runningOrder"
            title="Hold Orders"
            columns={columns}
            rows={runningOrderList}
            page={page - 1}
            total={totalRecord}
            pagedata={setPage}
            setSearchText={setSearchText}
            order={order}
            orderBy={orderBy}
            orderData={setOrderData}
            orderByData={setOrderByData}
            editAction={handleEdit}
            setOffset={setOffset}
            offset={offset}
          />*/}
      </Container>

      {flowStepListOrder && openShowFlowListView &&
        <OrderFlowListDialog open={openShowFlowListView} viewDialogData={{ title: "Test" }} onClose={setOpenShowFlowListView} OrderDetail={flowStepListOrder} />
      }
      {viewOrderId && openViewOrder &&
        <ViewOrderDialog open={openViewOrder} onClose={setOpenViewOrder} OrderId={viewOrderId} />
      }

      {openGoodsDetailDialog &&
        <ViewOrdersGEDialog onClose={setOpenGoodsDetailDialog} open={openGoodsDetailDialog} OrderDetail={goodsDetailDialogData} />
      }
    </>
  );
};

export default ScrappedOrders;