/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
//import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import SalesReportTable from './components/SalesReportTable';
import { useHistory, useParams } from 'react-router-dom';
import { currentUser } from 'services/user-service';

const useStyles = makeStyles(componentStyles);

const DailyDetailGoodsEntryReport = () => {
  const classes = useStyles();
  const history = useHistory();
  const { month, year, day } = useParams();
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(year);

  const columns = [
    { id: 'GoodsEntryNumber', numeric: false, disablePadding: false, label: '#', dataType:'string'},
    { id: 'ReceiveDate', numeric: false, disablePadding: true, label: 'Receive Date', dataType:'date' },
    { id: 'CustomerName', numeric: false, disablePadding: true, label: 'Customer Name', dataType:'string' },
    { id: 'FileNames', numeric: false, disablePadding: true, label: 'File Name', dataType:'string' },
    {
      id: 'Sampler',
      numeric: false,
      disablePadding: false,  
      label: 'Sampler',
      dataType: 'string',
    },
    {
      id: 'Bundles',
      numeric: false,
      disablePadding: false,
      label: 'Bundles',
      dataType: 'string',
    },
    {
      id: 'Quantity',
      numeric: false,
      disablePadding: false,
      label: 'Quantity',
      dataType: 'string',
    } 
  ];

  // 
  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async() => {
    let curUser = currentUser();
    if (curUser?.UserId !== 1 && curUser?.UserId !== 3) {
      history.push(`/admin`);
      return false;
    }
    setIsLoading(true);
    let reqBody = generateQueryPostData();
    let res = await dataService('post', 'GoodsEntryReport', reqBody);
    setCompletedOrderList(res?.data?.dataReport);
    setIsLoading(false);
  }

  const viewAction = (rowData) => {
    history.push({
      pathname :  `/admin/report/detail-goods-entry-order-report/${rowData?.GoodsEntryId}`,
      state : {GoodsNumber : rowData?.GoodsEntryNumber}
    })
  };

  const generateQueryPostData = () => {
    let reqBody = {
      Year: year,
      Month: month,
      ReportType: 'DAILY',
      Day: day,
    };
    return reqBody;
  };

  const exportToPDF = async () => {
    const doc = new jsPDF();
    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    doc.text('Sales Order Report', 20, 10);
    autoTable(doc, {
      columnStyles: { europe: { halign: 'center' } }, // European countries centered
      columns: columns.map((col) => ({ ...col, dataKey: col.id })),
      body: completedOrderList,
    });
    doc.save();
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <SalesReportTable
          title={null}
          columns={columns}
          rows={completedOrderList}
          actions={[]}
          loading={isLoading}
          viewAction={viewAction}
          exportToPDF={exportToPDF}
          selectedYear={selectedYear}
          selectedMonth={month}
          setSelectedYear={setSelectedYear}
          reportType={'DAILY'}
          selectedDay={day}
          isNotAmount={true}
        />
      </Container>
    </>
  );
};

export default DailyDetailGoodsEntryReport;
