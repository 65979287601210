import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import EditIcon from '@mui/icons-material/Edit';
import {
  Chip,
  TextField,
  Typography
} from "@mui/material";
import { useHistory } from 'react-router-dom';
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles(componentStyles);

const HoldReasonList = () => {
  const classes = useStyles();
  const history = useHistory();

  const [holdReasonList, setHoldReasonList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isReload, setIsReload] = useState(true);
  const [workCenterId, setWorkCenterId] = useState(null)

  const columns = [
    { id: 'WorkCenterName', numeric: false, disablePadding: false, label: 'WorkCenter Name', dataType: 'string' },
    { id: 'ReasonOption', numeric: false, disablePadding: true, label: 'Reason Option', dataType: 'string' },
    {
      id: 'UserName', numeric: false, disablePadding: false, label: 'UserName', dataType: 'string', Component: ({ row }) => {
        return (
          <>
            <Typography>{row?.UserName ?? "-"}</Typography>
          </>
        )
      }
    },
    {
      id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType: 'string', Component: ({ row }) => {
        return (
          <>
            {row?.Status === "Active" ?
              <Chip className="font-bold bg-blue-500" color="primary" label={row?.Status} />
              :
              <Chip className="font-bold" color="default" label={row?.Status} />}
          </>
        )
      }
    },
  ];
  const [workCenterList, setWorkCenterList] = useState([]);

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload, workCenterId])

  const fetchData = async () => {
    setIsLoading(true);
    let res = await dataService('get', 'HoldReasonList', `workCenterId=${workCenterId ? workCenterId : 0}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
    setHoldReasonList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  useEffect(() => {
    getWorkcenterList();
  }, [])



  const handleEdit = (row) => {
    history.push(`update-hold-reason/${row.HoldReasonOptionId}`)
  };
  let actionList = [{ label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit }]

  const getWorkcenterList = async () => {
    let res = await dataService('get', 'WorkCenterList', `searchText=&sortBy=Name&sortOrder=asc&page=1&offset=100`);
    if (res.status === 200) {
      setWorkCenterList(res?.data);

    }
  }

  const HeaderComponent = () => {
    return (
      <Autocomplete
        id="tags-outlined"
        value={workCenterList?.find(c => Number(c.WorkCenterId) === Number(workCenterId))}
        options={workCenterList}
        onChange={(event, newValue) => {
          setWorkCenterId(newValue?.WorkCenterId)
          setPage(1)
        }}
        autoHighlight
        getOptionLabel={(option) => option.Name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select Work Center"
            autoComplete="new-password"
            sx={{
              '& .MuiOutlinedInput-input:not(:last-child)': {
                height: 35,
              },
            }}
          />
        )}
      />
    );
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Hold Reason List"
          component={<HeaderComponent />}
          columns={columns}
          rows={holdReasonList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          view="hold-reason"
        />
      </Container>

    </>
  );
};

export default HoldReasonList;