import { Avatar, Box, Button, Chip, CircularProgress, DialogContent, FilledInput, FormGroup, Grid, Menu, MenuItem, Paper, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { ChevronRight, Close } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { dataService } from 'services/data-service';
import { formatDistanceToNow } from 'date-fns';
import { currentUser } from "services/user-service";

function MyTaskDetailViewDialog({ isLoading, setIsLoading, onClose, open, setOpen, setIsReload, isReload }) {

    const handleClose = () => {
        onClose(false);
    };

    const commentsContainerRef = useRef(null);

    useEffect(() => {
        if (commentsContainerRef.current) {
            commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
    }, [open?.data?.Comments, open?.isVisible]);


    const [showSubmitLoading, setShowSubmitLoading] = useState(false);

    const rowColor = open?.extraData ? JSON.parse(open?.extraData?.RowColor) : null;

    const formik = useFormik({
        initialValues: {
            Comment: "",
            TaskCommentId: 0,
        },
        enableReinitialize: true,
        validationSchema:
            yup.object().shape({
                Comment: yup.string().required('Comment is required field'),
            }),
        onSubmit: async (values) => {
            setShowSubmitLoading(true);
            if (values?.TaskCommentId) {
                let res = await dataService('post', `TaskCommentSave/${open?.data?.TaskId}/${values?.TaskCommentId}`, { Comment: values?.Comment });
                setShowSubmitLoading(false);
                formik.resetForm()
                if (res && res.status === 200) {
                    setTimeout(async () => {
                        let taskRes = await dataService('get', `TaskGet/${open?.data?.TaskId}`, '');
                        if (taskRes.status === 200) {
                            let TaskData = taskRes?.data
                            if (TaskData) {
                                setOpen({ isVisible: true, data: TaskData, extraData: open?.extraData })
                            }
                        }
                    }, 500);
                }
            } else {
                let res = await dataService('post', `TaskCommentSave/${open?.data?.TaskId}/${0}`, { Comment: values?.Comment });
                setShowSubmitLoading(false);
                formik.resetForm()
                if (res && res.status === 200) {
                    setTimeout(async () => {
                        let taskRes = await dataService('get', `TaskGet/${open?.data?.TaskId}`, '');
                        if (taskRes.status === 200) {
                            let TaskData = taskRes?.data
                            if (TaskData) {
                                setOpen({ isVisible: true, data: TaskData, extraData: open?.extraData })
                            }
                        }
                    }, 500);
                }
            }
        }
    })

    function EnhancedStatusMenu(props) {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const { dataRow, index, handleStatusChange } = props;
        const rowColor = JSON.parse(dataRow?.RowColor);


        const handleClick = (event) => {
            if (dataRow?.Status === 'Assigned') {
                setAnchorEl(event.currentTarget);
            }

        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleUpdateStatus = (status) => {
            handleStatusChange(dataRow, status)
        };


        return (
            <Box key={index}>
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                        type="button"
                        className={`relative bg-${rowColor?.color}-${rowColor?.value} text-white inline-flex items-center px-4 py-1.5 rounded-l-md  text-sm font-medium hover:bg-${rowColor?.color}-${rowColor?.hoverValue}`}
                    >
                        {dataRow?.Status}
                    </button>
                    <button
                        type="button"
                        className={`relative bg-${rowColor?.color}-${rowColor?.value} text-white inline-flex items-center px-1 rounded-r-md border-l border-white text-sm font-medium hover:bg-${rowColor?.color}-${rowColor?.hoverValue}`}
                        onClick={handleClick}
                    >
                        <ChevronRight className='p-0 w-6 h-auto' />
                    </button>
                </span>
                {dataRow?.Status === 'Assigned' &&
                    <Menu
                        //id={`card-actions-menu-${index}`}
                        id={`simple-menu-${index}`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >

                        <MenuItem onClick={(e) => {
                            handleUpdateStatus('FiniShed')
                        }} className='border-b border-solid border-gray-300'>Finished</MenuItem>
                    </Menu>
                }
            </Box>
        )
    }

    const handleStatusChange = async (row, updateStatus) => {
        setIsLoading(true);
        let res = await dataService('post', `TaskStatusUpdate/${row.TaskId}`, { Status: updateStatus });
        setIsReload(!isReload);
        onClose()
    };


    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="simple-dialog-title" aria-describedby="alert-dialog-description" open={open?.isVisible}>
            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className='flex flex-row items-center gap-2'>
                    <span className="text-2xl">
                        {open?.data?.Title ? `#${open?.data?.Title?.split(" - ")[0]}` : "-"}
                    </span>
                    <EnhancedStatusMenu dataRow={open?.extraData} handleStatusChange={handleStatusChange} />
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
            </div>

            <DialogContent>
                <div className="bg-white overflow-hidden sm:rounded-lg">
                    <div>
                        <div className="col-span-4 pb-5 ">
                            <dt className="text-sm font-medium text-gray-500">Title</dt>
                            <dd className="text-sm text-black font-bold whitespace-pre-wrap">
                                {open?.data?.Title}
                            </dd>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Typography variant="subtitle2" color="textSecondary">Created At</Typography>
                                <Typography variant="body2" color="textPrimary">
                                    {moment(open?.data?.CreatedAt).format('LL')}
                                </Typography>
                            </Grid>
                            {open?.extraData &&
                                <Grid item xs={12} sm={6} lg={3}>
                                    <Typography variant="subtitle2" color="textSecondary">Due Date</Typography>
                                    <Typography variant="body2" color={`${rowColor?.color}.${rowColor?.value}`}>
                                        {open?.data?.DueDate ? moment(open?.data?.DueDate).format('LL') : '-'}
                                    </Typography>
                                </Grid>}
                            <Grid item xs={12} sm={6} lg={3}>
                                <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                                <Typography variant="body2" color="textPrimary">
                                    {open?.data?.Status}
                                </Typography>
                            </Grid>
                            {open?.extraData &&
                                <Grid item xs={12} sm={6} lg={3}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {open?.extraData?.DueInDays ? 'Due In' : 'Due Since'}
                                    </Typography>
                                    <Typography variant="body2" color="textPrimary">
                                        {open?.extraData?.DueDate !== null || open?.extraData?.DueSinceDays !== null ? (
                                            <Chip
                                                className="font-bold"
                                                color="error"
                                                label={
                                                    open?.extraData?.DueInDays !== null
                                                        ? `${open?.extraData?.DueInDays} Day`
                                                        : open?.extraData?.DueSinceDays !== null
                                                            ? `${open?.extraData?.DueSinceDays} Days`
                                                            : '-'
                                                }
                                            />
                                        ) : (
                                            "-"
                                        )}
                                    </Typography>
                                </Grid>}

                        </Grid>
                        <div className="col-span-4 py-5 -mt-3">
                            <dt className="text-sm font-medium text-gray-500">Description</dt>
                            <dd className="mt-1 text-sm font-bold text-black whitespace-pre-wrap">
                                {open?.data?.Description}
                            </dd>
                        </div>
                        <div className="col-span-4 py-5 -mt-3">
                            <dt className="text-sm font-medium text-gray-500">Assigned User Name</dt>
                            <dd className="mt-1 text-sm text-black font-semibold whitespace-pre-wrap">
                                {open?.data?.Name}
                            </dd>
                        </div>

                        <div>
                            <Typography variant="subtitle1" className='font-bold border-b border-b-gray-300' >Comments</Typography>
                            <div>
                                <Box ref={commentsContainerRef} sx={{ maxHeight: 250, overflowY: 'auto', p: "5px", overflowX: "hidden" }}>
                                    {open?.data?.Comments?.map((comment, index) => (
                                        <div key={index}>
                                            <Paper elevation={0} style={{ padding: '8px', marginBottom: '8px' }}>
                                                <Grid container spacing={1} alignItems="flex-start">
                                                    <Grid item>
                                                        <Avatar sx={{ width: 32, height: 32 }}>{comment?.CommentByName?.charAt(0)}</Avatar>
                                                    </Grid>
                                                    <Grid item xs sx={{ ml: "-10px" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                            <Box sx={(theme) => ({
                                                                display: "flex", flexDirection: "row", alignItems: "center", gap: 1,
                                                                [theme.breakpoints.down('sm')]: {
                                                                    flexDirection: 'column',
                                                                    alignItems: "flex-start",
                                                                    gap: 0
                                                                }
                                                            })}>
                                                                <Typography variant="body1" style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                                    {comment?.CommentByName} {Number(currentUser()?.UserId) === Number(comment.CommentBy) ?<Typography color="textSecondary">(You)</Typography> : null}
                                                                </Typography>
                                                                <Typography variant="caption" color="textSecondary" sx={theme => ({
                                                                    [theme.breakpoints.down('sm')]: {
                                                                        mt: -0.5
                                                                    }
                                                                })}>
                                                                    {formatDistanceToNow(new Date(comment?.CommentAt))} ago
                                                                </Typography>
                                                            </Box>
                                                            {/* {formik.values?.TaskCommentId === comment?.TaskCommentId ? (
                                                            <Box
                                                                aria-controls="simple-menu-1"
                                                                aria-haspopup="true"
                                                                size="small"
                                                                onClick={() => {
                                                                    formik.setFieldValue("Comment", "")
                                                                    formik.setFieldValue("TaskCommentId", 0)
                                                                }}
                                                                component={Button}
                                                                width="1.5rem!important"
                                                                height="1.5rem!important"
                                                                minWidth="1.5rem!important"
                                                                minHeight="1.5rem!important"
                                                                className={`px-0 py-0 bg-gray-100 mr-2 -mt-1`}
                                                            >
                                                                <Close color='error' />
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                aria-controls="simple-menu-1"
                                                                aria-haspopup="true"
                                                                size="small"
                                                                onClick={() => {
                                                                    formik.setFieldValue("Comment", comment?.Comment)
                                                                    formik.setFieldValue("TaskCommentId", comment?.TaskCommentId)
                                                                }}
                                                                component={Button}
                                                                width="1.5rem!important"
                                                                height="1.5rem!important"
                                                                minWidth="1.5rem!important"
                                                                minHeight="1.5rem!important"
                                                                className={`px-0 py-0 bg-gray-100 mr-2 -mt-1`}
                                                            >
                                                                <Edit />
                                                            </Box>
                                                        )} */}
                                                        </Box>
                                                        <Typography variant="body2" style={{ marginTop: '2px', width: "95%", whiteSpace: 'pre-line'  }}>
                                                            {comment?.Comment}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </div>
                                    ))}
                                </Box>

                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <TextField
                                                variant="filled"
                                                placeholder='comment'
                                                name="Comment"
                                                component={Box}
                                                multiline={true}
                                                rows={3}
                                                sx={{ color: "red" }}
                                                width="100%"
                                                marginBottom="1rem!important"
                                                value={formik.values.Comment}
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                error={formik.touched.Comment && formik.errors.Comment}
                                            >
                                                <Box
                                                    paddingLeft="0.75rem"
                                                    paddingRight="0.75rem"
                                                    component={FilledInput}
                                                    autoComplete="off"
                                                    type="text"
                                                />
                                            </TextField>
                                        </FormGroup>
                                    </Grid>

                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} mt={-2}>
                                        <Box
                                            justifyContent="flex-end"
                                            display="flex"
                                            flexWrap="wrap"
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                type="submit"
                                                disabled={showSubmitLoading}
                                                className="bg-[#5e72e4] border-[#5e72e4]"
                                            >
                                                {formik.values?.TaskCommentId ? "Update Comment" : "Add Comment"}
                                                {showSubmitLoading && <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </div>  
            </DialogContent>
        </Dialog >
    );
};
export default MyTaskDetailViewDialog;