import React from "react";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, MenuItem } from "@mui/material";

export function OrderShadeCard(props) {
    return (
        <>
        {/* Party Fabric Information Start */}
        <div className="border-2 border-yellow-300 rounded-md mb-6">
                    <div className="bg-yellow-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Party Fabric</h3>
                    </div>
                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormLabel>Full</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBFull"
                            value={props?.orderModel?.PBFull}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormLabel>Full Meter</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBFullMtr"
                            value={props?.orderModel?.PBFullMtr}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormLabel>Main</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBMain"
                            value={props?.orderModel?.PBMain}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormLabel>Main Meter</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBMainMtr"
                            value={props?.orderModel?.PBMainMtr}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PBRemarks"
                            value={props?.orderModel?.PBRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    </div>  
                  </div>
                  {/* Party Fabric Information End */}

                  
                  {/* Cutting Information Start */}
                  <div className="border-2 border-blue-300 rounded-md mb-6">

                    <div className="bg-blue-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Cutting</h3>
                    </div>

                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Small Piece Patta</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingSmallPiecePatta"
                            value={props?.orderModel?.CuttingSmallPiecePatta}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Small Piece Size</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingSmallPieceSize"
                            value={props?.orderModel?.CuttingSmallPieceSize}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Table</FormLabel>
                          <Select value={props?.orderModel?.CuttingTable} name="CuttingTable" onChange={e => props?.handleSelectChange('CuttingTable', e.target.value)}>
                            <MenuItem value={null}>Select</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                          </Select>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Filler Patta</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingFillerPatta"
                            value={props?.orderModel?.CuttingFillerPatta}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Filler Size</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingFillerSize"
                            value={props?.orderModel?.CuttingFillerSize}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormGroup>
                          <FormLabel>Total</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingTotal"
                            value={props?.orderModel?.CuttingTotal}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagZigZag}
                                onChange={props?.handleCheckBoxes}
                                name="TagZigZag"
                                color="primary"
                              />
                            }
                            label="ZigZag"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.TagInterlock}
                                onChange={props?.handleCheckBoxes}
                                name="TagInterlock"
                                color="primary"
                              />
                            }
                            label="Interlock"
                          />
                        </FormGroup>
                      </Grid>
                      </Grid>
                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="CuttingRemarks"
                            value={props?.orderModel?.CuttingRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    </div>
                  </div>
                  {/* Cutting Information End */}

                  {/* Binding Information Start */}
                  <div className="border-2 border-red-300 rounded-md mb-6">

                    <div className="bg-red-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Binding</h3>
                    </div>

                    <div className="p-2">

                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Back to Back</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="BindingsBackToBack"
                            value={props?.orderModel?.BindingsBackToBack}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Total</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="BindingsTotal"
                            value={props?.orderModel?.BindingsTotal}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsDieCut}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsDieCut"
                                color="primary"
                              />
                            }
                            label="Die Cut"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsFoil}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsFoil"
                                color="primary"
                              />
                            }
                            label="Foil"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsTagPrinted}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsTagPrinted"
                                color="primary"
                              />
                            }
                            label="Tag Printed"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsMDF}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsMDF"
                                color="primary"
                              />
                            }
                            label="MDF"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.BindingsCappaBoard}
                                onChange={props?.handleCheckBoxes}
                                name="BindingsCappaBoard"
                                color="primary"
                              />
                            }
                            label="Cappa Board"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>
                        <FormLabel>Remarks</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="BindingsRemarks"
                          value={props?.orderModel?.BindingsRemarks}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => props?.handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  </div>


                  </div>
                  {/* Binding Information End */}

                  {/* Photos Information Start */}
                  <div className="border-2 border-indigo-300 rounded-md mb-6">

                    <div className="bg-indigo-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Photo Making</h3>
                    </div>

                    <div className="p-2">
                    <Grid container>
                        <Grid item xs={12} lg={3}>
                            <FormGroup>
                            <FormLabel>Printer</FormLabel>
                            <TextField
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                                autoComplete="off"
                                name="PhotosPrinter"
                                value={props?.orderModel?.PhotosPrinter}
                                onChange={e => props?.handleChange(e)}
                            >
                                <Box
                                paddingLeft="0.75rem"
                                paddingRight="0.75rem"
                                component={FilledInput}
                                autoComplete="off"
                                type="text"
                                />
                            </TextField>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormLabel>Photo Paper GSM</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PhotosPaperGSM"
                            value={props?.orderModel?.PhotosPaperGSM}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                          <FormLabel>Photo Size</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PhotosSize"
                            value={props?.orderModel?.PhotosSize}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1}  className="pt-8">
                        <Typography className="font-semibold"> Page </Typography>
                      </Grid>
                      <Grid item xs={12} lg={1}  className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.PhotosPageArry?.includes(1)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={1}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="1"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                              checked={props?.orderModel?.PhotosPageArry?.includes(2)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={2}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="2"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                               checked={props?.orderModel?.PhotosPageArry?.includes(3)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={3}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="3"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1} className="pt-6">
                        <FormGroup>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={props?.orderModel?.PhotosPageArry?.includes(4)}
                                onChange={props?.handleMultiCheckBoxes}
                                value={4}
                                name="PhotosPage"
                                color="primary"
                              />
                            }
                            label="4"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={2}>
                        <FormGroup>
                            <FormControlLabel
                              className="ml-2"
                              control={
                                <Checkbox
                                  checked={props?.orderModel?.PhotosLamination}
                                  onChange={props?.handleCheckBoxes}
                                  name="PhotosLamination"
                                  color="primary"
                                />
                              }
                              label="Lamination"
                            />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1}>
                        <FormGroup>
                              <FormControlLabel
                                className="ml-2"
                                control={
                                  <Checkbox
                                    checked={props?.orderModel?.PhotosUV}
                                    onChange={props?.handleCheckBoxes}
                                    name="PhotosUV"
                                    color="primary"
                                  />
                                }
                                label="UV"
                              />
                          </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1}>
                        <FormGroup>
                              <FormControlLabel
                                className="ml-2"
                                control={
                                  <Checkbox
                                    checked={props?.orderModel?.PhotosMatt}
                                    onChange={props?.handleCheckBoxes}
                                    name="PhotosMatt"
                                    color="primary"
                                  />
                                }
                                label="Matt"
                              />
                          </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={1}>
                        <FormGroup>
                              <FormControlLabel
                                className="ml-2"
                                control={
                                  <Checkbox
                                    checked={props?.orderModel?.PhotosGlossy}
                                    onChange={props?.handleCheckBoxes}
                                    name="PhotosGlossy"
                                    color="primary"
                                  />
                                }
                                label="Glossy"
                              />
                          </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="PhotosRemarks"
                            value={props?.orderModel?.PhotosRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                    </Grid>
                  </Grid>
                  </div>


                  </div>
                  {/* Photos Information End */}

                  {/* Screen Printing Information Start */}
                  <div className="border-2 border-purple-300 rounded-md mb-6">


                    <div className="bg-purple-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Screen Printing</h3>
                    </div>


                    <div className="p-2">
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Gumming</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="ScreenGumming"
                            value={props?.orderModel?.ScreenGumming}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Total</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="ScreenTotal"
                            value={props?.orderModel?.ScreenTotal}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>
                        <FormLabel>Remarks</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ScreenRemarks"
                          value={props?.orderModel?.ScreenRemarks}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => props?.handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  </div>


                  </div>
                  {/* Screen Printing Information End */}

                  {/* Fitting Information Start */}
                  <div className="border-2 border-pink-300 rounded-md mb-6">
                    

                    <div className="bg-pink-300 mb-4 px-4 py-3 border-b sm:px-6">
                      <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Fittings</h3>
                    </div>


                    <div className="p-2">

                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Filler</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="FittingFiller"
                            value={props?.orderModel?.FittingFiller}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormGroup>
                          <FormLabel>Total</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="FittingTotal"
                            value={props?.orderModel?.FittingTotal}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <FormGroup>
                          <FormLabel>Remarks</FormLabel>
                          <TextField
                            variant="filled"
                            component={Box}
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="FittingRemarks"
                            value={props?.orderModel?.FittingRemarks}
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={e => props?.handleChange(e)}
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </FormGroup>
                    </Grid>
                  </Grid>
                  </div>


                  </div>
                  {/* Fitting Information End */}
        </>
    );
};