import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
import { useHistory } from 'react-router-dom';
import { currentUser } from 'services/user-service';
import {Delete as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon} from "@mui/icons-material"

import { updatePreviousLocations } from 'generic/store';
import { useDispatch, useSelector } from 'react-redux';
import ViewDataDialog from 'components/dialoges/ViewDataDialog';
import CommonTable from 'components/Tables/CommonTable';
import {  Typography } from '@mui/material';

const useStyles = makeStyles(componentStyles);

const CustomerDataReport = () => {
  const previousLocationState = useSelector(
    (state) => state?.previousLocationsStore?.previous_locations
  );
  const dispatch = useDispatch(); // to update store values

  const classes = useStyles();
  const [customerList, setCustomerList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [searchText, setSearchText] = useState(
    previousLocationState?.searchText || ''
  );
  const [page, setPage] = useState(previousLocationState?.page || 1);
  const [orderBy, setOrderByData] = useState(
    previousLocationState?.orderBy || 'Quantity'
  );
  const [order, setOrderData] = useState(
    previousLocationState?.order || 'desc'
  );
  const [offset, setOffset] = useState(previousLocationState?.offset || 10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewDialogData] = useState({
    labels: [],
    data: {},
  });
  const columns = [
    {
      id: 'Name',
      numeric: false,
      disablePadding: true,
      label: 'Customer Name',
      dataType: 'string',
      Component: ({ row }) => (
        <div>
          <Typography
            style={{
              cursor: row?.Quantity ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              if (row?.Quantity) {
                history.push(`/admin/report/customer-order/${row?.CustomerId}`);
              }
            }}
          >
            {row?.Name}
          </Typography>
        </div>
      ),
    },

    {
      id: 'Quantity',
      numeric: false,
      disablePadding: true,
      label: 'Quantity',
      dataType: 'string',
    },
    {
      id: 'TotalPrice',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
      dataType: 'currency',
    },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload]);

  const fetchData = async() => {
    dispatch(updatePreviousLocations(null));
    setIsLoading(true);
    let res = await dataService(
      'get',
      'CustomerDataList',
      `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`
    );
    setCustomerList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const handleEdit = (row) => {
    dispatch(
      updatePreviousLocations({
        view: 'customers',
        searchText: searchText,
        page: page,
        order: order,
        orderBy: orderBy,
        offset: offset,
      })
    );

    history.push(`update-customer/${row.CustomerId}`);
  };

  const viewAction = (row) => {
    history.push(`/admin/report/customer-order/${row?.CustomerId}`);
  };

  const handleDelete = async (row) => {
    let res = await dataService(
      'post',
      `CustomerDelete/${row.CustomerId}`,
      null
    );
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  let actionList = [
    {
      label: 'View',
      icon: VisibilityIcon,
      iconColor: 'purple-500',
      action: viewAction,
    },
    {
      label: 'Edit',
      icon: EditIcon,
      iconColor: 'blue-500',
      action: handleEdit,
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      iconColor: 'red-500',
      action: handleDelete,
      isConfirmAction: true,
    },
  ];

  // remove edit action if not super admin
  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter((ac) => {
      return ac.label !== 'Edit';
    });
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Customer List"
          columns={columns}
          rows={customerList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          searchText={searchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>
      <ViewDataDialog
        open={open}
        onClose={setOpen}
        viewDialogData={viewDialogData}
      />
    </>
  );
};

export default CustomerDataReport;

