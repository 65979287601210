/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';

import SalesReportTable from './components/SalesReportTable';
import { useHistory, useParams } from 'react-router-dom';
import { currentUser } from 'services/user-service';

const useStyles = makeStyles(componentStyles);

const DetailGoodsEntryOrderReport = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {  goodsEntryId } = useParams();
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      id: 'OrderNumber',
      numeric: false,
      disablePadding: true,
      label: 'Order Number',
      dataType: 'string',
    },
    {
      id: 'ProductName',
      numeric: false,
      disablePadding: true,
      label: 'Product Name',
      dataType: 'string',
    },
    {
      id: 'FileName',
      numeric: false,
      disablePadding: true,
      label: 'File Name',
      dataType: 'string',
    },
    {
      id: 'CustomerName',
      numeric: false,
      disablePadding: true,
      label: 'Customer Name',
      dataType: 'string',
    },
    {
      id: 'Client',
      numeric: false,
      disablePadding: true,
      label: 'Client',
      dataType: 'string',
    },
    {
      id: 'Quantity',
      numeric: false,
      disablePadding: true,
      label: 'Quantity',
      dataType: 'string',
    }
  ];

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async() => {
    let curUser = currentUser();
    if (curUser?.UserId !== 1 && curUser?.UserId !== 3) {
      history.push(`/admin`);
      return false;
    }
    setIsLoading(true);
    let reqBody = generateQueryPostData();
    let res = await dataService('post', 'GoodsEntryReport', reqBody);
    setCompletedOrderList(res?.data?.dataReport);
    setIsLoading(false);
  }

  const generateQueryPostData = () => {
    let reqBody = {
      ReportType: 'GoodsEntryOrder',
      GoodsEntryId: goodsEntryId
    };
    return reqBody;
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <SalesReportTable
          title={null}
          columns={columns}
          rows={completedOrderList}
          actions={[]}
          loading={isLoading}
          reportType={'GoodsEntryOrder'}
          selectedDay={10}
          isNotAmount={true}
          goodsEntryId={props?.location?.state?.GoodsNumber}
        />
      </Container>
    </>
  );
};

export default DetailGoodsEntryOrderReport;
