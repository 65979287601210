import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
//import TaskTable from "components/Tables/TaskTable";
import MyTaskTable from "components/Tables/MyTaskTable";
import { Button, ButtonGroup, CircularProgress, Grid } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material"
import MyTaskDetailViewDialog from "components/dialoges/MyTaskDetailViewDialog";

const useStyles = makeStyles(componentStyles);

const MyTaskList = () => {
  const classes = useStyles();
  const [taskList, setTaskList] = useState([]);
  const history = useHistory();
  const [searchStatus, setSearchStatus] = useState('Assigned');

  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(12);
  const [isReload, setIsReload] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isShowLoadMore, setIsShowLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [viewDetailDialog, setViewDetailDialog] = useState({
    isVisible: false,
    data: null,
    extraData : null
  });

  useEffect(() => {
    fetchData()
  }, [page, order, orderBy, offset, isReload, searchStatus])

  const fetchData = async () => {
    setIsLoading(true);
    let res = await dataService('get', 'TaskList', `IsMyTask=true&Status=${searchStatus}&searchText=${''}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
    let disTaskList = isLoadMore ? [...taskList, ...res?.data] : res?.data;
    setTaskList(disTaskList);
    setIsLoading(false);
    setIsShowLoadMore(disTaskList?.length !== res?.totalRecord);
    await dataService('post', 'TaskUserReadUpdate');
  }

  const handleStatusChange = async (row, updateStatus) => {
    setIsLoading(true);
    let res = await dataService('post', `TaskStatusUpdate/${row.TaskId}`, { Status: updateStatus });
    setIsReload(!isReload);
  };

  const searchStatusHandle = (selSearchStatus) => {
    setPage(1);
    setIsLoadMore(false)
    setSearchStatus(selSearchStatus)
  }

  const loadMoreHandle = () => {
    setPage(page + 1);
    setIsLoadMore(true)
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-7rem"
        classes={{ root: classes.containerRoot }}
      >

        <Grid item xs={12} sm={12} md={4}>
          <ButtonGroup variant="text" className="bg-white" aria-label="outlined button group">
            <Button className={`${searchStatus === '' ? "text-white" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"}`} variant={`${searchStatus === '' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('')}>All</Button>
            <Button className={`${searchStatus === 'Assigned' ? "text-white" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"}`} variant={`${searchStatus === 'Assigned' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Assigned')}>Assigned</Button>
            <Button className={`${searchStatus === 'Finished' ? "text-white" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"}`} variant={`${searchStatus === 'Finished' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Finished')}>Finished</Button>
            <Button className={`${searchStatus === 'Approved' ? "text-white" : "text-[#424242]"}`} variant={`${searchStatus === 'Approved' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Approved')}>Approved</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} sm={12} className="!mt-3">
          <MyTaskTable rows={taskList} handleStatusChange={handleStatusChange} setOpen={setViewDetailDialog} />
        </Grid>

        {isShowLoadMore &&
          <Grid item xs={12} sm={12} className="mt-3 text-center border-t border-gray-200 py-4">
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="relative bg-blue-500 text-white inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium hover:bg-blue-600"
                onClick={loadMoreHandle}
              >
                Load More
              </button>
              <button
                type="button"
                className="-ml-px relative bg-blue-500 text-white inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 text-sm font-medium hover:bg-blue-600"
                onClick={loadMoreHandle}
              >
                {!isLoading ? (<KeyboardArrowDownIcon className="w-3.5 h-3.5" />) : (<CircularProgress className="w-4 h-auto text-white" />)}
              </button>
            </span>
          </Grid>
        }
        {viewDetailDialog?.isVisible &&
          <MyTaskDetailViewDialog 
          isLoading={isLoading} 
          setIsLoading={setIsLoading}
          setIsReload={setIsReload} 
          isReload={isReload}
          open={viewDetailDialog} 
          setOpen={setViewDetailDialog} 
          onClose={() => {
            setViewDetailDialog({ data: null, isVisible: false, extraData : null})
          }} />}
      </Container>
    </>
  );
};

export default MyTaskList;