import React, { useEffect, useState } from 'react';
//import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import FormGroup from "@mui/material/FormGroup";

import Grid from "@mui/material/Grid";
import { checkEmptyValidation } from 'services/validation-service';
import { dataService } from 'services/data-service';
import MessageBar from "../MessageBar/MessageBar";
import { Close } from '@mui/icons-material';
import { CustomButton } from "components/Common/CustomButton";
import { FormHelperText, FormLabel } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function AddHoldReasonDialog(props) {
    const { open, onClose, Order, setIsReload, isReload } = props;
    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
    const [holdReasonOptionsList, setHoldReasonOptionsList] = useState([])

    const { WorkCenterId } = useParams();

    // Usef defined states
    const holdReasonModelInitialState = {
        HoldReason: Order?.FlowStepHoldReason || '',
        HoldReasonOptionId: Order?.HoldReasonOptionId || null,
        DueDate: Order?.DueDate || moment().add(2, 'days').format("YYYY-MM-DD")
    }
    const [holdReasonModel, setHoldReasonModel] = useState(holdReasonModelInitialState);

    // Usef defined states
    const validateModelInitialState = {
        HoldReasonData: { message: '', touched: false },
        IsValidationError: false
    }
    const [holdReasonValidateModel, setHoldReasonValidateModel] = useState(validateModelInitialState);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    const getHoldReasonOptions = async () => {
        let res = await dataService('get', 'CommonList', `Query=WorkCenterHoldReasonOptions&Param1=${WorkCenterId}`);
        if (res.status === 200) {
            setHoldReasonOptionsList(res?.data);
        }
    }

    useEffect(() => {
        getHoldReasonOptions()
    }, [])

    const handleClose = () => {
        onClose();
        setIsReload(!isReload)
    };

    const handleChange = (id, value) => {

        switch (id) {
            case 'DueDate':
                if (moment(value, "DD/MM/YYYY", true).isValid()) {
                    setHoldReasonModel({ ...holdReasonModel, DueDate: moment(value).format("YYYY-MM-DD") });
                }
                break;
            case 'HoldReason':
                setHoldReasonModel({ ...holdReasonModel, HoldReason: value });
                setHoldReasonValidateModel({ ...holdReasonValidateModel, HoldReasonData: { message: checkEmptyValidation(value, 'At least ond field'), touched: true } });
                break;
            case 'HoldReasonOptionId':
                setHoldReasonModel({ ...holdReasonModel, HoldReasonOptionId: value?.HoldReasonOptionId });
                setHoldReasonValidateModel({ ...holdReasonValidateModel, HoldReasonData: { message: checkEmptyValidation(value, 'At least ond field'), touched: true } });
                break;
        }
    }

    const handleSubmit = async () => {
        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let res = await dataService('post', `AddOrderHoldReason/${Order?.OrderId}/${Order?.FlowStepId}`, holdReasonModel);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                handleClose();
            }
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            HoldReasonData: { message: checkEmptyValidation((holdReasonModel.HoldReason || holdReasonModel.HoldReasonOptionId), 'At least ond field'), touched: true },
            IsValidationError: false
        }
        if (
            validateErrors.HoldReasonData.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        
        setHoldReasonValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <div className='bg-red-500 border-red-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between'>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div className="text-gray-100">
                            <span className="text-2xl">Add Hold Reason - {Order?.WorkCenterName}</span><br />
                        </div>
                        <div className="text-gray-100 flex flex-wrap justify-end">
                            <div className="mr-10">
                                <span className="text-base block mb-1">  <strong> Order #: </strong>{Order?.OrderNumber}</span>
                                <span className="text-xs flex flex-wrap justify-start items-center">
                                    <strong>Total Quantity: </strong>
                                    <span className="px-2 py-1 text-xs font-bold bg-white text-black rounded mr-3 ml-1"> {Order?.Quantity} </span>
                                    <strong>In Quantity: </strong>
                                    <span className="px-2 py-1 text-xs font-bold bg-white text-black rounded ml-1">{Order?.FlowStepQuantityIn}</span></span>
                            </div>
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>

            <DialogContent className="px-6 py-2">
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                        <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormGroup>
                            <FormLabel>Hold Reason Options</FormLabel>
                            {holdReasonOptionsList && holdReasonOptionsList?.length > 0 &&
                                <Autocomplete
                                    id="tags-outlined"
                                    value={holdReasonOptionsList?.find(c => Number(c.HoldReasonOptionId) === Number(holdReasonModel.HoldReasonOptionId))}
                                    defaultValue={holdReasonOptionsList?.find(c => Number(c.HoldReasonOptionId) === Number(holdReasonModel.HoldReasonOptionId))}
                                    options={holdReasonOptionsList}
                                    onChange={(event, newValue) => {
                                        handleChange('HoldReasonOptionId', newValue)
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.ReasonOption}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Hold Reason Option"
                                            autoComplete="new-password"
                                        />
                                    )}
                                />
                            }
                            {holdReasonOptionsList && holdReasonOptionsList?.length === 0 &&
                                <Autocomplete
                                    id="tags-outlined"
                                    value={holdReasonOptionsList?.find(c => Number(c.HoldReasonOptionId) === Number(holdReasonModel.HoldReasonOptionId))}
                                    defaultValue={holdReasonOptionsList?.find(c => Number(c.HoldReasonOptionId) === Number(holdReasonModel.HoldReasonOptionId))}
                                    options={holdReasonOptionsList}
                                    onChange={(event, newValue) => {
                                        handleChange('HoldReasonOptionId', newValue)
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.ReasonOption}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Hold Reason Option"
                                            autoComplete="new-password"
                                        />
                                    )}
                                />
                            }
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormGroup>
                            <FormLabel>Due Date</FormLabel>
                            <div className="bg-white custom-datepicker">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        minDate={new Date()}
                                        className="w-full"
                                        name="Date"
                                        format="dd/MM/yyyy"
                                        value={holdReasonModel.DueDate ? new Date(holdReasonModel.DueDate) : null}
                                        onChange={e => handleChange('DueDate', e)}
                                    />
                                </LocalizationProvider>
                            </div>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FormGroup className="mb-0">
                            <FormLabel>Hold Reason</FormLabel>
                            <TextField
                                variant="filled"
                                name="HoldReason"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                                autoComplete="off"
                                multiline
                                minRows={4}
                                maxRows={6}
                                value={holdReasonModel.HoldReason}
                                onChange={e => handleChange('HoldReason', e.target.value)}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FormHelperText className="text-red-500">{holdReasonValidateModel.HoldReasonData.message !== '' && holdReasonValidateModel.HoldReasonData.touched ? holdReasonValidateModel.HoldReasonData.message : ''}</FormHelperText>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Save" loading={showSubmitLoading} onClick={handleSubmit} />

            </DialogActions>
        </Dialog>
    );
}