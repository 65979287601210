import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';  
import { currentUser } from "services/user-service";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material"

const useStyles = makeStyles(componentStyles);

const Machines = () => {
  const classes = useStyles();
  const [machineList, setMachineList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();
  
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name', dataType:'string' },
    { id: 'WorkCenterName', numeric: false, disablePadding: false, label: 'WorkCenterName', dataType:'string' },
    { id: 'Default', numeric: false, disablePadding: false, label: 'Default', dataType:'string'},
    { id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Created At', dataType:'date' },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload]) 

  const fetchData = async() => {
    setIsLoading(true);
    let res = await dataService ('get', 'MachineList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
    setMachineList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const handleEdit = (row) => {
    history.push(`update-machine/${row.MachineId}`)
    
  };

  const handleDelete = async (row) => {
    let res = await dataService('post', `MachineDelete/${row.MachineId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };


  let actionList = [
    {label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit},
    {label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true}
  ]

  // remove edit action if not super admin
if(currentUser()?.Role !== 'SUPER_ADMIN'){
  actionList = actionList.filter(ac => {
    return ac.label !== 'Edit'
  })

  actionList = actionList.filter(ac => {
    return ac.label !== 'Delete'
  })
}
  

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        
          <CommonTable 
            view="machine"
            title="Machine List"
            columns={columns}
            rows={machineList}
            page={page - 1}
            total={totalRecord}
            pagedata={setPage}
            setSearchText={setSearchText}
            order={order}
            orderBy={orderBy}
            orderData={setOrderData}
            orderByData={setOrderByData}
            actions={actionList}
            setOffset={setOffset}
            offset={offset}
            loading={isLoading}

          />
        </Container>
    </>
  );
};

export default Machines;