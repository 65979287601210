import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, Save, Cancel as CancelIcon } from '@mui/icons-material';
import { CustomButton } from "components/Common/CustomButton";
import { Box, FilledInput, FormGroup, FormLabel, Grid, TextField } from '@mui/material';
import { checkEmptyValidation } from 'services/validation-service';

function ConfirmDialog(props) {
  const { onClose, open, message, type, handleConfirm, title, confirmBtnTitle, orderModel, setOrderModel } = props;

  const handleConfirmEvent = () => {
    if (!checkValidations()) {
      handleConfirm();
      handleClose();
    }
  };



  const validateModelInitialState = {
    Remarks: { message: '', touched: false },
    UnitPrice: { message: '', touched: false },
  }
  const [passwordValidateModel, setPasswordValidateModel] = useState(validateModelInitialState);


  const handleClose = () => {
    onClose();
    setOrderModel({
      Remarks: null,
      UnitPrice: null
    })
    setPasswordValidateModel(validateModelInitialState)
  };

  const checkValidations = () => {

    let validateErrors = {
      Remarks: { message: checkEmptyValidation(orderModel.Remarks === null ? '' : orderModel.Remarks, 'Remarks'), touched: true },
      UnitPrice: { message: checkEmptyValidation(orderModel.UnitPrice === null ? '' : orderModel.UnitPrice, 'Price'), touched: true }
    }
    if (
      validateErrors.Remarks.message !== '' ||
      validateErrors.UnitPrice.message !== ''
    ) {
      validateErrors.IsValidationError = true;
    }
    else {
      validateErrors.IsValidationError = false;
    }
    setPasswordValidateModel(validateErrors);
    return validateErrors.IsValidationError;

  }

  const handleChange = (evt) => {
    setOrderModel({ ...orderModel, [evt.target.name]: evt.target.value });
    if (evt.target.name === "Remarks") {
      setPasswordValidateModel({ ...passwordValidateModel, Remarks: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
    }
    if (evt.target.name === "UnitPrice") {
      setPasswordValidateModel({ ...passwordValidateModel, UnitPrice: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
    }
  }


  return (
    <Dialog
      open={open}
      disableBackdropClick
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={`bg-blue-700 border-blue-700 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
        <DialogTitle id="form-dialog-title" className="px-4 py-4 text-xl">
          <div className="text-gray-100 text-2xl">
            {title}
          </div>
        </DialogTitle>
        <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
      </div>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
         

          <Grid container>
            <Grid item xs={12} lg={12}>
              <div className="border-2 border-blue-500 rounded-md mb-6">
                <div className="bg-blue-500 mb-4 px-4 py-3 border-b sm:px-6">
                  <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Remarks</h3>
                </div>
                <div className="p-2">
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>

                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="Remarks"
                          value={orderModel?.Remarks}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => handleChange(e)}
                          error={passwordValidateModel.Remarks.message !== '' && passwordValidateModel.Remarks.touched}
                          helperText={passwordValidateModel.Remarks.message !== '' && passwordValidateModel.Remarks.touched ? passwordValidateModel.Remarks.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>


              </div>
            </Grid>
            <Grid item xs={12} lg={12}>
              <div className="border-2 border-purple-500 rounded-md mb-6">
                <div className="bg-purple-500 mb-4 px-4 py-3 border-b sm:px-6">
                  <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                </div>


                <div className="p-2">
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>
                        <FormLabel>Price</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="UnitPrice"
                          type="number"
                          value={orderModel?.UnitPrice}
                          onChange={e => handleChange(e)}
                          error={passwordValidateModel.UnitPrice.message !== '' && passwordValidateModel.UnitPrice.touched}
                          helperText={passwordValidateModel.UnitPrice.message !== '' && passwordValidateModel.UnitPrice.touched ? passwordValidateModel.UnitPrice.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="number"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>


              </div>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="justify-center">
        <CustomButton className='bg-white text-black  border-none' size="none" label="Cancel " onClick={handleClose} icon={CancelIcon} iconClassName="w-5 h-auto" />
        <CustomButton className='bg-blue-700 text-white border-none'  size="none" label={confirmBtnTitle || 'Delete '} onClick={handleConfirmEvent} icon={Save} iconClassName="w-5 h-auto" />
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;