import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
//import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
// @mui/icons-material components


import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation} from "services/validation-service";
import { Divider, FormControlLabel, FormHelperText, MenuItem } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
//import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { Autocomplete } from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { CustomButton } from "components/Common/CustomButton";
import ChangePasswordDialog from "components/dialoges/ChangePasswordDialog";
//import roles from "services/roles";

const useStyles = makeStyles(componentStyles);

function CustomerProfile() {

  //Use properties
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  //Then inside your component
  const { id } = useParams();
  const PageTitle = id ? 'Update Customer' : 'Add Customer';

  // Usef defined states
  const [customerModel, setCustomerModel] = useState({
    CustomerId : id ? id : 0,
    Name: '',
    ContactName: '',
    PhoneNumber: '',
    Email: '',
    Type: '',
    Client: '',
    GSTNumber: '',
    Status: '',
    BillingContactPerson: '',
    BillingContactEmail: '',
    BillingContactPhoneNumber: '',
    BillingAddress: '',
    BillingCity: '',
    BillingState: '',
    BillingZipCode: '',
    BillingCountryId: 103,
    ShippingContactPerson: '',
    ShippingContactEmail: '',
    ShippingContactPhoneNumber: '',
    ShippingAddress: '',
    ShippingCity: '',
    ShippingState: '',
    ShippingZipCode: '',
    ShippingCountryId: 103,
    Password : '',
    UpdatedBy : 1
  });

  // Usef defined states
  const [customerValidateModel, setCustomerValidateModel] = useState({

    Name: { message: '', touched: false },
    Type: { message: '', touched: false },
    Status: { message: '', touched: false },
    IsValidationError: false
  });

  const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
  const [shippingSameASBilling, setShippingSameASBilling] = useState(false); // loading page state value
  const [countryList, setCountryList] = useState([]);

  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });

  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false); // loading page state value


  useEffect(() => {
    fetchData()
    if (id) {
      getCustomerData();
    }
  }, [id])

  const fetchData = async () => {
    await getCountryList();
  }

  const getCountryList = async () => {
    let res = await dataService('get', 'CommonList', `Query=countries`);
    if (res.status === 200) {
      if (res?.data?.length > 0) {
        let mList = res?.data //reduce((acc, e) => { acc.push({ CountryId: e.CountryId, WorkCenterId: e.WorkCenterId, WorkCenterName: e.WorkCenterName, Name: e.Name, Default: e.Default }); return acc }, []);
        

        //let sC = countryList?.find(c => c.CountryId === (customerModel.CountryId  ? customerModel.CountryId : 103))
        setCountryList(mList);
      }
    }
  }


  const getCustomerData = async () => {
    let res = await dataService('get', `CustomerGet/${id}`, '');
    if (res.status === 200) {
      const CustomerData = res?.data;

      if (CustomerData) {
        setCustomerModel({
          ...customerModel,
          Name: CustomerData?.Name,
          ContactName: CustomerData?.ContactName,
          PhoneNumber: CustomerData?.PhoneNumber,
          Email: CustomerData?.Email,
          Type: CustomerData?.Type,
          Client: CustomerData?.Client,
          GSTNumber: CustomerData?.GSTNumber,
          Status: CustomerData?.Status,
          BillingContactPerson: CustomerData?.BillingContactPerson,
          BillingContactEmail: CustomerData?.BillingContactEmail,
          BillingContactPhoneNumber: CustomerData?.BillingContactPhoneNumber,
          BillingAddress: CustomerData?.BillingAddress,
          BillingCity: CustomerData?.BillingCity,
          BillingState: CustomerData?.BillingState,
          BillingZipCode: CustomerData?.BillingZipCode,
          BillingCountryId: CustomerData?.BillingCountryId,
          ShippingContactPerson: CustomerData?.ShippingContactPerson,
          ShippingContactEmail: CustomerData?.ShippingContactEmail,
          ShippingContactPhoneNumber: CustomerData?.ShippingContactPhoneNumber,
          ShippingAddress: CustomerData?.ShippingAddress,
          ShippingCity: CustomerData?.ShippingCity,
          ShippingState: CustomerData?.ShippingState,
          ShippingZipCode: CustomerData?.ShippingZipCode,
          ShippingCountryId: CustomerData?.ShippingCountryId,
        });
      }

    }
  }

  const handleChange = (evt, type, checkValidation) => {

    
    setCustomerModel({ ...customerModel, [evt.target.name]: evt.target.value });
    if (checkValidation) {
      setCustomerValidateModel({ ...customerValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
    }
  };

  
  const handleCountryChange = (id, value) => {
    switch (id) {
      case 'BillingCountryId':
        //setCustomerModel({ ...customerModel, BillingCountryId: countryList?.find(c => { return c.CountryId === value.CountryId })});
        setCustomerModel({ ...customerModel, BillingCountryId: value?.CountryId || 103});
      break;
      case 'ShippingCountryId':
        setCustomerModel({ ...customerModel, ShippingCountryId: value?.CountryId || 103});
      break;
    }
  }

  
  const handleshippingSameASBilling = (evt) => {
    setShippingSameASBilling(evt.target.checked)
    if(evt.target.checked){
      setCustomerModel({
        ...customerModel,
        ShippingContactPerson: customerModel?.BillingContactPerson,
        ShippingContactEmail: customerModel?.BillingContactEmail,
        ShippingContactPhoneNumber: customerModel?.BillingContactPhoneNumber,
        ShippingAddress: customerModel?.BillingAddress,
        ShippingCity: customerModel?.BillingCity,
        ShippingState: customerModel?.BillingState,
        ShippingZipCode: customerModel?.BillingZipCode,
        ShippingCountryId: customerModel?.BillingCountryId
      })
    }
  }

  const handleSubmitCustomer = async () => {

    
    //return false;
    if (!checkValidations()) {

      setShowSubmitLoading(true);
      let reqData = { ...customerModel };
      

      let res = await dataService('post', `CustomerSave`, reqData);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      setShowSubmitLoading(false);
      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialoge({ show: true, type: 'success', message: id ? 'Customer updated successfully!' : 'Customer registered successfully!' });
        if (!id) {
          setTimeout(() => {
            history.push('customers')
          }, 500);
        }

      } else {
        //setOpen(res.message);
        setMessageDialoge({ show: true, type: 'error', message: res.message });
      }
    }
  }


  const checkValidations = () => {

    let validateErrors = {
      Name: { message: checkEmptyValidation(customerModel.Name, 'Name'), touched: true },
      Type: { message: checkEmptyValidation(customerModel.Type, 'Type'), touched: true },
      Status: { message: checkEmptyValidation(customerModel.Status, 'Status'), touched: true }
    }
    if (validateErrors.Name.message !== '' ||
      validateErrors.Type.message !== '' ||
      validateErrors.Status.message !== '') {

      validateErrors.IsValidationError = true;

    }
    else {
      validateErrors.IsValidationError = false;
    }

    
    setCustomerValidateModel(validateErrors);
    return validateErrors.IsValidationError;

  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      // paddingTop="10rem"
      // paddingBottom="3rem"
      >
        <Grid container >
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {PageTitle}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                </Grid>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  Customer Information
                </Box>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          name="Name"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={customerModel.Name}
                          onChange={e => handleChange(e, 'text', true)}
                          error={customerValidateModel.Name.message !== '' && customerValidateModel.Name.touched}
                          helperText={customerValidateModel.Name.message !== '' && customerValidateModel.Name.touched ? customerValidateModel.Name.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Type</FormLabel>
                        <RadioGroup row aria-label="position" name="Type" onChange={handleChange} value={customerModel.Type} >
                          <FormControlLabel value="CUSTOMER" control={<Radio color="primary" />} label="Customer" />
                          <FormControlLabel value="SUPPLIER" control={<Radio color="primary" />} label="Supplier" />
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Client</FormLabel>
                        <TextField
                          variant="filled"
                          name="Client"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={customerModel.Client}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>GST Number</FormLabel>
                        <TextField
                          variant="filled"
                          name="GSTNumber"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={customerModel.GSTNumber}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Box
                    component={Divider}
                    marginBottom="1.5rem!important"
                    marginTop="1.5rem!important"
                  />
                  <Box
                    component={Typography}
                    variant="h6"
                    color={theme.palette.gray[600] + "!important"}
                    paddingTop=".25rem"
                    paddingBottom=".25rem"
                    fontSize=".75rem!important"
                    letterSpacing=".04em"
                    marginBottom="1.5rem!important"
                    classes={{ root: classes.typographyRootH6 }}
                  >
                    Contact Information
                  </Box>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ContactName"
                          value={customerModel.ContactName}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="Email"
                          value={customerModel.Email}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="email"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Phone Number</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          name="PhoneNumber"
                          value={customerModel.PhoneNumber}
                          autoComplete="off"
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Box
                    component={Divider}
                    marginBottom="1.5rem!important"
                    marginTop="1.5rem!important"
                  />
                  <Box
                    component={Typography}
                    variant="h6"
                    color={theme.palette.gray[600] + "!important"}
                    paddingTop=".25rem"
                    paddingBottom=".25rem"
                    fontSize=".75rem!important"
                    letterSpacing=".04em"
                    marginBottom="1.5rem!important"
                    classes={{ root: classes.typographyRootH6 }}
                  >
                    Billing Address
                  </Box>

                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="BillingContactPerson"
                          value={customerModel.BillingContactPerson}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="BillingContactEmail"
                          value={customerModel.BillingContactEmail}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="email"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Phone Number</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          name="BillingContactPhoneNumber"
                          value={customerModel.BillingContactPhoneNumber}
                          autoComplete="off"
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Address</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="BillingAddress"
                          value={customerModel.BillingAddress}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>City</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          name="BillingCity"
                          value={customerModel.BillingCity}
                          autoComplete="off"
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Zip Code</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="BillingZipCode"
                          value={customerModel.BillingZipCode}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>State</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={customerModel.BillingState}
                          onChange={e => handleChange(e)}
                          name="BillingState"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="email"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Country</FormLabel>

                        {countryList?.length > 0 &&
                          <Autocomplete
                          id="tags-outlined"
                          value={countryList?.find(c => Number(c.CountryId) === Number(customerModel.BillingCountryId))}
                          defaultValue={countryList?.find(c => Number(c.CountryId) === Number(customerModel.BillingCountryId))}
                          options={countryList}
                          onChange={(event, newValue) => {
                            handleCountryChange('BillingCountryId', newValue)
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.Name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Country"
                              autoComplete="new-password"
                            />
                          )}
                        />
                        }
                      </FormGroup>
                    </Grid>
                  </Grid>

                  


                  <Box
                    component={Divider}
                    marginBottom="1.5rem!important"
                    marginTop="1.5rem!important"
                  />
                  <Box
                    component={Typography}
                    variant="h6"
                    color={theme.palette.gray[600] + "!important"}
                    paddingTop=".25rem"
                    paddingBottom=".25rem"
                    fontSize=".75rem!important"
                    letterSpacing=".04em"
                    marginBottom="1.5rem!important"
                    classes={{ root: classes.typographyRootH6 }}
                  >
                    <div>
                      <span>Shipping Address</span>
                      <FormControlLabel
                      className="ml-2"
                    control={
                      <Checkbox
                        checked={shippingSameASBilling}
                        onChange={handleshippingSameASBilling}
                        name="shippingSameASBilling"
                        color="primary"
                      />
                    }
                    label="Same as billing?"
                  />
                    </div>
                  </Box>

                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ShippingContactPerson"
                          value={customerModel.ShippingContactPerson}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ShippingContactEmail"
                          value={customerModel.ShippingContactEmail}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="email"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Phone Number</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          name="ShippingContactPhoneNumber"
                          value={customerModel.ShippingContactPhoneNumber}
                          autoComplete="off"
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Address</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ShippingAddress"
                          value={customerModel.ShippingAddress}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>City</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          name="ShippingCity"
                          value={customerModel.ShippingCity}
                          autoComplete="off"
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Zip Code</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ShippingZipCode"
                          value={customerModel.ShippingZipCode}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>State</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="ShippingState"
                          value={customerModel.ShippingState}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="email"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Country</FormLabel>
                        {countryList?.length > 0 &&
                          <Autocomplete
                          id="tags-outlined"
                          value={countryList?.find(c => Number(c.CountryId) === Number(customerModel.ShippingCountryId))}
                          defaultValue={countryList?.find(c => Number(c.CountryId) === Number(customerModel.ShippingCountryId))}
                          //value={countryList?.find(c => { return c.CountryId === customerModel.CountryId ? customerModel.CountryId : 103 })}
                          options={countryList}
                          onChange={(event, newValue) => {
                            handleCountryChange('ShippingCountryId', newValue)
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.Name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Country"
                              autoComplete="new-password"
                            />
                          )}
                        />
                        }
                        
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={4}>
                    <FormGroup>
                        <FormLabel>Status</FormLabel>
                        <Select value={customerModel.Status} name="Status" onChange={e => handleChange(e)}>
                          <MenuItem value={'Active'}>Active</MenuItem>
                          <MenuItem value={'InActive'}>In Active</MenuItem>
                        </Select>
                      </FormGroup>
                      <FormHelperText>{customerValidateModel.Status.message !== '' && customerValidateModel.Status.touched ? customerValidateModel.Status.message : ''}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Password</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          name="Password"
                          value={customerModel.Password}
                          onChange={e => handleChange(e)}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            placeholder="test@rajsampler.com"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitCustomer} />
                      </Box>
                    </Grid>
                  </Grid>
                </div>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {id && openChangePasswordDialog &&
        <ChangePasswordDialog title="Change Password" open={openChangePasswordDialog} setOpen={setOpenChangePasswordDialog} CustomerId={id} customerType="other" />
      }
    </>
  );
}

export default CustomerProfile;
