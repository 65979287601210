import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { DialogActions, FormLabel } from '@mui/material';
import { CustomButton } from "components/Common/CustomButton";

import { Grid,  FormGroup } from '@mui/material';
import ImageUpload from './ImageUpload';
import { dataService } from 'services/data-service';

import AWS from 'aws-sdk';

function UploadCatalougeLogo({ onClose, open, afterAPICall }) {
    const [showSubmitLoading, setShowSubmitLoading] = useState(false)

    const [document, setDocument] = useState({
        CatalogueLogo: open?.logoData?.LogoPath ? [{
            name: open?.logoData?.LogoName,
            path: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.logoData?.LogoPath}${open?.logoData?.LogoName}`,
            type: open?.logoData?.Extention?.split(".")[open?.logoData?.Extention?.split(".")?.length - 1],
            preview: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.logoData?.LogoPath}${open?.logoData?.LogoName}`,
            isUpdate: true
        }] : null
    });


    const [errors, setErrors] = useState({});

    const handleClose = () => {
        onClose();
    };

     const handleSubmit = async () => {

        const errors = {};

        if (!document.CatalogueLogo) {
            errors['CatalogueLogo'] = 'Catalogue logo is required';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setShowSubmitLoading(true);
        try {

            const { REACT_APP_S3_BUCKET_NAME, REACT_APP_S3_REGION, REACT_APP_S3_ACCESS_KEY, REACT_APP_S3_SECRET_ACCESS_KEY } = process.env;
            AWS.config.update({ accessKeyId: REACT_APP_S3_ACCESS_KEY, secretAccessKey: REACT_APP_S3_SECRET_ACCESS_KEY });

            const s3 = new AWS.S3({ params: { Bucket: REACT_APP_S3_BUCKET_NAME }, region: REACT_APP_S3_REGION });
            const randomNumber = Math.floor(100 + Math.random() * 900);

            const uploadToS3 = (file, key, contentType) => {
                return s3.putObject({
                    Bucket: REACT_APP_S3_BUCKET_NAME,
                    Key: key,
                    Body: file,
                    ACL: "public-read",
                    ContentType: contentType,
                    ContentDisposition: undefined
                }).on("httpUploadProgress", (evt) => {
                }).promise();
            };

            const imageExtension = document.CatalogueLogo[0].name.split('.').pop();
            const logoPath = `catalogue/cat-${open?.data?.CatalogueId || 0}/`;
          

            await Promise.all([
                uploadToS3(document.CatalogueLogo[0], `${logoPath}catalogue_logo_${randomNumber}.${imageExtension}`, `image/${imageExtension}`)
            ]);


            const payload = {
                LogoName: `catalogue_logo_${randomNumber}.${imageExtension}`,
                LogoPath: logoPath,
                Extention: imageExtension,
                Size: `${(document.CatalogueLogo[0].size / (1024 * 1024)).toFixed(2)} MB`,
            };
    
            const formData = new FormData();
            Object.keys(payload).forEach(key => formData.append(key, payload[key]));
    
            const res = await dataService('post', `CatalogueLogoUpload/${open?.data?.CatalogueId}`, formData);
    
            if (res && (res.status === 200 || res.status === 304)) {
                onClose();
                afterAPICall();
            }
        } catch (err) {
            console.error("Error uploading file:", err);
        } finally {
            setShowSubmitLoading(false);
        }
      
    };
    

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={open.isVisible}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-3 pb-2 text-xl">
                    <div className="text-2xl">
                        Catalogue Logo
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-800 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                <div className="mt-5">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormGroup className='mb-0'>
                                <div className="flex flex-wrap items-center mb-2">
                                    <FormLabel className="mr-3 mb-0">Logo</FormLabel>
                                </div>
                                <ImageUpload
                                    setDocument={setDocument}
                                    document={document}
                                    name="CatalogueLogo"
                                    submit={() => { }}
                                    accept={{
                                        'image/*': []
                                    }}
                                    displayPreview={false}
                                    displayUploadButton={false}
                                    setErrors={setErrors}
                                    error={errors}
                                />
                                {errors['CatalogueLogo'] && (
                                    <div className="text-red-500 text-xs mt-1">
                                        {errors['CatalogueLogo']}
                                    </div>
                                )}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className='border-t border-gray-200'>
                <CustomButton disabled={true} autoFocus className='bg-green-500 text-white border-none mx-1 text-base' size="none" label={'Upload'} onClick={handleSubmit} iconClassName="w-5 h-auto" loading={showSubmitLoading} />
                <CustomButton autoFocus className='bg-red-500 text-white border-none mx-1 text-base' size="none" label={`Cancel `} onClick={handleClose} iconClassName="w-5 h-auto" />
            </DialogActions>
        </Dialog>
    );
};
export default UploadCatalougeLogo;