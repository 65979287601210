import React from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { useHistory } from 'react-router-dom';  

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// @mui/icons-material components


// core components
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";

import componentStyles from "assets/theme/components/admin-navbar.js";
import { KeyboardBackspace } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Hidden } from "@mui/material";

const useStyles = makeStyles(componentStyles);

export default function AdminNavbar({ brandText }) {
  const classes = useStyles();
  const history = useHistory();
  const previousLocationState = useSelector(state => state?.previousLocationsStore?.previous_locations);
  return (
    <>
      <AppBar
        position="absolute"
        color="transparent"
        elevation={0}
        classes={{ root: classes.appBarRoot }}
      >
        <Toolbar disableGutters>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              marginTop="0.5rem"
            >
              <div>
                {previousLocationState && <KeyboardBackspace className="text-white text-xxl w-10 h-10 mr-3 cursor-pointer" fontSize="large" onClick={() => {history?.goBack()}} />  }
                <Typography
                  className={classes.brandTitle}
                  variant="h4"
                  component="a"
                >
                  {brandText}
                </Typography>
              
              </div>
              <Hidden mdDown implementation="css">
              <Box display="flex" alignItems="center" width="auto">
                <NavbarDropdown />
              </Box>
              </Hidden>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
