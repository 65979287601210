import React, { useEffect, useState } from "react";
import { Checkbox, DialogContent, FormControlLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
//import CircularProgress from '@mui/material/CircularProgress';
//import moment from "moment";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
//import Button from "@mui/material/Button";
//import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
//import FilledInput from "@mui/material/FilledInput";
import MessageBar from "components/MessageBar/MessageBar";
import { FormHelperText } from "@mui/material";
import { CustomButton } from "components/Common/CustomButton";
import { Autocomplete } from "@mui/material";
//import { currentUser } from "services/user-service";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function MapOrderDialog(props) {
    const { onClose, open, MappingData, setIsReload, isReload, from, mapTo } = props;
    const [orderList, setOrderList] = useState([]);
    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });
    // Usef defined states

    const [mapOrderValidateModel, setMapOrderValidateModel] = useState({
        Order: { message: '', touched: false },
        IsValidationError: false
    });

    const setMapOrderModelData = (flowData) => {
        let mapOrderModel = {
            Order: [],
            OrderId: null,
            Status: false
        }
        return mapOrderModel;
    }

    const [mapOrderModel, setMapOrderModel] = useState(setMapOrderModelData(null));

    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
        fetchData()
    }, [MappingData, mapOrderModel.Status])

    const fetchData = async () => {
        let reqMethod = '';
        if (from === 'orders') {
            reqMethod = `GoodsEntryOrdersForMappingList/${MappingData?.OrderId}/0/${MappingData?.CustomerId}`
        }
        else {
            reqMethod = `GoodsEntryOrdersForMappingList/0/${MappingData?.GoodsEntryId}/${MappingData?.CustomerId}`
        }
        let res = await dataService('get', reqMethod, `FromType=${from}&Status=${mapOrderModel.Status ? 'All' : 'InProgress'}`);

        console.log('res?.data: ', res?.data)
        setOrderList(res?.data);

    }

    const handleChange = (evt, type, checkValidation) => {
        setMapOrderModel({ ...mapOrderModel, [evt.target.name]: evt.target.value });
        if (checkValidation) {
            setMapOrderValidateModel({ ...mapOrderValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
        }
    };

    const handleCheckBoxes = (evt) => {

        setMapOrderModel({ ...mapOrderModel, [evt.target.name]: evt.target.checked });
        console.log('mapOrderModel: ', mapOrderModel, evt.target.name, evt.target.checked)
    }

    const handleSelectChange = (value) => {
        console.log('value: ', value)

        // let mapOrdrs = [...mapOrderModel.Order];
        // mapOrdrs.push(value)
        setMapOrderModel({ ...mapOrderModel, Order: value });
    }

    const handleSubmitOrder = async () => {
        let reqData = {
            MappingList: mapOrderModel?.Order
        };
        console.log('reqData: ', reqData)
        if (!checkValidations()) {
            setShowSubmitLoading(true);

            let res = await dataService('post', `GoodsEntryOrdersMapping`, reqData);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: 'Order mapped successfully!' });
                setIsReload(!isReload)
                handleClose();

            } else {
                //setOpen(res.message);
                setMessageDialoge({ show: true, type: 'error', message: res?.message || 'Error occured please try again later' });
            }
        }

    }


    const checkValidations = () => {

        let validateErrors = {
            Order: { message: checkEmptyValidation(mapOrderModel.Order?.length === 0 ? null : 'Passed', from), touched: true },
        }
        if (validateErrors.Order.message !== '') {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }

        setMapOrderValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const icon = <CheckBoxOutlineBlankIcon color="primary" />;
    const checkedIcon = <CheckBoxIcon color="primary" />;

    return (
        <Dialog className="" onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="simple-dialog-title" disableBackdropClick={true} open={open}>
            <div className={`bg-blue-600 border-blue-600 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div className="text-gray-100">
                            <span className="text-2xl">Map {mapTo}</span><br />
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>

            <DialogContent className={`border-blue-600 border-2`}>

                <div className="overflow-hidden">
                    <div>
                        <dl>
                            <Grid item xs={12} lg={12}>
                                <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />

                            </Grid>
                            <div className="">
                                <div className="bg-white">
                                    <Grid container direction='row' alignItems='flex-center'>
                                        <Grid item xs={8} lg={8}>
                                            <FormGroup>
                                                <FormLabel className="capitalize">{mapTo}</FormLabel>
                                                <Autocomplete
                                                    multiple
                                                    debug={true}
                                                    id="checkboxes-tags-demo"
                                                    options={orderList}
                                                    onChange={(event, newValue) => {
                                                        console.log('event.target.value: ', event.target.value, newValue)
                                                        handleSelectChange(newValue)
                                                    }}
                                                    disableClearable
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => from === 'orders' ? option.DisplayGoodsEntry : option.DisplayOrderNumber}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={selected} />}
                                                                label={from === 'orders' ? option.DisplayGoodsEntry : option.DisplayOrderNumber}
                                                            />
                                                        </li>
                                                        // <React.Fragment>
                                                        //     <Checkbox
                                                        //         icon={icon}
                                                        //         checkedIcon={checkedIcon}

                                                        //         checked={selected}
                                                        //     />
                                                        //     {from === 'orders' ? option.DisplayGoodsEntry : option.DisplayOrderNumber}
                                                        // </React.Fragment>
                                                    )}

                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" placeholder={mapTo} />
                                                    )}
                                                />
                                                {/* {orderList?.length > 0 ?(
                                                

                                                
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={mapOrderModel?.Order}
                                                    onChange={(event) => {
                                                        handleSelectChange(event.target.value)
                                                    }}
                                                    input={<Input />}
                                                    renderValue={(selected) => (
                                                        
                                                        <div>
                                                          {selected.map((value) => (
                                                            <Chip key={from === 'orders' ? value.DisplayGoodsEntry : value.DisplayOrderNumber} label={from === 'orders' ? value.DisplayGoodsEntry : value.DisplayOrderNumber} />
                                                          ))}
                                                        </div>
                                                      )}
                                                    MenuProps={MenuProps}
                                                    >
                                                        
                                                    {orderList.map((orderData) => (
                                                        
                                                        <MenuItem key={from === 'orders' ? orderData.DisplayGoodsEntry : orderData.DisplayOrderNumber} value={orderData}>
                                                        <Checkbox color="primary" checked={mapOrderModel?.Order?.find(v => {return v === orderData}) ? true : false}
                                                            />
                                                        <ListItemText primary={from === 'orders' ? orderData.DisplayGoodsEntry : orderData.DisplayOrderNumber} />
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                                
                                                ) : (
                                                    <FormHelperText className="text-yellow-500">Orders Not found to map.</FormHelperText>
                                                )
                                            } */}
                                                <FormHelperText className="text-red-500">{mapOrderValidateModel.Order.message !== '' && mapOrderValidateModel.Order.touched ? mapOrderValidateModel.Order.message : ''}</FormHelperText>
                                            </FormGroup>

                                        </Grid>
                                        <Grid item xs={4} lg={4}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    className="ml-2 sm:mt-6"
                                                    control={
                                                        <Checkbox
                                                            checked={mapOrderModel?.Status}
                                                            onChange={handleCheckBoxes}
                                                            name="Status"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Show All"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>



                            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-6 items-center">
                                <div className="sm:col-span-2">
                                    <Box
                                        justifyContent="flex-start"
                                        display="flex"
                                        flexWrap="wrap"
                                    >
                                        {/* <Button
                                            variant="contained"
                                            size="large"
                                            onClick={handleSubmitOrder}
                                            disabled={showSubmitLoading}
                                            className={`bg-blue-600 px-16 mt-1 text-white`}
                                        >
                                            Map Order
                                            {showSubmitLoading && <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />}
                                        </Button> */}

                                        <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitOrder} label="Map Order" />


                                    </Box>

                                </div>
                            </div>
                        </dl>
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    );
};
export default MapOrderDialog;