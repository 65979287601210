import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import moment from "moment";
import CustomerGoodsEntryTable from "../components/Tables/CustomerGoodsEntryTable";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useHistory } from 'react-router-dom';  
import { currentUser } from "services/user-service";
import ViewGoodsEntryDialog from "./ViewGoodsEntryDialog";

import VisibilityIcon from '@mui/icons-material/Visibility';

const useStyles = makeStyles(componentStyles);

const CustomerGoodsEntries = () => {
  const classes = useStyles();
  const history = useHistory();
  const [goodsEntryList, setGoodsEntryList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  
  const [isReload, setIsReload] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchReceiveDate, setSearchReceiveDate] = useState({
    startDate: null,
    endDate: null
  });

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('LRNumber');
  const [order, setOrderData] = useState('asc');
  const [offset, setOffset] = useState(10);

  const [isLoading, setIsLoading] = useState(true);
  const [searchStatus, setSearchStatus] = useState('Pending');
  const [filterModel, setFilterModel] = useState({
    products: []
  });
  const [goodsEntryViewId, setGoodsEntryViewId] = useState(null);

  const columns = [
    { id: 'LRNumbers', numeric: false, disablePadding: false, label: 'LR Numbers', dataType:'string'},
    { id: 'Transport', numeric: false, disablePadding: false, label: 'Transport', dataType:'string'},
    { id: 'Bundles', numeric: false, disablePadding: false, label: 'Bundles', dataType:'string'},
    { id: 'FileList', numeric: false, disablePadding: false, label: 'Files', dataType:'jsonArray'},
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType:'string'},
  ];

  useEffect(() => {
   fetchData()
  }, [page, searchText, order, orderBy,isReload, offset, searchReceiveDate, searchStatus, filterModel])

  const fetchData = async() => {
    setIsLoading(true);
    let startDate = searchReceiveDate?.startDate === null ? null : moment(searchReceiveDate?.startDate).format('YYYY-MM-DD')
    let endDate = searchReceiveDate?.endDate === null ? null : moment(searchReceiveDate?.endDate).format('YYYY-MM-DD')
    
    let filProductIds =  null
    let res = await dataService('get', 'CustomerGoodsEntryList', `status=${searchStatus}&productIds=${filProductIds}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}&customerId=${currentUser()?.CustomerId}`);
    const startTime = performance.now();
    
    if(res?.status === 200 || res?.status === 304){
      setGoodsEntryList(res?.data);
      setTotalRecord(res?.totalRecord);
    }
    setIsLoading(false);
  }
 
  const handleDelete = async (row) => {
    
    let res = await dataService('post', `CustomerGoodsEntryDelete/${row.CustomerGoodsEntryID}`,  {UserId :currentUser()?.CustomerId}  );
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  const handleEdit = (row) => { 
    history.push(`customer-update-goods-entry/${row.CustomerGoodsEntryID}`)
  };


  const viewAction = (row) => {
   setOpen(true);
   setGoodsEntryViewId(row?.CustomerGoodsEntryID);
   
 };

  const tableData = () => {
    
      return <CustomerGoodsEntryTable 
          view="customer-goods-entry"
          title="Goods Entries"
          columns={columns}
          rows={goodsEntryList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          setSearchReceiveDate={setSearchReceiveDate}
          searchReceiveDate={searchReceiveDate}
          setSearchStatus={setSearchStatus}
          searchStatus={searchStatus}
          setFilterModel={setFilterModel}
          filterModel={filterModel}
          />
        }

        let actionList = [
          {label: 'View', icon: VisibilityIcon, iconColor: 'purple-500', action: viewAction}, 
          {label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit, notAuthenticated : true},
          {label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true, notAuthenticated : true},
        ]

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        {tableData()}
        </Container>

        {open && goodsEntryViewId &&
          <ViewGoodsEntryDialog open={open} onClose={setOpen} GoodsEntryId={goodsEntryViewId} />
        }
    </>
  );
};

export default CustomerGoodsEntries;