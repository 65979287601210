import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
import { useHistory } from 'react-router-dom';
import { updatePreviousLocations } from 'generic/store';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import SalesReportTable from './components/SalesReportTable';
import moment from 'moment';
import { currencyFormat } from 'services/data-service';

const useStyles = makeStyles(componentStyles);

const CustomerDataReportMonthly = () => {
  const previousLocationState = useSelector(
    (state) => state?.previousLocationsStore?.previous_locations
  );
  const dispatch = useDispatch(); // to update store values

  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [page, setPage] = useState(previousLocationState?.page || 1);
  const [orderBy] = useState(
    previousLocationState?.orderBy || 'Quantity'
  );
  const [order] = useState(
    previousLocationState?.order || 'desc'
  );
  const [offset, setOffset] = useState(previousLocationState?.offset || 10);
  const [isReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const MonthCompo = (row, quantity, amount, monthNumber) => {
    return (
      <div>
        <Typography
          style={{
            cursor: quantity ? 'pointer' : 'not-allowed',
            fontWeight: "600"
          }}
          onClick={() => {
            if (quantity) {
              history.push(`/admin/report/daily-customer-order/${row?.CustomerId}/${selectedYear}/${monthNumber}`);
            }
          }}
        >
          {quantity ? `${currencyFormat(amount)}` : "-"}
        </Typography>
      </div>
    )
  }



  const generateMonthList = () => {
    let columnsList = [
      {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'Customer Name',
        dataType: 'string',
        Component: ({ row }) => (
          <div>
            <Typography
              style={{
                cursor: row?.Quantity ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (row?.Quantity) {
                  history.push(`/admin/report/customer-order/${row?.CustomerId}`);
                }
              }}
            >
              {row?.Name}
            </Typography>
          </div>
        ),
      },

      {
        id: 'Quantity',
        numeric: false,
        disablePadding: true,
        label: 'Quantity',
        dataType: 'string'
      },
      {
        id: 'Amount',
        numeric: false,
        disablePadding: true,
        label: 'Amount',
        dataType: 'currency'
      }]


    moment.monthsShort().forEach((element, indx) => {
      columnsList.push({
        id: `${element}Quantity`,
        numeric: false,
        disablePadding: false,
        label: element,
        dataType: 'component',
        Component: ({ row }) => MonthCompo(row, row[`${element}Quantity`], row[`${element}Amount`], indx + 1)
      })
    });

    setColumns(columnsList);
  }

  useEffect(() => {
    const fetchData = async() => {
      generateMonthList();
      const reqBody = {
        Year: selectedYear,
        ReportType: 'MONTHLY',
        sortBy: orderBy,
        sortOrder: order,
        page: page,
        offset: offset
      };
      dispatch(updatePreviousLocations(null));
      setIsLoading(true);
      let res = await dataService(
        'post',
        'CustomerDataReport',
        reqBody
      );
      setCustomerList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    }

    fetchData()
  }, [selectedYear, page, order, orderBy, offset, isReload]);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <SalesReportTable
          title={null}
          columns={columns}
          rows={customerList}
          actions={[]}
          loading={isLoading}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setOffset={setOffset}
          offset={offset}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          // viewAction={viewAction}
          reportType={'MONTHLY'}
          isChartVisible={false}
          isPagination={true}
        />
      </Container>
    </>
  );
};

export default CustomerDataReportMonthly;

