// Store: global states.
// Ref:
    //1.  https://www.digitalocean.com/community/tutorials/how-to-manage-state-in-react-with-redux
    //2.  https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#combinereducers

    import { createStore } from 'redux';
    import { combineReducers } from 'redux';
    
    //-- Verification Code Store -- START --//
    
    const UPDATE_VERIFICATION_CODE = 'UPDATE_VERIFICATION_CODE';
    
    // function to update show cart values
    export function updateVerificationCodeStore(verificationCode) {
        return {
          type: UPDATE_VERIFICATION_CODE,
          verificationCode,
        }
      }

      let verificationCode = {
        title: '',
        openDialog: false,
        onClose: undefined,
        onSubmit: undefined
      };
    
      function verificationCodeStore(state={verificationCode: verificationCode}, action) {
        switch (action.type) {
          case UPDATE_VERIFICATION_CODE:
            let verificationCode = action.verificationCode;
            return{ 
              ...state,
              verificationCode
            };
          default:
            return state;
        }
      }
    
      //-- Verification Code Store -- END --//
      
      
      //-- Snackbar Store  -- START --//
    
    const UPDATE_SNACKBAR = 'UPDATE_SNACKBAR';
    
    // function to update show cart values
    export function updateSnackBarStore(snackbarData) {
        return {
          type: UPDATE_SNACKBAR,
          snackbarData,
        }
      }

      let snackbarData = {
        title: '',
        message: '',
        type: '',
        open: false,
        setOpen: undefined
      };
    
      function snackBarStore(state={snackbarData: snackbarData}, action) {
        switch (action.type) {
          case UPDATE_SNACKBAR:
            let snackbarData = action.snackbarData;
            return{ 
              ...state,
              snackbarData
            };
          default:
            return state;
        }
      }
    
      //-- Snackbar Store -- END --//
    
      //-- UPDATE ORDER FLOW -- START --//
    
    const UPDATE_ORDER_FLOW = 'UPDATE_ORDER_FLOW';
    
    // Update cart counter
    export function updateOrderFlow(selected_flow) {
        return {
          type: UPDATE_ORDER_FLOW,
          selected_flow,
        }
      }
    
      // const defaultUser = {
      //     avatar_url: '',
      //   };

      let defaultFlow = {};
      if(localStorage.getItem('selected_flow'))
      {
        defaultFlow = {selected_flow: JSON.parse(localStorage.getItem('selected_flow'))};
      }
    
      function selectedOrderFlow(state=defaultFlow, action) {
        switch (action.type) {
          case UPDATE_ORDER_FLOW:
            let selected_flow = action.selected_flow;
            return{ 
              ...state,
              selected_flow
            };
          default:
            return state;
        }
      }
    
      //--  UPDATE ORDER FLOW -- END --//
    
    
    //-- UPDATE INFLUENCER MISSING PROFILE FLAGS -- START --//
    
    const UPDATE_MISSING_PROFILE_STORE = 'UPDATE_MISSING_PROFILE_STORE';
    
    // Update cart counter
    export function updateMissingProfileStore(missing_profile, is_store_updated) {
        return {
          type: UPDATE_MISSING_PROFILE_STORE,
          missing_profile: missing_profile,
          is_store_updated: is_store_updated
        }
      }
    
      const defaultProfile = { missing_profile: false, is_store_updated: false};
    
      function missingProfileStore(state=defaultProfile, action) {
        switch (action.type) {
          case UPDATE_MISSING_PROFILE_STORE:
            
            let missing_profile = action.missing_profile;
            let is_store_updated  = action.is_store_updated;
            return{ 
              ...state,
              missing_profile,
              is_store_updated
            };
          default:
            return state;
        }
      }
    
      //--  missingProfileStore  -- END --//
    
      //-- UPDATE INFLUENCER MISSING BILLING FLAGS -- START --//
    
    const UPDATE_MISSING_BILLING_STORE = 'UPDATE_MISSING_BILLING_STORE';
    
    // Update cart counter
    export function updateMissingBillingStore(missing_billing, is_store_updated) {
        return {
          type: UPDATE_MISSING_BILLING_STORE,
          missing_billing: missing_billing,
          is_store_updated: is_store_updated
        }
      }
    
      const defaultBilling = { missing_billing: false, is_store_updated: false};
    
      function missingBillingStore(state=defaultBilling, action) {
        switch (action.type) {
          case UPDATE_MISSING_BILLING_STORE:
            let missing_billing = action.missing_billing;
            let is_store_updated  = action.is_store_updated;
            return{ 
              ...state,
              missing_billing,
              is_store_updated
            };
          default:
            return state;
        }
      }
    
      //--  missing bILLING  -- END --//
    
      //-- UPDATE INFLUENCER MISSING PRICING FLAGS -- START --//
    
    const UPDATE_MISSING_PRICING_STORE = 'UPDATE_MISSING_PRICING_STORE';
    
    // Update cart counter
    export function updateMissingPricingStore(missing_pricing, is_store_updated) {
        return {
          type: UPDATE_MISSING_PRICING_STORE,
          missing_pricing: missing_pricing,
          is_store_updated: is_store_updated
        }
      }
    
      const defaultPricing = { missing_pricing: false, is_store_updated: false};
    
      function missingPricingStore(state=defaultPricing, action) {
        switch (action.type) {
          case UPDATE_MISSING_PRICING_STORE:
            let missing_pricing = action.missing_pricing;
            let is_store_updated  = action.is_store_updated;
            return{ 
              ...state,
              missing_pricing,
              is_store_updated
            };
          default:
            return state;
        }
      }
    
      //--  missing PRICING  -- END --//


      //-- UPDATE PREVIOUS LOCATIONS -- START --//
    
    const UPDATE_PREVIOUS_LOCATIONS = 'UPDATE_PREVIOUS_LOCATIONS';
    
    // Update cart counter
    export function updatePreviousLocations(previous_locations) {
        return {
          type: UPDATE_PREVIOUS_LOCATIONS,
          previous_locations,
        }
      }

      let defaultPreviousLocations = null;
    
      function previousLocationsStore(state=defaultPreviousLocations, action) {
        switch (action.type) {
          case UPDATE_PREVIOUS_LOCATIONS:
            let previous_locations = action.previous_locations;
            return{ 
              ...state,
              previous_locations
            };
          default:
            return state;
        }
      }
    
      //--  UPDATE PREVIOUS LOCATIONS -- END --//
    
      
    
      // Combine multipe reducers
      const rajSamplerStore = combineReducers({
        verificationCodeStore,
        snackBarStore,
        selectedOrderFlow,
        previousLocationsStore,
        missingProfileStore,
        missingBillingStore,
        missingPricingStore
      });
    
      export const store = createStore(rajSamplerStore);