/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
//import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import SalesReportTable from './components/SalesReportTable';
import { useHistory, useParams } from 'react-router-dom';
import { currentUser } from 'services/user-service';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Bar, Line } from 'react-chartjs-2';
import {
  chartExample1,
  chartExample2,
  parseOptions,
  chartOptions,
} from 'variables/charts';
import { CustomButton } from 'components/Common/CustomButton';
import dashboardStyles from 'assets/theme/views/admin/dashboard.js';
import { Chart } from 'chart.js';

const useStyles = makeStyles(componentStyles);
const useDashboardStyles = makeStyles(dashboardStyles);

const DailySalesReport = () => {
  const classes = useStyles();
  const history = useHistory();
  const dashboardClasses = useDashboardStyles();
  const theme = useTheme();

  const { month, year } = useParams();
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(year);
  const [lineChartReportType] = useState('quantity');
  const [lineChartReportData, setLineCharReportData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const columns = [
    {
      id: 'Date',
      numeric: false,
      disablePadding: true,
      label: 'Date',
      dataType: 'string',
    },
    {
      id: 'Quantity',
      numeric: false,
      disablePadding: false,
      label: 'Quantity',
      dataType: 'string',
    },
    {
      id: 'Amount',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
      dataType: 'currency',
    },
  ];

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async() => {
    let curUser = currentUser();
    if (curUser?.UserId !== 1 && curUser?.UserId !== 3) {
      history.push(`/admin`);
      return false;
    }
    setIsLoading(true);
    let reqBody = generateQueryPostData();
    let res = await dataService('post', 'SalesOrderReportV2', reqBody);
    setCompletedOrderList(res?.data?.dataReport);
    generateDayChartReport(res?.data?.dataReport);
    setIsLoading(false);
  }

  const generateDayChartReport = (dayReport) => {
    dayReport.sort(function (a, b) {
      return a.Date - b.Date;
    });
    let rptDataset = null;

    rptDataset = {
      label: 'Quantity',
      data: dayReport.map((rpt) => {
        return rpt.Quantity;
      }),
    };
    let reportData = {
      labels: dayReport.map((rpt) => {
        return rpt.Date;
      }),
      datasets: [rptDataset],
    };
    setLineCharReportData(reportData);
  };

  const generateQueryPostData = () => {
    let reqBody = {
      Year: year,
      Month: month,
      ReportType: 'DAILY',
    };
    return reqBody;
  };

  const viewAction = (rowData) => {
    history.push(
      `/admin/report/daily-detail-sales-report/${rowData?.Year}/${moment()
        .month(rowData?.Month)
        .format('M')}/${rowData?.Date}`
    );
  };

  const exportToPDF = async () => {
    const doc = new jsPDF();
    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    doc.text('Sales Order Report', 20, 10);
    autoTable(doc, {
      columnStyles: { europe: { halign: 'center' } }, // European countries centered
      columns: columns.map((col) => ({ ...col, dataKey: col.id })),
      body: completedOrderList,
    });
    doc.save();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <SalesReportTable
          title={null}
          columns={columns}
          rows={completedOrderList}
          actions={[]}
          loading={isLoading}
          exportToPDF={exportToPDF}
          selectedYear={selectedYear}
          selectedMonth={month}
          setSelectedYear={setSelectedYear}
          viewAction={viewAction}
          reportType={'DAILY'}
          setIsChartOpen={setIsOpen}
          isChartVisible={true}
        />

        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <div
            style={{
              background: theme.palette.info.main,
              borderColor: theme.palette.info.main,
            }}
            className="bg-blue-600 border-blue-600 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between"
          >
            <DialogTitle className="pb-1 w-full">
              <div className="sm:grid sm:grid-cols-2 items-center">
                <div className="text-gray-100">
                  <span className="text-2xl">Monthly Sales Report</span>
                  <br />
                </div>
              </div>
            </DialogTitle>
            <Close
              className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2"
              onClick={handleClose}
            />
          </div>

          <DialogContent className="px-4 py-2">
            <Grid container>
              <Grid
                item
                xs={12}
                xl={8}
                component={Box}
                marginBottom="3rem!important"
                classes={{ root: dashboardClasses.gridItemRoot }}
              >
                <Card
                  classes={{
                    root:
                      dashboardClasses.cardRoot +
                      ' ' +
                      dashboardClasses.cardRootBgGradient,
                  }}
                >
                  <CardHeader
                    subheader={
                      <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs="auto">
                          <Box
                            component={Typography}
                            variant="h6"
                            letterSpacing=".0625rem"
                            marginBottom=".25rem!important"
                            className={dashboardClasses.textUppercase}
                          >
                            <ButtonGroup
                              size="small"
                              disableElevation
                              variant="contained"
                              color="primary"
                            >
                              <Button
                                classes={{
                                  root:
                                    lineChartReportType === 'quantity'
                                      ? ''
                                      : dashboardClasses.buttonRootUnselected,
                                }}
                              >
                                Quantity
                              </Button>
                            </ButtonGroup>
                          </Box>
                          <Box
                            component={Typography}
                            variant="h2"
                            marginBottom="0!important"
                          >
                            <Box
                              component="span"
                              color={theme.palette.info.main}
                            >
                              <span className="capitalize">
                                {lineChartReportType}
                              </span>{' '}
                              Report
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                    classes={{ root: dashboardClasses.cardHeaderRoot }}
                  ></CardHeader>
                  <CardContent>
                    <Box position="relative" height="350px">
                      {lineChartReportData && (
                        <Line
                          data={lineChartReportData}
                          options={chartExample1.options}
                          getDatasetAtEvent={(e) => console.log(e)}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} xl={4}>
                <Card classes={{ root: dashboardClasses.cardRoot }}>
                  <CardHeader
                    title={
                      <Box component="span" color={theme.palette.gray[600]}>
                        Performane
                      </Box>
                    }
                    subheader={
                      <span className="capitalize">Total Quantity</span>
                    }
                    classes={{ root: dashboardClasses.cardHeaderRoot }}
                    titleTypographyProps={{
                      component: Box,
                      variant: 'h6',
                      letterSpacing: '.0625rem',
                      marginBottom: '.25rem!important',
                      classes: {
                        root: dashboardClasses.textUppercase,
                      },
                    }}
                    subheaderTypographyProps={{
                      component: Box,
                      variant: 'h2',
                      marginBottom: '0!important',
                      color: 'initial',
                    }}
                  ></CardHeader>
                  <CardContent>
                    <Box position="relative" height="350px">
                      {lineChartReportData && (
                        <Bar
                          data={lineChartReportData}
                          options={chartExample2.options}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default DailySalesReport;
