import React from 'react';

export function LoadingIcon(props) {
    return (
        <div className={`flex items-center justify-center ${props?.overlay ? 'absolute w-full left-0 top-0 text-center h-full bg-black bg-opacity-50 rounded z-10' : ''}`}>
            <div className={`flex items-center justify-center space-x-${props?.space || 1} ${props?.overlay ? '' : props?.margin || 'my-2'} animate-pulse`}>
                <div className={`w-${props?.size || 4} h-${props?.size || 4} bg-${props?.colors ? props?.colors[0] || 'blue-400' :  props?.color || 'blue-400'} rounded-full`}></div>
                <div className={`w-${props?.size || 4} h-${props?.size || 4} bg-${props?.colors ? props?.colors[1] || 'blue-400' :  props?.color || 'blue-400'} rounded-full`}></div>
                <div className={`w-${props?.size || 4} h-${props?.size || 4} bg-${props?.colors ? props?.colors[2] || 'blue-400' :  props?.color || 'blue-400'} rounded-full`}></div>
            </div>
        </div>
    );
};

