/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { currencyFormat } from 'services/data-service';

import { LocalizationProvider, DatePicker, CalendarIcon } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useHistory } from 'react-router-dom';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';

export function DailyDetailSalesReportFilterHeader(props) {

  const { data, selectedYear, selectedMonth, selectedDay, title, isHideTotal = false, workCenterName = false,
    isNotAmount = false, reportType, goodsEntryId, isHideAmount = false } = props

  const history = useHistory();

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };


  useEffect(() => {
    fetchData()
  }, [data]);

  const fetchData = async () => {
    if (data && data?.length > 0) {
      let totalAmountVal = 0;
      let totalQuantityVal = 0;
      data.map((x) => {
        totalAmountVal += x?.Amount || x?.TotalPrice;
        totalQuantityVal += x?.Quantity;
      });

      setTotalAmount(currencyFormat(totalAmountVal));
      setTotalQuantity(totalQuantityVal);
    } else {
      setTotalAmount(currencyFormat(0));
      setTotalQuantity(0);
    }
  }

  const handleLeftArrowClick = async () => {
    const currentDate = moment(props?.selectedDate?.formatDate, "YYYY-MM-DD");
    const previousDate = currentDate.subtract(1, 'day');
    const selectedYear = previousDate.format("YYYY");
    const selectedMonth = previousDate.format("MM");
    const selectedDay = previousDate.format("DD");

    props?.setSelectedDate({
      date: selectedDay,
      month: selectedMonth,
      year: selectedYear,
      formatDate: previousDate.format("YYYY-MM-DD")
    });
    props?.setSelectedYear(selectedYear)

    history.replace(
      `/admin/report/productions-report-daily-detail/${selectedYear}/${selectedMonth}/${props?.WorkCenterId || 0}/${selectedDay}/${props?.WorkCenterId || 0}`
    );
  }

  const handleRightArrowClick = async () => {

    const currentDate = moment(props?.selectedDate?.formatDate, "YYYY-MM-DD");
    const previousDate = currentDate.add(1, 'day');
    const selectedYear = previousDate.format("YYYY");
    const selectedMonth = previousDate.format("MM");
    const selectedDay = previousDate.format("DD");

    props?.setSelectedDate({
      date: selectedDay,
      month: selectedMonth,
      year: selectedYear,
      formatDate: previousDate.format("YYYY-MM-DD")
    });
    props?.setSelectedYear(selectedYear)
    history.replace(
      `/admin/report/productions-report-daily-detail/${selectedYear}/${selectedMonth}/${props?.WorkCenterId || 0}/${selectedDay}/${props?.WorkCenterId || 0}`
    );
  }


  return (
    <Grid
      container spacing={2}
    >
      <Grid xs={12} sm={1} lg={0.5}
        sx={theme => ({
          display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%",
          [theme.breakpoints.down("lg")]: {
            justifyContent: "flex-start",
            alignItems: "flex-start",
            mt: 2
          },
          [theme.breakpoints.down("md") && theme.breakpoints.up("xmd")]: {
            justifyContent: "center",
            alignItems: "center"
          },
        })}>
        <IconButton
          size="large"
          onClick={handleLeftArrowClick}
          sx={{
            mr: 2,
            width: 30,
            height: 30,
            borderRadius: 0,
            backgroundColor: '#f0f0f0',
            '&:hover': {
              backgroundColor: '#e0e0e0' 
            },
            fontSize: '24px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <KeyboardDoubleArrowLeft sx={{ height: 20, width: 20 }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={12} xmd={5.5} md={12} lg={5.5}>
        <div className="text-gray-900 justify-start">
          <div className="mr-10 flex flex-col gap-0">
            {title && <div className='mb-4 font-bold'><p className='text-black font-bold text-lg'>{title}</p></div>}
            {reportType === "GoodsEntryOrder" ?
              <span className="text-sm flex flex-wrap justify-start items-center gap-2  mb-2">
                <strong>Goods Entry: </strong>
                <span className="px-2 py-1 text-sm font-bold  bg-blue-300 rounded">
                  {goodsEntryId}
                </span>
              </span> :
              <span className="text-sm flex flex-wrap justify-start items-center gap-2  mb-2">
                <strong>Date: </strong>
                <span className="px-2 py-1 text-sm font-bold  bg-blue-300 rounded">
                  {selectedDay}-
                  {moment()
                    .month(selectedMonth - 1)
                    .format('MMMM')}
                  -{selectedYear}{' '}
                </span>
                {props?.isCalender &&
                  <div className="bg-white custom-datepicker">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        open={open}
                        onClose={() => setOpen(false)}
                        slots={{ textField: () => null }}
                        slotProps={{
                          popper: {
                            anchorEl: anchorEl,
                            placement: 'bottom-start',
                          },
                        }}
                        value={props.selectedDate?.formatDate ? new Date(props.selectedDate?.formatDate) : null}
                        onChange={(date) => {
                          if (moment(date, "DD/MM/YYYY", true).isValid()) {
                            const formattedDate = moment(date).format("YYYY-MM-DD");
                            const selectedYear = moment(date).format("YYYY");
                            const selectedMonth = moment(date).format("MM");
                            const selectedDay = moment(date).format("DD");
                            props?.setSelectedDate({
                              date: selectedDay,
                              month: selectedMonth,
                              year: selectedYear,
                              formatDate: formattedDate
                            });
                            props?.setSelectedYear(selectedYear)
                            history.replace(
                              `/admin/report/productions-report-daily-detail/${selectedYear}/${selectedMonth}/${props?.WorkCenterId || 0}/${selectedDay}/${props?.WorkCenterId || 0}`
                            );
                          }
                        }}
                      />
                      <Box
                        onClick={handleIconClick}
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '8px',
                          cursor: 'pointer',
                          height: '40px',
                          width: '40px',
                        }}
                      >
                        <IconButton sx={{ padding: 0, ml: 1 }}>
                          <CalendarIcon />
                        </IconButton>
                      </Box>
                    </LocalizationProvider>
                  </div>
                }
              </span>}
            {workCenterName &&
            <span className="text-sm flex flex-wrap justify-start items-center gap-2  mb-2">
              <strong>Work Center: </strong>
              <span className="px-2 py-1 text-sm font-bold  bg-blue-300 rounded">
                {workCenterName}{' '}
              </span>
            </span>}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} xmd={4.5} md={12} lg={5.5} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        {!isHideTotal &&
          <div className="text-gray-900">
            <div className="mr-10 flex flex-col gap-2">
              <span className="text-sm justify-start items-center mt-2">
                <strong>Total Quantity: </strong>
                <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded mr-3 ml-1">
                  {' '} 
                  {totalQuantity}{' '}
                </span>
              </span>
              {!isNotAmount && !isHideAmount ?
              <span className="text-sm justify-start items-center">
                <strong>Total Amount: </strong>
                <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded ml-1">
                  ₹ {totalAmount}
                </span>
              </span>
              : null}
              {/* <span className="text-xs">  [In Quantity: {flowStepModel.Quantity}]</span> */}
            </div>
          </div>
        }
      </Grid>
      <Grid xs={12} sm={1} lg={0.5}
        sx={theme => ({
          display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%",
          [theme.breakpoints.down("lg")]: {
            justifyContent: "flex-start",
            alignItems: "flex-start"
          },
          [theme.breakpoints.down("md") && theme.breakpoints.up("xmd")]: {
            justifyContent: "center",
            alignItems: "center"
          },
        })}>
        <IconButton
          size="large"
          onClick={handleRightArrowClick}
          sx={{
            width: 30,
            height: 30,
            borderRadius: 0,
            backgroundColor: '#f0f0f0',
            '&:hover': {
              backgroundColor: '#e0e0e0'
            },
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <KeyboardDoubleArrowRight sx={{ height: 20, width: 20 }} />
        </IconButton>
      </Grid>
    </Grid >
  );
}
