import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { DialogActions } from '@mui/material';
// import { checkEmptyValidation } from 'services/validation-service';
import { CustomButton } from "components/Common/CustomButton";
import FileUploadForm from './FileUploadForm';
import UploadedDocumentsView from './UploadedDocumentsView';

function FileUploadDialog(props) {
    const { onClose, open, documentTitle, uploadedDocuments, setSelectedDocuments, selectedDocuments, handleDeleteDocument, handleUploadDocument } = props;
    const [showSubmitLoading, setShowSubmitLoading] = useState(false)

    const handleClose = () => {
        ///setConfirmData(null)
        onClose();
    };

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        if (selectedDocuments?.length > 0) {
            setSelectedDocuments(selectedDocuments)
        }
        else {
            if (uploadedDocuments?.length === 0) {
                setSelectedDocuments([
                    { Title: '', SelectedFiles: null }
                ])
            }

        }
    }

    const handleSubmit = async () => {
        if (selectedDocuments?.length > 0) {
            setShowSubmitLoading(true);
            //setSelectedDocuments(inputFields);
            await handleUploadDocument();
            setShowSubmitLoading(false);
        }

        //handleClose();
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-3 pb-2 text-xl">
                    <div className="text-2xl">
                        {documentTitle} Documents
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-800 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                {uploadedDocuments?.length > 0 &&
                    <UploadedDocumentsView
                        documentsList={uploadedDocuments}
                        handleDeleteDocument={handleDeleteDocument}
                        showDelete={true}
                    />
                }

                <FileUploadForm
                    inputFields={selectedDocuments}
                    setInputFields={setSelectedDocuments}
                />
            </DialogContent>
            <DialogActions className='border-t border-gray-200'>
                <CustomButton autoFocus className='bg-green-500 text-white border-none mx-1 text-base' size="none" label={uploadedDocuments?.length > 0 ? 'Upload' : 'Save'} onClick={handleSubmit} iconClassName="w-5 h-auto" loading={showSubmitLoading} />
                <CustomButton autoFocus className='bg-red-500 text-white border-none mx-1 text-base' size="none" label={`Cancel `} onClick={handleClose} iconClassName="w-5 h-auto" />
            </DialogActions>
        </Dialog>
    );
};
export default FileUploadDialog;