import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TableSortLabel from '@mui/material/TableSortLabel';
import { dataService } from "services/data-service";
import SearchIcon from "@mui/icons-material/Search";

import componentStyles from "assets/theme/views/admin/tables.js";
import { Chip, CircularProgress, Fab, FormControlLabel, FormGroup, InputAdornment, Radio, RadioGroup, TablePagination, TextField, Typography } from "@mui/material";
import moment from "moment";
import ThreeDotsMenu from "components/ThreeDotMenu/ThreeDotMenu";
import { LoadingIcon } from "components/Icons/Loading";
import { BarChart } from "@mui/icons-material";
import CardStatsDialog from "components/dialoges/CardStatsDialog";
//import moment from "moment";


const useStyles = makeStyles(componentStyles);

const CommonOrdersTable = (props) => {
  let timeout = 0;
  const { title, rows, page, total, totalQuantity, pagedata, setSearchText, setCustomerSearchText, setFileSearchText, order, orderBy, orderData, orderByData, columns, editAction, actions, setOffset, offset, loading, runningOrder, setOrderFilter, orderFilter } = props;
  const headCells = columns;
  const classes = useStyles();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);

  const [reportStats, setReportStats] = useState([]);
  const [openReportStats, setOpenReportStats] = useState(false);
  const [showReportStatsLoading, setShowReportStatsLoading] = useState(false);

  const handleEditClick = (row) => {
    editAction(row);
  };

  const handleViewClick = (row) => {

    if (actions?.length > 0) {
      let viewAct = actions?.find(ac => ac.label === 'View')
      if (viewAct) {
        let viewAction = viewAct?.action;
        viewAction(row);
      }
    }

  };

  //Handle Page changes
  const handleChangePage = (event, newPage) => {
    pagedata(newPage + 1);
  };

  //Handle Page changes
  const handleChangePageRows = (event) => {
    setOffset(parseInt(event.target.value, 10))
    pagedata(1);
  };

  //Search data
  const handleSearchInput = (e) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      switch (e.target.name) {
        case 'CustomerName':
          setCustomerSearchText(e.target.value)
          break;
        case 'FileName':
          setFileSearchText(e.target.value)
          break;
        case 'CommonSearch':
          setSearchText(e.target.value)
          break;
      }
      pagedata(1);
    }, 400);
  }

  /*------------- Table Headers Start ------------*/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    orderData(isAsc ? 'desc' : 'asc');
    orderByData(property);
  };

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };


    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
              classes={{
                root:
                  classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            key={100}
            align={'center'}
            padding={'none'}
            classes={{
              root:
                classes.tableCellRoot + " " + classes.tableCellRootHead,
            }}
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  /*------------- Table Headers End ------------*/

  /*------------- Table Content Start ------------*/

  function EnhancedTableRows(props) {
    const { classes, rows } = props;


    return (
      <TableBody className="relative">
        {rows?.map((rowData, index) => {
          return (

            // <TableRow key={index} onClick={(e) => {handleViewClick(rowData)}} className="cursor-pointer">
            <TableRow key={index} className={`cursor-pointer ${selectedRowIndex === index ? 'bg-blue-100' : ''}`}>
              {headCells.map((headCell, hdIndex) => (
                <TableCell
                  key={hdIndex}
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  {headCell?.Component && <headCell.Component row={rowData} headCellData={headCell} />}
                  {headCell.dataType === 'string' && rowData[headCell.id]}
                  {/* {headCell.dataType === 'date' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('Do MMMM YYYY') : ''} */}
                  {headCell.dataType === 'date' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('D-MM-YYYY') : ''}
                </TableCell>
              ))
              }

              <TableCell
                classes={{ root: classes.tableCellRoot }}
                align="center"
              >
                {actions?.length > 0 &&
                  <ThreeDotsMenu data={rowData} actions={actions} index={index} setSelectedRowIndex={setSelectedRowIndex} />
                }
              </TableCell>
            </TableRow>

          )
        })
        }
        {rows?.length === 0 && !loading &&
          <TableRow key={0}>
            <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
              Record Not Found
            </TableCell>
          </TableRow>
        }
        {loading &&
          <TableRow key={0}>
            <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
              <LoadingIcon overlay={rows?.length > 0} />
            </TableCell>
          </TableRow>
        }
      </TableBody>
    )
  }


  EnhancedTableRows.propTypes = {
    // rows, page, total, pagedata, setSearchText
    classes: PropTypes.object.isRequired,
    rows: PropTypes.array
  };

  /*------------- Table Content End ------------*/

  const handleStatsAction = async () => {
    setShowReportStatsLoading(true);
    let res = await dataService('get', 'OrderCountStats', `type=ByProducts&status=InProgress&startDate=null&endDate=null`);

    if (res?.status === 200 || res?.status === 304) {
      setOpenReportStats(true);
      setReportStats(res?.data);
    }
    setShowReportStatsLoading(false);

  }


  const handleChangeOrderFilter = (event) => {
    const selectedValue = event.target.value;
    setOrderFilter((prevState) => {
      if (prevState[selectedValue] === 1) {
        return {
          ...prevState,
          [selectedValue]: null,
        };
      } else {
        return {
          TagInterlock: null,
          TagZigZag: null,
          BindingsDieCut: null,
          BindingsFoil: null,
          BindingsTagPrinted: null,
          [selectedValue]: 1,
        };
      }
    });
  };




  return (

    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        subheader={
          <>
            <Grid
              container spacing={2}
            >
              <Grid item xs={12} xl={2} className="pr-0">
                <Box
                  component={Typography}
                  variant="h3"
                  sx={{ mt: 0.5 }}
                >
                  <span>{title}</span>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} xl={2.5} className="pr-0">
                <TextField
                  size='small'
                  id="name"
                  fullWidth
                  name="CommonSearch"
                  autoComplete="name"
                  placeholder={"Search"}
                  onChange={(e) => { handleSearchInput(e) }}
                  sx={{
                    '& .MuiOutlinedInput-input:not(:last-child)': {
                      height: 35,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="text-black" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={2.5} className="pr-0">
                <TextField
                  size='small'
                  id="name"
                  fullWidth
                  name="CustomerName"
                  autoComplete="name"
                  placeholder={"Customer Name"}
                  onChange={(e) => { handleSearchInput(e) }}
                  sx={{
                    '& .MuiOutlinedInput-input:not(:last-child)': {
                      height: 35,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="text-black" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={2.5} className="pr-0">
                <TextField
                  size='small'
                  id="name"
                  fullWidth
                  name="FileName"
                  autoComplete="name"
                  placeholder={"File Name"}
                  onChange={(e) => { handleSearchInput(e) }}
                  sx={{
                    '& .MuiOutlinedInput-input:not(:last-child)': {
                      height: 35,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="text-black" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {runningOrder &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  alignItems: 'center',
                  gap: '16px'
                }}
              >
                <FormGroup>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="Type"
                    value={Object.keys(orderFilter).find(key => orderFilter[key] === 1) || ''}
                  >
                    <FormControlLabel
                      value="TagInterlock"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Interlock"
                    />
                    <FormControlLabel
                      value="TagZigZag"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="ZigZag"
                    />
                    <FormControlLabel
                      value="BindingsDieCut"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Die Cut"
                    />
                    <FormControlLabel
                      value="BindingsFoil"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Foil"
                    />
                    <FormControlLabel
                      value="BindingsTagPrinted"
                      control={<Radio color="primary" onClick={handleChangeOrderFilter} />}
                      label="Tag Printed"
                    />
                  </RadioGroup>
                </FormGroup>
              </Box>}
          </>
        }
        classes={{ root: classes.cardHeaderRoot }}
      ></CardHeader>
      <TableContainer sx={{ mt: runningOrder ? -8 : -3 }}>

        <Table className="mb-0" >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />

          <EnhancedTableRows
            classes={classes}
            rows={rows}
            page={page}
            total={total}
          />
        </Table>
      </TableContainer>
      <Box
        classes={{ root: classes.cardActionsRoot }}
        component={CardActions}
        justifyContent="space-between"
      >

        <div>
          <Fab color="primary" aria-label="edit" onClick={handleStatsAction} size="small" className="mr-2">
            {showReportStatsLoading ?
              <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />
              :
              <BarChart className="w-6 h-auto" />
            }
          </Fab>
          <Chip className="font-bold bg-blue-600 mr-2" color="primary" label={`Total Quantity : ${totalQuantity}`} />
          <Chip className="font-bold bg-blue-600" color="primary" label={`Total Records : ${total}`} />
        </div>
        <div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={offset}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageRows}
          />
        </div>
      </Box>
      {openReportStats &&
        <CardStatsDialog open={openReportStats} onClose={setOpenReportStats} viewDialogData={{ title: 'Order Stats' }} rows={reportStats} />
      }
    </Card>


  );
};

export default CommonOrdersTable;