import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { red } from '@mui/material/colors';
import moment from "moment";
import { Box, Menu, MenuItem, Grid, Chip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { dataService } from 'services/data-service';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function MyTaskTable(props) {
    const { rows, handleStatusChange, setOpen } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);


    /* Status list and update status component ----> START <---- */

    function EnhancedStatusMenu(props) {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const { dataRow, index, handleStatusChange } = props;
        const rowColor = JSON.parse(dataRow?.RowColor);


        const handleClick = (event) => {
            if (dataRow?.Status === 'Assigned') {
                setAnchorEl(event.currentTarget);
            }

        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleUpdateStatus = (status) => {
            handleStatusChange(dataRow, status)
        };


        return (
            <Box key={index}>
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                        type="button"
                        className={`relative bg-${rowColor?.color}-${rowColor?.value} text-white inline-flex items-center px-4 py-1.5 rounded-l-md  text-sm font-medium hover:bg-${rowColor?.color}-${rowColor?.hoverValue}`}
                    >
                        {dataRow?.Status}
                    </button>
                    <button
                        type="button"
                        className={`relative bg-${rowColor?.color}-${rowColor?.value} text-white inline-flex items-center px-1 rounded-r-md border-l border-white text-sm font-medium hover:bg-${rowColor?.color}-${rowColor?.hoverValue}`}
                        onClick={handleClick}
                    >
                        <ChevronRightIcon className='p-0 w-6 h-auto' />
                    </button>
                </span>
                {dataRow?.Status === 'Assigned' &&
                    <Menu
                        //id={`card-actions-menu-${index}`}
                        id={`simple-menu-${index}`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >

                        <MenuItem onClick={(e) => { 
                            handleUpdateStatus('FiniShed')
                            }} className='border-b border-solid border-gray-300'>Finished</MenuItem>
                        {/* <MenuItem onClick={(e) => handleUpdateStatus('OnHold')}>On Hold</MenuItem> */}
                    </Menu>
                }
            </Box>
        )
    }

    /* Status list and update status component ----> END <---- */

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getTaskData = async (rowData) => {
        let res = await dataService('get', `TaskGet/${rowData?.TaskId}`, '');
        if (res.status === 200) {
            let TaskData = res?.data
            if (TaskData) {
                setOpen({ isVisible : true, data: TaskData, extraData : rowData})
            }
        }
    }

    return (
        <Grid container className={classes.root}>


            {rows?.map((rowData, index) => {
                let rowColor = JSON.parse(rowData?.RowColor);
                return (
                    <Grid key={index} item xl={4} md={6} className='!my-3' >
                        <Card className={`border border-1 border-solid border-${rowColor?.color}-${rowColor?.value}`}>
                            <CardHeader
                            onClick={() => getTaskData(rowData)}
                            sx={{cursor : "pointer"}}
                                // action={
                                //     <EnhancedStatusMenu dataRow={rowData} handleStatusChange={handleStatusChange} />
                                // }
                                //title={rowData?.Name}
                                title={<span className={`text-base font-semibold text-${rowColor?.color}-${rowColor?.value}`}>{rowData?.Title}</span>}
                                subheader={<span className={`-mt-2 block text-${rowColor?.color}-${rowColor?.value}`}>{moment(rowData?.CreatedAt).format('LL')}</span>}
                            />
                            <CardContent>
                                <div className="bg-white">
                                    <div className="border-gray-200">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Created At</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{moment(rowData?.CreatedAt).format('LL')}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Due Date</dt>
                                                <dd className={`mt-1 text-sm text-${rowColor?.color}-${rowColor?.value}`}>{rowData?.DueDate ? moment(rowData?.DueDate).format('LL') : '-'}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{rowData?.Status}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">{rowData?.DueInDays ? 'Due In' : 'Due Since'}</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {rowData?.DueInDays !== null || rowData?.DueSinceDays !== null ?
                                                        <Chip className="font-bold !py-0 h-6 rounded-md" color={"error"} label={rowData?.DueInDays !== null ? rowData?.DueInDays + ' Day' : rowData?.DueSinceDays !== null ? rowData?.DueSinceDays + ' Days' : '-'} />
                                                        : "-"}
                                                </dd>
                                            </div>
                                            <EnhancedStatusMenu dataRow={rowData} handleStatusChange={handleStatusChange} />
                                            {/* <div className="sm:col-span-2 border-t border-gray-200 py-5">
                                                <dt className="text-sm font-medium text-gray-500">Description</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
                                                    {rowData?.Description}
                                                </dd>
                                            </div> */}
                                        </dl>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            }
            )}


        </Grid>
    );

}
