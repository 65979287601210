export const MONTHS_DATA = [
    {
      label: "January",
      val: 1,
    },
    {
      label: "February",
      val: 2,
    },
    {
      label: "March",
      val: 3,
    },
    {
      label: "April",
      val: 4,
    },
    {
      label: "May",
      val: 5,
    },
    {
      label: "June",
      val: 6,
    },
    {
      label: "July",
      val: 7,
    },
    {
      label: "August",
      val: 8,
    },
    {
      label: "September",
      val: 9,
    },
    {
      label: "October",
      val: 10,
    },
    {
      label: "November",
      val: 11,
    },
    {
      label: "December",
      val: 12,
    }
  ];

  export const PRE_ORDER_STATUS_DATA = [
    {
      label : "Pending"
    },
    {
      label : "Approved"
    },
    {
      label : "Canceled"
    }
  ]