import React from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// core components
import componentStyles from "assets/theme/components/auth-footer.js";

const useStyles = makeStyles(componentStyles);

const Footer = () => {
  const classes = useStyles();
  return (
    <Box component="footer" width="100%" paddingTop="1rem" className="mb-3">
      <Container
        component={Box}
        maxWidth="xl"
        display="flex!important"
        alignItems="center"
        className="items-center justify-center flex"
        classes={{
          root:
            classes.justifyContentCenter + " " + classes.flexDirectionColumn,
        }}
      >
        <Grid item xs={12} xl={12} className="text-center">
          <div className={`${classes.copyrightWrapper} text-center text-black`}>
            © {new Date().getFullYear()}{" "}
            <a
              className={classes.copyrightLink}
              href="https://www.creative-tim.com?ref=adr-admin-footer"
              rel="noopener noreferrer"
              target="_blank"
            >
              Raj Sampler
            </a>
          </div>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
