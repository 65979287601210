import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, DialogContent, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, VpnKey } from '@mui/icons-material';
import moment from "moment";
import { dataService } from "services/data-service";
import { currentUser } from "services/user-service";
import { showVerificationDialog } from "./VerifiyOTPDialog";
import ConfirmDialog from "./ConfirmDialog";
import MessageBar from "components/MessageBar/MessageBar";

function ViewOrderDialog(props) {
    const { onClose, open, OrderId } = props;
    const [orderData, setOrderData] = useState();
    const [showPrice, setShowPrice] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false)

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    const handleClose = () => {
        onClose(false);
    };

    const handleShowPrice = () => {
        showVerificationDialog('Verify to view price', true, () => {
        }, async () => {
            setShowPrice(true)
        })
    };

    useEffect(() => {
        fetchData()
    }, [OrderId])

    const fetchData = async () => {
        let res = await dataService('get', `OrderGet/${OrderId}`, '');
        if (res?.data) {
            res.data.PhotosPage = res?.data?.PhotosPage ? res?.data?.PhotosPage?.split(',')?.map(Number) : [];
            res.data.FittingNumber = res?.data?.FittingNumber ? res?.data?.FittingNumber?.split(',')?.map(Number) : [];
        }
        setOrderData(res?.data);
    }

    const handleGenerateOrder = async () => {
        let eCateLouge = {
            CustomerId: orderData ? orderData?.CustomerId : null,
            Client: orderData ? orderData?.Client : null,
            Date: orderData ? orderData?.Date : null,
            ProductFileId: orderData ? orderData?.ProductFileId : null,
            FileId: orderData ? orderData?.FileId : null,
            Quantity: orderData ? orderData?.Quantity : null,
            DeliveryDate: orderData ? orderData?.DeliveryDate : null,
            IsRepeat: orderData ? orderData?.IsRepeat : null,
            RepeatOrderId: orderData ? orderData?.RepeatOrderId : null,
            UnitPrice: orderData ? orderData?.UnitPrice : null,
            Parcel: orderData ? orderData?.Parcel : null,
            Loose: orderData ? orderData?.Loose : null,
            ChallanSequence1: orderData ? orderData?.ChallanSequence1 : null,
            ChallanSequence3: orderData ? orderData?.ChallanSequence3 : null,
            Remarks: orderData ? orderData?.Remarks : null,
            ProductId: orderData ? 4 : null,
            ECataloguePhotoShoot: true,
            ECatalogueFabricLayout: true,
            ECatalogueColorMatching: true,
            ECatalogueApproval: true,
            ECatalogueMail: true,
            ECatalogueDelivery: true,
            BillingEmail: orderData ? orderData?.BillingEmail : null,
            ShippingEmail: orderData ? orderData?.ShippingEmail : null
        }

        eCateLouge.PhotosPage = orderData?.PhotosPageArry?.sort()?.join();
        eCateLouge.FittingNumber = orderData?.FittingNumberArry?.sort()?.join();

        let res = await dataService('post', `OrderSave/${0}`, eCateLouge);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        if (res && (res.status === 200 || res.status === 304)) {
            setMessageDialoge({ show: true, type: 'success', message: 'E-catalogue order generate successfully!' });
            setConfirmOpen(false)

            setTimeout(() => {
                handleClose()
            }, 500);
        } else {
            setMessageDialoge({ show: true, type: 'error', message: res.message });
        }
    }

    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" open={open}>
            <div className="flex flex-wrap items-center justify-between">
                <DialogTitle id="form-dialog-title" >
                    <div className="flex flex-row">
                        <div>
                            <span className="text-2xl">Order Information</span><br />
                            <span className="text-lg text-gray-500">  [Order #: {orderData?.OrderNumber}]</span>
                        </div>
                        <Box
                            justifyContent="center"
                            display="flex"
                            flexWrap="wrap"
                            className="ml-4"
                        >
                            <Button
                                className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => setConfirmOpen(true)}
                            >
                                {"Generate E-catalogue"}
                            </Button>
                        </Box>
                    </div>
                    {/* <div className="border-gray-200 shadow sm:rounded-lg py-2">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-2 sm:px-2 sm:pb-2  md:border-b border-gray-300">
                            <span className="text-sm md:border-r border-gray-300"><span className="text-gray-500">Order #:</span> {orderData?.OrderNumber}</span>
                            <span className="text-sm"><span className="text-gray-500">Product:</span> {orderData?.ProductName}</span>
                        </div>
                        <div className="sm:grid sm:grid-cols-2 sm:gap-2 sm:px-2 sm:py-2 ">
                            <span className="text-sm md:border-r border-gray-300"><span className="text-gray-500">Customer:</span> {orderData?.CustomerName}</span>
                            <span className="text-sm"><span className="text-gray-500">Client:</span> {orderData?.Client}</span>
                        </div>
                    </div> */}

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
            </div>
            <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
            <DialogContent>
                <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">General Information</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Product</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.ProductName}</dd>
                                <dt className="text-sm font-medium text-gray-500">Customer</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.CustomerName}</dd>
                                <dt className="text-sm font-medium text-gray-500">Client</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Client}</dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Order Date</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{moment(orderData?.Date).format('Do MMMM YYYY')}</dd>
                                <dt className="text-sm font-medium text-gray-500">Delivery Date</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{moment(orderData?.DeliveryDate).format('Do MMMM YYYY')}</dd>
                                <dt className="text-sm font-medium text-gray-500">Quantity</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Quantity}</dd>
                            </div>

                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">File Name</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.FileName}</dd>
                                <dt className="text-sm font-medium text-gray-500">File Size</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.FileSize}</dd>
                                <dt className="text-sm font-medium text-gray-500">IsRepeat?</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.IsRepeat === 0 || null ? 'No' : 'Yes'}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-yellow-300 border-yellow-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Party Fabric</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Full</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBFull}</dd>
                                <dt className="text-sm font-medium text-gray-500">Full Meter</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBFullMtr}</dd>

                                <dt className="text-sm font-medium text-gray-500">Waterfall</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBWaterfall}</dd>
                                <dt className="text-sm font-medium text-gray-500">Waterfall Meter</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBWaterfallMtr ? orderData?.PBWaterfallMtr : '-'}</dd>
                            </div>

                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Half</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBHalf}</dd>
                                <dt className="text-sm font-medium text-gray-500">Half Meter</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBHalfMtr}</dd>

                                <dt className="text-sm font-medium text-gray-500">Main</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBMain}</dd>
                                <dt className="text-sm font-medium text-gray-500">Main Meter</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBMainMtr}</dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Step</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBStep}</dd>
                                <dt className="text-sm font-medium text-gray-500">Step Meter</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBStepMtr}</dd>
                                <dt className="text-sm font-medium text-gray-500">Total</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBTotal}</dd>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBRemarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-blue-300 border-blue-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Cutting</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Patta</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingPatta}</dd>
                                <dt className="text-sm font-medium text-gray-500">Tukda</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingTukda}</dd>
                                <dt className="text-sm font-medium text-gray-500">Table</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingTable}</dd>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                                <span className=""><Checkbox checked={orderData?.TagZigZag === 1 ? true : false} name="TagZigZag" color="primary" />ZigZag</span>
                                <span className=""><Checkbox checked={orderData?.TagInterlock === 1 ? true : false} name="TagInterlock" color="primary" />TagInterlock</span>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingRemarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>


                <div className="bg-red-300 border-red-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Binding</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500">Fabric Color</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 py-3 md:border-r border-red-300">{orderData?.BindingsFabricColor}</dd>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">
                                    <span className="md:border-r border-red-300 pr-3"> <Checkbox checked={orderData?.Bindings15 === 1 ? true : false} name="Bindings15" color="primary" />1.5</span>
                                    <span className="md:border-r border-red-300 pr-3"><Checkbox checked={orderData?.Bindings11 === 1 ? true : false} name="Bindings11" color="primary" />11</span>
                                    <span className=""><Checkbox checked={orderData?.BindingsFull ? true : false} name="BindingsFull" color="primary" />Full</span>
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Kaan</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsKaan}</dd>
                                <dt className="text-sm font-medium text-gray-500">Patti</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsPatti}</dd>
                                <dt className="text-sm font-medium text-gray-500">Dori</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsDori}</dd>
                                <dt className="text-sm font-medium text-gray-500">Corner</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.BindingsCorner}</dd>

                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.BindingsRemarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-green-300 border-green-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Tagging</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                                <span className="md:border-r border-green-300 pr-3"> <Checkbox checked={orderData?.TagLogo === 1 ? true : false} name="TagLogo" color="primary" />Logo</span>
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagCollection === 1 ? true : false} name="TagCollection" color="primary" />Collection</span>
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagDesign === 1 ? true : false} name="TagDesign" color="primary" />Design</span>
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagShed === 1 ? true : false} name="TagShed" color="primary" />Shed</span>
                                <span className="md:border-r border-green-300 pr-3"> <Checkbox checked={orderData?.TagInstruction === 1 ? true : false} name="TagInstruction" color="primary" />Instruction</span>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagSymbol === 1 ? true : false} name="TagSymbol" color="primary" />Symbol</span>
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagSrNo === 1 ? true : false} name="TagDesign" color="primary" />Sr No.</span>
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagWidth === 1 ? true : false} name="Width" color="primary" />Width ({orderData?.TagWidthValue})</span>
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TaggingRubs === 1 ? true : false} name="Width" color="primary" />Rubs ({orderData?.TaggingRubsValue})</span>
                                <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TaggingGSM === 1 ? true : false} name="Width" color="primary" />Weight ({orderData?.TaggingGSMValue})</span>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.TagRemarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-indigo-300 border-indigo-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Photo Making</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500">Photo Size</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-indigo-300">{orderData?.PhotosSize}</dd>


                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">
                                    Page
                                    <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosPage?.includes(1) ? true : false} name="PhotosPage" color="primary" />1</span>
                                    <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(2) ? true : false} name="PhotosPage" color="primary" />2</span>
                                    <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(3) ? true : false} name="PhotosPage" color="primary" />3</span>
                                    <span><Checkbox checked={orderData?.PhotosPage?.includes(4) ? true : false} name="PhotosPage" color="primary" />4</span>
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosLamination === 1 ? true : false} name="PhotosLamination" color="primary" />Lamination</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosColdPage === 1 ? true : false} name="PhotosColdPage" color="primary" />ColdPage</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosMatt === 1 ? true : false} name="PhotosMatt" color="primary" />Matt</span>
                                <span className=""><Checkbox checked={orderData?.PhotosGlossy === 1 ? true : false} name="PhotosGlossy" color="primary" />Glossy</span>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.PhotosRemarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-purple-300 border-purple-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Screen Printing</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Top: {orderData?.ScreenTop}</dt>
                                <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Bottom: {orderData?.ScreenBottom}</dt>
                                <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Patti: {orderData?.ScreenPatti}</dt>
                                <dt className="text-sm font-medium text-gray-500">Printed: {orderData?.ScreenPrinted} <Checkbox checked={orderData?.ScreenPrintedBit === 1 ? true : false} name="PhotosGlossy" color="primary" /></dt>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.ScreenRemarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-pink-300 border-pink-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Fittings</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500">Step Gap: {orderData?.FittingStepGap}</dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <span className="md:border-r border-pink-300 pr-3"> <Checkbox checked={orderData?.FittingNumber?.includes(1) ? true : false} name="FittingNumber" color="primary" />1</span>
                                    <span className="md:border-r border-pink-300 pr-3"><Checkbox checked={orderData?.FittingNumber?.includes(2) ? true : false} name="FittingNumber" color="primary" />2</span>
                                    <span className="md:border-r border-pink-300 pr-3"><Checkbox checked={orderData?.FittingNumber?.includes(3) ? true : false} name="FittingNumber" color="primary" />3</span>
                                    <span><Checkbox checked={orderData?.FittingNumber?.includes(4) ? true : false} name="PhotosPage" color="primary" />4</span>
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.FittingRemarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-red-500 border-red-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Extra</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                    <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                                </dt>

                                <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                    <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                                </dt>
                            </div>
                        </dl>
                    </div>
                </div>


                <div className="bg-blue-500 border-blue-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Remarks</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Remarks}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                {currentUser()?.Role === 'SUPER_ADMIN' &&
                    <div className="bg-purple-500 border-purple-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                        Price: {showPrice ? orderData?.UnitPrice : '****'}

                                        {!showPrice &&
                                            <Tooltip title="Verify to view price" placement="top" arrow>
                                                <Box className="bg-blue-500 text-white ml-4" aria-controls="simple-menu-1" aria-haspopup="true" onClick={handleShowPrice} size="small" component={Button}  >
                                                    <VpnKey onClick={handleShowPrice} className="w-5 h-auto" />
                                                </Box>
                                            </Tooltip>
                                        }

                                    </dd>

                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <ConfirmDialog onClose={() => setConfirmOpen(false)} open={confirmOpen} message="Are you sure you want to generate e-catelouge order" handleConfirm={handleGenerateOrder} title={"Generate E-catelouge Order"} confirmBtnTitle="Yes" />
            </DialogContent>
        </Dialog>
    );
};
export default ViewOrderDialog;