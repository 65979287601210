import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";


export function currentUser() {
    if(Cookies.get('token'))
    {
        return jwtDecode(Cookies.get('token'));
        
    }
    else
    {
        return null;
    }
}

export function decodeToken(token) {
    if(token)
    {
        return jwtDecode(token);
    }
    else
    {
        return null;
    }
}