import React from "react";
import { Box, Button, Checkbox, DialogContent, Tooltip } from '@mui/material';
import { VpnKey } from '@mui/icons-material';
import moment from "moment";
import { currentUser } from "services/user-service";

function ViewECatalogue(props) {
    const { orderData, handleShowPrice, showPrice } = props;

    return (
        <DialogContent>
            <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">General Information</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Product</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.ProductName}</dd>
                            <dt className="text-sm font-medium text-gray-500">Customer</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.CustomerName}</dd>
                            <dt className="text-sm font-medium text-gray-500">Client</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Client}</dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Order Date</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{moment(orderData?.Date).format('Do MMMM YYYY')}</dd>
                            <dt className="text-sm font-medium text-gray-500">Delivery Date</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{moment(orderData?.DeliveryDate).format('Do MMMM YYYY')}</dd>
                            <dt className="text-sm font-medium text-gray-500">Quantity</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Quantity}</dd>
                        </div>

                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">File Name</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.FileName}</dd>
                            <dt className="text-sm font-medium text-gray-500">File Size</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.FileSize}</dd>
                            <dt className="text-sm font-medium text-gray-500">IsRepeat?</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.IsRepeat === 0 || null ? 'No' : 'Yes'}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-yellow-300 border-yellow-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Photoshoot</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECataloguePhotoShoot ? true : false} name="ECataloguePhotoShoot" color="primary" />Photoshoot</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-blue-300 border-blue-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Fabric Layout</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueFabricLayout ? true : false} name="ECatalogueFabricLayout" color="primary" />Fabric Layout</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="bg-red-300 border-red-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Color Matching</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueColorMatching ? true : false} name="ECatalogueColorMatching" color="primary" />Color Matching</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-indigo-300 border-indigo-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Approval</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueApproval ? true : false} name="ECatalogueApproval" color="primary" />Approval</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-purple-300 border-purple-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Mail</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueMail ? true : false} name="ECatalogueMail" color="primary" />Mail</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-pink-300 border-pink-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Delivery</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={orderData?.ECatalogueDelivery ? true : false} name="ECatalogueDelivery" color="primary" />Delivery</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-red-500 border-red-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>


            <div className="bg-blue-500 border-blue-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Remarks</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Remarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            {currentUser()?.Role === 'SUPER_ADMIN' &&
                <div className="bg-purple-500 border-purple-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                    Price: {showPrice ? orderData?.UnitPrice : '****'}

                                    {!showPrice &&
                                        <Tooltip title="Verify to view price" placement="top" arrow>
                                            <Box className="bg-blue-500 text-white ml-4" aria-controls="simple-menu-1" aria-haspopup="true" onClick={handleShowPrice} size="small" component={Button}  >
                                                <VpnKey onClick={handleShowPrice} className="w-5 h-auto" />
                                            </Box>
                                        </Tooltip>
                                    }

                                </dd>

                            </div>
                        </dl>
                    </div>
                </div>
            }
        </DialogContent>
    );
};
export default ViewECatalogue;