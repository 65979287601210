/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';

import { dataService } from 'services/data-service';
import SalesReportTable from './components/SalesReportTable';
import { currentUser } from 'services/user-service';

import OrderHeader from 'components/Headers/OrderHeader';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles(componentStyles);

const ProductionReportsDailyDetail = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { month, year, day, workCenerDateId, workCenertId } = useParams();

  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(year);
  const selectedOrderFlow = useSelector(state => state?.selectedOrderFlow);

  const [selectedDate, setSelectedDate] = useState({
    date : day, month : month, year : year, formatDate : `${year}-${month}-${day}`
  })
  const columns = [
    {
      id: 'OrderNumber',
      numeric: false,
      disablePadding: true,
      label: 'Order Number',
      dataType: 'string',
    },
    {
      id: 'ProductName',
      numeric: false,
      disablePadding: true,
      label: 'Product Name',
      dataType: 'string',
    },
    {
      id: 'FileName',
      numeric: false,
      disablePadding: true,
      label: 'File Name',
      dataType: 'string',
    },
    {
      id: 'CustomerName',
      numeric: false,
      disablePadding: true,
      label: 'Customer Name',
      dataType: 'string',
    },
    {
      id: 'Client',
      numeric: false,
      disablePadding: true,
      label: 'Client',
      dataType: 'string',
    },
    {
      id: 'WorkCenterName',
      numeric: false,
      disablePadding: true,
      label: 'Work Center',
      dataType: 'string',
    },
    {
      id: 'Quantity',
      numeric: false,
      disablePadding: true,
      label: 'Quantity Out',
      dataType: 'string',
    },
    {
      id: 'FlowStepHoldReason',
      numeric: false,
      disablePadding: true,
      label: 'Hold Reason',
      dataType: 'string',
    },
  ];

  useEffect(() => {
    fetchData()
  }, [selectedYear, selectedOrderFlow?.selected_flow?.product?.ProductId, selectedDate]);

  const fetchData = async () => {
    let curUser = currentUser();
    if (curUser?.UserId !== 1 && curUser?.UserId !== 3) {
      history.push(`/admin`);
      return false;
    }

    setIsLoading(true);
    let reqBody = generateQueryPostData();
    let res = await dataService('post', 'ProcutionReports', reqBody);

    const newRes = res?.data?.dataReport?.map((ele) => ({
      ...ele, Quantity: ele?.QuantityOut
    }))
    setCompletedOrderList(newRes);

    setIsLoading(false);
  }

  const generateQueryPostData = () => {
    let reqBody = {
      Year: selectedYear,
      ReportType: 'DAILY',
      Month: month,
      ProductId: selectedOrderFlow?.selected_flow?.product?.ProductId,
      Day: day,
      WorkCenterId: Number(workCenertId) || Number(workCenerDateId) || 0
    };
    return reqBody;
  };

  return (
    <>
      <OrderHeader isHideHeader={true} />
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <SalesReportTable
          title={null}
          columns={columns}
          rows={completedOrderList}
          actions={[]}
          loading={isLoading}
          selectedYear={selectedYear}
          selectedMonth={month}
          selectedDay={day}
          setSelectedYear={setSelectedYear}
          reportType={'DAILY'}
          isHideTotal={false}
          isHideAmount={true}
          isNotAmount={false}
          workCenterName={workCenerDateId && props?.location?.state?.workCenterName}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          isCalender={true}
          WorkCenterId={workCenerDateId}
        />
        <div>
        </div>

      </Container>
    </>
  );
};

export default ProductionReportsDailyDetail;
