/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';

import { dataService } from 'services/data-service';
import moment from 'moment';
import SalesReportTable from './components/SalesReportTable';
import { useHistory } from 'react-router-dom';
import { currentUser } from 'services/user-service';

import {Chart} from 'chart.js';
import { parseOptions } from 'variables/charts';
import { chartOptions } from 'variables/charts';
import { Typography } from '@mui/material';
import OrderHeader from 'components/Headers/OrderHeader';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(componentStyles);

const ProductionReports = () => {
  const classes = useStyles();
  const history = useHistory();
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const selectedOrderFlow = useSelector(state => state?.selectedOrderFlow);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const columns = Object.keys(completedOrderList?.[0] || []).map(columnId => ({
    id: columnId,
    numeric: false,
    disablePadding: true,
    label: columnId,
    dataType: 'component',
    Component: ({ row }) => (
      <Typography onClick={() => {
        if(columnId === "Month") {
          history.push(
            `/admin/report/productions-report-daily/${selectedYear}/${moment()
              .month(row[columnId]?.value)
              .format('M')}/0`    
          )
        }
        else {
          history.push(
            `/admin/report/productions-report-daily/${selectedYear}/${moment()
              .month(row[columnId]?.month)
              .format('M')}/${row[columnId].id}`
          )
        }
       
      }}>
        {row[columnId].value}
      </Typography>
    ),  
  }));

  useEffect(() => {
    fetchData()
  }, [selectedYear, selectedOrderFlow?.selected_flow?.product?.ProductId]);

  const fetchData = async() => {
    let curUser = currentUser();
    if (curUser?.UserId !== 1 && curUser?.UserId !== 3) {
      history.push(`/admin`);
      return false;
    }

    setIsLoading(true);
    let reqBody = generateQueryPostData();
    let res = await dataService('post', 'ProcutionReports', reqBody);

    const convertedData = res?.data?.dataReport.map(item => {
      const convertedItem = { Month: item.Month };
      for (const key in item) {
        if (key !== 'Month') {
          const id = parseInt(key.match(/\d+/)[0]);
          convertedItem[key.split('{')[0].trim()] = { title: key.split('{')[0].trim(), value: item[key], id, month :item.Month  };
        }
      }
      return convertedItem;
    });

    const formattedData = convertedData?.map((item) => {
      const formattedItem = { ...item };
      formattedItem.Month = {
          title: "Month",
          value: item.Month,
          id: null  
      };
      return formattedItem;
  });
    setCompletedOrderList(formattedData)
    setIsLoading(false);
  }  

  const generateQueryPostData = () => {
    let reqBody = {
      Year: selectedYear,
      ReportType: 'MONTHLY',
      ProductId: selectedOrderFlow?.selected_flow?.product?.ProductId
    };
    return reqBody;
  };

  return (
    <>
      <OrderHeader isHideHeader={true} />
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <SalesReportTable
          title={null}
          columns={columns || []}
          rows={completedOrderList}
          actions={[]}
          loading={isLoading}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          reportType={'MONTHLY'}
          isHideTotal={true}
        />
        <div>
        </div>

      </Container>
    </>
  );
};

export default ProductionReports;
