import { DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import moment from "moment";
import React from 'react';

function ViewDataDialog(props){
	const { onClose, open, message, viewDialogData } = props;

	const handleClose = () => {
		onClose(false);
	};

	return (
		<Dialog onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="simple-dialog-title" open={open}>
            <div className="flex flex-wrap items-center justify-between">
            <DialogTitle id="form-dialog-title" > 
                <span className="text-2xl">{viewDialogData.title}</span>
            </DialogTitle>
            <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
            </div>
			
            <DialogContent>                
            {/* <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                <Close />
            </svg> */}

            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    {/* <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{viewDialogData.title}</h3>
                    </div> */}
                    <div className="border-t border-gray-200">
                        <dl>
                        {viewDialogData.labels.map((label, index) => (
                            <div className={`px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                <dt className="text-sm font-medium text-gray-500">{label.DisplayName}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {label?.DataType === 'date' ?
                                        viewDialogData?.data[label.Name] === null ? '' : moment(viewDialogData?.data[label.Name]).format('Do MMMM YYYY')
                                    :
                                        viewDialogData?.data[label.Name]
                                    }
                                </dd>
                            </div>    
                        ))}
                        </dl>
                    </div>
                    </div>
            </DialogContent>
		</Dialog>
	);
};
export default ViewDataDialog;