/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
import { useParams } from 'react-router-dom';
import { updatePreviousLocations } from 'generic/store';
import { useDispatch } from 'react-redux';
import SalesReportTable from './components/SalesReportTable';


const useStyles = makeStyles(componentStyles);

const CustomerDailyDetailOrderReport = () => {
  
  const dispatch = useDispatch();

  const { customerId, month, year , day} = useParams();
  const classes = useStyles();
  const [customerList, setCustomerList] = useState([]);

  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth] = useState(month);
  const [isLoading, setIsLoading] = useState(true);

 
  const columns = [
  
    {
      id: 'ProductName',
      numeric: false,
      disablePadding: false,
      label: 'Product Name',
      dataType: 'string',
    },
    {
      id: 'OrderNumber',
      numeric: false,
      disablePadding: false,
      label: 'Order Number',
      dataType: 'string',
    },
    {
      id: 'Client',
      numeric: false,
      disablePadding: false,
      label: 'Client',
      dataType: 'string',
    },
    {
      id: 'FileName',
      numeric: false,
      disablePadding: false,
      label: 'File Name',
      dataType: 'string',
    },
    {
      id: 'Status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      dataType: 'string',
    },
    {
      id: 'Quantity',
      numeric: false,
      disablePadding: false,
      label: 'Quantity',
      dataType: 'string',
    },
    {
      id: 'Amount',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
      dataType: 'currency',
    },
   
  ];

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async() => {
    dispatch(updatePreviousLocations(null));
    setIsLoading(true);
    let res = await dataService(
      'get',
      'CustomerReportSales',
      `Year=${year}&Month=${month}&Day=${day}&CustomerId=${customerId}`
      
    );
    setCustomerList(res?.data);
    setIsLoading(false);
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
         <SalesReportTable
          title={customerList?.CustomerName}
          columns={columns}
          rows={customerList?.data || []}
          actions={[]}
          loading={isLoading}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          setSelectedYear={setSelectedYear}
          reportType={'DAILY'}
          isChartVisible={false}
          selectedDay={day}
        />
       
    
      </Container>
    </>
  );
};

export default CustomerDailyDetailOrderReport;
