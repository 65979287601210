import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { dataService } from "services/data-service";
import { showVerificationDialog } from "../VerifiyOTPDialog";
import ViewOrderFile from "./ViewOrderFile";
import ViewOrderHeader from "./ViewOrderHeader";
import ViewShadeCard from "./ViewOrderShadeCard";
import ViewECatalogue from "./ViewOrderECatalogue";
import ProductBGColors from "components/Common/ProductBGColors";
import ConfirmDialog from "../ConfirmDialog";
import { Box, Button } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";

function ViewOrderDialog(props) {
  const { onClose, open, OrderId, setIsReload, isReload } = props;
  const [orderData, setOrderData] = useState();
  const [showPrice, setShowPrice] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [eCateLougeValue, seteCateLougeValue] = useState({
    Remarks : null,
    UnitPrice : null
  })

  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleShowPrice = () => {
    showVerificationDialog('Verify to view price', true, () => {
    }, async () => {
      setShowPrice(true)
    })
  };

  useEffect(() => {
    fetchData()
  }, [OrderId])

  const fetchData = async () => {
    let res = await dataService('get', `OrderGet/${OrderId}`, '');
    if (res?.data) {
      res.data.PhotosPage = res?.data?.PhotosPage ? res?.data?.PhotosPage?.split(',')?.map(Number) : [];
      res.data.FittingNumber = res?.data?.FittingNumber ? res?.data?.FittingNumber?.split(',')?.map(Number) : [];
    }
    setOrderData(res?.data);
  }

  console.log("orderData,,", orderData)

  const renderSection = () => {
    switch (orderData?.ProductName) {
      case 'Header':
        return <ViewOrderHeader orderData={orderData} handleShowPrice={handleShowPrice} showPrice={showPrice} />;
      case 'Shade':
        return <ViewShadeCard orderData={orderData} handleShowPrice={handleShowPrice} showPrice={showPrice} />;
      case 'E-Catalogue':
        return <ViewECatalogue orderData={orderData} handleShowPrice={handleShowPrice} showPrice={showPrice} />;
      default:
        return <ViewOrderFile orderData={orderData} handleShowPrice={handleShowPrice} showPrice={showPrice} />;
    }
  }

  const handleGenerateOrder = async () => {
    let eCateLouge = {
      // ProductId: orderData ? orderData?.ProductId : null,
      CustomerId: orderData ? orderData?.CustomerId : null,
      Client: orderData ? orderData?.Client : null,
      Date: orderData ? orderData?.Date : null,
      ProductFileId: orderData ? orderData?.ProductFileId : null,
      FileId: orderData ? orderData?.FileId : null,
      Quantity: orderData ? orderData?.Quantity : null,
      DeliveryDate: orderData ? orderData?.DeliveryDate : null,
      IsRepeat: orderData ? orderData?.IsRepeat : null,
      RepeatOrderId: orderData ? orderData?.RepeatOrderId : null,
      UnitPrice: orderData ? eCateLougeValue?.UnitPrice : null,
      Parcel: orderData ? orderData?.Parcel : null,
      Loose: orderData ? orderData?.Loose : null,
      ChallanSequence1: orderData ? orderData?.ChallanSequence1 : null,
      ChallanSequence3: orderData ? orderData?.ChallanSequence3 : null,
      Remarks: orderData ? eCateLougeValue?.Remarks : null,
      ProductId: orderData ? 4 : null,
      ECataloguePhotoShoot: true,
      ECatalogueFabricLayout: true,
      ECatalogueColorMatching: true,
      ECatalogueApproval: true,
      ECatalogueMail: true,
      ECatalogueDelivery: true,
      BillingEmail: orderData ? orderData?.BillingEmail : null,
      ShippingEmail: orderData ? orderData?.ShippingEmail : null
    }

    eCateLouge.PhotosPage = orderData?.PhotosPageArry?.sort()?.join();
    eCateLouge.FittingNumber = orderData?.FittingNumberArry?.sort()?.join();



    let res = await dataService('post', `OrderSave/${0}`, eCateLouge);

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    if (res && (res.status === 200 || res.status === 304)) {
    setMessageDialoge({ show: true, type: 'success', message:  'E-catalogue order generate successfully!' });
    setConfirmOpen(false)
    setIsReload(!isReload)
    setTimeout(() => {
      handleClose()
    }, 500);
    } else {
      setMessageDialoge({ show: true, type: 'error', message: res.message });
    }
   
  }

  return (
    <Dialog onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" open={open}>
      <div className={`bg-${ProductBGColors[orderData?.ProductName]}`}>
        <div className="flex flex-wrap items-center justify-between">
          <DialogTitle id="form-dialog-title" >
            <div className="flex flex-row">
              <div>
                <span className="text-2xl">Order Information </span><br />
                <span className="text-lg text-gray-500">  [Order #: {orderData?.OrderNumber}]</span>
              </div>
              {orderData?.ProductId !== 4 &&
              <Box
                justifyContent="center"
                display="flex"
                flexWrap="wrap"
                className="ml-4"
              >
                <Button
                  variant="contained"
                  className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]"
                  color="primary"
                  size="small"
                  onClick={() => setConfirmOpen(true)}
                >
                  {"Generate E-catalogue"}
                </Button>
              </Box>
}
            </div>
          </DialogTitle>
          <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
        </div>
        <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
        {renderSection()}
      </div>
      <ConfirmDialog onClose={() => setConfirmOpen(false)} open={confirmOpen} message="Are you sure you want to generate e-catelouge order" handleConfirm={handleGenerateOrder} title={"Generate E-catelouge Order"} confirmBtnTitle="Yes" orderModel={eCateLougeValue} setOrderModel={seteCateLougeValue} />
    </Dialog>
  );
};
export default ViewOrderDialog;