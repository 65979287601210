import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import EditIcon from '@mui/icons-material/Edit';
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  FilledInput,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import { CustomButton } from "components/Common/CustomButton";
import { checkEmptyValidation } from "services/validation-service";


const useStyles = makeStyles(componentStyles);

const FileDataList = () => {
  const classes = useStyles();
  const [fileList, setFileList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isReload, setIsReload] = useState(true);

  const columns = [
    { id: 'FileId', numeric: false, disablePadding: true, label: 'File ID', dataType: 'string' },
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name', dataType: 'string' },
    { id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Created At', dataType: 'date' }
  ];

  // File form related variables
  const [PageTitle, setPageTitle] = useState(`Add File`);
  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
  const [FileModel, setFileModel] = useState({ FileId: 0, Name: '' });
  const [FileValidateModel, setFileValidateModel] = useState({
    Name: { message: '', touched: false },
    IsValidationError: false
  });

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  const fetchData = async() => {
    setIsLoading(true);
    let res = await dataService('get', 'FileList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
    setFileList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const handleEdit = (row) => {
    setPageTitle('Update File');
    setOpen(true);
    setFileModel({
      ...FileModel,
      FileId: row?.FileId,
      Name: row?.Name
    });

  };
  let actionList = [{ label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit }]

  const handleClose = () => {
    setOpen(false);
    setFileModel({ ...FileModel, FileId: 0, Name: '' })
    setPageTitle("Add File")
    setMessageDialoge({
      ...messageDialoge,
      show: false,
      type: '',
      message: ''
    });
    setShowSubmitLoading(false);
    setIsReload(!isReload);
  }
  // File add/edit dialoge
  const _renderFileDialog = () => {
    return (
      <Dialog onClose={handleClose} disableBackdropClick aria-labelledby="simple-dialog-title" open={open}>
        <DialogContent>
          <Card
            classes={{
              root: classes.cardRoot + " " + classes.cardRootSecondary,
            }}
          >
            <CardHeader
              subheader={
                <Grid
                  container
                  component={Box}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs="auto">
                    <Box
                      component={Typography}
                      variant="h3"
                      marginBottom="0!important"
                    >
                      {PageTitle}
                    </Box>
                  </Grid>
                </Grid>
              }
              classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>
            <CardContent>
              {messageDialoge.show &&
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                </Grid>}
              <div className={classes.plLg4}>
                <FormGroup>
                  <div className="flex flex-wrap items-center justify-start ">
                    <div className="w-full md:w-auto">
                      <FormLabel>File Name</FormLabel>
                      <TextField
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                        autoComplete="off"
                        value={FileModel.Name}
                        error={FileValidateModel.Name.message !== '' && FileValidateModel.Name.touched}
                        helperText={FileValidateModel.Name.message !== '' && FileValidateModel.Name.touched ? FileValidateModel.Name.message : ''}
                        onChange={e => handleChange('Name', e.target.value)}
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                        />
                      </TextField>
                    </div>
                  </div>
                </FormGroup>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitFile} />
          <CustomButton onClick={handleClose} label="Cancel" className="bg-red-700" />
        </DialogActions>
      </Dialog>
    )

  }


  const handleChange = (id, value) => {
    switch (id) {
      case 'Name':
        setFileModel({ ...FileModel, Name: value });
        setFileValidateModel({ ...FileValidateModel, Name: { message: checkEmptyValidation(value, 'File Name'), touched: true } });
        break;
    }
  };


  const checkValidations = () => {
    let validateErrors = {
      Name: { message: checkEmptyValidation(FileModel.Name, `File Name`), touched: true }
    }
    if (validateErrors.Name.message !== '') {
      validateErrors.IsValidationError = true;
    }
    else {
      validateErrors.IsValidationError = false;
    }
    setFileValidateModel(validateErrors);
    return validateErrors.IsValidationError;

  }

  const handleSubmitFile = async () => {
    if (!checkValidations()) {
      setShowSubmitLoading(true);
      let res = await dataService('post', `FileSave/${FileModel?.FileId}`, FileModel);
      setShowSubmitLoading(false);
      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialoge({ show: true, type: 'success', message: FileModel?.FileId > 0 ? `File updated successfully!` : 'File registered successfully!' });
      } else {
        //setOpen(res.message);
        setMessageDialoge({ show: true, type: 'error', message: res.message });
      }
    }
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="File Name List"
          columns={columns}
          rows={fileList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>

      {open && _renderFileDialog()}
    </>
  );
};

export default FileDataList;