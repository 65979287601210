import React, { useEffect, useState } from 'react';
//import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import Grid from "@mui/material/Grid";
import { dataService } from 'services/data-service';
import { Close } from '@mui/icons-material';
import { CustomButton } from "components/Common/CustomButton";
import { Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function AddPriorityDialog(props) {
    const { open, onClose, priority, filters, setIsReload, isReload } = props;

    const { WorkCenterId } = useParams();
    const [priorityList, setPriorityList] = useState(null);

    const getHoldReasonOptions = async () => {
        let res = await dataService('get', `FlowStepPriorityList/${WorkCenterId}?ProductId=${filters?.ProductId}&CuttingTable=${filters?.CuttingTable}`);
        if (res.status === 200) {

            const fullPriorityList = Array.from({ length: process.env.REACT_APP_FLOW_PRIORITY || 5 }, (_, i) => {
                const priority = i + 1;
                const existingItem = res?.data.find(item => item.Priority === priority);
                return existingItem ? { "OrderId": existingItem.OrderId, "Priority": priority } : { "OrderId": null, "Priority": priority };
            });

            setPriorityList(fullPriorityList);
        }
    }

    useEffect(() => {
        getHoldReasonOptions()
    }, [])

    const handleClose = () => {
        onClose();
        setIsReload(!isReload)
    };


    const handleCheckboxChange = async (selectedPriority, checked) => {
        if (checked) {
            await dataService('post', `FlowStepPrioritySave/${priority?.FlowStepId}`, { WorkCenterId: WorkCenterId, Priority: selectedPriority });
        }
        else{
           await dataService('post', `FlowStepPrioritySave/${priority?.FlowStepId}`, { WorkCenterId: WorkCenterId, Priority: null });
        }

        setPriorityList(prevList =>
            prevList.map(item => {
                if (item.Priority === selectedPriority) {
                    return { ...item, OrderId: item.OrderId ? null : priority?.OrderId };
                } else if (item.OrderId === priority?.OrderId) {
                    return { ...item, OrderId: null };
                } else {
                    return item;
                }

            })
        );
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
            <div className='bg-green-500 border-green-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between'>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div className="text-gray-100">
                            <span className="text-2xl">Priority - {priority?.WorkCenterName}</span><br />
                        </div>
                        <div className="text-gray-100 flex flex-wrap justify-end">
                            <div className="mr-10">
                                <span className="text-base block mb-1">  <strong> Order #: </strong>{priority?.OrderNumber}</span>
                            </div>
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>

            <DialogContent className="py-2">
                <Grid container>
                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center" textAlign="center">
                            {priorityList && priorityList.map((item) => {
                                const isChecked = item.OrderId !== null;
                                return (
                                    <Grid item xs={6} sm={4} md={2.4} key={item.Priority}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(event) => handleCheckboxChange(item.Priority, event?.target?.checked)}
                                                />
                                            }
                                            label={isChecked ? `${item.Priority} - (#${item.OrderId})` : item.Priority.toString()}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Ok" onClick={handleClose} />
            </DialogActions>
        </Dialog>
    );
}