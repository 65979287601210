import React from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableSortLabel from '@mui/material/TableSortLabel';
// @mui/icons-material components
//import MoreVert from "@mui/icons-material/MoreVert";
// import EditIcon from '@mui/icons-material/Edit';

import InputBase from "@mui/material/InputBase";
// @mui/icons-material components
import SearchIcon from "@mui/icons-material/Search";

import componentStyles from "assets/theme/views/admin/tables.js";
import { TablePagination } from "@mui/material";
import moment from "moment";
import { LoadingIcon } from "components/Icons/Loading";
//import moment from "moment";

const useStyles = makeStyles(componentStyles);

const ViewTable = (props) => {
    let timeout = 0;
    const { title, rows, page, total, pagedata, detail, setSearchText, order, orderBy, orderData, orderByData, columns, actions, setOffset, offset, isSearchable, loadingRows, isLoading } = props;
    const headCells = columns;
    const classes = useStyles();

    // const handleEditClick = (row) => {
    //     editAction(row);
    // };

    const handleViewClick = (row) => {
        if (actions?.[0]) {
            let viewAction = actions[0];
            viewAction(row);
        }

    };

    //Handle Page changes
    const handleChangePage = (event, newPage) => {
        pagedata(newPage + 1);
    };

    //Handle Page changes
    const handleChangePageRows = (event) => {
        setOffset(parseInt(event.target.value, 10))
        pagedata(1);
    };

    //Search data
    const handleSearchInput = (e) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchText(e.target.value)
            pagedata(1);
        }, 400);
    }

    /*------------- Table Headers Start ------------*/
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        orderData(isAsc ? 'desc' : 'asc');
        orderByData(property);
    };

    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={'none'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            classes={{
                                root:
                                    classes.tableCellRoot + " " + classes.tableCellRootHead,
                            }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    {/* <TableCell
                        key={100}
                        align={'center'}
                        padding={'none'}
                        classes={{
                            root:
                                classes.tableCellRoot + " " + classes.tableCellRootHead,
                        }}
                    >
                        Action
                    </TableCell> */}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    /*------------- Table Headers End ------------*/

    /*------------- Table Content Start ------------*/

    function EnhancedTableRows(props) {
        const { classes, rows } = props;


        return (
            <TableBody>
                {rows?.map((rowData, index) => {
                    return (

                        <TableRow key={index} onClick={() => { handleViewClick(rowData) }} className="cursor-pointer">
                            {headCells.map((headCell) => {
                                return (
                                    <>
                                        {isLoading && loadingRows[rowData.FlowStepId] ? (
                                            <TableCell colSpan={columns.length + 2} align="center">
                                                <LoadingIcon />
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                classes={{
                                                    root:
                                                        classes.tableCellRoot +
                                                        " " +
                                                        classes.tableCellRootBodyHead,
                                                }}
                                                // style={{
                                                //     whiteSpace: "normal",
                                                //     wordWrap: "break-word"
                                                //   }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                <Box alignItems="center" display="flex">
                                                    <Box display="flex" alignItems="flex-start">
                                                        <Box fontSize=".875rem" component="span">
                                                            {headCell.action ? (
                                                                <div onClick={() => { headCell?.action(rowData) }} className="cursor-pointer hover:underline" >{rowData[headCell.id]}</div>
                                                            ) : (<>
                                                                {headCell.dataType === "component" && headCell.Component(rowData)}
                                                                {headCell.dataType === 'string' && rowData[headCell.id]}
                                                                {headCell.dataType === 'date' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('D-MM-YYYY') : ''}
                                                                {headCell.dataType === 'datetime' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('D-MM-YYYY HH:mm:ss A') : ''}
                                                            </>)}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )}
                                    </>
                                )
                            })
                            }
                            {/* <TableCell
                                classes={{ root: classes.tableCellRoot }}
                                align="right"
                            >
                                <Box
                                    aria-controls="simple-menu-1"
                                    aria-haspopup="true"
                                    onClick={(e) => { e.stopPropagation(); handleEditClick(rowData) }}
                                    size="small"
                                    component={Button}
                                    width="2rem!important"
                                    height="2rem!important"
                                    minWidth="2rem!important"
                                    minHeight="2rem!important"
                                >
                                    <Box
                                        component={EditIcon}
                                        width="1.25rem!important"
                                        height="1.25rem!important"
                                        position="relative"
                                        top="2px"
                                        color={theme.palette.gray[500]}
                                    />
                                </Box>

                            </TableCell> */}
                        </TableRow>

                    )
                })
                }
            </TableBody>
        )
    }


    EnhancedTableRows.propTypes = {
        // rows, page, total, pagedata, setSearchText
        classes: PropTypes.object.isRequired,
        rows: PropTypes.array
    };

    /*------------- Table Content End ------------*/




    return (
        <>

            {/* Page content */}
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    className="px-2 py-3"
                    subheader={
                        <Grid
                            container
                            component={Box}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item xs="auto">
                                <Box
                                    component={Typography}
                                    variant="h3"
                                    marginBottom="0!important"
                                >
                                    {title}<br />
                                    <span className="text-base"> Order # : {detail?.OrderNumber} </span>
                                </Box>

                            </Grid>
                            {isSearchable &&
                                <Grid item xs="auto">
                                    <Box display="flex" alignItems="center" width="auto">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            width="auto"
                                            marginRight="1rem"
                                            classes={{
                                                root: classes.searchBox,
                                            }}
                                        >
                                            <SearchIcon className={`${classes.searchIcon} text-black`} />
                                            <InputBase
                                                placeholder="Search"
                                                classes={{
                                                    input: `${classes.searchInput} text-black`,
                                                }}
                                                onChange={(e) => { handleSearchInput(e) }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>
                <TableContainer>
                    <Box
                        component={Table}
                        alignItems="center"
                        marginBottom="0!important"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows?.length}
                        />

                        <EnhancedTableRows
                            classes={classes}
                            rows={rows}
                            page={page}
                            total={total}
                        />
                    </Box>
                </TableContainer>
                <Box
                    classes={{ root: classes.cardActionsRoot }}
                    component={CardActions}
                    justifyContent="flex-end"
                >

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={offset}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangePageRows}
                    />
                </Box>
            </Card>
        </>
    );
};

export default ViewTable;