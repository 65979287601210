import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, Stack } from '@mui/material';

// third-party
import { useDropzone } from 'react-dropzone';

// project import
import FilesPreview from './FilesPreview';
import { Close } from '@mui/icons-material';
import RejectionFiles from 'components/Upload/RejectionFiles';
import PlaceholderImage from 'components/Upload/PlaceholderImage';

const DropzoneWrapper = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  // padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed black`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' }
}));


// ==============================|| UPLOAD - SINGLE FILE ||============================== //

const ImageUpload = ({ error, document, name, setDocument, sx, accept, submit, displayPreview, displayUploadButton = true, setErrors }) => {
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: accept,
    multiple: false,
    onDrop: (acceptedFiles) => {
      setDocument({
        ...document,
        [name]: acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      }
      );
      setErrors({
        ...error,
        [name] : delete error?.[name]
      })
    }
  });

  const thumbs =
    document?.[name] &&
    document?.[name]?.map((item) => (
      <Box sx={{width : "100%"}}>
      <Box
        key={item.name}
        sx={{
          width: "60%",
          height: "180px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <img
          key={item.name}
          alt={item.name}
          src={item.preview}
          style={{
            top: 8,
            left: 8,
            borderRadius: 2,
            position: 'absolute',
            width: 'calc(100% - 20px)',
            height: 'calc(100% - 20px)',
            background: theme.palette.background.paper
          }}
          onLoad={() => {
            URL.revokeObjectURL(item.preview);
          }}
        />
      </Box>
        <Button
          size="large"
          color="error"
          shape="rounded"
          onClick={(event) => {
            event.stopPropagation();
            onRemove(document?.[name]);
          }}
          sx={{
            fontSize: "0.875rem",
            bgcolor: "background.paper",
            p: 0.5,
            width: "auto",
            height: "auto",
            top: 0,
            right: 0,
            position: "absolute",
            zIndex: 10,
          }}
        >
          <Close fontSize='large' />
        </Button>
      </Box>
    ));

  const onRemove = () => {
    setDocument({
      ...document, [name]: null
    });
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropzoneWrapper
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
        }}
      >
        <input {...getInputProps()} />
        {displayPreview ?
          (document?.[name] && document?.[name].length > 0) && <FilesPreview files={document?.[name]} showList={false} onRemove={onRemove} type={false} /> : null}
        {!document?.[name] && <PlaceholderImage theme={theme} />}
        {!displayPreview && thumbs}
      </DropzoneWrapper>


      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}


      {displayUploadButton ?
        document?.[name] && document?.[name].length > 0 && (
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1.5 }}>
            <Button sx={{ mx: 2 }} variant="contained" color="error" onClick={onRemove}>
              Remove
            </Button>
            <Button variant="contained" color="primary" onClick={submit}>
              Upload
            </Button>
          </Stack>
        ) : null}
    </Box>
  );
};

ImageUpload.propTypes = {
  error: PropTypes.object,
  file: PropTypes.object,
  name: PropTypes.string,
  setDocument: PropTypes.func,
  sx: PropTypes.object
};

export default ImageUpload;
