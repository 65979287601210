import React, { useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableSortLabel from '@mui/material/TableSortLabel';
// @mui/icons-material components
//import MoreVert from "@mui/icons-material/MoreVert";

import InputBase from "@mui/material/InputBase";
// @mui/icons-material components
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from 'react-router-dom';

import componentStyles from "assets/theme/views/admin/tables.js";
import { ButtonGroup, Chip, TablePagination, Tooltip } from "@mui/material";
import moment from "moment";
import ThreeDotsMenu from "components/ThreeDotMenu/ThreeDotMenu";
import { LoadingIcon } from "components/Icons/Loading";
import { GoodsEntryIcon } from "components/Icons/CustomIcons";
import CardStatsDialog from "components/dialoges/CardStatsDialog";
// import FilterListIcon from '@mui/icons-material/FilterList';
// import { GoodsEntryFilter } from "components/Filters/GoodsEntryFilter";
//import moment from "moment";

const useStyles = makeStyles(componentStyles);

const CustomerGoodsEntryTable = (props) => {
  const history = useHistory();
  let timeout = 0;
  const { view, title, rows, page, total, pagedata, setSearchText, order, orderBy, orderData, orderByData, columns, actions, setOffset, offset, loading, searchStatus, setSearchStatus } = props;
  const headCells = columns;
  const classes = useStyles();
  const [goodsEntryStats] = useState([]);
  const [openGoodsEntryStats, setOpenGoodsEntryStats] = useState(false);

  //Handle Page changes
  const handleChangePage = (event, newPage) => {
    pagedata(newPage + 1);
  };

  //Handle Page changes
  const handleChangePageRows = (event) => {
    setOffset(parseInt(event.target.value, 10))
    pagedata(1);
  };

  //Search data
  const handleSearchInput = (e) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchText(e.target.value)
      pagedata(1);
    }, 400);
  }


  const searchStatusHandle = (selSearchStatus) => {
    pagedata(1);
    setSearchStatus(selSearchStatus)
  }

  /*------------- Table Headers Start ------------*/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    orderData(isAsc ? 'desc' : 'asc');
    orderByData(property);
  };

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
              classes={{
                root:
                  classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            key={100}
            align={'center'}
            padding={'none'}
            classes={{
              root:
                classes.tableCellRoot + " " + classes.tableCellRootHead,
            }}
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  /*------------- Table Headers End ------------*/

  /*------------- Table Content Start ------------*/

  function EnhancedTableRows(props) {
    const { classes, rows } = props;


    return (
      <TableBody className="relative">
        {rows?.map((rowData, index) => {
          return (
            <TableRow key={index} className="cursor-pointer">
              {headCells.map((headCell, hdIndex) => (
                <TableCell
                  key={hdIndex}
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  {headCell.dataType === 'string' && rowData[headCell.id]}

                  {headCell.dataType === 'date' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('D-MM-YYYY') : ''}

                  {headCell.dataType === 'jsonArray' && rowData[headCell.id] !== null && (

                    JSON.parse(rowData[headCell.id]).map((fileData) => (


                      <Tooltip title={
                        <span>
                          File Detail<br /><hr />
                          <span>
                            Quantity: {fileData?.Quantity}<br />
                            Client: {fileData?.Client}
                          </span>
                        </span>
                      } placement="top" arrow>
                        <span className="pr-2 text-green-500">
                          {fileData?.Name},
                        </span>
                      </Tooltip>

                    ))
                  )}
                </TableCell>
              ))
              }

              <TableCell classes={{ root: classes.tableCellRoot }} align="center">
                {actions?.length > 0 &&
                  <ThreeDotsMenu data={rowData} actions={actions} index={index} />
                }
              </TableCell>
            </TableRow>

          )
        })
        }
        {rows?.length === 0 && !loading &&
          <TableRow key={0}>
            <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
              Record Not Found
            </TableCell>
          </TableRow>
        }
        {loading &&
          <TableRow key={0}>
            <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
              <LoadingIcon overlay={rows?.length > 0} />
            </TableCell>
          </TableRow>
        }
      </TableBody>
    )
  }


  EnhancedTableRows.propTypes = {
    // rows, page, total, pagedata, setSearchText
    classes: PropTypes.object.isRequired,
    rows: PropTypes.array
  };

  /*------------- Table Content End ------------*/

  return (
    <>

      {/* Page content */}
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >


              <Grid item xs={12} sm={6} md={6}>
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={12} sm={5} md={5}>
                    <Box component={Typography} variant="h3" className="md:my-1">
                      <span className="mb-2 sm:my-1 block">{title}</span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7} md={7} className="md:pl-0">
                    <Box display="flex" alignItems="center" className="w-full my-1 pr-3">
                      <Box
                        display="flex"
                        alignItems="center"
                        className="border border-gray-300 w-full"
                        classes={{
                          root: classes.searchBox,
                        }}
                      >
                        <SearchIcon className={`${classes.searchIcon} text-black`} />
                        <InputBase
                          name="CommonSearch"
                          placeholder="Search"
                          classes={{
                            input: `${classes.searchInput} text-black`,
                          }}
                          onChange={(e) => { handleSearchInput(e) }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={12} sm={8} md={8}>
                    <ButtonGroup variant="text" className="bg-white" aria-label="outlined button group">
                      <Button variant={`${searchStatus === 'Pending' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Pending')}>Pending</Button>
                      <Button variant={`${searchStatus === 'Approved' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Approved')}>Approved</Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} className="md:pl-0">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2] capitalize"
                        onClick={() => { history.push(view) }}
                      >
                        Add Goods Entry
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer>
          <Box
            component={Table}
            alignItems="center"
            marginBottom="0!important"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />

            <EnhancedTableRows
              classes={classes}
              rows={rows}
              page={page}
              total={total}
            />
          </Box>
        </TableContainer>
        <Box
          classes={{ root: classes.cardActionsRoot }}
          component={CardActions}
          justifyContent="space-between"
        >

          <div>
            <Chip className="pl-2 font-bold bg-blue-500" color="primary" icon={<GoodsEntryIcon />} label={`Total Records : ${total}`} />
          </div>

          <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={offset}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageRows}
            />
          </div>
        </Box>
      </Card>
      {openGoodsEntryStats &&
        <CardStatsDialog open={openGoodsEntryStats} onClose={setOpenGoodsEntryStats} viewDialogData={{ title: 'Goods Entry Stats' }} rows={goodsEntryStats} />
      }

    </>
  );
};

export default CustomerGoodsEntryTable;