import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// @mui/icons-material components

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { CustomButton } from "components/Common/CustomButton";
import UploadedDocumentsView from "components/dialoges/FileUpload/UploadedDocumentsView";
import { AddCircleOutline } from "@mui/icons-material";
import CatalogueFileListDialog from "./CatalogueFileListDialog";
import PDFViewerDialog from "./PdfVIewerDialog";
import { convertBlobToBase64 } from "utils/helper";
import { LoadingIcon } from "components/Icons/Loading";
// import { currentUser } from "services/user-service";
//import roles from "services/roles";

const useStyles = makeStyles(componentStyles);

function CreateCatalogue() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id } = useParams();

    console.log("idd", id)
    const PageTitle = id ? `Update Catalogue` : `Add Catalogue`;

    // Usef defined states
    const [catalogueModel, setCatalogueModel] = useState({
        Title: null,
        Description: null,
        QRCode: "",
    });
    const [catalogueData, setCatalogueData] = useState(null)
    const [pdfViewerOpen, setPdfViewerOpen] = useState({ isVisible : false, file : null, item : null})

    // Usef defined states
    const [catalogueValidationSchema, setCatalogueValidationSchema] = useState({
        Title: { message: '', touched: true },
        Description: { message: '', touched: false },
        IsValidationError: false
    });
    const [catalogueFileData, setCatalogueFileData] = useState([])

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [openFilesDialog, setOpenFilesDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        fetchData()
    }, [id])

    const fetchData = async () => {
        if (id) {
            getCatalogueDeta();
            fetchCatalogueFileList()
        }
    }

    const afterAPICall = async () => {
        if (id) {
            fetchCatalogueFileList()
        }
    }

    const getCatalogueDeta = async () => {
        let res = await dataService('get', `CatalogueGet/${id}`, '');
        if (res.status === 200) {
            let catalogueData = res?.data
            if (catalogueData) {
                setCatalogueModel({
                    ...catalogueModel,
                    Title: catalogueData?.Title,
                    Description: catalogueData?.Description,
                    QRCode: catalogueData?.QRCode
                });
                setCatalogueData(res?.data)
            }
        }
    }

    const fetchCatalogueFileList = async () => {
        let res = await dataService('get', `CatalogueFileList/${id}`, null);
        if (res.status === 200) {
            if (res?.data) {
                setCatalogueFileData(res?.data)
            }

        }
    }

    const handleChange = (id, value) => {
        switch (id) {
            case 'Title':
                setCatalogueModel({ ...catalogueModel, Title: value });
                setCatalogueValidationSchema({ ...catalogueValidationSchema, Title: { message: checkEmptyValidation(value, `Title`), touched: true } });
                break;
            case 'Description':
                setCatalogueModel({ ...catalogueModel, Description: value });
                setCatalogueValidationSchema({ ...catalogueValidationSchema, Title: { message: checkEmptyValidation(value, 'Description'), touched: true } });
                break;
        }
    };

    const handleSubmit = async () => {

        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let res = await dataService('post', `CatalogueSave/${id ? id : 0}`, catalogueModel);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: id ? `Catalogue updated successfully!` : 'Catalogue created successfully!' });

                // if (!id) {
                setTimeout(() => {
                    history.replace('/admin/catalogues')
                }, 500);
                // }    

            } else {
                //setOpen(res.message);
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }

    const handleDialogCLose = () => {
        setOpenFilesDialog(false)
    }

    const checkValidations = () => {

        let validateErrors = {
            Title: { message: checkEmptyValidation(catalogueModel.Title, `Title`), touched: true },
            Description: { message: checkEmptyValidation(catalogueModel.Description, `Description`), touched: true },
        }
        if (
            validateErrors.Title.message !== '' ||
            validateErrors.Description.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setCatalogueValidationSchema(validateErrors);
        return validateErrors.IsValidationError;

    }
    

    
    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>

                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <div className="flex flex-wrap items-center mb-2">
                                                    <FormLabel className="mr-3 mb-0">Title</FormLabel>
                                                </div>
                                                <div className="flex flex-wrap items-center justify-start ">
                                                    <div className="w-full ">
                                                        <TextField
                                                            variant="filled"
                                                            component={Box}
                                                            width="100%"
                                                            marginBottom="1rem!important"
                                                            autoComplete="off"
                                                            value={catalogueModel.Title}
                                                            // className={classes1.errorTransform}
                                                            onChange={e => handleChange('Title', e.target.value)}
                                                            error={catalogueValidationSchema.Title.message !== '' && catalogueValidationSchema.Title.touched}
                                                            helperText={catalogueValidationSchema.Title.message !== '' && catalogueValidationSchema.Title.touched ? catalogueValidationSchema.Title.message : ''}
                                                        // inputProps={{ maxLength: 12 }}
                                                        >
                                                            <Box
                                                                paddingLeft="0.75rem"
                                                                paddingRight="0.75rem"
                                                                component={FilledInput}
                                                                autoComplete="off"
                                                                type="text"
                                                            />
                                                        </TextField>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <div className="flex flex-wrap items-center mb-2">
                                                    <FormLabel className="mr-3 mb-0">Description</FormLabel>
                                                </div>
                                                <div className="flex flex-wrap items-center justify-start ">
                                                    <div className="w-full ">
                                                        <TextField
                                                            variant="filled"
                                                            component={Box}
                                                            width="100%"
                                                            marginBottom="1rem!important"
                                                            autoComplete="off"
                                                            value={catalogueModel.Description}
                                                            // className={classes1.errorTransform}
                                                            onChange={e => handleChange('Description', e.target.value)}
                                                            error={catalogueValidationSchema.Description.message !== '' && catalogueValidationSchema.Description.touched}
                                                            helperText={catalogueValidationSchema.Description.message !== '' && catalogueValidationSchema.Description.touched ? catalogueValidationSchema.Description.message : ''}
                                                        // inputProps={{ maxLength: 12 }}
                                                        >
                                                            <Box
                                                                paddingLeft="0.75rem"
                                                                paddingRight="0.75rem"
                                                                component={FilledInput}
                                                                autoComplete="off"
                                                                type="text"
                                                            />
                                                        </TextField>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Grid>

                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={12} lg={12}>
                                            {catalogueFileData?.length > 0 &&
                                                <UploadedDocumentsView
                                                    documentsList={catalogueFileData}
                                                    isCatalogue={true}
                                                    openPdfViewer = {async(document) => {
                                                        setIsLoading(true)
                                                        try {
                                                            const response = await fetch(`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${document?.FilePath}${document?.FileName}`);
                                                            const blob = await response.blob();
                                                            const base64 = await convertBlobToBase64(blob);
                                                            setIsLoading(false)
                                                            setPdfViewerOpen({file : base64, isVisible: true, item : document})
                                                        } catch (error) {
                                                            setIsLoading(false)
                                                            console.error('Error fetching and converting PDF:', error);
                                                        }
                                                    }}
                                                />
                                            }
                                        </Grid>
                                    </Grid>

                                    {catalogueFileData?.length > 0 ?
                                        <Grid container>
                                            <Grid item xs={12} lg={6}>
                                                <Box
                                                    justifyContent="flex-start"
                                                    display="flex"
                                                    flexWrap="wrap"
                                                >
                                                    <Button onClick={() => { setOpenFilesDialog(true) }} className='bg-blue-500 border-none py-1 px-3 shadow-none min-w-0 flex flex-wrap items-center mt-2'>
                                                        <AddCircleOutline className='w-8 h-auto text-white' /> <span className='text-base text-white font-semibold ml-1'>Files</span>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid> : null}

                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmit} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>

                            {openFilesDialog &&
                                <CatalogueFileListDialog
                                    title="Catalogue Files"
                                    onClose={handleDialogCLose}
                                    open={openFilesDialog}
                                    setSelectedData={setCatalogueFileData}
                                    selectedData={catalogueFileData}
                                    isUpdate={true}
                                    selectedFile={catalogueData}
                                    setSelectedFile={setCatalogueData}
                                    afterCall={afterAPICall}
                                />
                            }
                            {
                              pdfViewerOpen?.isVisible ? <PDFViewerDialog viewer={pdfViewerOpen} handleClose={() => {
                                setPdfViewerOpen({file : null, isVisible : false})
                              }} /> : null}
                        </Card>
                    </Grid>
                </Grid>

                {
              isLoading && <LoadingIcon overlay={isLoading} />
            }
            </Container>
        </>
    );
}

export default CreateCatalogue;