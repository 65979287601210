import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { dataService } from "services/data-service";
import FileUploadDialog from "components/dialoges/FileUpload/FileUploadDialog";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';




function GoodsEntryDocControl(props) {
    const { goodsEntryId, goodsEntryNumber, setInitialSelectedDocuments, reload, setRelaod } = props
    const [openUploadDocumentsDialog, setOpenUploadDocumentsDialog] = useState(false);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    useEffect(() => {
        const fetchData = async() => {
            if (goodsEntryId) {
                await getGoodsEntryDocumentList()
            }
        }
        
        fetchData()
    }, [])

  

    // UPLOAD DOCUMENT FUNCTION
    const uploadGoodsEntryDocuments = async () => {
        let selectedFilesArray = []
        const formData = new FormData();
        formData.append(`GoodsEntryNumber`, goodsEntryNumber);
        selectedDocuments.forEach(selectedDocument => {
            if (selectedDocument?.SelectedFiles?.length > 0) {
                for (let i = 0; i < selectedDocument?.SelectedFiles?.length; i++) {
                    selectedFilesArray.push({ file: selectedDocument?.SelectedFiles[i], title: selectedDocument?.Title })
                }
            }
        });

        if (selectedFilesArray?.length > 0) {
            selectedFilesArray.forEach((element, index) => {
                formData.append(
                    `file_${index + 1}`,
                    element?.file,
                    element?.file?.name
                );
                formData.append(`file_${index + 1}`, element?.title);
            });
             await dataService('post', `GoodsEntryDocumentsUpload/${goodsEntryId}`, formData, 'multipart/form-data');
        }

    }

    const handleDeleteGoodsEntryDocument = async (GoodsEntryDocumentId) => {
        await dataService('post', `GoodsEntryDocumentDelete/${GoodsEntryDocumentId}`, null);
        await getGoodsEntryDocumentList();
    }

    const getGoodsEntryDocumentList = async () => {
        let res = await dataService('get', `GoodsEntryDocumentList/${goodsEntryId}`, '');
        if (res.status === 200) {
            setUploadedDocuments(res?.data);
        }
    }

    const handleUploadDocument = async () => {
        if (goodsEntryId) {
            await uploadGoodsEntryDocuments();
            await getGoodsEntryDocumentList();
            setSelectedDocuments([]);
        }
        else {
            setInitialSelectedDocuments(selectedDocuments);
            setOpenUploadDocumentsDialog(false);
        }

    }

    const handleDialogCLose = () => {
        setOpenUploadDocumentsDialog(false)
        setRelaod(!reload)
    }

    return <div>
        <Button onClick={() => { setOpenUploadDocumentsDialog(true) }} className='bg-blue-500 border-none py-1 px-3 shadow-none min-w-0 flex flex-wrap items-center mt-2'>
            <AddCircleOutlineIcon className='w-8 h-auto text-white' /> <span className='text-base text-white font-semibold ml-1'>Documents</span>
        </Button>

        {openUploadDocumentsDialog &&
            <FileUploadDialog
                documentTitle="Goods Entry"
                onClose={handleDialogCLose}
                open={openUploadDocumentsDialog}
                handleUploadDocument={handleUploadDocument}
                uploadedDocuments={uploadedDocuments}
                setSelectedDocuments={setSelectedDocuments}
                selectedDocuments={selectedDocuments}
                handleDeleteDocument={handleDeleteGoodsEntryDocument}
            />
        }
    </div>
}

export default GoodsEntryDocControl;