import React from 'react';
import { Grid, Link } from '@mui/material';
// import { Delete, Visibility } from '@mui/icons-material';
import DeleteConfirmDialog from '../DeleteConfirmDialog';
import moment from "moment";

function UploadedDocumentsView(props) {
    const { documentsList, handleDeleteDocument, showDelete, isCatalogue, openPdfViewer } = props;
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(false);
    const [confirmData, setConfirmData] = React.useState(null);

    function handleConfirmClick(index, rowData) {
        handleDeleteDocument(index, rowData);
    }

    const handleShowConfirmDialog = (index, rowData) => {
        setShowDeleteConfirmDialog(true)
        setConfirmData({ action: index, rowData: rowData })
    }

    const getFileIcon = (mimeType) => {
        return <img width="40" alt='file' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/${getIconFromMimeTypes(mimeType)}`} />
    }

    const getIconFromMimeTypes = (mimeType) => {

        var icon_classes = {
            // Media
            image: "png-icon.png",
            audio: "audio-icon.png",
            video: "video-icon.png",
            // Documents
            "application/pdf": "pdf-icon.png",
            "application/msword": "word-icon.png",
            "application/vnd.ms-word": "word-icon.png",
            "application/vnd.oasis.opendocument.text": "word-icon.png",
            "application/vnd.openxmlformats-officedocument.wordprocessingml":
                "word-icon.png",
            "application/vnd.ms-excel": "xls-icon.png",
            "application/vnd.openxmlformats-officedocument.spreadsheetml":
                "xls-icon.png",
            "application/vnd.oasis.opendocument.spreadsheet": "xls-icon.png",
            "text/csv": "xls-icon.png",
            "text/plain": "txt-icon.png",
        };


        for (var key in icon_classes) {
            if (icon_classes.hasOwnProperty(key)) {
                if (mimeType.search(key) === 0) {
                    // Found it
                    return icon_classes[key];
                }
            } else {
                return "file-icon.png";
            }
        }
        return "file-icon.png";

    }

    return (
        <Grid container>
            {documentsList?.length > 0 &&
                <Grid item xs={12} lg={12}>
                    {documentsList?.map((document, index) => (
                        <Grid container className='py-3 mt-1 border-b border-gray-200' key={index}>
                            <Grid item xs={12} sm={8}>
                                <Grid container>
                                    <Grid item>
                                        <Link href={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${document?.FileUrl}`} target="_blank" >
                                            {getFileIcon(document?.MimeType)}
                                            {/* <img width="40" src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/pdf-icon.png`} /> */}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <div className='text-lg font-semibold'>{document.Title}</div>
                                        <p className='text-sm text-gray-400'>{moment(document.CreatedAt).format('D MMM, YYYY')} - {document.Size}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} className="text-right">
                                {showDelete &&
                                    <Link href="#" onClick={() => handleShowConfirmDialog(document?.GoodsEntryDocumentId, index)} className="inline-block" >
                                        <img width="40" alt='delete-icon' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/delete-icon.png`} />
                                    </Link>
                                }
                                {isCatalogue ?
                                        <div className="inline-block cursor-pointer" onClick={() => openPdfViewer(document)}>
                                        <img width="40" alt='download-cloud-icon' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/download-cloud-icon.png`} />
                                        </div>
                                    :
                                    <Link href={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${isCatalogue ? `${document?.FilePath}${document?.FileName}` : document?.FileUrl}`} target="_blank" className="inline-block">
                                        <img width="40" alt='download-cloud-icon' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/download-cloud-icon.png`} />
                                    </Link>
                                }

                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            }
            {showDeleteConfirmDialog &&
                <DeleteConfirmDialog onClose={setShowDeleteConfirmDialog} open={showDeleteConfirmDialog} handleConfirm={handleConfirmClick} confirmData={confirmData} setConfirmData={setConfirmData} />
            }
        </Grid>

    );
};
export default UploadedDocumentsView;