import React from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import componentStyles from "assets/theme/components/header.js";
import { useSelector } from 'react-redux';
import VerifyOTPDialog from "components/dialoges/VerifiyOTPDialog";
import MessageSnackBar from "components/MessageBar/ErrorSnackBar";
//import { updateVerificationCodeStore } from "generic/store";

const useStyles = makeStyles(componentStyles);

const Header = () => {
  const classes = useStyles();
  const verificationCodeStore = useSelector(state => state?.verificationCodeStore?.verificationCode); // show/hide cart sidebar
  const snackBarStore = useSelector(state => state?.snackBarStore?.snackbarData); // show/hide cart sidebar

  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
        </Container>
      </div>
      { verificationCodeStore?.openDialog &&
        <VerifyOTPDialog />
      }

      { snackBarStore?.open &&
        <MessageSnackBar />
      }
      
    </>
  );
};

export default Header;
