import React from 'react';

function SimpleDialog(props) {
    const { show, message, type } = props;

    return (
        <>
            {show &&
                <>
                    {type === 'error' &&
                        <div className={`text-base text-red-500 text-center ${props?.className || 'mb-5'}`}>
                            {message}
                        </div>
                    }
                    {type === 'success' &&
                        <div className={`text-base text-green-500 text-center  ${props?.className || 'mb-5'}`}>
                            {message}
                        </div>
                    }
                    {type === 'validation' &&
                        <div className="text-3xl bg-black">
                            {message}
                        </div>
                    }
                </>
            }

            {/* <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">{message}</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>OK</Button>
			</DialogActions>
		</Dialog> */}
        </>
    );
};
export default SimpleDialog;