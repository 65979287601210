import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import { renderTitleDetails } from "utils/helper";
import { Delete } from "@mui/icons-material";

const useStyles = makeStyles(componentStyles);

const FlowCheckList = () => {
  const classes = useStyles();
  const history = useHistory();

  const [flowCheckList, setFlowCheckList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isReload, setIsReload] = useState(true);
  const [workCenterId, setWorkCenterId] = useState(null)

  const columns = [
    { id: 'WorkCenterName', numeric: false, disablePadding: false, label: 'WorkCenter Name', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    {
      id: 'CheckItem', numeric: false, disablePadding: false, label: 'Check Item', dataType: 'string', Component: ({ row }) => {
        return (
          <>
          {renderTitleDetails(row?.CheckItem, 200, { fontWeight: 400, fontSize: "14px" })}
          </>
        )
      }
    },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload, workCenterId])

  const fetchData = async () => {
    setIsLoading(true);
    let res = await dataService('get', 'WorkCenterCheckItemList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
    setFlowCheckList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }



  const handleEdit = (row) => {
    history.push(`update-flow-check-item/${row.WorkCenterCheckItemId}`)
  };

  const handleDelete = async (row) => {
    let res = await dataService('post', `WorkCenterCheckItemDelete/${row.WorkCenterCheckItemId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  let actionList = [
    { label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit },
    { label: 'Delete', icon: Delete, iconColor: 'red-500', action: handleDelete, isConfirmAction: true }
  ]


  return (
    <>
      <Header />
      <Container  
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Flow Check List"
          columns={columns}
          rows={flowCheckList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          view="check item"
          viewURL="flow-check-item"
        />
      </Container>

    </>
  );
};

export default FlowCheckList;