import React, { useEffect, useState } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import DeilveryChallanDialog from "components/dialoges/DeliveryChallanDialog";
import ViewOrdersGEDialog from "components/dialoges/ViewOrdersGEDialog";
import OrderFlowListDialog from "components/dialoges/OrderFlowListDialog";
import MapOrderDialog from "components/dialoges/MapOrderDialog";
import PrintOrderForm from "components/dialoges/PrintOrder/PrintOrderForm";
import ViewOrderDialog from "components/dialoges/ViewOrderDialog";
import { Button, ButtonGroup, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { currentUser } from "services/user-service";

const useStyles = makeStyles(componentStyles);

const CustomerCompletedOrders = () => {
  const classes = useStyles();

  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('DeliveryDate');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);

  const [deliveryChallanId] = useState(null);

  const [open, setOpen] = useState(false);

  const [printOrderFormData] = useState(null);
  const [openPrintOrderForm, setOpenPrintOrderForm] = useState(null);

  const [goodsDetailDialogData] = useState(false);
  const [openGoodsDetailDialog, setOpenGoodsDetailDialog] = useState(false);

  const [customerSearchText] = useState('');
  const [runningOrderList, setRunningOrderList] = useState([]);
  const [fileSearchText] = useState('');
  const [selectedTab, setSelectedTab] = useState("Completed")

  const [openShowFlowListView, setOpenShowFlowListView] = useState(null);
  const [flowStepListOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  const [openMaporder, setOpenMaporder] = useState(false);
  const [mappingData] = useState(null);
  const [isReload, setIsReload] = useState(false);


  const [openViewOrder, setOpenViewOrder] = useState(false);
  const [viewOrderId] = useState(null);

  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const columns = [
    { id: 'OrderNumber', numeric: false, disablePadding: true, label: 'Order Number', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    { id: 'FileName', numeric: false, disablePadding: false, label: 'File Name', dataType: 'string' },
    { id: 'FileSize', numeric: false, disablePadding: false, label: 'File Size', dataType: 'string' },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'Client', numeric: false, disablePadding: false, label: 'Client', dataType: 'string' },
    { id: 'QuantityOut', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
    { id: 'DCNo', numeric: false, disablePadding: false, label: 'DCNo', dataType: 'string' },
    { id: 'BillNo', numeric: false, disablePadding: false, label: 'BillNo', dataType: 'string' },
    { id: 'ChallanDate', numeric: false, disablePadding: false, label: 'DC Date', dataType: 'date' },
    { id: 'DeliveryDate', numeric: false, disablePadding: false, label: 'Delivery Date', dataType: 'date' },
    { id: 'HoldReason', numeric: false, disablePadding: false, label: 'Hold Reason', dataType: 'string' },
  ];

  const pendingOrdercolumns = [
    { id: 'OrderNumber', numeric: false, disablePadding: true, label: 'Order #', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    { id: 'FileName', numeric: false, disablePadding: false, label: 'File Name', dataType: 'string' },
    { id: 'FileSize', numeric: false, disablePadding: false, label: 'File Size', dataType: 'string' },
    // { id: 'WorkCenterName', numeric: false, disablePadding: false, label: 'Status', dataType:'string'},
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'Client', numeric: false, disablePadding: false, label: 'Client', dataType: 'string' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Total Qty', dataType: 'string' },
    { id: 'FlowStepQuantityIn', numeric: false, disablePadding: false, label: 'In Qty', dataType: 'string' },
    // { id: 'OrderDate', numeric: false, disablePadding: false, label: 'Order Date', dataType:'date'},
    // { id: 'DeliveryDate', numeric: false, disablePadding: false, label: 'Delivery Date', dataType:'date'},
    { id: 'FlowStepHoldReason', numeric: false, disablePadding: false, label: 'Hold Reason', dataType: 'string' },
  ];

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async() => {
    if (localStorage.getItem('products')) {
      let productsAr = JSON.parse(localStorage.getItem('products'));
      setProductList(productsAr);
    }
    else {
      let res = await getProductList();
      setProductList(res?.data);
    }
  }

  useEffect(() => {
    fetchDataPage()
  }, [page, searchText, order, orderBy, offset, selectedProductId, selectedTab])

  const fetchDataPage = async() => {
    setIsLoading(true);
    if(selectedTab === "Completed"){
      let res = await dataService('get', 'CustomerCompletedOrderList', `productId=${selectedProductId || 0}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}&orderStatus=Completed&customerId=${currentUser()?.CustomerId}`);
      setCompletedOrderList(res?.data);
      setTotalRecord(res?.totalRecord);
    }
    else {
      let res = await dataService('get', `CustomerFlowOrderList/${1}`, `customerSearchText=${customerSearchText}&fileSearchText=${fileSearchText}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}&ProductId=${selectedProductId || 0}&customerId=${currentUser()?.CustomerId}`);
      setRunningOrderList(res?.data);
      setTotalRecord(res?.totalRecord);
    }
   
    setIsLoading(false);
  }

  const getProductList = async () => {
    let res = await dataService('get', 'ProductList', null);
    if (res?.data) {
      localStorage.setItem('products', JSON.stringify(res?.data));
      return res;
    }
  }

  let actionList = []


  const handleSelect = async (ProductId) => {
    setSelectedProductId(parseInt(ProductId));
  }

  const searchStatusHandle = async (selSearchStatus) => {
    setSelectedTab(selSearchStatus)
  }

  const customHeaderTitle = () => {
    return (
      <Grid item xs={12} sm={12} md={2}>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs={12} sm={8} md={8}>
            <ButtonGroup variant="text" className="bg-white" aria-label="outlined button group">
              <Button variant={`${selectedTab === 'Completed' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Completed')}>Completed</Button>
              <Button variant={`${selectedTab === 'Pending' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Pending')}>Pending</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >

        <RadioGroup row aria-label="position" className="mb-0 text-black mr-auto ml-0" onChange={(e) => handleSelect(e?.target?.value)} name="position" value={selectedProductId}>
          <FormControlLabel value={0} className="z-10" control={<Radio color="default" />} label="All" />
          {productList?.map((product, index) => {
            return <FormControlLabel className="z-10" key={index} value={product?.ProductId} control={<Radio color="default" />} label={product?.Name} />
          })}
        </RadioGroup>
        {selectedTab === "Completed" ?
          <CommonTable
            title="Completed Orders"
            customHeaderTitle={customHeaderTitle}
            columns={columns}
            rows={completedOrderList}
            page={page - 1}
            total={totalRecord}
            pagedata={setPage}
            setSearchText={setSearchText}
            order={order}
            orderBy={orderBy}
            orderData={setOrderData}
            orderByData={setOrderByData}
            setOffset={setOffset}
            actions={actionList}
            offset={offset}
            loading={isLoading}
          />
          :
          <CommonTable
            title="Pending Orders"
            customHeaderTitle={customHeaderTitle}
            columns={pendingOrdercolumns}
            rows={runningOrderList}
            page={page - 1}
            total={totalRecord}
            pagedata={setPage}
            setSearchText={setSearchText}
            order={order}
            orderBy={orderBy}
            orderData={setOrderData}
            orderByData={setOrderByData}
            setOffset={setOffset}
            actions={actionList}
            offset={offset}
            loading={isLoading}
          />
        }
      </Container>

      {open &&
        <DeilveryChallanDialog open={open} onClose={setOpen} deliveryChallanId={deliveryChallanId} viewOnly={true} />
      }

      {openGoodsDetailDialog &&
        <ViewOrdersGEDialog onClose={setOpenGoodsDetailDialog} open={openGoodsDetailDialog} OrderDetail={goodsDetailDialogData} />
      }

      {flowStepListOrder && openShowFlowListView &&
        <OrderFlowListDialog open={openShowFlowListView} viewDialogData={{ title: "Test" }} onClose={setOpenShowFlowListView} OrderDetail={flowStepListOrder} />
      }

      {mappingData && openMaporder &&
        <MapOrderDialog from={'orders'} mapTo={'GoodsEntry'} open={openMaporder} onClose={setOpenMaporder} MappingData={mappingData} setIsReload={setIsReload} isReload={isReload} />
      }
      {printOrderFormData && openPrintOrderForm &&
        <PrintOrderForm open={openPrintOrderForm} onClose={setOpenPrintOrderForm} OrderId={printOrderFormData} setIsReload={setIsReload}/>
      }
      {viewOrderId && openViewOrder &&
        <ViewOrderDialog open={openViewOrder} onClose={setOpenViewOrder} OrderId={viewOrderId} isReload={isReload}/>
      }
    </>
  );
};

export default CustomerCompletedOrders;