import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { Delete } from "@mui/icons-material";

const useStyles = makeStyles(componentStyles);

const QuotationsList = () => {
  const classes = useStyles();

  const [quotationList, setQuotationList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isReload, setIsReload] = useState(true);

  const columns = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name', dataType:'string' },
    { id: 'PhoneNumber', numeric: false, disablePadding: false, label: 'Phone Number', dataType:'string' },
    { id: 'Email', numeric: false, disablePadding: false, label: 'Email', dataType:'string' },
    { id: 'Company', numeric: false, disablePadding: false, label: 'Company', dataType:'string' },
    { id: 'RequiredQuantity', numeric: false, disablePadding: false, label: 'Required Quantity', dataType:'string'},
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  const fetchData = async () => {
    setIsLoading(true);
    let res = await dataService('get', 'QuotationList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
    setQuotationList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const handleDelete = async (row) => {
    let res = await dataService('post', `QuotationDelete/${row.QuotationId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  let actionList = [{label: 'Delete', icon: Delete, iconColor: 'red-500', action: handleDelete, isConfirmAction: true, notAuthenticated : true}] 

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Quotation List"
          columns={columns}
          rows={quotationList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>

    </>
  );
};

export default QuotationsList;