import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';  

const useStyles = makeStyles(componentStyles);

const Products = () => {
  const classes = useStyles();
  const [productList, setProductList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();
  
  const [searchText, setSearchText] = useState('');
  const [orderBy, setOrderByData] = useState('name');
  const [order, setOrderData] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const columns = [{ id: 'Name', numeric: false, disablePadding: true, label: 'Name', dataType:'string' }];

  useEffect(() => {
    fetchData()
  }, [searchText, order]) 

  const fetchData = async() => {
    setIsLoading(true);
    let res = await dataService('get', 'ProductList', `searchText=${searchText}&sortBy=Name&sortOrder=asc`);
    setProductList(res?.data);
    setTotalRecord(res?.data?.length);
    setIsLoading(false);
  }

  const handleEdit = (row) => {
    
    history.push(`update-product/${row.ProductId}`)
    
  };
  


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        
          <CommonTable 
            view="product"
            title="Product List"
            columns={columns}
            rows={productList}
            total={totalRecord}
            setSearchText={setSearchText}
            order={order}
            orderBy={orderBy}
            orderData={setOrderData}
            orderByData={setOrderByData}
            editAction={handleEdit}
            loading={isLoading}
            pagination={false}
          />
        </Container>
    </>
  );
};

export default Products;