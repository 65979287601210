const componentStyles = (theme) => ({
  mainContent: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "250px",
    },
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px !important",
      paddingRight: "39px !important",
    },
  },
  textinput: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)', // customize the label transformation
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shorter, // customize the transition duration
      }),
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // customize the transformed label position
    },
  },
});

export default componentStyles;
