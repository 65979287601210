import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export function GoodsEntryFilter(props) {
    const { setFilterModel, filterModel } = props;

    const [productListModel, setProductListModel] = useState([]);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async() => {
        let productList = localStorage.getItem('products');
        console.log('productList: ', JSON.parse(productList), filterModel)

        if (productList) {
            productList = JSON.parse(productList);
            if(filterModel?.products?.length > 0){
                productList?.map(p => {
                    if(filterModel?.products?.indexOf(p?.ProductId) >= 0){
                        p.checked = true;
                    }
                })
                console.log('productList NEW : ', productList)
            }
            
            setProductListModel(productList);
        }

    }

    const productSelectChange = (ProductId) => {
        let productIds = filterModel?.products;    
        if(!productIds.includes(ProductId)){          //checking weather array contain the id
            productIds.push(ProductId);               //adding to array because value doesnt exists
        }else{
            productIds.splice(productIds.indexOf(ProductId), 1);  //deleting
        }
        setFilterModel({...filterModel, products: productIds})
    }
    return (
        <Grid container direction="row" justify="flex-start">
            <Box p={2}>
                <Grid container direction="column" spacing={4} className="pt-6 pl-4 underline">
                      <Typography
                        id="discrete-slider-always"
                        gutterBottom
                    >
                        Product Filter
                    </Typography>

                </Grid>
                <Grid>
                    {productListModel?.map((productData, indx) => {
                        return <Grid key={indx}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    color="primary"
                                        checked={productData?.checked}
                                        onChange={(e) => {
                                            productData.checked = e.target.checked;
                                            productSelectChange(productData?.ProductId, e)
                                        }}
                                    />
                                }
                                label={productData?.Name}
                                labelPlacement="end"
                            />
                        </Grid>

                    })}
                </Grid>
            </Box>
            {/* <Hidden only={["xs"]}>
                <Divider
                    style={{
                        width: "1.5px",
                        height: "18em",
                        margin: "auto 10px",
                    }}
                    orientation="vertical"
                    color="secondary"
                />
            </Hidden>
            <Grid item></Grid> */}
        </Grid>
    );
};