import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, Delete, Info as InfoIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { CustomButton } from "components/Common/CustomButton";
function DeleteConfirmDialog(props) {
  const { onClose, open, message, type, handleConfirm, title, confirmBtnTitle, confirmData, setConfirmData } = props;

  const handleConfirmEvent = () => {
    handleConfirm(confirmData?.action, confirmData?.rowData);
    handleClose();
  };

  const handleClose = () => {
    setConfirmData(null)
    onClose();
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={`bg-red-600 border-red-600 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
        <DialogTitle id="form-dialog-title" className="px-4 py-4 text-xl">
          <div className="text-gray-100 text-2xl">
            Delete Record
          </div>
        </DialogTitle>
        <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
      </div>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="text-black text-xl text-center">
            Are you sure you want to delete this record? <InfoIcon className='w-8 h-auto text-red-600' />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="justify-center">
        <CustomButton className='bg-blue-700 text-white  border-none' size="none" label="Cancel " onClick={handleClose} icon={CancelIcon} iconClassName="w-5 h-auto" />
        <CustomButton className='bg-red-600 text-white border-none'  size="none" label={confirmBtnTitle || 'Delete '} onClick={handleConfirmEvent} icon={Delete} iconClassName="w-5 h-auto" />
      </DialogActions>
    </Dialog>
  );
};
export default DeleteConfirmDialog;