import React from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableSortLabel from '@mui/material/TableSortLabel';
import InputBase from "@mui/material/InputBase";
// @mui/icons-material components
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from 'react-router-dom';

import componentStyles from "assets/theme/views/admin/tables.js";
import { ButtonGroup, MenuItem, Select, TablePagination } from "@mui/material";
import moment from "moment";
import ThreeDotsMenu from "components/ThreeDotMenu/ThreeDotMenu";
import { LoadingIcon } from "components/Icons/Loading";
//import moment from "moment";

import classNames from "classnames";

const useStyles = makeStyles(componentStyles);

const TaskTable = (props) => {
    const history = useHistory();
    let timeout = 0;
    const { view, title, rows, page, total, pagedata, setSearchText, order, orderBy, orderData, orderByData, columns, actions, setOffset, offset, loading, handleStatusChange, searchStatus, setSearchStatus, handleRowClick } = props;
    const headCells = columns;
    const classes = useStyles();
    const theme = useTheme();
    const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);

    //Handle Page changes
    const handleChangePage = (event, newPage) => {
        pagedata(newPage + 1);
    };

    //Handle Page changes
    const handleChangePageRows = (event) => {
        setOffset(parseInt(event.target.value, 10))
        pagedata(1);
    };

    //Search data
    const handleSearchInput = (e) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchText(e.target.value)
            pagedata(1);
        }, 400);
    }

    /*------------- Table Headers Start ------------*/
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        orderData(isAsc ? 'desc' : 'asc');
        orderByData(property);
    };


    // const handleStatusChangeEv = (rowDetail, updateStatus) => {
    //     console.log('rowDetail: ', rowDetail, updateStatus)

    //     setIsReload(!isReload);

    // };

    function EnhancedTableHead(props) {
        const { classes, order, orderBy, rowCount, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };


        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={'none'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            classes={{
                                root:
                                    classes.tableCellRoot + " " + classes.tableCellRootHead,
                            }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell
                        key={100}
                        align={'center'}
                        padding={'none'}
                        classes={{
                            root:
                                classes.tableCellRoot + " " + classes.tableCellRootHead,
                        }}
                    >
                        Action
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    /*------------- Table Headers End ------------*/

    /*------------- Table Content Start ------------*/

    function EnhancedTableRows(props) {
        const { classes, rows } = props;


        return (
            <TableBody className="relative">
                {rows?.map((rowData, index) => {
                    const rowColor = JSON.parse(rowData?.RowColor);
                    return (

                        // <TableRow key={index} onClick={(e) => {handleViewClick(rowData)}} className="cursor-pointer">
                        <TableRow onDoubleClick={() => handleRowClick && handleRowClick(rowData)} key={index} className={`cursor-pointer ${rowColor && rowColor?.color !== 'blue' ? `text-${rowColor?.color}-${rowColor?.value}` : ''}`}>
                            {headCells.map((headCell, hdIndex) => (
                                <TableCell
                                    className={`${rowColor && rowColor?.color !== 'blue' ? `text-${rowColor?.color}-${rowColor?.value}` : ''}`}
                                    key={hdIndex}
                                    classes={{
                                        root:
                                            classes.tableCellRoot +
                                            " " +
                                            classes.tableCellRootBodyHead,
                                    }}
                                    component="th"
                                    variant="head"
                                    scope="row"
                                >
                                    <Box alignItems="center" display="flex">
                                        {
                                            headCell?.isSelectEditable ? (
                                                <Select value={rowData.Status} onChange={e => handleStatusChange(rowData, e.target.value)} >
                                                    <MenuItem value={'Assigned'}>Assigned</MenuItem>
                                                    <MenuItem value={'UnAssigned'}>UnAssigned</MenuItem>
                                                    <MenuItem value={'Finished'}>Finished</MenuItem>
                                                    <MenuItem value={'Approved'}>Approved</MenuItem>
                                                    <MenuItem value={'OnHold'}>OnHold</MenuItem>
                                                </Select>
                                            )
                                                :
                                                (<Box display="flex" alignItems="flex-start">
                                                    <Box fontSize=".875rem" component="span">

                                                        {headCell?.Component && <headCell.Component row={rowData} headCellData={headCell} />}
                                                        {headCell.dataType === 'string' && rowData[headCell.id]?.substring(0, headCell?.MaxLength || 2000)}
                                                        {headCell?.MaxLength && rowData[headCell.id]?.length > headCell?.MaxLength ? '...' : ''}
                                                        {/* {headCell.dataType === 'date' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('Do MMMM YYYY') : ''} */}
                                                        {headCell.dataType === 'date' && !headCell.dateFromNow && rowData[headCell.id] ? moment(rowData[headCell.id]).format('D-MM-YYYY') : ''}
                                                        {headCell.dataType === 'date' && headCell.dateFromNow && rowData[headCell.id] ? moment(rowData[headCell.id]).fromNow(true) : ''}

                                                    </Box>
                                                </Box>)
                                        }


                                    </Box>
                                </TableCell>
                            ))
                            }

                            <TableCell
                                classes={{ root: classes.tableCellRoot }}
                                align="center"
                            >
                                {actions?.length > 0 &&
                                    <ThreeDotsMenu data={rowData} actions={actions} index={index} setSelectedRowIndex={setSelectedRowIndex} />
                                }
                            </TableCell>
                        </TableRow>

                    )
                })
                }
                {rows?.length === 0 && !loading &&
                    <TableRow key={0}>
                        <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
                            Record Not Found
                        </TableCell>
                    </TableRow>
                }
                {loading &&
                    <TableRow key={0}>
                        <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
                            <LoadingIcon overlay={rows?.length > 0} />
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    }


    EnhancedTableRows.propTypes = {
        // rows, page, total, pagedata, setSearchText
        classes: PropTypes.object.isRequired,
        rows: PropTypes.array
    };

    /*------------- Table Content End ------------*/




    return (

        <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
                subheader={
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                            <Box
                                component={Typography}
                                variant="h3"
                                marginBottom="0!important"
                            >
                                {title}
                            </Box>

                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={8} xl={6}>
                            <ButtonGroup variant="text" color="primary" aria-label="contained primary button group">
                                <Button className={`${searchStatus === '' ? "bg-[#5e72e4]" : ""} md:text-xs md:px-2 lg:px-4 lg:text-sm`} color="primary" variant={`${searchStatus === '' ? 'contained' : 'text'}`} onClick={() => setSearchStatus('')}>All</Button>
                                <Button className={`${searchStatus === 'Assigned' ? "bg-[#5e72e4]" : ""} md:text-xs md:px-2 lg:px-4 lg:text-sm`} color="primary" variant={`${searchStatus === 'Assigned' ? 'contained' : 'text'}`} onClick={() => setSearchStatus('Assigned')}>Assigned</Button>
                                <Button className={`${searchStatus === 'Finished' ? "bg-[#5e72e4]" : ""} md:text-xs md:px-2 lg:px-4 lg:text-sm`} color="primary" variant={`${searchStatus === 'Finished' ? 'contained' : 'text'}`} onClick={() => setSearchStatus('Finished')}>Finished</Button>
                                <Button className={`${searchStatus === 'Approved' ? "bg-[#5e72e4]" : ""} md:text-xs md:px-2 lg:px-4 lg:text-sm border-r-[0.5px] border-r-[#5e72e4] border-t-[0.5px] border-t-[#5e72e4] border-b-[0.5px] border-b-[#5e72e4]`} color="primary" variant={`${searchStatus === 'Approved' ? 'contained' : 'text'}`} onClick={() => setSearchStatus('Approved')}>Approved</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={4}>
                            <Box display="flex" alignItems="center" className="w-full">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginRight="1rem"
                                    className={classNames('w-full', classes.searchBox)}
                                >
                                    <SearchIcon className={`${classes.searchIcon} text-black`} />
                                    <InputBase
                                        placeholder="Search"
                                        classes={{
                                            input: `${classes.searchInput} text-black`,
                                        }}
                                        onChange={(e) => { handleSearchInput(e) }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} xl={2} style={{ marginLeft: 'auto' }}>
    <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
      <Button
        className="capitalize bg-[#5e72e4]"
        variant="contained"
        color="primary"
        size="small"
        onClick={() => { history.push(view) }}
      >
        Add {view}
      </Button>
    </Box>
  </Grid>

                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>
            <TableContainer>
                <Box
                    component={Table}
                    alignItems="center"
                    marginBottom="0!important"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows?.length}
                    />

                    <EnhancedTableRows
                        classes={classes}
                        rows={rows}
                        page={page}
                        total={total}
                    />
                </Box>
            </TableContainer>
            <Box
                classes={{ root: classes.cardActionsRoot }}
                component={CardActions}
                justifyContent="flex-end"
            >

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={offset}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageRows}
                />
            </Box>
        </Card>

    );
};

export default TaskTable;