
//======================= This method check input text empty or not =======================//
export const checkEmptyValidation = (field, fieldName) => {
    let error = "";
    if (field === "" || field === undefined || field === null) {
      error = fieldName + " is required";
    }
    return error;
  };

  export const checkEmptySelectionValidation = (field, fieldName) => {
    let error = "";
  
    if (field ===false) {
      error = fieldName + "select any one label "; 
    }
    return error;
  };
//=========================================================//


//======================= This method check email input text pattern =======================//
  export const checkEmailValidation = (emailText, required = true) => {
    let error = "";
  
    if (required === true) {
      if (emailText === "") {
        return (error = "Email is required");
      }
    }
  
    const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g; // email pattern
    if (pattern.test(emailText)) {
      return "";
    } else {
      error = "Invalid email";
    }
    return error;
  };
  //=========================================================//


  //======================= This method check password input text pattern =======================//
  export const checkPhoneNumberValidation = (phoneNumberText, required = true) => {
    let error = "";
    if (required === true) {
      if (phoneNumberText === "") {
        return (error = "Phone Number is required");
      }
    }
    
    const pattern = /^[0-9]{10}$/; // /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/; //phone number pattern 
    if (pattern.test(phoneNumberText)) {
      return "";
    } else {
      error = "Invalid phone number: ";
    }
    return error;
  };
  //=========================================================//