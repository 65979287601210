// import Button from '@mui/material/Button';
import { Grid, Table, TableCell, TableContainer, TableHead, TableRow, Typography, TableBody } from '@mui/material';
import moment from "moment";
import React, {useState, useEffect} from 'react';

function DeilveryChallanComponent(props) {
	const { selectedGCOrdersList, DCDetail } = props;
	const [isFirstLoad, setIsFirstLoad] = useState(true); // loading page state value
	const [emptyRowsCount, setEmptyRowsCount] = useState(6);

	useEffect(() => {
		setEmptyRowsCount(selectedGCOrdersList?.length >= 6 ? 0 : 6 - selectedGCOrdersList?.length);
		console.log('6 - selectedGCOrdersList?.length: ',selectedGCOrdersList?.length, selectedGCOrdersList?.length >= 6 ? 0 : 6 - selectedGCOrdersList?.length);
	}, [isFirstLoad, selectedGCOrdersList])

	return (
		
			<Grid>
				<Grid container direction='row' alignItems='flex-end' >
					<Grid xs={4} >
						<img width="180" alt='logo' src='/img/rajsampler-logo-180.png' />
					</Grid>
					<Grid xs={4} justifyContent='center'>
						<Grid justifyContent='center' className='flex flex-wrap mt-4 mb-10'>
							<Typography variant="h2">
								<span className='border border-gray px-4 py-2'>
									Delivery Challan
								</span>
							</Typography>
						</Grid>

					</Grid>
					<Grid xs={4} className='text-right'>
						<Typography variant="h2" >
							<small>Phone Nr: <strong>+91 9898554003</strong> <br />
								GST Nr: <strong>24AGSPJ1604121ZD</strong></small><br />

							<strong>All kind of Quality Sample Maker</strong>

						</Typography>

					</Grid>
				</Grid>
				<Grid container direction='row' alignItems='center' >
					<Grid xs={12}>
						<hr className='mt-2' />
					</Grid>
				</Grid>

				<Grid container direction='row' alignItems='center' >
					<Grid xs={12}>
						<Typography variant="h2">
							<Grid className='text-center mt-2  leading-6'>
								178/1/B, Phase-1, Near Ingersoll Rand, GIDC, Naroda, Ahmedabad - 382330 <br />
								Email : raj.sampler@gmail.com

							</Grid>
						</Typography>
					</Grid>
				</Grid>
				<Grid container direction='row' alignItems='flex-start'  >
					<Grid xs={6}>
						<Grid className='border border-gray-200 mr-2 p-2'>
							<Grid container direction='row' alignItems='flex-start' >
								<Grid xs={2} className='px-3 pr-2'>
									<span className='text-xl font-semibold text-gray-600'>M/S.</span>
								</Grid>
								<Grid xs={10} className='px-3'>
									<Grid className='w-full border-b border-gray-200 mb-3 pb-2'>
										{/* <Typography variant='h3'>{selectedGCOrdersList?.length > 0 ? selectedGCOrdersList[0]?.CustomerName : ''}</Typography> */}
										<span className='text-lg font-semibold text-gray-600'>{selectedGCOrdersList?.length > 0 ? selectedGCOrdersList[0]?.CustomerName : ''}</span>
									</Grid>

								</Grid>
							</Grid>


						</Grid>
					</Grid>
					<Grid xs={6}>
						<Grid className='border border-gray-200 ml-2 p-2'>

							<Grid container direction='row' alignItems='flex-start' className='w-full border-b border-gray-200 -mx-2'>
								<Grid xs={3} className='px-3'>
									<span className='text-lg font-semibold text-gray-600'>D/C No.</span>
								</Grid>
								<Grid xs={9} className='px-3'>
									<Grid className='w-full'>
										<span className='text-lg font-semibold text-gray-600'>{DCDetail?.DCNo}</span>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction='row' alignItems='flex-start' className='w-full border-b border-gray-200 -mx-2'>
								<Grid xs={3} className='px-3'>
									<span className='text-lg font-semibold text-gray-600'>Date:</span>
								</Grid>
								<Grid xs={9} className='px-3'>
									<Grid className='w-full'>
										<span className='text-lg font-semibold text-gray-600'>{moment(DCDetail?.ChallanDate).format('D/MM/YYYY')}</span>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction='row' alignItems='flex-start' className='w-full  -mx-2'>
								<Grid xs={3} className='px-3'>
									<span className='text-lg font-semibold text-gray-600'>Bill No.</span>
								</Grid>
								<Grid xs={9} className='px-3'>
									<Grid className='w-full'>
										<span className='text-lg font-semibold text-gray-600'>{DCDetail?.BillNo}</span>
									</Grid>
								</Grid>
							</Grid>


						</Grid>
					</Grid>
				</Grid>
				<Grid container direction='row' alignItems='center' >
					<Grid xs={12}>
						<Typography variant="h3">
							<Grid className='text-center mt-2 leading-6'>
								Please received the following goods in good condition and order and return the duplicate duly signed.
							</Grid>
						</Typography>
					</Grid>
				</Grid>
				<Grid container direction='row' alignItems='center' >
					<Grid xs={12}>
						<TableContainer>
							<Table className="min-w-full mb-0 border-l border-gray-200">
								{/* <caption>A basic table example with a caption</caption> */}
								<TableHead>
									<TableRow>
										<TableCell className='leading-3 border-r border-gray-200 text-xxs' align="center">Sr #</TableCell>
										<TableCell className='leading-3 border-r border-gray-200 text-xxs' align="center">Particulars</TableCell>
										<TableCell className='leading-3 border-r border-gray-200 text-xxs' align="center">Order #</TableCell>
										<TableCell className='leading-3 border-r border-gray-200 text-xxs' align="center">Client</TableCell>
										<TableCell className='leading-3 border-r border-gray-200 text-xxs' align="center">Size</TableCell>
										<TableCell className='leading-3 border-r border-gray-200 text-xxs' align="center">Photos</TableCell>
										<TableCell className='leading-3 border-r border-gray-200 text-xxs' align="center">Quantity</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedGCOrdersList.map((row, index) => {
										row.FlowStepQuantityFinish = row.FlowStepQuantityFinish ? row.FlowStepQuantityFinish : row.FlowStepQuantityIn;
										return (
											<TableRow key={row.OrderNumber}>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center" component="th" scope="row">{index + 1}</TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center">{row.FileName}</TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center" scope="row">{row.OrderNumber}</TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center">{row.Client}</TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center">{row.FileSize}</TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center">{row.PhotosPage}</TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center">{row.FlowStepQuantityFinish}</TableCell>
											</TableRow>
										)
									})}
									{[...Array(emptyRowsCount)].map((e, i) => {
										return (
											<TableRow key={i}>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' style={{height: '18px'}} align="center" component="th" scope="row"></TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center"></TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center"></TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center"></TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center"></TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center"></TableCell>
												<TableCell className='leading-2 border-r border-gray-200 text-xxs' align="center"></TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>

				<Grid container direction='row' alignItems='flex-start' className='mt-2' >
					<Grid xs={12}>
						<Grid className='mr-2 p-2 py-1'>
							<Grid container direction='row' alignItems='flex-start' >
								<Grid xs={2} className='px-3'>
									<p>Remarks:</p>
								</Grid>
								<Grid xs={10} className='px-3'>
									<Grid className='w-full border-b border-gray-200'>
										<p variant='h3'>{DCDetail?.Remarks}</p>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={12}>
						<Grid className='mr-2 p-2 py-1'>
							<Grid container direction='row' alignItems='flex-start' >
								<Grid xs={2} className='px-3'>
									<p>Transport Detail:</p>
								</Grid>
								<Grid xs={10} className='px-3'>
									<Grid className='w-full border-b border-gray-200'>
										<p>{DCDetail?.TransportDetail}</p>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container direction='row' alignItems='flex-start' className='mt-2' >
					<Grid xs={12}>
						<Grid className='border border-gray-200 mr-2 px-2'>
							<Grid container direction='row' alignItems='flex-start' >
								<Grid xs={6} className='px-3 pt-3'>
									<Typography variant='h3'>Receiver's sign</Typography>
								</Grid>
								<Grid xs={6} className='px-3 text-right pt-5'>
									For, RAJ SAMPLER <sup>TM</sup>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		
	);
};
export default DeilveryChallanComponent;