import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { CustomButton } from "components/Common/CustomButton";
import { checkEmptyValidation } from 'services/validation-service';
import { FormLabel } from '@mui/material';

export default function CatelougeDialog(props) {
    const { open, setOpen, orderModel, setOrderModel, handleSubmitOrder } = props;

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const validateModelInitialState = {
        Remarks: { message: '', touched: false },
        UnitPrice: { message: '', touched: false },
        IsValidationError: false
    }
    const [passwordValidateModel, setPasswordValidateModel] = useState(validateModelInitialState);

    const handleClose = () => {
        setOrderModel({
            Remarks: null,
            UnitPrice: null
        });
        setPasswordValidateModel(validateModelInitialState);
        setOpen(false);
    };

    const handleSubmitChangePassword = async () => {
        if (!checkValidations()) {
            handleSubmitOrder()
            setOpen(false)
        }
    }


    const handleChange = (evt) => {
        if (evt.target.name === "Remarks") {
            setOrderModel({ ...orderModel, Remarks: evt.target.value });
            setPasswordValidateModel({ ...passwordValidateModel, Remarks: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
        }
        if (evt.target.name === "UnitPrice") {
            setOrderModel({ ...orderModel, UnitPrice: evt.target.value });
            setPasswordValidateModel({ ...passwordValidateModel, UnitPrice: { message: checkEmptyValidation(evt.target.value, "Price"), touched: true } });
        }
    }

    const checkValidations = () => {

        let validateErrors = {
            Remarks: { message: checkEmptyValidation(orderModel.Remarks === null ? '' : orderModel.Remarks, 'Remarks'), touched: true },
            UnitPrice: { message: checkEmptyValidation(orderModel.UnitPrice === null ? '' : orderModel.UnitPrice, 'Price'), touched: true }
        }
        if (
            validateErrors.Remarks.message !== '' ||
            validateErrors.UnitPrice.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setPasswordValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"> <span className="text-2xl">{"Create E-Catalogue"}</span></DialogTitle>
            <DialogContent>
                <Grid container>

                    <Grid item xs={12} lg={12}>
                        <div className="border-2 border-blue-500 rounded-md mb-6">
                            <div className="bg-blue-500 mb-4 px-4 py-3 border-b sm:px-6">
                                <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Remarks</h3>
                            </div>
                            <div className="p-2">
                                <Grid container>
                                    <Grid item xs={12} lg={12}>
                                        <FormGroup>

                                            <TextField
                                                variant="filled"
                                                component={Box}
                                                width="100%"
                                                marginBottom="1rem!important"
                                                autoComplete="off"
                                                name="Remarks"
                                                value={orderModel?.Remarks}
                                                multiline
                                                minRows={4}
                                                maxRows={6}
                                                onChange={e => handleChange(e)}
                                                error={passwordValidateModel.Remarks.message !== '' && passwordValidateModel.Remarks.touched}
                                                helperText={passwordValidateModel.Remarks.message !== '' && passwordValidateModel.Remarks.touched ? passwordValidateModel.Remarks.message : ''}
                                            >
                                                <Box
                                                    paddingLeft="0.75rem"
                                                    paddingRight="0.75rem"
                                                    component={FilledInput}
                                                    autoComplete="off"
                                                    type="text"
                                                />
                                            </TextField>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </div>


                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <div className="border-2 border-purple-500 rounded-md mb-6">
                            <div className="bg-purple-500 mb-4 px-4 py-3 border-b sm:px-6">
                                <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                            </div>


                            <div className="p-2">
                                <Grid container>
                                    <Grid item xs={12} lg={12}>
                                        <FormGroup>
                                            <FormLabel>Price</FormLabel>
                                            <TextField
                                                variant="filled"
                                                component={Box}
                                                width="100%"
                                                marginBottom="1rem!important"
                                                autoComplete="off"
                                                name="UnitPrice"
                                                type="number"
                                                value={orderModel?.UnitPrice}
                                                onChange={e => handleChange(e)}
                                                error={passwordValidateModel.UnitPrice.message !== '' && passwordValidateModel.UnitPrice.touched}
                                                helperText={passwordValidateModel.UnitPrice.message !== '' && passwordValidateModel.UnitPrice.touched ? passwordValidateModel.UnitPrice.message : ''}
                                            >
                                                <Box
                                                    paddingLeft="0.75rem"
                                                    paddingRight="0.75rem"
                                                    component={FilledInput}
                                                    autoComplete="off"
                                                    type="number"
                                                />
                                            </TextField>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </div>


                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CustomButton label="Cancel" onClick={handleClose} />
                <CustomButton label="Save" loading={showSubmitLoading} onClick={handleSubmitChangePassword} />
            </DialogActions>
        </Dialog>
    );
}
