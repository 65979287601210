import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { DialogActions } from '@mui/material';
import { CustomButton } from "components/Common/CustomButton";

import { Grid } from '@mui/material';

import QRCodeStyling from 'qr-code-styling';

function QRCodeDialog({ onClose, open }) {

    const handleClose = () => {
        onClose();
    };
    return (
        <Dialog
            fullWidth={true}
            open={open.isVisible}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <div className='bg-blue-500 border-blue-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between'>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid items-center">
                        <div className="text-gray-100">
                            <span className="text-2xl">{"Catalogue QRCode"}</span><br />
                        </div>
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent className="pb-0">
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <QrCode CatalogueId={open?.data?.UUId} open={open} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="pt-0">
                <CustomButton className="bg-red-500 border-red-500 hover:bg-red-700" label="Cancel" onClick={handleClose} />
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Open" onClick={async () => {
                    window.open(open?.url, "_blank")
                }} />
            </DialogActions>
        </Dialog>
    );
};

const QrCode = ({CatalogueId, open}) => {
    const ref = useRef(null)

    const [options, setOptions] = useState({
        width: 200,
        height: 200,
        image: open?.logoData?.LogoPath
              ? `${`${process.env.REACT_APP_AWS_S3_BUCKET_URL}`}${open?.logoData?.LogoPath}${open?.logoData?.LogoName}`
              : "/app-icon-36.png",
        dotsOptions: {
            color: "black",
            type: "dots",
            gradient: {
                type: "linear",
                rotation: 0,
                colorStops: [
                    { offset: 0, color: "black" },
                    { offset: 1, color: "black" }   
                ]
            }
        },  
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 0,
            imageSize: 0.4,
            hideBackgroundDots : true
        },
        cornersSquareOptions: {
            type: "dot",
            color: "black"
        },
        cornersDotOptions: {
            type: "dot"
        },
        data: `${process.env.REACT_APP_URL}/catalogue/${CatalogueId}`
    })

    useEffect(() => {
        setOptions(() => ({...options, data: `${process.env.REACT_APP_URL}/catalogue/${CatalogueId}`}))
    }, [CatalogueId])

    const [qrCode] = useState(new QRCodeStyling(options));

    useEffect(() => {
        if (ref.current && open.isVisible) {
            qrCode?.append(ref.current);
        }

    }, [qrCode, ref, open.isVisible]);

    useEffect(() => {
        if (!qrCode) return;
        qrCode.update(options);
    }, [qrCode, options]);

    return (
        <div className="flex flex-wrap items-center justify-center my-2 w-full sm:max-w-sm xl:max-w-md ml-auto mr-auto">
            <div ref={ref} id={`qr-gen-${CatalogueId}`} />
        </div>
    )
}
export default QRCodeDialog;