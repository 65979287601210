import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import "assets/scss/raj-sampler-react.scss";
import { StylesProvider } from "@mui/styles";


import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import { Provider } from 'react-redux';
import { store } from "generic/store";

//import "styles/tailwind.css";
//import "styles/default.css";
import "./styles/index.css";

import AuthProvider from "./context/AuthContext";
import theme from "assets/theme/theme";
import CatalogueDetail from "views/admin/catalogue/components/CatalogueDetail";
import PreOrder from "views/admin/preOrder/PreOrder";
//import PrivateRoute from './components/PrivateRoute';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AuthProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
              <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
              <Route path="/catalogue/:id" component={CatalogueDetail} />
              <Route path="/pre-order" component={PreOrder} />
              <Redirect from="/" to="/admin/index" />
            </Switch>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </StylesProvider>
  </Provider>
  // document.querySelector("#root")
);
