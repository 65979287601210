import React from "react";
// @mui/material components
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import {  FormControlLabel } from "@mui/material";

export function OrderECatalogue(props) {

    return (
        <>
            {/* PhotoShoot Information Start */}
            <div className="border-2 border-yellow-300 rounded-md mb-6">
                <div className="bg-yellow-300 mb-4 px-4 py-3 border-b sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Photoshoot</h3>
                </div>
                <div className="p-2">
                    <Grid container>
                        <Grid item xs={12} lg={2}>
                            <FormGroup>
                                <FormControlLabel
                                    className="ml-2"
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECataloguePhotoShoot}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECataloguePhotoShoot"
                                            color="primary"
                                        />
                                    }
                                    label="Photoshoot"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* PhotoShoot Information End */}


            {/* Fabric Layout Information Start */}
            <div className="border-2 border-blue-300 rounded-md mb-6">
                <div className="bg-blue-300 mb-4 px-4 py-3 border-b sm:px-6">
                    <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Fabric Layout</h3>
                </div>

                <div className="p-2">
                    <Grid container>
                        <Grid item xs={12} lg={2}>
                            <FormGroup>
                                <FormControlLabel
                                    className="ml-2"
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueFabricLayout}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueFabricLayout"
                                            color="primary"
                                        />
                                    }
                                    label="Fabric Layout"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Fabric Layout Information End */}

            {/* Color Matching Information Start */}
            <div className="border-2 border-red-300 rounded-md mb-6">
                <div className="bg-red-300 mb-4 px-4 py-3 border-b sm:px-6">
                    <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Color Matching</h3>
                </div>
                <div className="p-2">
                    <Grid container>
                        <Grid item xs={12} lg={2}>
                            <FormGroup>
                                <FormControlLabel
                                    className="ml-2"
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueColorMatching}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueColorMatching"
                                            color="primary"
                                        />
                                    }
                                    label="Color Matching"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Color Matching  Information End */}

            {/* Approval Information Start */}
            <div className="border-2 border-indigo-300 rounded-md mb-6">
                <div className="bg-indigo-300 mb-4 px-4 py-3 border-b sm:px-6">
                    <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Approval</h3>
                </div>
                <div className="p-2">
                    <Grid container>
                        <Grid item xs={12} lg={2}>
                            <FormGroup>
                                <FormControlLabel
                                    className="ml-2"
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueApproval}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueApproval"
                                            color="primary"
                                        />
                                    }
                                    label="Approval"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Approval Information End */}

            {/* Mail Information Start */}
            <div className="border-2 border-purple-300 rounded-md mb-6">
                <div className="bg-purple-300 mb-4 px-4 py-3 border-b sm:px-6">
                    <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Mail</h3>
                </div>
                <div className="p-2">
                    <Grid container>
                        <Grid item xs={12} lg={1}>
                            <FormGroup>
                                <FormControlLabel
                                    className="ml-2"
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueMail}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueMail"
                                            color="primary"
                                        />
                                    }
                                    label="Mail"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>


            </div>
            {/* Mail Information End */}

            {/* Delivery Information Start */}
            <div className="border-2 border-pink-300 rounded-md mb-6">
                <div className="bg-pink-300 mb-4 px-4 py-3 border-b sm:px-6">
                    <h3 className="text-xl text-center font-bold leading-6 text-gray-900">Delivery</h3>
                </div>

                <div className="p-2">
                    <Grid container>
                        <Grid item xs={12} lg={1}>
                            <FormGroup>
                                <FormControlLabel
                                    className="ml-2"
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueDelivery}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueDelivery"
                                            color="primary"
                                        />
                                    }
                                    label="Delivery"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>


            </div>
            {/* Fitting Information End */}
        </>
    );
};