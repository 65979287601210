import { Box, Button, FormGroup, FormHelperText, FormLabel, Grid } from "@mui/material";
import React, { useState, useContext } from "react";
import { verifyUserService } from "services/data-service";
import MessageBar from "components/MessageBar/MessageBar";
import { authContext } from '../../context/AuthContext'
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import VerificationCodeInput from "components/Common/CodeInput";


const QRCode = require("qrcode.react"); // react Qrcode component


//======================= This class used to show qrcode and user link =======================//
export const Verification = (props) => {
    let verifyInput = React.createRef();
    const { secret, Username, defaultShowQRCode, userToken } = props;
    const { setAuthData } = useContext(authContext);
    const history = useHistory();
    const [verificationModel, setVerificationModel] = useState({
        VerificationCode: ''
    });
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [showQRCode] = useState(defaultShowQRCode ? true : false);
    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    const url = `otpauth://totp/RajSamplerERP%20(${Username})?secret=${secret}`; // authentication user url and user name


    const handleChange = (id, value) => {
        switch (id) {
            case 'VerificationCode':
                setVerificationModel({ ...verificationModel, VerificationCode: value });
                setShowValidationMessage(value === '' ? true : false)
                break;
            default:
                setVerificationModel({ ...verificationModel });
                setShowValidationMessage(false)
                break;
        }
    };

    const verifyHandle = async (val) => {
        setMessageDialoge({ show: false, type: '', message: '' });
        setShowValidationMessage(false)
        if (verificationModel.VerificationCode === '') {
            setShowValidationMessage(true)
            return false;
        }

        let reqData = {
            Token: userToken,
            VerificationCode: val || verificationModel.VerificationCode
        }
        let res = await verifyUserService(reqData);
        if (res && (res.status === 200 || res.status === 304)) {
            let data = res.data;
            if (res.newSecret === true) {

            }
            else {
                Cookies.set('token', data.token, { expires: 2 });
                setAuthData(data.token)
                history.push('/admin/index');
            }
        }
        else {
            setMessageDialoge({ show: true, message: res?.message, type: 'error' })
        }
    }

    return (
        <>
            <div>
                <MessageBar className="mb-0" show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                <FormGroup>
                    <FormLabel>Verification Code</FormLabel>
                    <Grid className='flex flex-wrap items-center'>
                        <VerificationCodeInput
                            ref={verifyInput}
                            className="custom-class"
                            onChange={val => handleChange('VerificationCode', val)}
                            onComplete={val => verifyHandle(val)} />
                    </Grid>
                    {verificationModel.VerificationCode === '' && showValidationMessage &&
                        <FormHelperText className="text-red-500">Please enter verification code</FormHelperText>
                    }
                </FormGroup>

                {/* <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                >
                    <FilledInput
                        autoComplete="off"
                        placeholder="VerificationCode"
                        value={verificationModel.VerificationCode}
                        onChange={e => handleChange('VerificationCode', e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <VpnKey />
                            </InputAdornment>
                        }
                    />
                    {verificationModel.VerificationCode === '' && showValidationMessage &&
                        <FormHelperText className="text-red-500 mx-0">Please enter verification code</FormHelperText>
                    }
                </FormControl> */}
                <Box textAlign="center" marginBottom="1rem">
                    <Button color="primary" className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2] px-10" variant="contained" onClick={verifyHandle}>
                        Verify
                    </Button>
                </Box>
            </div>
            {showQRCode &&
                <div className="w-full">
                    <div className="container">

                        <div className="flex flex-wrap items-center justify-center my-2 w-full sm:max-w-sm xl:max-w-md ml-auto mr-auto">
                            <div className="bg-white rounded mt-3 p-4 w-4/5">
                                <QRCode id="qrcode" value={url} style={{ height: "100%", width: "100%" }} />
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}
//=========================================================//

export default Verification;