import React from "react";
import { Checkbox } from '@mui/material';

function PrintOrderFormEcatalogue(props) {
    const { orderData } = props;

    return (
        <div className="w-full mt-2 uppercase text-sm	">
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">
                            Photoshoot <Checkbox size={'small'} checked={orderData?.ECataloguePhotoShoot === 1 ? true : false} name="ECataloguePhotoShoot" className="p-0" color="primary" />
                        </span>
                    </div>
                    <div className="col-span-5 py-2 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="PBDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">
                            Fabric Layout <Checkbox size={'small'} checked={orderData?.ECatalogueFabricLayout === 1 ? true : false} name="ECatalogueFabricLayout" className="p-0" color="primary" />
                        </span>
                    </div>
                    <div className="col-span-5 py-2 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="CuttingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
            </div>
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">
                            Color Matching <Checkbox size={'small'} checked={orderData?.ECatalogueColorMatching === 1 ? true : false} name="ECatalogueColorMatching" className="p-0" color="primary" />
                        </span>
                    </div>
                    <div className="col-span-5 py-2 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="BindingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">
                            Approval <Checkbox size={'small'} checked={orderData?.ECatalogueApproval === 1 ? true : false} name="ECatalogueApproval" className="p-0" color="primary" />
                        </span>
                    </div>
                    <div className="col-span-5 py-2 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="TagDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">
                            Mail <Checkbox size={'small'} checked={orderData?.ECatalogueMail === 1 ? true : false} name="ECatalogueMail" className="p-0" color="primary" />
                        </span>
                    </div>
                    <div className="col-span-5 py-2 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="PhotosDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">
                            Delivery <Checkbox size={'small'} checked={orderData?.ECatalogueDelivery === 1 ? true : false} name="ECatalogueDelivery" className="p-0" color="primary" />
                        </span>
                    </div>
                    <div className="col-span-5 py-2 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="ScreenDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
            </div>
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">Extra</span>
                    </div>
                    <div className="col-span-5 py-2 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="ExtraDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-2 py-2">
                        <span className="flex items-center justify-start">
                            <span>Parcel Type:</span>
                        </span>
                    </div>
                    <div className="col-span-4 py-2">
                        <span className="flex items-center justify-start mr-4">
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.Parcel === 1 ? true : false} name="Parcel" className="p-0" color="primary" />
                                <span className="ml-1">Parcel</span>
                            </span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.Loose === 1 ? true : false} name="Loose" className="p-0" color="primary" />
                                <span className="ml-1">Loose</span>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 pl-2 border-l-4">
                        <span className="flex items-center justify-start">
                            <span>Challan Sequence:</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-2">
                        <span className="flex items-center justify-start">
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" className="p-0" color="primary" />
                                <span className="ml-1">1</span>
                            </span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" className="p-0" color="primary" />
                                <span className="ml-1">3</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-2 px-1 font-semibold uppercase">Remarks</span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-2">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-2 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.Remarks}
                        </span></span>
                    </div>
                </div>
            </div>


            <div className="grid grid-cols-12 gap-2 px-1 mt-2">
                <div className="col-span-6 py-2">
                    <span className="flex items-center justify-start mr-4">
                        Party Signature
                    </span>
                </div>
                <div className="col-span-6 py-2">
                    <span className="flex items-center justify-end"><span className="ml-1">
                        Signature
                    </span></span>
                </div>
            </div>


        </div>
    );
};
export default PrintOrderFormEcatalogue;