import React, { useState, useEffect, useCallback } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { debounce, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { currentUser } from "services/user-service";

import * as yup from 'yup';
import { useFormik } from "formik"
import DeliveryFileQuantity from "../components/dialog/DeliveryFileQuantity";
import { CustomButton } from "components/Common/CustomButton";
import { Edit } from "@mui/icons-material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();
const useStyles = makeStyles(componentStyles);

function CustomerGoodsEntry() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id } = useParams();
    const PageTitle = id ? 'Update Goods Entry' : 'Add Goods Entry';

    // Usef defined states
    const [goodsEntryModel, setGoodsEntryModel] = useState();
    const [productList, setProductList] = useState([]);

    const [selectedFileNamesList, setSelectedFileNamesList] = useState([]);
    const [openAddQuantityDialog, setOpenAddQuantityDialog] = useState(false);
    const [selectedFileForQuantity, setSelectedFileForQuantity] = useState();
    const [openEditQuantityDialog, setOpenEditQuantityDialog] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null)

    const [FileNamesList, setFileNamesList] = useState([]);
    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [fileloading, setFileLoading] = useState(false);
    const [isReload] = useState(false);

    const [messageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        fetchData()
    }, [id, isReload])

    const fetchData = async () => {
        if (id) {
            await getGoodsEntryData();
        }
        await getProductList();
    }

    const getProductList = async () => {
        let res = await dataService('get', 'CommonList', `Query=products`);
        if (res.status === 200) {
            if (res?.data?.length > 0) {
                setProductList(res?.data);
            }
        }
    }

    const getGoodsEntryData = async () => {
        let res = await dataService('get', `CustomerGoodsEntryGet`, `GoodsEntryId=${id}&GoodsCustomerId=${currentUser()?.CustomerId}`);
        if (res.status === 200) {
            if (res?.data) {
                setGoodsEntryModel(res?.data);
            }
            if (res?.data?.GoodsEntryFiles?.length > 0) {
                setSelectedFileNamesList(res?.data?.GoodsEntryFiles);
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            LRNumbers: goodsEntryModel?.GoodsEntryData?.LRNumbers || "",
            Transport: goodsEntryModel?.GoodsEntryData?.Transport || "",
            Parcel: goodsEntryModel?.GoodsEntryData?.Parcel || "",
            Bundles: goodsEntryModel?.GoodsEntryData?.Bundles || "",
            CustomerId: currentUser()?.CustomerId,
            TagDetails: goodsEntryModel?.GoodsEntryData?.TagDetails || "",
            CreatedBy: currentUser()?.CustomerId,
            Status: goodsEntryModel?.GoodsEntryData?.Status || "Pending",
            CustomerGoodsEntryID: goodsEntryModel?.GoodsEntryData?.CustomerGoodsEntryID || 0,
            DisplayFilesData: goodsEntryModel?.GoodsEntryFiles || [],
            FilesData: [],
            FileNames: goodsEntryModel?.GoodsEntryFiles?.reduce((acc, e) => { acc.push(e.FileNames); return acc }, []) || [],
            DeleteGoodsEntryFiles: [],
        },
        enableReinitialize: true,
        validationSchema:
            yup.object().shape({
                LRNumbers: yup.string().required('LR Number is required'),
                Transport: yup.string().required('Transport is required'),
                Parcel: yup.string().required('Parcel is required'),
                Bundles: yup.string().required('Bundles is required'),
                TagDetails: yup.string().required('TagDetails is required.'),
            }),
        onSubmit: async (values) => {
            const payload = {
                ...values, DeleteGoodsEntryFiles: formik.values.DeleteGoodsEntryFiles?.reduce((acc, e) => { acc.push(e.CustomerGoodsEntryFileId); return acc }, []) || []
            }
            setShowSubmitLoading(true);
            if (id) {
                let res = await dataService('post', `CustomerGoodsEntry`, payload);
                setShowSubmitLoading(false);
                if (res && res.status === 200) {
                    setTimeout(() => {
                        history.push('/admin/customer-goods-entries')
                    }, 500);
                }
            }
            else {
                let res = await dataService('post', `CustomerGoodsEntry`, payload);
                setShowSubmitLoading(false);
                if (res && res.status === 200) {
                    setTimeout(() => {
                        history.push('customer-goods-entries')
                    }, 500);
                }
            }
        }
    })

    const handleEditFileQuantityDialog = (index, label) => {
        setSelectedFileForQuantity(label);
        setCurrentIndex(index)
        setOpenEditQuantityDialog(true);
    }
    const fileNameChangeHandle = (event, list, reason, detail) => {
        if (reason === 'removeOption') {
            let farr = formik.values.FileNames.filter(item => item !== detail?.option?.FileNames)
            let sFileNamesList = [...selectedFileNamesList];
            let sFileNamesListNew = [...selectedFileNamesList];
            sFileNamesList = detail?.option?.CustomerGoodsEntryFileId ? sFileNamesList.filter(item => item.CustomerGoodsEntryFileId !== detail?.option?.CustomerGoodsEntryFileId) : sFileNamesList.filter(item => item.FileNames !== detail?.option?.FileNames);
            let deleteGoodsEntryFilesId = detail?.option?.CustomerGoodsEntryFileId ? sFileNamesListNew.filter(item => Number(item.CustomerGoodsEntryFileId) === Number(detail?.option?.CustomerGoodsEntryFileId)) : [];
            let selFilesData = [...formik.values.FilesData]
            let selectedFileIds = selFilesData.filter(item => item.FileId ? item.FileId !== detail?.option?.FileId : item.FileNames !== detail?.option?.FileNames);

            let selDisplayFilesData = [...formik.values.DisplayFilesData]
            let selDisplayFileIds = selDisplayFilesData.filter(item => item.FileId ? item.FileId !== detail?.option?.FileId : item.FileNames !== detail?.option?.FileNames);

            setSelectedFileNamesList(sFileNamesList);
            formik.setValues({
                ...formik.values,
                FileNames: farr,
                FilesData: selectedFileIds,
                DisplayFilesData: selDisplayFileIds,
                DeleteGoodsEntryFiles: [...formik.values.DeleteGoodsEntryFiles, ...deleteGoodsEntryFilesId],
            })
        }
        else {
            let insrtFile = {
                FileId: detail?.option?.FileId || null,
                FileNames: detail?.option?.Name || ""
            }
            setOpenAddQuantityDialog(true);
            setSelectedFileForQuantity(insrtFile);
        }
    }


    const addFileQuantityHandle = (quantityFileData) => {
        let selList = [...selectedFileNamesList];

        selList.push({
            FileId: quantityFileData?.FileId || null,
            FileNames: quantityFileData?.FileNames,
            Quantity: quantityFileData?.Quantity,
            ProductFileId: quantityFileData?.ProductFileId,
            FileSize: quantityFileData?.FileSize,
            Client: quantityFileData?.Client,
            ProductName: quantityFileData?.ProductName,
            ProductId: quantityFileData?.ProductId,
        })

        const updatedDisplayFilesData = [
            ...formik.values.DisplayFilesData,
            quantityFileData
        ];
        formik.setFieldValue("FileNames", [...formik.values.FileNames, quantityFileData.FileNames])
        formik.setFieldValue("DisplayFilesData", updatedDisplayFilesData);
        formik.setFieldValue("FilesData", [...formik.values.FilesData, quantityFileData]);
        setSelectedFileNamesList(selList);
        setOpenAddQuantityDialog(false);
        fetchFileListData("")
    }

    const editFileQuantityHandle = (quantityFileData) => {
        const updateData = [...formik.values.DisplayFilesData];
        updateData[currentIndex] = {
            ...updateData[currentIndex],
            ...quantityFileData
        }
        formik.setFieldValue("DisplayFilesData", updateData);
        setOpenEditQuantityDialog(false);
        setSelectedFileForQuantity(null);
    }

    const fetchFileListData = useCallback(async (search = '') => {
        let res = await dataService('get', 'FileSearchByName', `searchText=${search}`);
        setTimeout(() => {
            setFileLoading(false)
        }, 1000);
        setFileNamesList(res?.data)
    }, []);

    const handleTextChange = useCallback(
        debounce(async function (event) {
            if (event.target.value.length === 0  || event.target.value.length >= 3) {
                setFileLoading(true)
                await fetchFileListData(event?.target?.value ?? '');
            }
        }, 300),
        [fetchFileListData]
    );

    return (
        <>
            <Header />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            xl={12}
                            component={Box}
                            marginBottom="3rem"
                            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                        >
                            <Card
                                classes={{
                                    root: classes.cardRoot + " " + classes.cardRootSecondary,
                                }}
                            >
                                <CardHeader
                                    subheader={
                                        <Grid
                                            container
                                            component={Box}
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item xs="auto">
                                                <Box
                                                    component={Typography}
                                                    variant="h3"
                                                    marginBottom="0!important"
                                                >
                                                    {PageTitle}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    classes={{ root: classes.cardHeaderRoot }}
                                ></CardHeader>
                                <CardContent>
                                    <Grid item xs={12} lg={12}>
                                        <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                    </Grid>
                                    <Box
                                        component={Typography}
                                        variant="h6"
                                        color={theme.palette.gray[600] + "!important"}
                                        paddingTop=".25rem"
                                        paddingBottom=".25rem"
                                        fontSize=".75rem!important"
                                        letterSpacing=".04em"
                                        marginBottom="1.5rem!important"
                                        classes={{ root: classes.typographyRootH6 }}
                                    >
                                        Goods Entry Information
                                    </Box>
                                    <div className={classes.plLg4}>
                                        <Grid container>
                                            <Grid item xs={12} lg={5}>
                                                <FormGroup>
                                                    <FormLabel>LR Numbers</FormLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="LRNumbers"
                                                        component={Box}
                                                        width="100%"
                                                        marginBottom="1rem!important"
                                                        value={formik.values.LRNumbers}
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.LRNumbers && formik.errors.LRNumbers}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </FormGroup>
                                            </Grid>
                                            {/* <Grid item xs={12} lg={4}>
                                            <FormGroup>
                                                <FormLabel>Status</FormLabel>
                                                {statusData?.length > 0 &&
                                                    <Autocomplete
                                                        id="Status"
                                                        value={statusData.find((item) => item.value === formik.values.Status) || null}
                                                        options={statusData}
                                                        onChange={(event, newValue) => {
                                                            formik.setFieldValue("Status", newValue?.value || "")
                                                        }}
                                                        autoHighlight
                                                        getOptionSelected={(option, value) => option.value === value.value }

                                                        getOptionLabel={(option) =>  {
                                                            return (
                                                                option.label
                                                            )
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                value={formik.values.Status}
                                                                variant="outlined"
                                                                name="Status"
                                                                placeholder="Select Status"
                                                                autoComplete="new-password"
                                                                error={formik.errors.Status}
                                                            />
                                                        )}
                                                    />
                                                }
                                                <FormHelperText className="text-red-500">{goodsEntryValidateModel.CustomerId.message !== '' && goodsEntryValidateModel.CustomerId.touched ? goodsEntryValidateModel.CustomerId.message : ''}</FormHelperText>
                                            </FormGroup>
                                        </Grid> */}
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} lg={3}>
                                                <FormGroup>
                                                    <FormLabel>Transport</FormLabel>
                                                    <TextField
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                        name="Transport"
                                                        marginBottom="1rem!important"
                                                        value={formik.values.Transport}
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.Transport && formik.errors.Transport}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} lg={3}>
                                                <FormGroup>
                                                    <FormLabel>Bundles</FormLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="Bundles"
                                                        component={Box}
                                                        width="100%"
                                                        marginBottom="1rem!important"
                                                        value={formik.values.Bundles}
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.Bundles && formik.errors.Bundles}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} lg={3}>
                                                <FormGroup>
                                                    <FormLabel>Parcel</FormLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="Parcel"
                                                        component={Box}
                                                        width="100%"
                                                        marginBottom="1rem!important"
                                                        value={formik.values.Parcel}
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.Parcel && formik.errors.Parcel}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </FormGroup>
                                            </Grid>

                                            <Grid item xs={12} lg={3}>
                                                <FormGroup>
                                                    <FormLabel>TagDetails</FormLabel>
                                                    <TextField
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                        name="TagDetails"
                                                        marginBottom="1rem!important"
                                                        value={formik.values.TagDetails}
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.TagDetails && formik.errors.TagDetails}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} lg={6}>

                                                <FormGroup>
                                                    <FormLabel>File Names</FormLabel>
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        classes={{
                                                            inputRoot: classes.inputRoot,
                                                        }}
                                                        loading={fileloading}
                                                        options={FileNamesList}
                                                        value={selectedFileNamesList}
                                                        onChange={fileNameChangeHandle}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            if (params.inputValue !== '') {
                                                                filtered.push({
                                                                    inputValue: params.inputValue,
                                                                    Name: params.inputValue
                                                                });
                                                            }
                                                            return filtered;
                                                        }}
                                                        getOptionLabel={(option) => option.Name || option.FileNames}
                                                        //defaultValue={topicsArray}
                                                        className="w-full flex items-center"
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            onChange={handleTextChange}
                                                            variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {fileloading ? <CircularProgress sx={{ marginRight: 5 }} color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                )
                                                            }}
                                                        />
                                                        )}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            {formik.values?.DisplayFilesData?.length > 0 &&
                                                <Grid item xs={12} lg={12}>
                                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
                                                        <div className="border-t border-gray-200">
                                                            <dl>
                                                                <div className={`px-4 py-5 sm:grid sm:grid-cols-6 sm:px-6 bg-white`}>
                                                                    <dt className="text-sm text-blue-700 font-bold">File Name</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">Product</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">File Size</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">Client</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">Quantity</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold text-center">Edit</dt>
                                                                </div>
                                                                {formik.values?.DisplayFilesData?.map((label, index) => (
                                                                    <div className={`px-4 py-5 sm:grid sm:grid-cols-6 sm:px-6 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                                                        <dt className="text-sm font-medium text-gray-500">{label?.FileNames || label?.Name}</dt>
                                                                        <dt className="text-sm font-medium text-gray-500">{label.ProductName}</dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                                            {label?.FileSize}
                                                                        </dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                                            {label?.Client}
                                                                        </dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                                            {label?.Quantity}
                                                                        </dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0  text-center">
                                                                            <CustomButton className='bg-white text-blue-700 border-none' size="small" label=" " onClick={() => { handleEditFileQuantityDialog(index, label) }} icon={Edit} iconClassName="w-5 h-auto" />
                                                                        </dt>
                                                                    </div>
                                                                ))}
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>


                                        <Grid container>
                                            <Grid item xs={12} lg={6}>
                                                <Box
                                                    justifyContent="flex-end"
                                                    display="flex"
                                                    flexWrap="wrap"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="submit"
                                                        disabled={showSubmitLoading}
                                                        className="bg-[#5e72e4] border-[#5e72e4]"
                                                    >
                                                        Submit
                                                        {showSubmitLoading && <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Container>

            {openAddQuantityDialog &&
                <DeliveryFileQuantity onClose={setOpenAddQuantityDialog} open={openAddQuantityDialog} addFileQuantityHandle={addFileQuantityHandle} fileData={selectedFileForQuantity} isEdit={false} productList={productList} />
            }
            {openEditQuantityDialog &&
                <DeliveryFileQuantity onClose={setOpenEditQuantityDialog} open={openEditQuantityDialog} fileData={selectedFileForQuantity} addFileQuantityHandle={editFileQuantityHandle} isEdit={true} productList={productList} />
            }

        </>
    );
}

export default CustomerGoodsEntry;
