// import Button from '@mui/material/Button';
import { DialogContent, Grid } from '@mui/material';
//import moment from "moment";
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { CustomButton } from "components/Common/CustomButton";
import "assets/css/print-label.css";

function PrintLabelDialog(props) {
    const { onClose, open, labelDetail } = props;
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        if (labelDetail?.FileList) {
            let fList = JSON.parse(labelDetail?.FileList).reduce((acc, e) => { acc?.push(e?.Name); return acc }, [])
            setFileList(fList?.toString());
        }
        setTimeout(() => {
            // window.print();    
        }, 2000);

    }

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogContent className="p-0">
                <Grid className='w-full flex align-center justify-center no-print mb-2'>
                    <CustomButton className='bg-blue-400 mt-1 text-white no-print text-center mr-1' size="small" label="Print" onClick={(e) => { window.print(); }} />
                    <CustomButton className='bg-blue-400 mt-1 text-white no-print text-center mr-1' size="small" label="Cancel" onClick={handleClose} />
                </Grid>
                <Grid className='w-8/9 text-sm text-black'>
                    <Grid>
                        Customer: <strong>{labelDetail?.CustomerName}</strong>
                    </Grid>
                    <Grid className='grid grid-cols-2 gap-2'>
                        <div>
                            File size: <strong>{labelDetail?.FileSize}</strong>
                        </div>
                        <div>
                            Parcels: <strong>{labelDetail?.Bundles}</strong>
                        </div>
                    </Grid>
                    <Grid className='grid grid-cols-2 gap-2'>
                        <div>
                            File Name: <strong>{fileList}</strong>
                        </div>
                        <div>
                            Pieces: <strong>{labelDetail?.Rolls}</strong>
                        </div>

                    </Grid>
                    <Grid>
                        Remarks: <strong>{labelDetail?.Remarks}</strong>
                    </Grid>



                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default PrintLabelDialog;