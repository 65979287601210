import React from "react";
import { Checkbox } from '@mui/material';

function PrintOrderFormShadeCard(props) {
    const { orderData } = props;

    return (
        <div className="w-full mt-2 uppercase text-sm	">
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">
                            Party Fabric
                        </span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="PBDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Full:</span>
                            <span className="ml-1"><strong>{orderData?.PBFull}</strong></span>

                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>MTR: </span>
                            <span className="ml-1"><strong>{orderData?.PBFullMtr}</strong></span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Main: </span>
                            <span className="ml-1"><strong>{orderData?.PBMain}</strong></span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>MTR: </span>
                            <span className="ml-1"><strong>{orderData?.PBMainMtr}</strong> </span>

                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks:</span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.PBRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Cutting</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="CuttingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Small Piece Patta: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingSmallPiecePatta}</strong> </span>

                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Small Piece Size: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingSmallPieceSize}</strong></span>

                        </span>
                    </div>

                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Table: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingTable}</strong></span>

                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Filler Patta: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingFillerPatta}</strong> </span>

                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Filler Size: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingFillerSize}</strong></span>

                        </span>
                    </div>

                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Total: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingTotal}</strong></span>

                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start border-l-4">
                            <Checkbox size={'small'} checked={orderData?.TagZigZag === 1 ? true : false} name="TagZigZag" className="p-0" color="primary" />
                            <span className="ml-1">ZigZag</span>
                        </span>
                    </div>                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagInterlock === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Interlock</span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks:</span>
                    </div>
                    <div className="col-span-11 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.CuttingRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">
                            Binding
                        </span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="BindingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-2 border-t border-slate-200 px-1">
                    <div className="py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Back to Back:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsBackToBack}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Total:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsTotal}
                                </strong>
                            </span>
                        </span>
                    </div>
                </div>
                  <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start border-l-4">
                            <Checkbox size={'small'} checked={orderData?.BindingsDieCut === 1 ? true : false} name="TagZigZag" className="p-0" color="primary" />
                            <span className="ml-1">Die Cut</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsFoil === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Foil</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsTagPrinted === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Tag Printed</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsMDF === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">MDF</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsCappaBoard === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Cappa Board</span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0  pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.BindingsRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Photos</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="PhotosDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Printer:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.PhotosPrinter}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Paper GSM:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.PhotosPaperGSM}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Photo Size:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.PhotosSize}
                                </strong>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-5 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span className="ml-1 mr-2">Page</span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(1) ? true : false} name="PhotosPage1" className="p-0" color="primary" />
                                <span className="ml-1">1</span>
                            </span>
                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(2) ? true : false} name="PhotosPage2" className="p-0" color="primary" />
                                <span className="ml-1">2</span>
                            </span>
                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(3) ? true : false} name="PhotosPage3" className="p-0" color="primary" />
                                <span className="ml-1">3</span>
                            </span>

                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(4) ? true : false} name="PhotosPage4" className="p-0" color="primary" />
                                <span className="ml-1">4</span>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosLamination === 1 ? true : false} name="PhotosLamination" className="p-0" color="primary" />
                            <span className="ml-1">Lamination</span>
                        </span>
                    </div>
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosUV === 1 ? true : false} name="PhotosUV" className="p-0" color="primary" />
                            <span className="ml-1">UV</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosMatt === 1 ? true : false} name="PhotosMatt" className="p-0" color="primary" />
                            <span className="ml-1">Matt</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosGlossy === 1 ? true : false} name="PhotosGlossy" className="p-0" color="primary" />
                            <span className="ml-1 mr-1">Glossy</span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.PhotosRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Screen</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="ScreenDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Gumming:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.ScreenGumming}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Total:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.ScreenTotal}
                                </strong>
                            </span>
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.ScreenRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Fittings</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="FittingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Filler:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.FittingFiller}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Total:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.FittingTotal}
                                </strong>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.FittingRemarks}
                        </span></span>
                    </div>
                </div>
            </div>
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Extra</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="ExtraDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <span>Parcel Type:</span>
                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.Parcel === 1 ? true : false} name="Parcel" className="p-0" color="primary" />
                                <span className="ml-1">Parcel</span>
                            </span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.Loose === 1 ? true : false} name="Loose" className="p-0" color="primary" />
                                <span className="ml-1">Loose</span>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 pl-2 border-l-4">
                        <span className="flex items-center justify-start">
                            <span>Challan Sequence:</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" className="p-0" color="primary" />
                                <span className="ml-1">1</span>
                            </span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" className="p-0" color="primary" />
                                <span className="ml-1">3</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Remarks</span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.Remarks}
                        </span></span>
                    </div>
                </div>
            </div>


            <div className="grid grid-cols-12 gap-2 px-1 mt-2">
                <div className="col-span-6 py-0">
                    <span className="flex items-center justify-start mr-4">
                        Party Signature
                    </span>
                </div>
                <div className="col-span-6 py-0">
                    <span className="flex items-center justify-end"><span className="ml-1">
                        Signature
                    </span></span>
                </div>
            </div>


        </div>
    );
};
export default PrintOrderFormShadeCard;