import React, { useEffect, useState } from "react";
import { Checkbox, DialogContent, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
//import moment from "moment";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import MessageBar from "components/MessageBar/MessageBar";
import moment from "moment";
import DeilveryChallanDialog from "./DeliveryChallanDialog";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { CustomButton } from "components/Common/CustomButton";
//import { currentUser } from "services/user-service";

function OrderFlowGCDialog(props) {
    const { onClose, open, WorkCenterId, setIsReload, isReload, selectedGCOrders } = props;
    const [selectedGCOrdersList, setSelectedGCOrders] = useState(selectedGCOrders);

    const [machineList, setMachineList] = useState([]);
    const [operatorList, setOperatorList] = useState([]);
    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
    const [openDeliveryChallanDialog, setOpenDeliveryChallanDialog] = useState(false); // loading page state value
    const [isFirstLoad, setIsFirstLoad] = useState(true); // loading page state value
    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });
    // Usef defined states

    const [flowStepValidateModel, setFlowStepValidateModel] = useState({

        ProductId: { message: '', touched: false },
        CustomerId: { message: '', touched: false },
        Date: { message: '', touched: false },
        ProductFileId: { message: '', touched: false },
        Quantity: { message: '', touched: false },
        UnitPrice: { message: '', touched: false },
        IsValidationError: false
    });

    const setFlowStepModelData = (flowData) => {
        let flowStepModel = {
            WorkCenterId: WorkCenterId || 0,
            TransportDetail: null,
            MachineId: null,
            OperatorId: null,
            MachineIds: [],
            OperatorIds: [],
            Remarks: null,
            Rolls: null,
            Bundles: null,
            DCNo: null,
            BillNo: null,
            ChallanDate: new Date()
        }
        return flowStepModel;
    }

    const [flowStepModel, setFlowStepModel] = useState(setFlowStepModelData(null));

    const handleClose = () => {
        onClose(false);
    };

    const handleGenerateAndClose = () => {
        setIsReload(!isReload)
        onClose(false);
    };

    useEffect(() => {
        fetchData()
    }, [isFirstLoad])

    const fetchData = async () => {
        let res = await dataService('get', `OrderOperatorMachineList/${18}`, '');
        let machineOperatorArray = res?.data
        const MachineValues = machineOperatorArray.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => Number(t.MachineId) === Number(thing.MachineId))) === i;
        });

        setMachineList(MachineValues);


        const OperatorValues = machineOperatorArray.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => Number(t.OperatorId) === Number(thing.OperatorId))) === i;
        });


        setOperatorList(OperatorValues);

        let resNewDCNo = await dataService('get', `GenerateDeliveryChallanNumber`, '');
        if (resNewDCNo) {
            let resNewDCNoData = resNewDCNo?.data
            setFlowStepModel({ ...flowStepModel, DCNo: resNewDCNoData?.DCNo });
        }
    }

    const handleChange = (evt, type, checkValidation) => {
        setFlowStepModel({ ...flowStepModel, [evt.target.name]: evt.target.value });
        if (checkValidation) {
            if (evt.target.name === 'QuantityFinish') {
                setFlowStepModel({ ...flowStepModel, [evt.target.name]: evt.target.value });
                if (evt.target.value === "") {
                    setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
                }
                else {
                    if (evt.target.value > flowStepModel.OriginalInQuantity) {
                        setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: "Quantity can not be greater then In Quantity", touched: true } });
                    }
                    else {
                        setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: "", touched: true } });
                    }
                }
            }
            else {
                setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
            }
        }
    };

    const handleSelectChange = (id, value) => {

        setFlowStepModel({ ...flowStepModel, [id]: value });

    }

    const handleQuantityChange = (evt, row) => {
        let selectedRowGC = [...selectedGCOrders];
        let rowGC = selectedRowGC.find((r) => Number(r.FlowStepId) === Number(row.FlowStepId));
        if (rowGC) {
            rowGC.FlowStepQuantityFinish = evt.target.value;
        }
        setSelectedGCOrders(selectedRowGC)
    };



    const handleCheckBoxes = (evt) => {

        if (evt.target.name === 'OperatorIds') {
            if (evt.target.checked) {
                let Oids = flowStepModel?.OperatorIds;
                Oids.push(parseInt(evt.target.value || 0));
                setFlowStepModel({ ...flowStepModel, OperatorIds: Oids });
            }
            else {
                let Oids = flowStepModel?.OperatorIds;
                Oids = Oids.filter(item => item !== parseInt(evt.target.value || 0))
                setFlowStepModel({ ...flowStepModel, OperatorIds: Oids });
            }
        }
        else if (evt.target.name === 'MachineIds') {
            if (evt.target.checked) {
                let Mids = flowStepModel?.MachineIds;
                Mids.push(parseInt(evt.target.value || 0));
                setFlowStepModel({ ...flowStepModel, MachineIds: Mids });
            }
            else {
                let Mids = flowStepModel?.MachineIds;
                Mids = Mids.filter(item => item !== parseInt(evt.target.value || 0))
                setFlowStepModel({ ...flowStepModel, MachineIds: Mids });
            }
        }
        //setFlowStepModel({ ...orderModel, [evt.target.name]: evt.target.checked });
    }



    const handleSubmitOrder = async () => {
        let reqData = { ...flowStepModel };

        if (!checkValidations()) {

            //setShowSubmitLoading(true);
            reqData.OperatorIds = reqData?.OperatorIds?.join(','); //Convert to comma seperated values 
            reqData.MachineIds = reqData?.MachineIds?.join(','); //Convert to comma seperated values 

            setOpenDeliveryChallanDialog(true);
            // let res = await dataService('post', `OrderFlowStartFinish/${Order?.OrderId || 0}/${flowStepModel?.FlowStepId || 0}`, reqData);
            // setShowSubmitLoading(false);
            // if (res && (res.status === 200 || res.status === 304)) {
            //     setMessageDialoge({ show: true, type: 'success', message: flowStepModel?.FlowStepId ? 'Order updated successfully!' : 'Order registered successfully!' });
            //     setIsReload(!isReload)
            //     handleClose();

            // } else {
            //     //setOpen(res.message);
            //     setMessageDialoge({ show: true, type: 'error', message: res?.message || 'Error occured please try again later' });
            // }
        }

    }


    const checkValidations = () => {

        let validateErrors = {
            Quantity: { message: flowStepModel.Quantity === "" ? checkEmptyValidation(flowStepModel.Quantity, 'Quantity') : (flowStepModel.Quantity > flowStepModel.OriginalInQuantity ? "Quantity can not be greater then In Quantity" : ""), touched: true },
        }
        if (validateErrors.Quantity.message !== '') {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setFlowStepValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }


    return (
        <div>
            <Dialog className="" onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" disableBackdropClick={true} open={open}>
                <div className={`bg-red-800 border-red-800 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                    <DialogTitle className="pb-1 w-full" >
                        <div className="sm:grid sm:grid-cols-2 items-center">
                            <div className="text-gray-100">
                                <span className="text-2xl">Finish & Generate Challan</span><br />
                            </div>
                        </div>
                    </DialogTitle>
                    <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
                </div>

                <DialogContent className={`border-red-800 border-2`}>

                    <div className="overflow-hidden">
                        <div>
                            <dl>
                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />

                                </Grid>


                                <Grid item xs={12} lg={12}>

                                    <TableContainer component={Paper}>
                                        <Table className="min-w-full mb-0">
                                            {/* <caption>A basic table example with a caption</caption> */}
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order #</TableCell>
                                                    <TableCell align="left">Client</TableCell>
                                                    <TableCell align="right">Quantity In</TableCell>
                                                    <TableCell align="right">Quantity Out</TableCell>
                                                    <TableCell align="left">File Name</TableCell>
                                                    <TableCell align="left">File Size</TableCell>
                                                    <TableCell align="left">Delivery Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectedGCOrdersList.map((row) => {
                                                    row.FlowStepQuantityFinish = row.FlowStepQuantityFinish ? row.FlowStepQuantityFinish : row.FlowStepQuantityIn;
                                                    return (
                                                        <TableRow key={row.OrderNumber}>
                                                            <TableCell component="th" scope="row">{row.OrderNumber}</TableCell>
                                                            <TableCell align="left">{row.Client}</TableCell>
                                                            <TableCell align="right">{row.FlowStepQuantityIn}</TableCell>
                                                            <TableCell align="right">
                                                                <TextField
                                                                    inputProps={{ className: "py-0 h-auto w-24" }}
                                                                    variant="filled"
                                                                    name="QuantityFinish"
                                                                    type="number"
                                                                    width="50%"
                                                                    autoComplete="off"
                                                                    value={row.FlowStepQuantityFinish}
                                                                    size="small"
                                                                    onChange={e => handleQuantityChange(e, row)}
                                                                // error={flowStepValidateModel.Quantity.message !== '' && flowStepValidateModel.Quantity.touched}
                                                                // helperText={flowStepValidateModel.Quantity.message !== '' && flowStepValidateModel.Quantity.touched ? flowStepValidateModel.Quantity.message : ''}
                                                                >
                                                                </TextField>
                                                            </TableCell>
                                                            <TableCell align="left">{row.FileName}</TableCell>
                                                            <TableCell align="left">{row.FileSize}</TableCell>
                                                            <TableCell align="left">
                                                                {moment(row.DeliveryDate).format('D-MM-YYYY')}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>



                                {(machineList?.length > 0 || operatorList?.length > 0) &&
                                    <Grid item xs={12} lg={12}>
                                        <div className="border-gray-100 border-2 shadow sm:rounded-lg sm:grid sm:grid-cols-2 mt-4">
                                            <div className="border-gray-100 border-r-4">
                                                <div className="px-4 py-2 sm:px-6 bg-gray-100">
                                                    <h3 className="text-base  text-center font-bold leading-6 text-gray-900">Machine</h3>
                                                </div>
                                                <Grid item xs={12} lg={6}>
                                                    <FormGroup>
                                                        {machineList.map((machineData) => (
                                                            <FormControlLabel
                                                                key={machineData?.MachineId}
                                                                value="end"
                                                                control={<Checkbox
                                                                    color="primary"
                                                                    className="py-1"
                                                                    checked={flowStepModel?.MachineIds.includes(machineData?.MachineId)}
                                                                    value={machineData?.MachineId}
                                                                    name="MachineIds"
                                                                    onChange={handleCheckBoxes} />}
                                                                label={machineData?.MachineName}
                                                                labelPlacement="end" />
                                                        ))
                                                        }
                                                    </FormGroup>

                                                </Grid>
                                            </div>

                                            <div>
                                                <div className="px-4 py-2 sm:px-6 bg-gray-100">
                                                    <h3 className="text-base text-center font-bold leading-6 text-gray-900">Operator</h3>
                                                </div>
                                                <Grid item xs={12} lg={6}>
                                                    <FormGroup>
                                                        {operatorList.map((operatorData) => (
                                                            <FormControlLabel
                                                                key={operatorData?.OperatorId}
                                                                value={operatorData?.OperatorId}
                                                                className="text-sm "
                                                                name="OperatorIds"
                                                                control={<Checkbox
                                                                    color="primary"
                                                                    className="py-1"
                                                                    checked={flowStepModel?.OperatorIds.includes(operatorData?.OperatorId)}
                                                                    value={operatorData?.OperatorId}
                                                                    name="OperatorIds"
                                                                    onChange={handleCheckBoxes} />}
                                                                label={operatorData?.OperatorName}
                                                                labelPlacement="end" />
                                                        ))
                                                        }
                                                    </FormGroup>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                }
                                <Grid item xs={12} lg={12}>
                                    <div className="py-3 sm:grid sm:grid-cols-2 sm:gap-6 items-start">
                                        <div>
                                            <FormGroup className="mb-0">
                                                <FormLabel>Transport Detail</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="TransportDetail"
                                                    component={Box}
                                                    type="number"
                                                    width="100%"
                                                    autoComplete="off"
                                                    multiline
                                                    rows={3}
                                                    value={flowStepModel.TransportDetail || ''}
                                                    onChange={e => handleChange(e, null, true)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <FormGroup className="mb-0">
                                                <FormLabel>Remarks</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="Remarks"
                                                    component={Box}
                                                    type="number"
                                                    width="100%"
                                                    autoComplete="off"
                                                    multiline
                                                    rows={3}
                                                    value={flowStepModel.Remarks || ''}
                                                    onChange={e => handleChange(e, null, true)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-6 items-start">
                                        <div>
                                            <FormGroup className="mb-0">
                                                <FormLabel>D/C No.</FormLabel>
                                                <TextField
                                                    disabled
                                                    variant="filled"
                                                    name="DeliveryChallanNumber"
                                                    component={Box}
                                                    width="100%"
                                                    autoComplete="off"
                                                    value={flowStepModel?.DCNo}
                                                    onChange={e => handleChange(e, null, true)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <FormGroup className="mb-0">
                                                <FormLabel>Bill No.</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="BillNo"
                                                    component={Box}
                                                    width="100%"
                                                    autoComplete="off"
                                                    value={flowStepModel?.BillNo}
                                                    onChange={e => handleChange(e, null, true)}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <FormGroup>
                                                <FormLabel>Date</FormLabel>

                                                <div className="bg-white custom-datepicker">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            name="ChallanDate"
                                                            format="dd/MM/yyyy"
                                                            value={flowStepModel?.ChallanDate ? new Date(flowStepModel?.ChallanDate) : null}
                                                            onChange={e => handleSelectChange('ChallanDate', e)}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="py-3 sm:grid sm:grid-cols-2 sm:gap-6 items-center">
                                        <div className="sm:col-span-2">
                                            <Box
                                                justifyContent="center"
                                                display="flex"
                                                flexWrap="wrap"
                                            >

                                                <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitOrder} label="Generate Challan" />

                                            </Box>

                                        </div>
                                    </div>
                                </Grid>
                            </dl>
                        </div>
                    </div>

                </DialogContent>
            </Dialog>
            {openDeliveryChallanDialog &&
                <DeilveryChallanDialog open={openDeliveryChallanDialog} selectedGCOrdersList={selectedGCOrdersList} onClose={setOpenDeliveryChallanDialog} DCDetail={flowStepModel} onGCDialogClose={handleGenerateAndClose} />
            }
        </div>
    );
};
export default OrderFlowGCDialog;