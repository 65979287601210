import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Info } from '@mui/icons-material';
import moment from 'moment';
import { dataService } from 'services/data-service';

const ApproxDeliveryDateIcon = ({ data, onClick }) => {
  const [date, setDate] = useState(null)
  const [selectedOrderId, setSelectedOrderId] = useState(null)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (row) => {
    getApproxyDeliveryDate(row?.OrderId)
  };

  const getApproxyDeliveryDate = async (orderId) => {
    if (orderId !== selectedOrderId) {
      let res = await dataService('get', `ApproxDeliveryDate/${orderId}`);
      setSelectedOrderId(orderId)
      setDate(res?.data?.[0]?.ApproxDeliveryDate)
    }

  }

  return (
    <Box className="flex flex-row items-center gap-1 relative" onClick={onClick}>
      <Typography sx={{fontSize : isMobile ? "10px !important" : "12px !important"}}>{data?.OrderNumber}</Typography>
      <Tooltip open={Number(data?.OrderId) === Number(selectedOrderId)}
        onClose={() => setSelectedOrderId(null)} title={`Approx Date: ${moment(date).format("D-MM-YYYY")}`} enterTouchDelay={0}>
        < IconButton onClick={(e) => {
          e.stopPropagation()
          handleClick(data)
          }} onMouseLeave={() => { setDate(""); setSelectedOrderId(null) }}>
          <Info className="w-3.5 h-3.5" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ApproxDeliveryDateIcon;