import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import { checkEmptyValidation } from 'services/validation-service';
import { dataService } from 'services/data-service';
import MessageBar from "../MessageBar/MessageBar";
import { CustomButton } from "components/Common/CustomButton";
import { currentUser } from 'services/user-service';

export default function CustomerChangePasswordDialog(props) {
    const { title, open, setOpen, userType } = props;
    // Usef defined states
    const passwordModelInitialState = {
        CurrentPassword: '',
        Password: '',
        ConfirmPassword: '',
    }
    const [passwordModel, setPasswordModel] = useState(passwordModelInitialState);

    // Usef defined states
    const validateModelInitialState = {
        CurrentPassword: { message: '', touched: false },
        Password: { message: '', touched: false },
        ConfirmPassword: { message: '', touched: false },
        IsValidationError: false
    }
    const [passwordValidateModel, setPasswordValidateModel] = useState(validateModelInitialState);

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    const handleClose = () => {
        setMessageDialoge({ show: false, type: '', message: '' })
        setPasswordModel(passwordModelInitialState);
        setPasswordValidateModel(validateModelInitialState);
        setOpen(false);
    };

    const handleChange = (id, value) => {

        switch (id) {

            case 'CurrentPassword':
                setPasswordModel({ ...passwordModel, CurrentPassword: value });
                setPasswordValidateModel({ ...passwordValidateModel, CurrentPassword: { message: checkEmptyValidation(value, 'Current Password'), touched: true } });
                break;
            case 'Password':
                setPasswordModel({ ...passwordModel, Password: value });
                setPasswordValidateModel({ ...passwordValidateModel, Password: { message: checkEmptyValidation(value, 'Password'), touched: true } });
                break;
            case 'ConfirmPassword':
                setPasswordModel({ ...passwordModel, ConfirmPassword: value });
                setPasswordValidateModel({ ...passwordValidateModel, ConfirmPassword: { message: checkEmptyValidation(value, 'Confirm Password'), touched: true } });
                break;
        }
    }

    const handleSubmitChangePassword = async () => {
        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let res = await dataService('post', `CustomerChanagePassword/${currentUser()?.CustomerId}`, passwordModel);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: 'Password changed successfully!' });
                setPasswordModel(passwordModelInitialState);
                setPasswordValidateModel(validateModelInitialState);
            } else {
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            CurrentPassword: { message: userType === 'loggedin' ? checkEmptyValidation(passwordModel.CurrentPassword, 'Current Password') : '', touched: userType === 'loggedin' ? true : false },
            Password: { message: checkEmptyValidation(passwordModel.Password, 'Password'), touched: true },
            ConfirmPassword: { message: checkEmptyValidation(passwordModel.ConfirmPassword, 'Confirm Password'), touched: true }
        }
        if (
            validateErrors.CurrentPassword.message !== '' ||
            validateErrors.Password.message !== '' ||
            validateErrors.ConfirmPassword.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setPasswordValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"> <span className="text-2xl">{title}</span></DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                    </Grid>
                    {userType === 'loggedin' &&
                        <Grid item xs={12} lg={12}>
                            <FormGroup>
                                <FormLabel>Current Password</FormLabel>
                                <TextField
                                    variant="filled"
                                    component={Box}
                                    width="100%"
                                    type="password"
                                    marginBottom="1rem!important"
                                    autoComplete="off"
                                    value={passwordModel.CurrentPassword}
                                    onChange={e => handleChange('CurrentPassword', e.target.value)}
                                    error={passwordValidateModel.CurrentPassword.message !== '' && passwordValidateModel.CurrentPassword.touched}
                                    helperText={passwordValidateModel.CurrentPassword.message !== '' && passwordValidateModel.CurrentPassword.touched ? passwordValidateModel.CurrentPassword.message : ''}
                                >
                                    <Box
                                        paddingLeft="0.75rem"
                                        paddingRight="0.75rem"
                                        component={FilledInput}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </TextField>
                            </FormGroup>
                        </Grid>
                    }
                    <Grid item xs={12} lg={12}>
                        <FormGroup>
                            <FormLabel>Password</FormLabel>
                            <TextField
                                variant="filled"
                                component={Box}
                                width="100%"
                                type="password"
                                marginBottom="1rem!important"
                                autoComplete="off"
                                value={passwordModel.Password}
                                onChange={e => handleChange('Password', e.target.value)}
                                error={passwordValidateModel.Password.message !== '' && passwordValidateModel.Password.touched}
                                helperText={passwordValidateModel.Password.message !== '' && passwordValidateModel.Password.touched ? passwordValidateModel.Password.message : ''}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FormGroup>
                            <FormLabel>Confirm Password</FormLabel>
                            <TextField
                                variant="filled"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                                type="password"
                                autoComplete="off"
                                value={passwordModel.ConfirmPassword}
                                onChange={e => handleChange('ConfirmPassword', e.target.value)}
                                error={passwordValidateModel.ConfirmPassword.message !== '' && passwordValidateModel.ConfirmPassword.touched}
                                helperText={passwordValidateModel.ConfirmPassword.message !== '' && passwordValidateModel.ConfirmPassword.touched ? passwordValidateModel.ConfirmPassword.message : ''}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Save" loading={showSubmitLoading} onClick={handleSubmitChangePassword} />
            </DialogActions>
        </Dialog>
    );
}
