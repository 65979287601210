import React from "react";
import { Checkbox, DialogContent, Grid, InputLabel, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';

function ViewPreOrderDialog({ onClose, open }) {

    const data = open?.data

    const handleClose = () => {
        onClose();
    };

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iMDScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const commonInputFieldDisabled = {
        '& input:disabled': {
            backgroundColor: 'transparent',
            color: 'black',
            WebkitTextFillColor: (theme) => (theme.palette.mode === 'dark' ? 'white' : 'black'),
            border: "1px solid #00000033"
        }
    };
    const commonInputFilledField = {
        '& .MuiFilledInput-input': {
            fontSize: isSmallScreen ? "10px" : "12px",
            height: 35,
        },
    };


    const ReadOnlyInput = ({ label, value, xs = 6, sm = 4, lg = 4,
        inputProps = {}, ...rest }) => {
        return (
            <Grid item xs={xs} sm={sm} lg={lg} px={1} {...rest}>
                <Stack spacing={0.5}>
                    <InputLabel className="text-xs sm:text-sm">{label}</InputLabel>
                    <TextField
                        variant="filled"
                        value={value}
                        inputProps={{ readOnly: true, ...inputProps }}
                        disabled
                        sx={{
                            ...commonInputFieldDisabled,
                            ...commonInputFilledField,
                        }}
                        size="small"
                        autoComplete="off"
                        width="100%"
                        marginBottom="1rem!important"
                    />
                </Stack>
            </Grid>
        );
    };

    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" sx={{margin : -2}} open={open?.isVisible}>
            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className='flex flex-row items-center gap-2'>
                    <span className="text-base sm:text-xl">
                        {"Pre Order View"}
                    </span>
                    <button
                        className={`${data?.Status === "Pending" ? "bg-[#11CDEF]" : data?.Status === "Approved" ? "bg-green-500" : "bg-red-500"}  text-white inline-flex items-center px-2 sm:px-4 py-1.5 rounded-md text-xs sm:text-sm font-medium`}
                    >
                        {data?.Status}
                    </button>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
            </div>

            <DialogContent dividers>
                <div className="border-2 border-gray-300 rounded-md mb-6" >
                    <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
                        <h3 className="text-lg text-center font-bold leading-6 text-gray-900">General Information</h3>
                    </div>
                    <div className="px-6 py-4">
                        <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                            <ReadOnlyInput label="Customer" value={data?.CustomerName} sm={6} lg={6} xs={12}/>
                            <ReadOnlyInput label="Collection" value={data?.Logo} sm={6} lg={6} xs={12}/>
                            <ReadOnlyInput label="Logo" value={data?.CollectionName} xs={12} lg={4} />
                            <ReadOnlyInput label="Quantity" value={data?.Quantity} xs={12} lg={4}/>
                            <ReadOnlyInput label="Size" value={data?.Size} />
                            <ReadOnlyInput label="Buckram" sm={2.5} lg={2.5} value={data?.Buckram} />
                            <ReadOnlyInput label="SKU" value={data?.SKU} sm={2.5} lg={2.5} />
                            <ReadOnlyInput label="MTR" value={data?.MTR} sm={2.5} lg={2.5}/>
                            <ReadOnlyInput label="Created By" lg={4.5} sm={4.5} xs={12} value={data?.CreatedByName || "CUSTOMER"} />
                        </Grid>
                    </div>
                </div>

                <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Story</h3>
                    </div>
                    <div className="">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:gap-1 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                    <span className="">{data?.Story}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Extra</h3>
                    </div>
                    <div className="">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500">
                                    <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={data?.Interlock === 1 ? true : false} name="Interlock" color="primary" />Interlock</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={data?.ZigZag === 1 ? true : false} name="ZigZag" color="primary" />ZigZag</span>
                                </dt>

                                <dt className="text-sm font-medium text-gray-500">
                                    <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={data?.Foil === 1 ? true : false} name="Foil" color="primary" />Foil</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={data?.Screen === 1 ? true : false} name="Screen" color="primary" />Screen</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={data?.Print === 1 ? true : false} name="Print" color="primary" />Print</span>
                                </dt>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">E-Catalogue</h3>
                    </div>
                    <div className="">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                    <span className=""><Checkbox checked={data?.ECatalogue ? true : false} name="ECatalogue" color="primary" />E-Catalogue</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Technical Details</h3>
                    </div>
                    <div className="">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{data?.TechnicalDetails}</dd>
                            </div>
                        </dl>
                    </div>
                </div>


            </DialogContent>
        </Dialog>
    );
};
export default ViewPreOrderDialog;