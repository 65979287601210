/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Chip, Grid } from '@mui/material';
import { BarChartOutlined } from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { currencyFormat } from 'services/data-service';

export function DailySalesReportFilterHeader({
  data,
  selectedYear,
  selectedMonth,
  setIsChartOpen,
  isChartVisible,
  title,
  isHideTotal = false,
  isNotAmount = true
}) {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  useEffect(() => {
    fetchData()
  }, [data]);

  const fetchData = async() => {
    if (data && data?.length > 0) {
      let totalAmountVal = 0;
      let totalQuantityVal = 0;
      data.map((x) => {
        totalAmountVal += x?.Amount || x?.TotalPrice;
        totalQuantityVal += x?.Quantity;
      });

      setTotalAmount(currencyFormat(totalAmountVal));
      setTotalQuantity(totalQuantityVal);
      //  data.reduce(function(a, b){
      //     return a?.Amount + b?.Amount;
      //   }
      // );
    }
  }

  return (
    <Grid
      container
      component={Box}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Grid item xs={12} sm={12}>
        <div className="sm:grid sm:grid-cols-2 items-center">
          <div className="text-gray-900 flex flex-wrap justify-start">
            <div className="mr-10">
              {title && <div className='mb-4 font-bold'><p className='text-black font-bold text-lg'>{title}</p></div>}
              {isChartVisible &&
                <Chip
                  onClick={() => setIsChartOpen(true)}
                  className="!pl-2 font-bold !bg-blue-500 !mb-4"
                  color="primary"
                  icon={<BarChartOutlined />}
                  label={`Monthly Charts `}
                />}
              <span className="text-sm flex flex-wrap justify-start items-center sm:grid sm:grid-cols-2 mb-2">
                <strong>Year: </strong>
                <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded mr-3 ml-1">
                  {' '}
                  {selectedYear}{' '}
                </span>
              </span>
              <span className="text-sm flex flex-wrap justify-start items-center sm:grid sm:grid-cols-2">
                <strong>Month: </strong>
                <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded ml-1">
                  {moment()
                    .month(selectedMonth - 1)
                    .format('MMMM')}
                </span>
              </span>

              {/* <span className="text-xs">  [In Quantity: {flowStepModel.Quantity}]</span> */}
            </div>
          </div>
          {!isHideTotal &&
            <div className="text-gray-900 flex flex-wrap justify-end">
              <div className="mr-10">
                <span className="text-sm flex flex-wrap justify-start items-center sm:grid sm:grid-cols-2 mb-2">
                  <strong>Total Quantity: </strong>
                  <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded mr-3 ml-1">
                    {' '}
                    {totalQuantity}{' '}
                  </span>
                </span>
                {!isNotAmount ?
                <span className="text-sm flex flex-wrap justify-start items-center sm:grid sm:grid-cols-2">
                  <strong>Total Amount: </strong>
                  <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded ml-1">
                    ₹ {totalAmount}
                  </span>
                </span> : null}
                {/* <span className="text-xs">  [In Quantity: {flowStepModel.Quantity}]</span> */}
              </div>
            </div>
          }
        </div>
      </Grid>
    </Grid>
  );
}
