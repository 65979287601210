/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Chip, Grid, MenuItem, Select } from '@mui/material';
import { BarChartOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { currencyFormat } from 'services/data-service';

export function SalesReportFilterHeader({
  data,
  selectedYear,
  setSelectedYear,
  setIsChartOpen,
  isChartVisible,
  title,
  isHideTotal = false,
  isNotAmount = true
}) {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  useEffect(() => {
    fetchData()
  }, [data]);

  const fetchData = async() => { 
    if (data && data?.length > 0) {
      let totalAmountVal = 0;
      let totalQuantityVal = 0;
      data.map((x) => {
        totalAmountVal += x?.Amount || x?.TotalPrice;
        totalQuantityVal += x?.Quantity;
      });

      setTotalAmount(currencyFormat(totalAmountVal));
      setTotalQuantity(totalQuantityVal);
      //  data.reduce(function(a, b){
      //     return a?.Amount + b?.Amount;
      //   }
      // );
    }
  }

  return (
    <Grid
      container
      component={Box}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Grid item xs={12} sm={12}>
        <div className="sm:grid sm:grid-cols-2 items-center">
          <div>
            {title && <div className='mb-4 font-bold'><p className='text-black font-bold text-lg'>{title}</p></div>}
            {isChartVisible &&
              <Chip
                onClick={() => setIsChartOpen(true)}
                className="!pl-2 font-bold !bg-blue-500 !mb-4"
                color="primary"
                icon={<BarChartOutlined />}
                label={`Yearly Charts`}
              />}
            <div className="text-lg text-gray-500">
              <span className="pr-5">Year</span>
              <Select
                value={selectedYear}
                name="selectedYear"
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {
                  Array.from({ length: new Date().getFullYear() - 2021 + 1 }, (_, index) => new Date().getFullYear() - index).map((yr, indx) => (
                    <MenuItem key={indx} value={yr}>{yr}</MenuItem>
                  ))
                }

              </Select>
            </div>
          </div>
          {!isHideTotal &&
            <div className="text-gray-900 flex flex-wrap justify-end">
              <div className="mr-10">
                <span className="text-sm flex flex-wrap justify-start items-center sm:grid sm:grid-cols-2 mb-2">
                  <strong>Total Quantity: </strong>
                  <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded mr-3 ml-1">
                    {' '}
                    {totalQuantity}{' '}
                  </span>
                </span>
                {!isNotAmount ?
                <span className="text-sm flex flex-wrap justify-start items-center sm:grid sm:grid-cols-2">
                  <strong>Total Amount: </strong>
                  <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded ml-1">
                    ₹ {totalAmount}
                  </span>
                </span> : null}
                {/* <span className="text-xs">  [In Quantity: {flowStepModel.Quantity}]</span> */}
              </div>
            </div>
          }
        </div>
      </Grid>
    </Grid>
  );
}
