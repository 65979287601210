import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import DeilveryChallanDialog from "components/dialoges/DeliveryChallanDialog";
import { GoodsEntryIcon, OrderFlowIcon } from "components/Icons/CustomIcons";
import ViewOrdersGEDialog from "components/dialoges/ViewOrdersGEDialog";
import OrderFlowListDialog from "components/dialoges/OrderFlowListDialog";
import MapOrderDialog from "components/dialoges/MapOrderDialog";
import PrintOrderForm from "components/dialoges/PrintOrder/PrintOrderForm";
import ViewOrderDialog from "components/dialoges/ViewOrderDialog";
import {Print as PrintIcon, Visibility as VisibilityIcon, FileCopy, Edit as EditIcon} from "@mui/icons-material"

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const useStyles = makeStyles(componentStyles);

const CompletedOrders = () => {
  const classes = useStyles();
  const history = useHistory();
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('UpdatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);

  const [deliveryChallanId, setDeliveryChallanId] = useState(null);

  const [open, setOpen] = useState(false);

  const [printOrderFormData, setPrintOrderFormData] = useState(null);
  const [openPrintOrderForm, setOpenPrintOrderForm] = useState(null);

  const [goodsDetailDialogData, setGoodsDetailDialogData] = useState(false);
  const [openGoodsDetailDialog, setOpenGoodsDetailDialog] = useState(false);


  const [openShowFlowListView, setOpenShowFlowListView] = useState(null);
  const [flowStepListOrder, setFlowStepListOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  const [openMaporder, setOpenMaporder] = useState(false);
  const [mappingData, setMappingData] = useState(null);
  const [isReload, setIsReload] = useState(false);


  const [openViewOrder, setOpenViewOrder] = useState(false);
  const [viewOrderId, setViewOrderId] = useState(null);

  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const columns = [
    { id: 'OrderNumber', numeric: false, disablePadding: true, label: 'Order Number', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    { id: 'FileName', numeric: false, disablePadding: false, label: 'File Name', dataType: 'string' },
    { id: 'FileSize', numeric: false, disablePadding: false, label: 'File Size', dataType: 'string' },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'Client', numeric: false, disablePadding: false, label: 'Client', dataType: 'string' },
    { id: 'QuantityOut', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
    { id: 'DCNo', numeric: false, disablePadding: false, label: 'DCNo', dataType: 'string' },
    { id: 'BillNo', numeric: false, disablePadding: false, label: 'BillNo', dataType: 'string' },
    { id: 'GoodsEntryCreatedAt', numeric: false, disablePadding: false, label: 'GE Date', dataType: 'date' },
    { id: 'OrderCompletedDate', numeric: false, disablePadding: false, label: 'Delivery Date', dataType: 'date' },
    { id: 'HoldSince', numeric: false, disablePadding: false, label: 'Completed Time', dataType: 'string' },
    { id: 'HoldReason', numeric: false, disablePadding: false, label: 'Hold Reason', dataType: 'string' },
  ];

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async() => {
    if (localStorage.getItem('products')) {
      let productsAr = JSON.parse(localStorage.getItem('products'));
      setProductList(productsAr);
    }
    else {
      let res = await getProductList();
      setProductList(res?.data);
    }
  }

  useEffect(() => {
    fetchDataOrderList()
  }, [page, searchText, order, orderBy, offset, selectedProductId])

  const fetchDataOrderList = async() => {
    setIsLoading(true);
    let res = await dataService('get', 'CompletedOrderList', `productId=${selectedProductId || 0}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}&orderStatus=Completed`);
    setCompletedOrderList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }
  const getProductList = async () => {
    let res = await dataService('get', 'ProductList', null);
    if (res?.data) {
      localStorage.setItem('products', JSON.stringify(res?.data));
      return res;
    }
  }

  const viewChallanAction = (row) => {
    setDeliveryChallanId(row?.DeliveryChallanId)
    setOpen(true);
  };

  const viewGoodsDetailAction = (row) => {
    setGoodsDetailDialogData(row)
    setOpenGoodsDetailDialog(true);
  };

  const FlowSetpListAction = (OrderDetail) => {
    setOpenShowFlowListView(true);
    setFlowStepListOrder(OrderDetail);
  };

  const makeCopyAction = (OrderDetail) => {
    history.push(`/admin/order-copy/${OrderDetail.OrderId}`)
  }

  const handleMapOrderDialog = (row) => {
    setOpenMaporder(true);
    setMappingData(row);
  };

  const handlePrintOrderFormDialog = (row) => {
    setOpenPrintOrderForm(true);
    setPrintOrderFormData(row.OrderId);
  };

  const viewAction = (OrderDetail) => {
    setOpenViewOrder(true);
    setViewOrderId(OrderDetail.OrderId);
  };

  let actionList = [
    { label: 'View Order', icon: VisibilityIcon, iconColor: 'purple-500', action: viewAction },
    { label: 'Print Challan', icon: PrintIcon, iconColor: 'green-500', action: viewChallanAction },
    { label: 'Goods Detail', icon: GoodsEntryIcon, iconColor: 'white', action: viewGoodsDetailAction },
    { label: 'FlowSteps', icon: OrderFlowIcon, iconColor: 'yellow-500', action: FlowSetpListAction },
    { label: 'Make Copy', icon: FileCopy, iconColor: 'pink-500', action: makeCopyAction },
    { label: 'Map Order', icon: EditIcon, iconColor: 'blue-500', action: handleMapOrderDialog },
    { label: 'Print Order Form', icon: PrintIcon, iconColor: 'blue-500', action: handlePrintOrderFormDialog },
  ]


  const handleSelect = async (ProductId) => {
    setSelectedProductId(parseInt(ProductId));
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >

        <RadioGroup row aria-label="position" className="mb-0 text-black mr-auto ml-0" onChange={(e) => handleSelect(e?.target?.value)} name="position" value={selectedProductId}>
          <FormControlLabel value={0} className="z-10" control={<Radio color="default" />} label="All" />
          {productList?.map((product, index) => {
            return <FormControlLabel className="z-10" key={index} value={product?.ProductId} control={<Radio color="default" />} label={product?.Name} />
          })}
        </RadioGroup>

        <CommonTable
          title="Completed Orders"
          columns={columns}
          rows={completedOrderList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          setOffset={setOffset}
          actions={actionList}
          offset={offset}
          loading={isLoading}
        />
      </Container>

      {open &&
        <DeilveryChallanDialog open={open} onClose={setOpen} deliveryChallanId={deliveryChallanId} viewOnly={true} />
      }

      {openGoodsDetailDialog &&
        <ViewOrdersGEDialog onClose={setOpenGoodsDetailDialog} open={openGoodsDetailDialog} OrderDetail={goodsDetailDialogData} />
      }

      {flowStepListOrder && openShowFlowListView &&
        <OrderFlowListDialog open={openShowFlowListView} viewDialogData={{ title: "Test" }} onClose={setOpenShowFlowListView} OrderDetail={flowStepListOrder} />
      }

      {mappingData && openMaporder &&
        <MapOrderDialog from={'orders'} mapTo={'GoodsEntry'} open={openMaporder} onClose={setOpenMaporder} MappingData={mappingData} setIsReload={setIsReload} isReload={isReload} />
      }
      {printOrderFormData && openPrintOrderForm &&
        <PrintOrderForm open={openPrintOrderForm} onClose={setOpenPrintOrderForm} OrderId={printOrderFormData} />
      }
      {viewOrderId && openViewOrder &&
        <ViewOrderDialog open={openViewOrder} onClose={setOpenViewOrder} OrderId={viewOrderId} setIsReload={setIsReload} isReload={isReload}/>
      }
    </>
  );
};

export default CompletedOrders;