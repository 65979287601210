import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import CommonTable from 'components/Tables/CommonTable';
import { dataService } from 'services/data-service';
import { useHistory } from 'react-router-dom';
import { currentUser } from 'services/user-service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { updatePreviousLocations } from 'generic/store';
import { useDispatch, useSelector } from 'react-redux';
import ViewDataDialog from 'components/dialoges/ViewDataDialog';

const useStyles = makeStyles(componentStyles);

const Customers = () => {
  const previousLocationState = useSelector(
    (state) => state?.previousLocationsStore?.previous_locations
  );
  const dispatch = useDispatch(); // to update store values

  const classes = useStyles();
  const [customerList, setCustomerList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [searchText, setSearchText] = useState(
    previousLocationState?.searchText || ''
  );
  const [page, setPage] = useState(previousLocationState?.page || 1);
  const [orderBy, setOrderByData] = useState(
    previousLocationState?.orderBy || 'CreatedAt'
  );
  const [order, setOrderData] = useState(
    previousLocationState?.order || 'desc'
  );
  const [offset, setOffset] = useState(previousLocationState?.offset || 10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewDialogData, setViewDialogData] = useState({
    labels: [],
    data: {},
  });
  const columns = [
    {
      id: 'Name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      dataType: 'string',
    },
    {
      id: 'Type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      dataType: 'string',
    },
    {
      id: 'BillingContactPerson',
      numeric: false,
      disablePadding: false,
      label: 'Billing Name',
      dataType: 'string',
    },
    {
      id: 'BillingEmail',
      numeric: false,
      disablePadding: false,
      label: 'Billing Email',
      dataType: 'string',
    },
    {
      id: 'BillingContactPhoneNumber',
      numeric: false,
      disablePadding: false,
      label: 'Billing PhoneNumber',
      dataType: 'string',
    },
    {
      id: 'BillingFullAddress',
      numeric: false,
      disablePadding: false,
      label: 'Billing Address',
      dataType: 'string',
    },
    {
      id: 'CreatedAt',
      numeric: false,
      disablePadding: false,
      label: 'Created At',
      dataType: 'date',
    },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload]);

  const fetchData = async () => {
    dispatch(updatePreviousLocations(null));
    setIsLoading(true);
    let res = await dataService(
      'get',
      'CustomerList',
      `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`
    );
    setCustomerList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const handleEdit = (row) => {
    dispatch(
      updatePreviousLocations({
        view: 'customers',
        searchText: searchText,
        page: page,
        order: order,
        orderBy: orderBy,
        offset: offset,
      })
    );

    history.push(`update-customer/${row.CustomerId}`);
  };

  const viewAction = (row) => {
    //Status, CreatedAt, UpdatedBy, BillingAddress, BillingCity, BillingState, BillingCountryId, BillingCountry, BillingZipCode, BillingEmail, BillingContactPhoneNumber, ShippingAddress, ShippingCity, ShippingState, ShippingCountry, ShippingCountryId, ShippingZipCode, ShippingContactPerson, ShippingEmail, ShippingContactPhoneNumber
    const labels = [
      { Name: 'Name', DisplayName: 'Name' },
      { Name: 'ContactName', DisplayName: 'Contact Name' },
      { Name: 'PhoneNumber', DisplayName: 'Phone Number' },
      { Name: 'Type', DisplayName: 'Type' },
      { Name: 'Client', DisplayName: 'Client' },
      { Name: 'GSTNumber', DisplayName: 'GST Number' },
      { Name: 'BillingContactPerson', DisplayName: 'Billing Name' },
      { Name: 'BillingEmail', DisplayName: 'Billing Email' },
      { Name: 'BillingContactPhoneNumber', DisplayName: 'Billing PhoneNumber' },
      { Name: 'BillingAddress', DisplayName: 'Billing Address' },
      { Name: 'BillingCity', DisplayName: 'Billing City' },
      { Name: 'BillingState', DisplayName: 'Billing State' },
      { Name: 'BillingZipCode', DisplayName: 'Billing ZipCode' },
      { Name: 'ShippingContactPerson', DisplayName: 'Shipping Name' },
      { Name: 'ShippingEmail', DisplayName: 'Shipping Email' },
      {
        Name: 'ShippingContactPhoneNumber',
        DisplayName: 'Shipping PhoneNumber',
      },
      { Name: 'ShippingAddress', DisplayName: 'Shipping Address' },
      { Name: 'ShippingCity', DisplayName: 'Shipping City' },
      { Name: 'ShippingState', DisplayName: 'Shipping State' },
      { Name: 'ShippingZipCode', DisplayName: 'Shipping ZipCode' },
      { Name: 'CreatedAt', DisplayName: 'Created At', DataType: 'date' },
    ];

    setOpen(true);
    setViewDialogData({
      labels: labels,
      data: row,
      title: 'Customer Information',
    });
    //history.push(`update-customer/${row.CustomerId}`)
  };

  const handleDelete = async (row) => {
    let res = await dataService(
      'post',
      `CustomerDelete/${row.CustomerId}`,
      null
    );
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  let actionList = [
    {
      label: 'View',
      icon: VisibilityIcon,
      iconColor: 'purple-500',
      action: viewAction,
    },
    {
      label: 'Edit',
      icon: EditIcon,
      iconColor: 'blue-500',
      action: handleEdit,
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      iconColor: 'red-500',
      action: handleDelete,
      isConfirmAction: true,
    },
  ];

  // remove edit action if not super admin
  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter((ac) => {
      return ac.label !== 'Edit';
    });
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          view="customer"
          title="Customer List"
          columns={columns}
          rows={customerList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          searchText={searchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          // editAction={handleEdit}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>
      <ViewDataDialog
        open={open}
        onClose={setOpen}
        viewDialogData={viewDialogData}
      />
    </>
  );
};

export default Customers;
