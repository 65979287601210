import React from 'react';
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export function CustomButton(props) {
    return (
        <Button className={`${props?.className}`}
            variant="contained" color={`${props?.color || 'primary'}`} size={`${props?.size || 'large'}`} onClick={props?.onClick} disabled={props?.loading}>
            {props?.label || 'Submit'}
            {props?.loading && <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />}
            {props?.icon && !props?.loading && <Box component={props?.icon} className={props?.iconClassName} />}
            
        </Button>
    );
};