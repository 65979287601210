import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ListItemText, ListItem, Button, Box } from '@mui/material';

// assets
import { Close } from '@mui/icons-material';
import getDropzoneData from 'components/Upload/getDropzoneData';
import { getFileIcon } from 'utils/helper';
import { renderTitleDetails } from 'utils/helper';

// ==============================|| MULTI UPLOAD - PREVIEW ||============================== //

export default function FilesPreview({ showList = false, files, onRemove, type }) {
  const theme = useTheme();
  const hasFile = files.length > 0;
  const layoutType = type;

  return (
    <>
      {files.map((file, index) => {
        const { key, name, size, preview, type } = getDropzoneData(file, index);
        if (showList) {
          return (
            <ListItem
              key={key}
              sx={{
                p: 0,
                m: 0.5,
                width: layoutType === 'STANDARD' ? 64 : 80,
                height: layoutType === 'STANDARD' ? 64 : 80,
                borderRadius: 1.25,
                position: 'relative',
                display: 'inline-flex',
                verticalAlign: 'text-top',
                border: `solid 1px ${theme.palette.divider}`,
                overflow: 'hidden'
              }}
            >
              {type?.includes('image') && <img alt="preview" src={preview} style={{ width: '100%' }} />}
              {!type?.includes('image') && getFileIcon("pdf")}

              {onRemove && (
                <Button
                  size="large"
                  color="error"
                  shape="rounded"
                  onClick={() => onRemove(file)}
                  sx={{
                    fontSize: '0.875rem',
                    bgcolor: 'background.paper',
                    p: 0,
                    width: 'auto',
                    height: 'auto',
                    top: 2,
                    right: 2,
                    position: 'absolute'
                  }}
                >
                  <Close />
                </Button>
              )}
            </ListItem>
          );
        }

        return (
          <Box
            sx={{
              width: "100%",
              height: "180px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              pl: 2
            }}
          >
            {getFileIcon("application/pdf")}
            <ListItemText
              sx={{ ml: 2 }}
              primary={typeof file === 'string' ? file : renderTitleDetails(name, 30, { fontWeight: 400, fontSize: "14px" })}
              secondary={typeof file === 'string' ? '' : size}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'caption' }}
            />

           
            {onRemove && (
              <Button size="large"
                color="error"
                shape="rounded"
                sx={{
                  fontSize: "0.875rem",
                  bgcolor: "background.paper",
                  p: 0.5,
                  width: "auto",
                  height: "auto",
                  top: 0,
                  right: 0,
                  position: "absolute",
                  zIndex: 100,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onRemove(file)
                }}>
                <Close style={{ fontSize: '1.15rem' }} />
              </Button>
            )}
          </Box>
        );
      })}
    </>
  );
}

FilesPreview.propTypes = {
  showList: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  type: PropTypes.string
};
