import PropTypes from 'prop-types';

// material-ui
import { Stack, CardMedia } from '@mui/material';

// assets
import UploadCover from 'assets/img/brand/upload.png';

// ==============================|| UPLOAD - PLACEHOLDER ||============================== //

export default function PlaceholderImage({ type, theme }) {
  return (
    <>
      {type !== 'STANDARD' && (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          padding={theme.spacing(3.25, 1)}
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1,  textAlign: { xs: 'center', md: 'left' } }}
        >
          <CardMedia component="img" image={UploadCover} sx={{ width: 150 }} />
        </Stack>
      )}
    </>
  );
}
PlaceholderImage.propTypes = {
  type: PropTypes.string
};
