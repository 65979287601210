import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { DialogActions, FormLabel, LinearProgress, Typography } from '@mui/material';
import { CustomButton } from "components/Common/CustomButton";
import { dataService } from 'services/data-service';


import { Grid, TextField, Box, FilledInput, FormGroup } from '@mui/material';
import ImageUpload from './ImageUpload';

function CreateCatalogueFileDialog({ onClose, open, selectedFile, afterAPICall }) {
    const [showSubmitLoading, setShowSubmitLoading] = useState(false)

    const [document, setDocument] = useState({
        SelectedFiles: open?.data?.FileName ? [{
            name: open?.data?.FileName,
            path: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FileName}`,
            type: open?.data?.Extention,
            preview: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FileName}`,
            size: open?.data?.Size,
            isUpdate: true
        }] : null,
        Title: open?.data?.Title || "",
        FeaturedImage: open?.data?.FeaturedImage ? [{
            name: open?.data?.FeaturedImage,
            path: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FeaturedImage}`,
            type: open?.data?.FeaturedImage?.split('.').pop(),
            preview: `${process.env.REACT_APP_AWS_S3_BUCKET_URL}${open?.data?.FilePath}${open?.data?.FeaturedImage}`,
        }] : null
    });
    const [progressValue, setProgressValue] = useState(null)


    const [errors, setErrors] = useState({});

    const handleClose = () => {
        onClose();
    };


    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }


    const handleSubmit = async () => {

        const errors = {};
        
        if ( !document.SelectedFiles) {
            errors['SelectedFiles'] = 'PDF is required';
        }
        if ( !document.FeaturedImage) {
            errors['FeaturedImage'] = 'Featured Image is required';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setShowSubmitLoading(true);

        try {
            const randomNumber = Math.floor(100 + Math.random() * 900);
            const timestamp = Date.now();
            
            let imageName = null;
            let imageExtension = null;
            let fileName = null;
            let fileExtension = null;

            if (document.SelectedFiles && !document?.SelectedFiles[0]?.isUpdate && document.SelectedFiles.length > 0) {
                fileExtension = document.SelectedFiles[0]?.name.split('.').pop();
                fileName = `catalogue_${randomNumber}_${timestamp}.${fileExtension}`;
            }
            if (document.FeaturedImage && document?.FeaturedImage[0]?.size && document.FeaturedImage.length > 0) {
                imageExtension = document.FeaturedImage[0]?.name.split('.').pop();
                imageName = `catalogue_fi_${randomNumber}.${imageExtension}`;
            }
            
            // Get pre-signed URLs
            const { uploadUrl, uploadImage } = await dataService('put', `GeneratePresignedURL/${selectedFile?.CatalogueId}`, {
                FileName: fileName,
                MimeType: fileExtension ? document.SelectedFiles[0]?.type : null,
                ImageName: imageName,
                ImageMimeType: imageExtension ? `image/${imageExtension}` : null,
            }).then(res => res?.data || {});
    
            const uploadTasks = [];
        
            if (uploadUrl && document.SelectedFiles && !document.SelectedFiles[0]?.isUpdate) {
                uploadTasks.push(
                    fetch(uploadUrl, {
                        method: 'PUT',
                        body: document.SelectedFiles[0],
                        headers: { 'Content-Type': document.SelectedFiles[0]?.type },
                    })
                );
            }
    
            if (uploadImage && document.FeaturedImage && document.FeaturedImage[0]?.size) {
                uploadTasks.push(
                    fetch(uploadImage, {
                        method: 'PUT',
                        body: document.FeaturedImage[0],
                        headers: { 'Content-Type': document.FeaturedImage[0]?.type },
                    })
                );
            }
    
    
            // Parallel upload
            await Promise.all(uploadTasks);
    
    
            const payload = {
                Title: document.Title,
                FilePath: `catalogue/cat-${selectedFile?.CatalogueId || 0}/`,
            };
    
            if (fileName) {
                payload.FileName = fileName;
                payload.MimeType = document.SelectedFiles[0]?.type;
                payload.Extention = fileExtension;
                payload.Size = `${(document.SelectedFiles[0]?.size / (1024 * 1024)).toFixed(2)} MB`;
            }
    
            if (imageName) {
                payload.FeaturedImage = imageName;
            }
    
    
            // Save metadata
            const formData = new FormData();
            Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    
            const saveResponse = await dataService(
                'post',
                `CatalogueFileSaveV2/${selectedFile?.CatalogueId}/${open?.data?.CatalogueFileId || 0}`,
                formData
            );
    
            if (saveResponse && (saveResponse.status === 200 || saveResponse.status === 304)) {
                onClose();
                afterAPICall();
                console.log('File uploaded successfully');
            }
        } catch (error) {
            console.error('Error during file upload process:', error);
        } finally {
            setShowSubmitLoading(false);
        }
    };

    const handleChange = (e, name) => {
        const newDocument = { ...document };
        if (name === 'title') {
            newDocument['Title'] = e.target.value;
        } else if (name === 'file') {
            newDocument['SelectedFiles'] = e.target.files;
            const newErrors = { ...errors };
            delete newErrors['SelectedFiles'];
            setErrors(newErrors);
        } else if (name === 'image') {
            newDocument['FeaturedImage'] = e.target.files;
            const newErrors = { ...errors };
            delete newErrors['FeaturedImage'];
            setErrors(newErrors);
        }
        setDocument(newDocument);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open.isVisible}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-3 pb-2 text-xl">
                    <div className="text-2xl">
                        Catalogue File
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-800 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                <div className="mt-5">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {progressValue && <LinearProgressWithLabel value={progressValue} />}
                        </Grid>
                        <Grid item xs={12} >
                            <FormGroup className='-mb-5'>
                                <div className="flex flex-wrap items-center mb-2">
                                    <FormLabel className="mr-3 mb-0">Title</FormLabel>
                                </div>
                                <TextField
                                    variant="filled"
                                    name="Title"
                                    component={Box}
                                    width="100%"
                                    placeholder='Title'
                                    marginBottom="1rem!important"
                                    value={document.Title}
                                    autoComplete="off"
                                    onChange={e => handleChange(e, 'title')}
                                >
                                    <Box
                                        paddingLeft="0.75rem"
                                        paddingRight="0.75rem"
                                        component={FilledInput}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </TextField>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup className='mb-0'>
                                <div className="flex flex-wrap items-center mb-2">
                                    <FormLabel className="mr-3 mb-0">PDF</FormLabel>
                                </div>
                                <ImageUpload
                                    setDocument={setDocument}
                                    document={document}
                                    name="SelectedFiles"
                                    submit={() => { }}
                                    accept={{ 'application/pdf': [] }}
                                    displayPreview={true}
                                    displayUploadButton={false}
                                    setErrors={setErrors}
                                    error={errors}
                                />
                                {errors['SelectedFiles'] && (
                                    <div className="text-red-500 text-xs mt-1">
                                        {errors['SelectedFiles']}
                                    </div>
                                )}
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup className='mb-0'>
                                <div className="flex flex-wrap items-center mb-2">
                                    <FormLabel className="mr-3 mb-0">Featured Image</FormLabel>
                                </div>
                                <ImageUpload
                                    setDocument={setDocument}
                                    document={document}
                                    name="FeaturedImage"
                                    submit={() => { }}
                                    accept={{
                                        'image/*': []
                                    }}
                                    displayPreview={false}
                                    displayUploadButton={false}
                                    setErrors={setErrors}
                                    error={errors}
                                />
                                {errors['FeaturedImage'] && (
                                    <div className="text-red-500 text-xs mt-1">
                                        {errors['FeaturedImage']}
                                    </div>
                                )}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className='border-t border-gray-200'>
                <CustomButton disabled={true} autoFocus className='bg-green-500 text-white border-none mx-1 text-base' size="none" label={'Upload'} onClick={handleSubmit} iconClassName="w-5 h-auto" loading={showSubmitLoading} />
                <CustomButton autoFocus className='bg-red-500 text-white border-none mx-1 text-base' size="none" label={`Cancel `} onClick={handleClose} iconClassName="w-5 h-auto" />
            </DialogActions>
        </Dialog>
    );
};
export default CreateCatalogueFileDialog;