import React from "react";
import { Chip, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { CustomButton } from "components/Common/CustomButton";


function HoldReasonDialog(props) {
    const { onClose, open, Order } = props;

    const handleClose = () => {
        onClose(false);
    };

    return (
        <Dialog
            open={open}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={`bg-red-600 border-red-600 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-4 text-xl">
                    <div className="text-gray-100 text-2xl">
                        Please Add Hold Reason
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="text-black font-semibold text-xl text-center">
                        Add hold reason to the following orders to proceed.
                    </div>
                </DialogContentText>
                <div className="flex flex-row items-center gap-2 pl-5 pt-5 flex-wrap justify-start">
                {Order?.length > 0 && Order?.map((ele, index) => {
                    return (
                        <Chip key={index} className=" font-bold bg-blue-500" color="primary"  label={ele?.NoReasonHoldOrderNumber} />
                    )
                })}
                </div>
            </DialogContent>
            <DialogActions className="justify-center">
                <CustomButton onClick={() => onClose(false) } className='bg-[#5e72e4] text-white border-none'  size="none" label={'OK'}  />
            </DialogActions>
        </Dialog>
    );
};
export default HoldReasonDialog;