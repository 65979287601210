import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import FormGroup from "@mui/material/FormGroup";

import Grid from "@mui/material/Grid";
import { checkEmptyValidation } from 'services/validation-service';
import { dataService } from 'services/data-service';
import MessageBar from "../MessageBar/MessageBar";
import { Close } from '@mui/icons-material';
import { CustomButton } from "components/Common/CustomButton";
import { FormLabel } from '@mui/material';

export default function AddScrapQuanitityDialog(props) {
    const { open, onClose, Order, setIsReload, isReload } = props;
    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
    // Usef defined states
    const scrapQuanitityModelInitialState = {
        ScrapQuanitity: 0,
        ScrapReason: ""
    }
    const [scrapQuanitityModel, setScrapQuanitityModel] = useState(scrapQuanitityModelInitialState);

    // Usef defined states
    const validateModelInitialState = {
        ScrapQuanitity: { message: '', touched: false },
        ScrapReason: { message: '', touched: false },
        IsValidationError: false

    }
    const [scrapQuanitityValidateModel, setScrapQuanitityValidateModel] = useState(validateModelInitialState);


    const [messageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
       fetchData()
    }, [Order])

    const fetchData = async() => {
        setScrapQuanitityModel({
            ScrapQuanitity: Order?.FlowStepQuantityIn || 0,
            ScrapReason: Order?.ScrapReason
        })
    }
    const handleClose = () => {
        onClose();
        setIsReload(!isReload)
    };

    const handleChange = (id, value) => {



        switch (id) {

            case 'ScrapQuanitity':
                setScrapQuanitityModel({ ...scrapQuanitityModel, ScrapQuanitity: value });
                if (value === '') {
                    setScrapQuanitityValidateModel({ ...scrapQuanitityValidateModel, ScrapQuanitity: { message: checkEmptyValidation(value, 'Scrap Quantity'), touched: true } });
                }
                else {
                    if (value > Order?.FlowStepQuantityIn) {
                        setScrapQuanitityValidateModel({ ...scrapQuanitityValidateModel, ScrapQuanitity: { message: "Scrap Quantity can not be greater then In Quantity", touched: true } });
                    }
                    else {
                        setScrapQuanitityValidateModel({ ...scrapQuanitityValidateModel, ScrapQuanitity: { message: "", touched: true } });
                    }
                }
                break;
            case 'ScrapReason':
                setScrapQuanitityModel({ ...scrapQuanitityModel, ScrapReason: value });
                setScrapQuanitityValidateModel({ ...scrapQuanitityValidateModel, ScrapReason: { message: checkEmptyValidation(value, 'Scrap Reason'), touched: true } });
                break;
        }
    }

    const handleSubmit = async () => {
        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let res = await dataService('post', `AddOrderScrapQuanitity/${Order?.OrderId}/${Order?.FlowStepId}`, scrapQuanitityModel);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                handleClose();
            }
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            ScrapQuanitity: { message: scrapQuanitityModel.ScrapQuanitity === "" ? checkEmptyValidation(scrapQuanitityModel.ScrapQuanitity, 'Scrap Quantity') : (scrapQuanitityModel.ScrapQuanitity > Order?.FlowStepQuantityIn ? "Scrap Quantity can not be greater then In Quantity" : ""), touched: true },
            ScrapReason: { message: checkEmptyValidation(scrapQuanitityModel.ScrapReason, 'Scrap Reason'), touched: true },
            IsValidationError: false
        }
        if (
            validateErrors.ScrapQuanitity.message !== '' ||
            validateErrors.ScrapReason.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setScrapQuanitityValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <Dialog open={open} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className='bg-blue-500 border-blue-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between'>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div className="text-gray-100">
                            <span className="text-2xl">Add Scrap - {Order?.WorkCenterName}</span><br />
                        </div>
                        <div className="text-gray-100 flex flex-wrap justify-end">
                            <div className="mr-10">
                                <span className="text-base block mb-1">  <strong> Order #: </strong>{Order?.OrderNumber}</span>
                                <span className="text-xs flex flex-wrap justify-start items-center">
                                    <strong>Total Quantity: </strong>
                                    <span className="px-2 py-1 text-xs font-bold bg-red-500 text-white rounded mr-3 ml-1"> {Order?.Quantity} </span>
                                    <strong>In Quantity: </strong>
                                    <span className="px-2 py-1 text-xs font-bold bg-red-500 text-white rounded ml-1">{Order?.FlowStepQuantityIn}</span></span>
                            </div>
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>

            <DialogContent className="px-2 py-2">
                <Grid>
                    <Grid item xs={12} lg={12}>
                        <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <FormGroup className="mb-0">
                            <FormLabel>Quantity</FormLabel>
                            <TextField
                                variant="filled"
                                name="ScrapQuanity"
                                component={Box}
                                type="number"
                                width="100%"
                                marginBottom="1rem!important"
                                autoComplete="off"
                                value={scrapQuanitityModel.ScrapQuanitity}
                                onChange={e => handleChange('ScrapQuanitity', e.target.value)}
                                error={scrapQuanitityValidateModel.ScrapQuanitity.message !== '' && scrapQuanitityValidateModel.ScrapQuanitity.touched}
                                helperText={scrapQuanitityValidateModel.ScrapQuanitity.message !== '' && scrapQuanitityValidateModel.ScrapQuanitity.touched ? scrapQuanitityValidateModel.ScrapQuanitity.message : ''}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>

                        
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        <FormGroup className="mb-0">
                            <FormLabel>Reason</FormLabel>
                            <TextField
                                variant="filled"
                                name="ScrapReason"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                                autoComplete="off"
                                multiline
                                minRows={4}
                                maxRows={6}
                                value={scrapQuanitityModel.ScrapReason}
                                onChange={e => handleChange('ScrapReason', e.target.value)}
                                error={scrapQuanitityValidateModel.ScrapReason.message !== '' && scrapQuanitityValidateModel.ScrapReason.touched}
                                helperText={scrapQuanitityValidateModel.ScrapReason.message !== '' && scrapQuanitityValidateModel.ScrapReason.touched ? scrapQuanitityValidateModel.ScrapReason.message : ''}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="flex flex-wrap items-center">
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Save" loading={showSubmitLoading} onClick={handleSubmit} />
            </DialogActions>
        </Dialog>
    );
}