import React from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

// import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material";

import componentStyles from 'assets/theme/views/admin/tables.js';
import moment from 'moment';
import { LoadingIcon } from 'components/Icons/Loading';
import { CardHeader, TablePagination } from '@mui/material';
import { SalesReportFilterHeader } from './SalesReportFilterHeader';
import { currencyFormat } from 'services/data-service';
import { DailySalesReportFilterHeader } from './DailySalesReportFilterHeader';
import { DailyDetailSalesReportFilterHeader } from './DailyDetailSalesReportFilterHeader';
import { DailySalesProductionFilterHeader } from './DailySalesProductionFilterHeader';
//import moment from "moment";

const useStyles = makeStyles(componentStyles);

const SalesReportTable = (props) => {
  const { rows, columns, loading, viewAction, reportType, setOffset, offset, page, total, pagedata, isNotAmount = false, isHideAmount = false, productionReportQuantityTotal = false, totalProductionQuantity = 0, isProductionReport = false } = props;
  const headCells = columns;
  const classes = useStyles();
  const [selectedRowIndex] = React.useState(null);

  function EnhancedTableHead(props) {
    const { classes } = props;

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              classes={{
                root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
              }}
            >
              <TableSortLabel>{headCell.label}</TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  /*------------- Table Headers End ------------*/

  /*------------- Table Content Start ------------*/

  function EnhancedTableRows(props) {
    const { classes, rows } = props;

    function totalAmount(rowData) {
      let totalAmountVal = 0;
      rowData.map((x) => (totalAmountVal += x?.Amount || x?.TotalPrice));

      return currencyFormat(totalAmountVal);
    }

    function totalQuantity(rowData) {
      let totalQuantityVal = 0;
      rowData.map((x) => (totalQuantityVal += x?.Quantity));
      return totalQuantityVal;
    }

    return (
      <TableBody className="relative">
        {rows?.map((rowData, index) => {
          return (
            // <TableRow key={index} onClick={(e) => {handleViewClick(rowData)}} className="cursor-pointer">
            <TableRow
              key={index}
              className={`cursor-pointer ${selectedRowIndex === index ? 'bg-blue-100' : ''
                }`}
            >
              {headCells.map((headCell, hdIndex) => (
                <TableCell
                  onClick={() => {
                    viewAction && viewAction(rowData);
                  }}
                  key={hdIndex}
                  classes={{
                    root:
                      classes.tableCellRoot +
                      ' ' +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                  className={`${hdIndex === 0 ? 'w-8/12' : 'w-2/12'}`}
                >
                  {headCell.dataType === "component" && <headCell.Component row={rowData} />}
                  {headCell.dataType === 'string' && rowData[headCell.id]}
                  {headCell.dataType === 'currency' &&
                    currencyFormat(rowData[headCell.id])}
                  {headCell.dataType === 'date' &&
                    !headCell.dateFromNow &&
                    rowData[headCell.id]
                    ? moment(rowData[headCell.id]).format('D-MM-YYYY')
                    : ''}
                  {headCell.dataType === 'date' &&
                    headCell.dateFromNow &&
                    rowData[headCell.id]
                    ? moment(rowData[headCell.id]).fromNow(true)
                    : ''}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
        {rows?.length > 0 && productionReportQuantityTotal &&
          <TableRow className="bg-gray-200 ">
            <TableCell className="!font-bold" align="right">
              <span className="py-3 pr-12 text-base block">Total</span>
            </TableCell>
            <TableCell className="!font-bold" align="left">
              <span className="py-3 text-base block">
                {totalProductionQuantity}
              </span>
            </TableCell>
          </TableRow>}
        {rows?.length > 0 && (reportType === 'DAILY' || reportType === "GoodsEntryOrder") && props?.selectedDay && !props?.isHideTotal && (
          <TableRow className="bg-gray-200 ">
            {!props.title &&
              <TableCell className="font-bold" align="right">
                <span className="py-3 pr-12 text-base block"></span>
              </TableCell>}
            <TableCell className="font-bold" align="right">
              <span className="py-3 pr-12 text-base block"></span>
            </TableCell>
            <TableCell className="font-bold" align="right">
              <span className="py-3 pr-12 text-base block"></span>
            </TableCell>
            <TableCell className="font-bold" align="right">
              <span className="py-3 pr-12 text-base block"></span>
            </TableCell>
            {reportType !== "GoodsEntryOrder" &&
              <TableCell className="font-bold" align="right">
                <span className="py-3 pr-12 text-base block"></span>
              </TableCell>}
            <TableCell className="!font-bold" align="right">
              <span className="py-3 pr-12 text-base block">Total</span>
            </TableCell>
            <TableCell className="!font-bold" align="left">
              <span className="py-3 text-base block">
                {totalQuantity(rows)}
              </span>
            </TableCell>
            {!isNotAmount ?
              <TableCell className="!font-bold" align="left">
                <span className="py-3 text-base block">
                  {!isHideAmount ? `₹ ${totalAmount(rows)}` : ""}
                </span>
              </TableCell>
              : null}
          </TableRow>
        )}

        {rows?.length > 0 && (reportType === 'DAILY' || reportType === "GoodsEntryOrder") && !props?.selectedDay && !props?.isHideTotal && (
          <TableRow className="bg-gray-200 ">
            {isNotAmount ?
              <TableCell className="font-bold" align="right">
                <span className="py-3 pr-12 text-base block"></span>
              </TableCell> : null}
            {/* {isNotAmount ?
              <TableCell className="font-bold" align="right">
                <span className="py-3 pr-12 text-base block"></span>
              </TableCell> : null} */}
            <TableCell className="!font-bold" align="right">
              <span className="py-3 pr-12 text-base block">Total</span>
            </TableCell>
            <TableCell className="!font-bold" align="left">
              <span className="py-3 text-base block">
                {totalQuantity(rows)}
              </span>
            </TableCell>
            {!isNotAmount ?
              <TableCell className="!font-bold" align="left">
                <span className="py-3 text-base block">
                  ₹ {totalAmount(rows)}
                </span>
              </TableCell> : null}
          </TableRow>
        )}

        {rows?.length === 0 && !loading && (
          <TableRow key={0}>
            <TableCell
              colSpan={columns?.length + 2}
              classes={{ root: classes.tableCellRoot }}
              align="center"
              className="font-bold"
            >
              Record Not Found
            </TableCell>
          </TableRow>
        )}
        {loading && (
          <TableRow key={0}>
            <TableCell
              colSpan={columns?.length + 2}
              classes={{ root: classes.tableCellRoot }}
              align="center"
              className="font-bold"
            >
              <LoadingIcon overlay={rows?.length > 0} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }

  EnhancedTableRows.propTypes = {
    classes: PropTypes.object.isRequired,
    rows: PropTypes.array,
  };

  const handleChangePage = (event, newPage) => {
    pagedata(newPage + 1);
  };

  //Handle Page changes
  const handleChangePageRows = (event) => {
    setOffset(parseInt(event.target.value, 10));
    pagedata(1);
  };

  /*------------- Table Content End ------------*/

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        subheader={
          isProductionReport ? (
            <DailySalesProductionFilterHeader
              data={rows}
              selectedMonth={props?.selectedMonth}
              selectedYear={props?.selectedYear}
              isChartVisible={props?.isChartVisible}
              setIsChartOpen={props?.setIsChartOpen}
              title={props?.title}
              isHideTotal={props?.isHideTotal}
              isNotAmount={isNotAmount}
              totalProductionQuantity={totalProductionQuantity}
              productionReportQuantityTotal={productionReportQuantityTotal}
            />
          ) : reportType === 'DAILY' && !props?.selectedDay ? (
            <DailySalesReportFilterHeader
              data={rows}
              selectedMonth={props?.selectedMonth}
              selectedYear={props?.selectedYear}
              isChartVisible={props?.isChartVisible}
              setIsChartOpen={props?.setIsChartOpen}
              title={props?.title}
              isHideTotal={props?.isHideTotal}
              isNotAmount={isNotAmount}
            />
          )
            : (reportType === 'DAILY' || reportType === "GoodsEntryOrder") && props?.selectedDay ? (
              <DailyDetailSalesReportFilterHeader
                data={rows}
                selectedDay={props?.selectedDay}
                selectedMonth={props?.selectedMonth}
                selectedYear={props?.selectedYear}
                title={props?.title}
                isHideTotal={props?.isHideTotal}
                workCenterName={props?.workCenterName}
                isNotAmount={isNotAmount}
                isHideAmount={isHideAmount}
                reportType={reportType}
                goodsEntryId={props?.goodsEntryId}
                isCalender={props?.isCalender}
                selectedDate={props?.selectedDate}
                setSelectedDate={props?.setSelectedDate}
                WorkCenterId={props?.WorkCenterId}
                setSelectedYear={props?.setSelectedYear}
              />
            ) : (
              <SalesReportFilterHeader
                data={rows}
                selectedYear={props?.selectedYear}
                setSelectedYear={props?.setSelectedYear}
                isChartVisible={props?.isChartVisible}
                setIsChartOpen={props?.setIsChartOpen}
                title={props?.title}
                isHideTotal={props?.isHideTotal}
                isNotAmount={isNotAmount}
              />
            )
        }
        classes={{ root: classes.cardHeaderRoot }}
      ></CardHeader>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <EnhancedTableHead classes={classes} />

          <EnhancedTableRows
            classes={classes}
            rows={rows}
            reportType={reportType}
            selectedDay={props?.selectedDay}
            title={props?.title}
            isHideTotal={props?.isHideTotal || false}
          />
        </Table>
      </TableContainer>
      <Box
        classes={{ root: classes.cardActionsRoot }}
        component={CardActions}
        justifyContent="flex-end"
      >
        {props?.isPagination ?
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={offset}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageRows}
          /> : null}
      </Box>
    </Card>
  );
};

export default SalesReportTable;
