import React, { useEffect, useState, useContext } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
// @mui/icons-material
import {Person as PersonIcon, Lock} from "@mui/icons-material"

import { dataService } from "../../../services/data-service";
import MessageBar from "../../../components/MessageBar/MessageBar";

import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import { authContext } from '../../../context/AuthContext'
import { currentUser, decodeToken } from "services/user-service";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import Verification from "../2FAVerification";

const useStyles = makeStyles(componentStyles);

function CustomerLogin() {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const { setAuthData } = useContext(authContext);

    const [loginModel, setLoginModel] = useState({
        Username: '',
        Password: ''
    });

    const [showMultiFA, setShowMultiFA] = useState(false);
    const [currentUserData, setCurrentUserData] = useState({})
    const [userToken, setUserToken] = useState({})

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        if (Cookies.get('token')) {
            history.push('/admin/customer-goods-entries');
        }
    }, [loginModel, history])

    // Multi factor login handle check with google authenticator.
    // Temp  not used as we are verify using OTP(SMS).
    const loginHandle = async () => {
        let res = await dataService('post', 'customerLogin', loginModel);
        if (res && (res.status === 200 || res.status === 304)) {
            let data = res.data;
            let currentUserInfo = decodeToken(data.token);
            if (currentUserInfo?.Role === 'SUPER_ADMIN') {
                setUserToken(data.token);
                setMessageDialoge({ show: false, type: '', message: '' });
                setCurrentUserData(currentUserInfo);
                setShowMultiFA(true)
            }
            else {
                Cookies.set('token', data.token, { expires: 2 });
                setCurrentUserData(currentUser());
                setShowMultiFA(false)
                setAuthData(data.token)
                history.push('/admin/customer-goods-entries');
            }


        } else {
            Cookies.remove('token');
            //setOpen(res.message);
            setMessageDialoge({ show: true, type: 'error', message: res.message });
        }
    }

    const handleChange = (id, value) => {
        switch (id) {
            case 'Username':
                setLoginModel({ ...loginModel, Username: value });
                break;
            case 'Password':
                setLoginModel({ ...loginModel, Password: value });
                break;

            default:
                setLoginModel({ ...loginModel })
                break;
        }
    };
    return (
        <>
            <Grid item xs={12} lg={5} md={7}>
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}

                        titleTypographyProps={{
                            component: Box,
                            textAlign: "center",
                            marginBottom: "1rem!important",
                            marginTop: ".5rem!important",
                            fontSize: "1rem!important",
                        }}
                    ></CardHeader>
                    <CardContent>
                        <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                        {!showMultiFA &&
                            <>
                                <Box
                                    color={theme.palette.gray[600]}
                                    textAlign="center"
                                    marginTop=".5rem"
                                    fontSize="1rem"
                                >
                                </Box>
                                <FormControl
                                    variant="filled"
                                    component={Box}
                                    width="100%"
                                    marginBottom="1rem!important"
                                >
                                    <FilledInput
                                        autoComplete="off"
                                        required
                                        placeholder="Username"
                                        value={loginModel.Username}
                                        onChange={e => handleChange('Username', e.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl
                                    variant="filled"
                                    component={Box}
                                    width="100%"
                                    marginBottom="1rem!important"
                                >
                                    <FilledInput
                                        autoComplete="off"
                                        type="password"
                                        placeholder="Password"
                                        value={loginModel.Password}
                                        onChange={e => handleChange('Password', e.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                    <Button className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" color="primary" variant="contained" onClick={loginHandle}>
                                        Sign in
                                    </Button>
                                </Box>
                            </>
                        }


                        {showMultiFA &&
                            <>
                                <Verification Username={currentUserData?.Username} secret={currentUserData?.MultiFactorSecret} defaultShowQRCode={false} userToken={userToken} />
                            </>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default CustomerLogin;