/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles, useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
import { useParams, useHistory } from 'react-router-dom';
import { updatePreviousLocations } from 'generic/store';
import { useDispatch } from 'react-redux';
import SalesReportTable from './components/SalesReportTable';
import { chartOptions } from 'variables/charts';
import { parseOptions } from 'variables/charts';
import { Chart } from 'chart.js';
import { Close } from '@mui/icons-material';
import dashboardStyles from 'assets/theme/views/admin/dashboard';
import { Bar, Line } from 'react-chartjs-2';
import { chartExample1 } from 'variables/charts';
import { chartExample2 } from 'variables/charts';
import { CustomButton } from 'components/Common/CustomButton';
import { Button, ButtonGroup, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, Typography } from '@mui/material';
import { MONTHS_DATA } from 'utils/constant';
import moment from 'moment';

const useStyles = makeStyles(componentStyles);
const useDashboardStyles = makeStyles(dashboardStyles);

const CustomerDailyOrderReport = () => {
  
  const dispatch = useDispatch(); // to update store values
  const history = useHistory();

  const { customerId, month, year  } = useParams();
  const classes = useStyles();
  const dashboardClasses = useDashboardStyles();
  const [customerList, setCustomerList] = useState([]);

  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [isLoading, setIsLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [lineChartReportType] = useState('quantity');
  const [lineChartReportData, setLineCharReportData] = useState(null);

  const theme = useTheme();
  
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const columns = [
    {
      id: 'Date',
      numeric: false,
      disablePadding: true,
      label: 'Date',
      dataType: 'string',
    },
    {
      id: 'Quantity',
      numeric: false,
      disablePadding: true,
      label: 'Quantity',
      dataType: 'string',
    },
    {
      id: 'TotalPrice',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
      dataType: 'string',
    },
   
  ];  

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async() => {
    dispatch(updatePreviousLocations(null));
    setIsLoading(true);
    let res = await dataService(
      'get',
      'CustomerReportSales',
      `Year=${year}&Month=${month}&CustomerId=${customerId}&ReportType=Daily`
      
    );
    setCustomerList(res?.data);
    generateDayChartReport(res?.data?.data);
    setIsLoading(false);
  }

  const generateDayChartReport = (dayReport) => {
    dayReport.sort(function (a, b) {
      return a.Date - b.Date;
    });
    let rptDataset = null;

    rptDataset = {
      label: 'Quantity',
      data: dayReport.map((rpt) => {
        return rpt.Quantity;
      }),
    };
    let reportData = {
      labels: dayReport.map((rpt) => {
        return rpt.Date;
      }),
      datasets: [rptDataset],
    };
    setLineCharReportData(reportData);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const viewAction = (rowData) => {
    history.push(
      `/admin/report/customer-daily-details/${customerId}/${rowData?.Year}/${moment()
        .month(rowData?.Month)
        .format('M')}/${rowData?.Date}`
    );
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
         <SalesReportTable
          title={customerList?.CustomerName}
          columns={columns}
          rows={customerList?.data || []}
          actions={[]}
          loading={isLoading}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          setSelectedYear={setSelectedYear}
          viewAction={viewAction}
          reportType={'DAILY'}
          isChartVisible={true}
          setIsChartOpen={setIsOpen}
        />
       
       <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <div
            style={{
              background: theme.palette.info.main,
              borderColor: theme.palette.info.main,
            }}
            className="bg-blue-600 border-blue-600 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between"
          >
            <DialogTitle className="pb-1 w-full">
              <div className="sm:grid sm:grid-cols-2 items-center">
                <div className="text-gray-100">
                  <span className="text-2xl">Monthly Sales Report</span>
                  <br />
                </div>
              </div>
            </DialogTitle>
            <Close
              className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2"
              onClick={handleClose}
            />
          </div>

          <DialogContent className="px-4 py-2">
            <Grid container>
              <Grid
                item
                xs={12}
                xl={8}
                component={Box}
                marginBottom="3rem!important"
                classes={{ root: dashboardClasses.gridItemRoot }}
              >
                <Card
                  classes={{
                    root:
                      dashboardClasses.cardRoot +
                      ' ' +
                      dashboardClasses.cardRootBgGradient,
                  }}
                >
                  <CardHeader
                    subheader={
                      <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs="auto">
                          <Box
                            component={Typography}
                            variant="h6"
                            letterSpacing=".0625rem"
                            marginBottom=".25rem!important"
                            className={dashboardClasses.textUppercase}
                          >
                            <ButtonGroup
                              size="small"
                              disableElevation
                              variant="contained"
                              color="primary"
                            >
                              <Button
                                classes={{
                                  root:
                                    lineChartReportType === 'quantity'
                                      ? ''
                                      : dashboardClasses.buttonRootUnselected,
                                }}
                              >
                                Quantity
                              </Button>
                            </ButtonGroup>
                          </Box>
                          <Box
                            component={Typography}
                            variant="h2"
                            marginBottom="0!important"
                          >
                            <Box
                              component="span"
                              color={theme.palette.info.main}
                            >
                              <span className="capitalize">
                                {lineChartReportType}
                              </span>{' '}
                              Report
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Select
                          value={selectedMonth}
                          onChange={(event) => setSelectedMonth(event.target.value)}
                          displayEmpty
                          className={`${dashboardClasses.selectEmpty} mr-2`}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {MONTHS_DATA.map((month) => {
                            return <MenuItem key={month.val} value={month.val}>{month.label}</MenuItem>
                          })}
                        </Select>
                      </Box>
                    </Grid>
                      </Grid>
                    }
                    classes={{ root: dashboardClasses.cardHeaderRoot }}
                  ></CardHeader>
                  <CardContent>
                    <Box position="relative" height="350px">
                      {lineChartReportData && (
                        <Line
                          data={lineChartReportData}
                          options={chartExample1.options}
                          getDatasetAtEvent={(e) => console.log(e)}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} xl={4}>
                <Card classes={{ root: dashboardClasses.cardRoot }}>
                  <CardHeader
                    title={
                      <Box component="span" color={theme.palette.gray[600]}>
                        Performane
                      </Box>
                    }
                    subheader={
                      <span className="capitalize">Total Quantity</span>
                    }
                    classes={{ root: dashboardClasses.cardHeaderRoot }}
                    titleTypographyProps={{
                      component: Box,
                      variant: 'h6',
                      letterSpacing: '.0625rem',
                      marginBottom: '.25rem!important',
                      classes: {
                        root: dashboardClasses.textUppercase,
                      },
                    }}
                    subheaderTypographyProps={{
                      component: Box,
                      variant: 'h2',
                      marginBottom: '0!important',
                      color: 'initial',
                    }}
                  ></CardHeader>
                  <CardContent>
                    <Box position="relative" height="350px">
                      {lineChartReportData && (
                        <Bar
                          data={lineChartReportData}
                          options={chartExample2.options}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default CustomerDailyOrderReport;
