import React, { useEffect, useState } from "react";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
// @mui/icons-material components
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

// core components
import Header from "components/Headers/Header.js";
import CardStats from "components/Cards/CardStats.js";

// @mui/icons-material components
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import PieChart from "@mui/icons-material/PieChart";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import dashboardStyles from "assets/theme/views/admin/dashboard.js";
import { dataService } from "services/data-service";
import { ButtonGroup, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(dashboardStyles);

function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [activeNav, setActiveNav] = useState(1);
  const [lineChartReportType, setLineChartReportType] = useState("order");
  const [monthlyOrderReportData, setMonthlyOrderReportData] = useState(null);
  const [yearlyOrderReportData, setYearlyOrderReportData] = useState(null);
  const [lineChartOrderReportData, setLineChartOrderReportData] = useState(null);
  const [quantityData, setQuantityData] = useState({})

  const yearOptions = Array.from({ length: 10 }, (value, index) => 2022 + index);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };



  const [ordersReport, setOrdersReport] = useState({
    totalOrders: 0,
    totalQuantity: 0
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const lineChartTypeHandleChange = (type) => {
    setLineChartReportType(type)
    if (type === 'order') {
      generateMonthlyOrderChartReport(monthlyOrderReportData, 'order')
    }
    else {
      generateYearlyOrderChartReport(yearlyOrderReportData, 'quantity');
    }
  }


  const toggleNavs = (index, reportType) => {
    setActiveNav(index);
    if (reportType === 'monthlyReport') {
      generateMonthlyOrderChartReport(monthlyOrderReportData, lineChartReportType)
    }
    else {
      generateYearlyOrderChartReport(yearlyOrderReportData, lineChartReportType);
    }
  };

  const generateMonthlyOrderChartReport = (monthlyReport, rptType) => {
    monthlyReport.sort(function (a, b) { return a.Month - b.Month });
    let rptDataset = null;
    if (rptType === 'order') {
      rptDataset = {
        label: "Orders",
        data: monthlyReport.map((rpt) => { return rpt.TotalOrders })
      }
    } else {
      rptDataset = {
        label: "Quantity",
        data: monthlyReport.map((rpt) => { return rpt.TotalQuantity })
      }
    }
    let reportData = {
      labels: monthlyReport.map((rpt) => { return rpt.MonthName }),
      datasets: [rptDataset]
    }
    setLineChartOrderReportData(reportData);
  }

  const generateYearlyOrderChartReport = (yearlyReport, rptType) => {
    yearlyReport.sort(function (a, b) { return a.Year - b.Year });
    let rptDataset = null;
    if (rptType === 'order') {
      rptDataset = {
        label: "Orders",
        data: yearlyReport.map((rpt) => { return rpt.TotalOrders })
      }
    } else {
      rptDataset = {
        label: "Quantity",
        data: yearlyReport.map((rpt) => { return rpt.TotalQuantity })
      }
    }
    let reportData = {
      labels: yearlyReport.map((rpt) => { return rpt.Year }),
      datasets: [rptDataset]
    }
    setLineChartOrderReportData(reportData);
  }


  const getOrderReportData = async () => {
    let res = await dataService('get', 'ReportDashboardStatistics', `year=${selectedYear || 2022}`);
    if (res?.status === 200 || res?.status === 304) {
      let yearlyReport = res?.data?.yearlyReport;
      let monthlyReport = res?.data?.monthlyReport;
      setMonthlyOrderReportData(monthlyReport);
      setYearlyOrderReportData(yearlyReport);
      generateMonthlyOrderChartReport(monthlyReport, lineChartReportType);
      setQuantityData(res?.data?.totalQuantity)

      //let resOrders = res?.data;
      let totalOrders = yearlyReport.map(resOrder => resOrder.TotalOrders).reduce((acc, resOrder) => resOrder + acc);
      let totalQuantity = yearlyReport.map(resOrder => resOrder.TotalQuantity).reduce((acc, resOrder) => resOrder + acc);
      setOrdersReport({ ...ordersReport, totalOrders: totalOrders, totalQuantity: totalQuantity })
      //setOrdersReport(res?.data);
    }
  }


  useEffect(() => {
    //let res = await dataService('get', 'OrderCountStats', `type=ByProducts&status=InProgress&startDate=null&endDate=null`);
    fetchData()
  }, [selectedYear])

  const fetchData = async () => {
    await getOrderReportData();
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <div>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xl={3} lg={6} xs={12}>
              <CardStats
                subtitle="Orders"
                title={ordersReport?.totalOrders.toString()}
                icon={InsertChartOutlined}
                color="bgError"
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12}>
              <CardStats
                subtitle="Quantity"
                title={ordersReport?.totalQuantity.toString()}
                icon={PieChart}
                color="bgWarning"
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12}>
              <Card style={{ cursor: "pointer" }} classes={{ root: classes.cardRoot }} elevation={0} className="shadow-md" onClick={() => history.push('report/sales-report')}>
                <CardContent classes={{ root: classes.cardContentRoot }}>
                  <Grid container component={Box} justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        color={theme.palette.gray[600] + "!important"}
                        marginBottom="0!important"
                        marginTop="0!important"
                        className={classes.textUppercase}
                      >
                        Sales Report
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xl={3} lg={6} xs={12}>
              <Card style={{ cursor: "pointer" }} classes={{ root: classes.cardRoot }} elevation={0} className="shadow-md" onClick={() => history.push('report/customers-report')}>
                <CardContent classes={{ root: classes.cardContentRoot }}>
                  <Grid container component={Box} justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        color={theme.palette.gray[600] + "!important"}
                        marginBottom="0!important"
                        marginTop="0!important"
                        className={classes.textUppercase}
                      >
                        Customer Report
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xl={3} lg={6} xs={12} style={{ cursor: "pointer" }} onClick={() => history.push('orderflow/holded-orders')}>
              <CardStats
                subtitle="Hold Orders"
                title={quantityData?.HoldOrderTotalQuantityCount?.toString() ?? ""}
                icon={InsertChartOutlined}
                color="bgInfo"
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12} style={{ cursor: "pointer" }} onClick={() => history.push('orders/running')}>
              <CardStats
                subtitle="Runninng Orders"
                title={quantityData?.RunningOrderTotalQuantityCount?.toString() ?? ""}
                icon={InsertChartOutlined}
                color="bgInfo"
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12} style={{ cursor: "pointer" }} onClick={() => history.push('goods-entries')}>
              <CardStats
                subtitle="GoodsEntry Count"
                title={quantityData?.GoodEntryTotalQuantityCount?.toString() ?? ""}
                icon={InsertChartOutlined}
                color="bgInfo"
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12}>
              <Card style={{ cursor: "pointer" }} classes={{ root: classes.cardRoot }} elevation={0} className="shadow-md" onClick={() => history.push('report/productions-report')}>
                <CardContent classes={{ root: classes.cardContentRoot }}>
                  <Grid container component={Box} justifyContent="space-between">
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        color={theme.palette.gray[600] + "!important"}
                        marginBottom="0!important"
                        marginTop="0!important"
                        className={classes.textUppercase}
                      >
                        Production Report
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </div>
        <Grid container>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootBgGradient,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h6"
                        letterSpacing=".0625rem"
                        marginBottom=".25rem!important"
                        className={classes.textUppercase}
                      >
                        {/* <Box component="span" color={theme.palette.gray[400]}>
                          Orders
                        </Box> */}
                        <ButtonGroup size="small" disableElevation variant="contained" color="primary">
                          <Button
                            onClick={() => { lineChartTypeHandleChange('order') }}
                            className={lineChartReportType === 'order' ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            classes={{
                              root:
                                lineChartReportType === 'order'
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >Orders</Button>
                          <Button
                            className={lineChartReportType === 'quantity' ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            onClick={() => { lineChartTypeHandleChange('quantity') }}
                            classes={{
                              root:
                                lineChartReportType === 'quantity'
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >Quantity</Button>
                        </ButtonGroup>
                      </Box>
                      <Box
                        component={Typography}
                        variant="h2"
                        marginBottom="0!important"
                      >
                        <Box component="span" color={theme.palette.white.main}>
                          <span className="capitalize">{lineChartReportType}</span> Report
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >

                        <Select
                          value={selectedYear}
                          onChange={handleYearChange}
                          displayEmpty
                          className={`${classes.selectEmpty} mr-2`}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {yearOptions.map((year, index) => {
                            return <MenuItem key={index} value={year}>{year}</MenuItem>
                          })}
                        </Select>
                        <ButtonGroup disableElevation variant="contained" color="primary">
                          <Button
                            onClick={() => toggleNavs(1, 'monthlyReport')}
                            className={activeNav === 1 ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            classes={{
                              root:
                                activeNav === 1
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >
                            Month
                          </Button>
                          <Button
                            onClick={() => toggleNavs(2, 'yearlyReport')}
                            className={activeNav === 2 ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            classes={{
                              root:
                                activeNav === 2
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >
                            Year
                          </Button>
                        </ButtonGroup>

                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Box position="relative" height="350px">
                  {lineChartOrderReportData &&
                    <Line
                      //data={chartExample1[chartExample1Data]}
                      data={lineChartOrderReportData}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                title={
                  <Box color={theme.palette.gray[600]}>
                    Performane
                  </Box>
                }
                subheader={<span className="capitalize">Total {lineChartReportType}</span>}
                classes={{ root: classes.cardHeaderRoot }}
                titleTypographyProps={{
                  // component: Box,
                  variant: "h6",
                  letterSpacing: ".0625rem",
                  marginBottom: ".25rem!important",
                  classes: {
                    root: classes.textUppercase,
                  },
                }}
                subheaderTypographyProps={{
                  component: Box,
                  variant: "h2",
                  marginBottom: "0!important",
                  color: "initial",
                }}
              ></CardHeader>
              <CardContent>
                <Box position="relative" height="350px">
                  {lineChartOrderReportData &&
                    <Bar
                      data={lineChartOrderReportData}
                      options={chartExample2.options}
                    />
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container component={Box} marginTop="3rem">
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >
            <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Page visits
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]"
                        >
                          See all
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Page name
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Visitors
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Unique users
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Bounce rate
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        /RajSampler/
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        4,569
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        340
                      </TableCell>
                      <Box
                        component={TableCell}
                        className={classes.tableCellRoot}
                        marginBottom="-2px"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1rem!important"
                          height="1rem!important"
                          marginRight="1rem"
                          color={theme.palette.success.main}
                        />
                        46,53%
                      </Box>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        /RajSampler/index.html
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        3,985
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        319
                      </TableCell>
                      <Box
                        component={TableCell}
                        className={classes.tableCellRoot}
                        marginBottom="-2px"
                      >
                        <Box
                          component={ArrowDownward}
                          width="1rem!important"
                          height="1rem!important"
                          marginRight="1rem"
                          color={theme.palette.warning.main}
                        />
                        46,53%
                      </Box>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        /RajSampler/charts.html
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        3,513
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        294
                      </TableCell>
                      <Box
                        component={TableCell}
                        className={classes.tableCellRoot}
                        marginBottom="-2px"
                      >
                        <Box
                          component={ArrowDownward}
                          width="1rem!important"
                          height="1rem!important"
                          marginRight="1rem"
                          color={theme.palette.warning.main}
                        />
                        36,49%
                      </Box>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        /RajSampler/tables.html
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        2,050
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        147
                      </TableCell>
                      <Box
                        component={TableCell}
                        className={classes.tableCellRoot}
                        marginBottom="-2px"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1rem!important"
                          height="1rem!important"
                          marginRight="1rem"
                          color={theme.palette.success.main}
                        />
                        50,87%
                      </Box>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead +
                            " " +
                            classes.borderBottomUnset,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        /RajSampler/profile.html
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.borderBottomUnset,
                        }}
                      >
                        1,795
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.borderBottomUnset,
                        }}
                      >
                        190
                      </TableCell>
                      <Box
                        component={TableCell}
                        className={
                          classes.tableCellRoot +
                          " " +
                          classes.borderBottomUnset
                        }
                        marginBottom="-2px"
                      >
                        <Box
                          component={ArrowDownward}
                          width="1rem!important"
                          height="1rem!important"
                          marginRight="1rem"
                          color={theme.palette.error.main}
                        />
                        46,53%
                      </Box>
                    </TableRow>
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Social traffic
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]"
                        >
                          See all
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Refferal
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Visitors
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Facebook
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        1,480
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box display="flex" alignItems="center">
                          <Box component="span" marginRight=".5rem">
                            60%
                          </Box>
                          <Box width="100%">
                            <LinearProgress
                              variant="determinate"
                              value={60}
                              classes={{
                                root: classes.linearProgressRoot,
                                bar: classes.bgGradientError,
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Facebook
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        5,480
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box display="flex" alignItems="center">
                          <Box component="span" marginRight=".5rem">
                            70%
                          </Box>
                          <Box width="100%">
                            <LinearProgress
                              variant="determinate"
                              value={70}
                              classes={{
                                root: classes.linearProgressRoot,
                                bar: classes.bgGradientSuccess,
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Google
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        4,807
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box display="flex" alignItems="center">
                          <Box component="span" marginRight=".5rem">
                            80%
                          </Box>
                          <Box width="100%">
                            <LinearProgress
                              variant="determinate"
                              value={80}
                              classes={{
                                root: classes.linearProgressRoot,
                                bar: classes.bgGradientPrimary,
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Instagram
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        3,678
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box display="flex" alignItems="center">
                          <Box component="span" marginRight=".5rem">
                            75%
                          </Box>
                          <Box width="100%">
                            <LinearProgress
                              variant="determinate"
                              value={75}
                              classes={{
                                root: classes.linearProgressRoot,
                                bar: classes.bgGradientInfo,
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead +
                            " " +
                            classes.borderBottomUnset,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        twitter
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.borderBottomUnset,
                        }}
                      >
                        2,645
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.borderBottomUnset,
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Box component="span" marginRight=".5rem">
                            30%
                          </Box>
                          <Box width="100%">
                            <LinearProgress
                              variant="determinate"
                              value={30}
                              classes={{
                                root: classes.linearProgressRoot,
                                bar: classes.bgGradientWarning,
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Dashboard;
