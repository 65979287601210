import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
import { Card, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LoadingIcon } from 'components/Icons/Loading';
import { convertBlobToBase64 } from 'utils/helper';
import PDFViewerDialog from './PdfVIewerDialog';

const useStyles = makeStyles(componentStyles);

const CatalogueDetail = () => {


  const classes = useStyles();

  const { id } = useParams();

  const [catalogueDetail, setCatalogueDetail] = useState(null)
  const [pdfViewerOpen, setPdfViewerOpen] = useState({ isVisible: false, file: null, item : null })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [id]);

  const fetchData = async () => {
    let res = await dataService('get', `public/CatalogueFileList/${id}`, null);
    if (res.status === 200) {
      if (res?.data) {
        setCatalogueDetail(res)
      }

    }
  }

  const handleClick = async (url, item) => {
    setIsLoading(true)
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const base64 = await convertBlobToBase64(blob);
        setIsLoading(false)
        setPdfViewerOpen({file : base64, isVisible: true, item : item})
    } catch (error) {
        setIsLoading(false)
        console.error('Error fetching and converting PDF:', error);
    }
  };

  return (
    <>
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.containerRoot }}
      // paddingTop="10rem"
      // paddingBottom="3rem"
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {catalogueDetail?.catalogueData?.Title}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>

                <div className={classes.plLg4}>
                  <div className="bg-indigo-300 border-indigo-300 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-3 sm:px-6">
                      <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Catalogue</h3>
                    </div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
                      <div className="border-t border-gray-200 p-5">
                        <Grid container spacing={2}>
                          {catalogueDetail?.data?.map((ele) => {
                            return (
                              <Grid item xs={12} lg={3} md={4} sm={6} key={ele?.id}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    border: '1px solid #ccc',
                                    padding: '16px',
                                    borderRadius: '8px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleClick(`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${ele?.FilePath}${ele?.FileName}`, ele)}
                                >
                                  <img
                                    width="250"
                                    style={{ height: "200px" }}
                                    alt='qrcode'
                                    src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${ele?.FilePath}${ele?.FeaturedImage}`}
                                  />
                                  <div className='flex flex-row items-center gap-2'>
                                    <div className="text-lg text-gray-500">
                                      <span style={{ color: '#000000' }} className='text-sm md:text-base'>{ele?.Title}</span>
                                    </div>
                                    <Link
                                      // href={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${ele?.FilePath}${ele?.FileName}`} 
                                      target="_blank"
                                      className="inline-block"
                                    // onClick={(e) => e.stopPropagation()} // Prevent onClick event from firing when the link is clicked
                                    >
                                      <img
                                        width="40"
                                        alt='download-cloud-icon'
                                        src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/download-cloud-icon.png`}
                                      />
                                    </Link>
                                  </div>
                                </Box>
                              </Grid>

                            )
                          })}
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>

              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {pdfViewerOpen?.isVisible ? <PDFViewerDialog viewer={pdfViewerOpen} handleClose={() => {
            setPdfViewerOpen({ file: null, isVisible: false })
          }} /> : null}
        {
          isLoading && <LoadingIcon overlay={isLoading} />
        }
      </Container>
    </>
  );
};

export default CatalogueDetail;

