import React, { useEffect, useState } from "react";
import { Chip, DialogActions, DialogContent, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { CustomButton } from "components/Common/CustomButton";
import { dataService } from "services/data-service";
import { LoadingIcon } from "components/Icons/Loading";
import MessageBar from "components/MessageBar/MessageBar";

function WorkCenterParallelDialog({ onClose, open }) {
    const [selected, setSelected] = useState([]);
    const [originalSelected, setOriginalSelected] = useState([]);
    const [workCenterCombinedIds, setWorkCenterCombinedIds] = useState([]);
    const [workCenterDeleteCombinedIds, setWorkCenterDeleteCombinedIds] = useState([]);
    const [showSubmitLoading, setShowSubmitLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        getWorkCenterParallelItem();
    }, []);

    const getWorkCenterParallelItem = async () => {
        setIsLoading(true);
        try {
            let res = await dataService('get', `WorkCenterParallelItemGet/${open?.extraData.WorkCenterId}`, null);
            setIsLoading(false);
            const fetchedIds = JSON.parse(res?.data?.WorkCenterCombinedIds) || [];
            setSelected(fetchedIds);
            setOriginalSelected(fetchedIds);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        setShowSubmitLoading(true);

        const payload = {
            WorkCenterCombinedIds: workCenterCombinedIds.join(','),
            WorkCenterDeleteCombinedIds: workCenterDeleteCombinedIds.join(',')
        };

        let res = await dataService('post', `WorkCenterParallelItemSave/${open?.extraData?.WorkCenterId}`, payload);
        setShowSubmitLoading(false);
        if (res && (res.status === 200 || res.status === 304)) {
            setMessageDialoge({ show: true, type: 'success', message: `Workcennter parallel updated successfully for ${open?.extraData?.Name}` });
            setTimeout(() => {
                onClose();
            }, 1000);
        } else {
            setMessageDialoge({ show: true, type: 'error', message: res.message || 'Error occured please try again later' });
        }
    };

    const handleSelect = (id) => {
        setSelected(prevSelected => {
            if (prevSelected.includes(id)) {
                if (originalSelected.includes(id)) {
                    setWorkCenterDeleteCombinedIds(prev => [...prev, id]);
                } else {
                    setWorkCenterCombinedIds(prev => prev.filter(wId => wId !== id));
                }
                return prevSelected.filter(wId => Number(wId) !== Number(id));
            } else {
                if (!originalSelected.includes(id)) {
                    setWorkCenterCombinedIds(prev => [...prev, id]);
                }
                return [...prevSelected, id];
            }
        });
    };

    return (
        <Dialog
            open={open?.isVisible}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={`bg-blue-600 border-blue-600 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-4 text-xl">
                    <div className="text-gray-100 text-2xl">
                        Workcenter Parallel
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                {isLoading ?
                    <LoadingIcon />
                    :
                    <>
                        <Grid item xs={12} lg={12}>
                            <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                        </Grid>
                        <div className="flex flex-row items-center gap-2 pl-5 pt-5 flex-wrap justify-start">
                            {open?.data?.length > 0 && open?.data.map((ele, index) => {
                                const isSelected = selected.includes(ele.WorkCenterId) || Number(open?.extraData?.WorkCenterId) === Number(ele?.WorkCenterId);
                                return (
                                    <Chip
                                        key={index}
                                        disabled={Number(open?.extraData?.WorkCenterId) === Number(ele?.WorkCenterId)}
                                        className={`cursor-pointer font-bold ${isSelected ? 'bg-blue-500 text-white' : 'bg-white border border-blue-500 text-black'}`}
                                        style={!isSelected ? { border: '2px solid #3b82f6' } : {}}
                                        color="primary"
                                        label={ele?.Name}
                                        onClick={() => handleSelect(ele.WorkCenterId)}
                                    />
                                )
                            })}
                        </div>
                    </>}
            </DialogContent>
            <DialogActions className="justify-end">
                <CustomButton className="bg-[#5e72e4]  border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
                <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Save" loading={showSubmitLoading} onClick={handleSubmit} />
            </DialogActions>
        </Dialog>
    );
};
export default WorkCenterParallelDialog;
