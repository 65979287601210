import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { FormHelperText } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// @mui/icons-material components

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DNDWorkCenters from "components/DrangAndDrop/DNDWorkCenters";
import { CustomButton } from "components/Common/CustomButton";
//import roles from "services/roles";

const useStyles = makeStyles(componentStyles);
let allList = [];
function Product() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id } = useParams();
    const PageTitle = id ? 'Update Product' : 'Add Product';

    // Usef defined states
    const [productModel, setProductModel] = useState({
        Name: ''
    });

    // Usef defined states
    const [productValidateModel, setProductValidateModel] = useState({
        Name: { message: '', touched: false },
        WorkCenters: { message: '', touched: false },
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    const [workCenterList, setWorkCenterList] = useState([]);
    const [selectedWorkCenterList, setSelectedWorkCenterList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await getWorkcenterList();
            if (id) {
                getProductData();
                //await getSelectedWorkcenterList();
            }
        }

        fetchData()
    }, [id])


    useEffect(() => {
        const fetchDataModel = async () => {
            setProductValidateModel({ ...productValidateModel, WorkCenters: { message: selectedWorkCenterList.length > 0 ? '' : 'Select atleast one workcenter', touched: true }, IsValidationError: selectedWorkCenterList.length > 0 ? false : true });
        }

        fetchDataModel()
    }, [selectedWorkCenterList])



    const getWorkcenterList = async () => {
        let res = await dataService('get', 'WorkCenterList', `searchText=&sortBy=Name&sortOrder=asc&page=1&offset=100`);
        if (res.status === 200) {
            if (res?.data?.length > 0) {
                let wList = res?.data.reduce((acc, e) => { acc.push({ WorkCenterId: e.WorkCenterId, Name: e.Name, Order: e.Order }); return acc }, []);
                setWorkCenterList(wList);
                allList = wList;
            }

        }
    }

    // const getSelectedWorkcenterList = async () => {
    //     let res = await dataService('get', 'WorkCenterList', `searchText=&sortBy=Name&sortOrder=asc&page=1&offset=100`);
    //     if (res.status === 200) {
    //         setSelectedWorkCenterList(res?.data);
    //     }
    // }


    const getProductData = async () => {
        let res = await dataService('get', `ProductGet/${id}`, '');
        if (res.status === 200) {
            const { ProductData, ProductWorkCenterData } = res?.data;
            //let ProductData = res?.data?.ProductData;
            if (ProductData) {
                setProductModel({
                    ...productModel,
                    Name: ProductData?.Name
                });
            }

            if (ProductWorkCenterData?.length > 0) {
                let wList = ProductWorkCenterData.reduce((acc, e) => { acc.push({ WorkCenterId: e.WorkCenterId, Name: e.WorkCenterName, Order: e.Order }); return acc }, []);
                wList.sort((a, b) => a.Order - b.Order);
                let allWorkCenterList = allList.filter(ar => !wList.find(rm => (Number(rm.WorkCenterId) === Number(ar.WorkCenterId))));
                setWorkCenterList(allWorkCenterList);
                setSelectedWorkCenterList(wList);
            }

        }
    }

    const handleChange = (id, value) => {
        switch (id) {
            case 'Name':
                setProductModel({ ...productModel, Name: value });
                setProductValidateModel({ ...productValidateModel, Name: { message: checkEmptyValidation(value, 'Name'), touched: true } });
                break;
            default:
                setProductModel({ ...productModel });
                break;
        }
    };

    const handleSubmitProduct = async () => {
        //return false;
        if (!checkValidations()) {
            setShowSubmitLoading(true);

            let sWorkCenterList = [];

            selectedWorkCenterList.forEach((wCenter, index) => {
                sWorkCenterList.push({ WorkCenterId: wCenter.WorkCenterId, Name: wCenter.Name, Order: index + 1 })
            });
            let reqData = {
                Name: productModel.Name,
                ProductWorkCenters: sWorkCenterList
            }
            let res = await dataService('post', `ProductSave/${id ? id : 0}`, reqData);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: id ? 'Product updated successfully!' : 'Product added successfully!' });
                if (!id) {
                    setTimeout(() => {
                        history.push('products')
                    }, 500);
                }

            } else {
                //setOpen(res.message);
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }


    const checkValidations = () => {
        let validateErrors = {
            Name: { message: checkEmptyValidation(productModel.Name, 'Name'), touched: true },
            WorkCenters: { message: selectedWorkCenterList.length > 0 ? '' : 'Select atleast one workcenter', touched: true }
        }
        if (validateErrors.Name.message !== '' || validateErrors.WorkCenters.message) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setProductValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }


    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>
                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <Box
                                    component={Typography}
                                    variant="h6"
                                    color={theme.palette.gray[600] + "!important"}
                                    paddingTop=".25rem"
                                    paddingBottom=".25rem"
                                    fontSize=".75rem!important"
                                    letterSpacing=".04em"
                                    marginBottom="1.5rem!important"
                                    classes={{ root: classes.typographyRootH6 }}
                                >
                                    Product Information
                                </Box>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12} lg={12}>
                                            <FormGroup>
                                                <FormLabel>Name</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    component={Box}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    value={productModel.Name}
                                                    onChange={e => handleChange('Name', e.target.value)}
                                                    error={productValidateModel.Name.message !== '' && productValidateModel.Name.touched}
                                                    helperText={productValidateModel.Name.message !== '' && productValidateModel.Name.touched ? productValidateModel.Name.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} lg={12}>
                                            {id > 0 && selectedWorkCenterList.length > 0 &&
                                                <DNDWorkCenters workcenters={workCenterList} selectedWorkcenters={selectedWorkCenterList} setSelectedWorkcenters={setSelectedWorkCenterList} />
                                            }
                                            {(id === 0 || id === undefined) && workCenterList.length > 0 &&
                                                <DNDWorkCenters workcenters={workCenterList} selectedWorkcenters={selectedWorkCenterList} setSelectedWorkcenters={setSelectedWorkCenterList} />
                                            }
                                            <FormHelperText className="text-red-500">{productValidateModel.WorkCenters.message !== '' && productValidateModel.WorkCenters.touched ? productValidateModel.WorkCenters.message : ''}</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="mt-4">
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitProduct} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Product;