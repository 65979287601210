import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import { FormHelperText, MenuItem } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
//import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomButton } from "components/Common/CustomButton";
//import roles from "services/roles";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles(componentStyles);

function Task() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id } = useParams();
    const PageTitle = id ? 'Update Task' : 'Add Task';

    // Usef defined states
    const [taskModel, setTaskModel] = useState({
        Title: '',
        Description: '',
        Status: 'Assigned',
        DueDate: null,
        UserId: ''
    });

    // Usef defined states
    const [taskValidateModel, setTaskValidateModel] = useState({
        Title: { message: '', touched: false },
        Description: { message: '', touched: false },
        UserId: { message: '', touched: false },
        IsValidationError: false
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [userList, setUserList] = useState([]);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        fetchData()
    }, [id])

    const fetchData = async () => {
        await getUsersList();
        if (id) {
            getTaskData();
        }
    }

    const getUsersList = async () => {
        let res = await dataService('get', 'CommonList', `Query=UsersForTask`);
        if (res.status === 200) {
            setUserList(res?.data);
        }
    }


    const getTaskData = async () => {
        let res = await dataService('get', `TaskGet/${id}`, '');
        if (res.status === 200) {


            let TaskData = res?.data
            if (TaskData) {
                setTaskModel({
                    ...taskModel,
                    Title: TaskData?.Title,
                    Description: TaskData?.Description,
                    DueDate: TaskData?.DueDate,
                    UserId: TaskData?.UserId,
                    Status: TaskData?.Status || 'Assigned'
                });
            }

        }
    }

    const handleChange = (id, value) => {
        switch (id) {
            case 'Title':
                setTaskModel({ ...taskModel, Title: value });
                setTaskValidateModel({ ...taskValidateModel, Title: { message: checkEmptyValidation(value, 'Title'), touched: true } });
                break;
            case 'Description':
                setTaskModel({ ...taskModel, Description: value });
                setTaskValidateModel({ ...taskValidateModel, Description: { message: checkEmptyValidation(value, 'Description'), touched: true } });
                break;
            case 'UserId':
                setTaskModel({ ...taskModel, UserId: value });
                setTaskValidateModel({ ...taskValidateModel, UserId: { message: checkEmptyValidation(value === "0" ? "" : value, 'Assigned To'), touched: true } });
                break;
            case 'Status':
                setTaskModel({ ...taskModel, Status: value });
                //setTaskValidateModel({ ...taskValidateModel, Status: { message: checkEmptyValidation(value === "0" ? "" : value, 'Assigned To'), touched: true } });
                break;
            case 'DueDate':
                setTaskModel({ ...taskModel, DueDate: value });
                //setTaskValidateModel({ ...taskValidateModel, Status: { message: checkEmptyValidation(value === "0" ? "" : value, 'Assigned To'), touched: true } });
                break;
            case 'Default':
                setTaskModel({ ...taskModel, Default: value });
                break;
        }
    };

    const handleSubmitTask = async () => {

        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let reqData = { ...taskModel };
            reqData.UserIds = [taskModel.UserId];
            console.log('reqData: ', reqData);
            let res = await dataService('post', `TaskSave/${id ? id : 0}`, reqData);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: id ? 'Task updated successfully!' : 'Task registered successfully!' });

                if (!id) {
                    setTimeout(() => {
                        history.push('task-list')
                    }, 2000);
                }

            } else {
                //setOpen(res.message);
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            Title: { message: checkEmptyValidation(taskModel.Title, 'Title'), touched: true },
            Description: { message: checkEmptyValidation(taskModel.Description, 'Description'), touched: true },
            UserId: { message: checkEmptyValidation(taskModel.UserId.toString() === "0" ? "" : taskModel.UserId, 'UserId'), touched: true }
        }
        if (validateErrors.Title.message !== '' ||
            validateErrors.UserId.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }

        setTaskValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }


    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>
                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12} lg={4}>
                                            <FormGroup>
                                                <FormLabel>Title</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    component={Box}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    value={taskModel.Title}
                                                    onChange={e => handleChange('Title', e.target.value)}
                                                    error={taskValidateModel.Title.message !== '' && taskValidateModel.Title.touched}
                                                    helperText={taskValidateModel.Title.message !== '' && taskValidateModel.Title.touched ? taskValidateModel.Title.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>

                                        <Grid item xs={12} lg={8}>
                                            <FormGroup>
                                                <FormLabel>Description</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    component={Box}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    multiline
                                                    minRows={4}
                                                    value={taskModel.Description}
                                                    onChange={e => handleChange('Description', e.target.value)}
                                                    error={taskValidateModel.Description.message !== '' && taskValidateModel.Description.touched}
                                                    helperText={taskValidateModel.Description.message !== '' && taskValidateModel.Description.touched ? taskValidateModel.Description.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>

                                        <Grid item xs={12} lg={2}>
                                            <FormGroup>
                                                <FormLabel>Status</FormLabel>

                                                <Select value={taskModel.Status || 'Assigned'} onChange={e => handleChange('Status', e.target.value)}>

                                                    <MenuItem value={'Assigned'}>Assigned</MenuItem>
                                                    <MenuItem value={'UnAssigned'}>UnAssigned</MenuItem>
                                                </Select>
                                            </FormGroup>
                                            {/* <FormHelperText>{taskValidateModel.Status.message !== '' && taskValidateModel.Status.touched ? taskValidateModel.Status.message : ''}</FormHelperText> */}
                                        </Grid>

                                        <Grid item xs={12} lg={3}>
                                            <FormGroup>
                                                <FormLabel>Assigned To</FormLabel>
                                                <Select value={taskModel.UserId ? taskModel.UserId : "0"} onChange={e => handleChange('UserId', e.target.value)}>

                                                    <MenuItem value="0">Select User Assigned To</MenuItem>
                                                    {userList?.map((user, index) => {
                                                        return (<MenuItem value={user.UserId}>{user.Name}</MenuItem>)
                                                    })
                                                    }
                                                </Select>
                                                <FormHelperText className="text-red-500">{taskValidateModel.UserId.message !== '' && taskValidateModel.UserId.touched ? taskValidateModel.UserId.message : ''}</FormHelperText>
                                            </FormGroup>

                                        </Grid>

                                        <Grid item xs={12} lg={3}>
                                            <FormGroup>
                                                <FormLabel>Due Date</FormLabel>
                                                <div className="bg-white custom-datepicker">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            className="w-full"
                                                            name="DueDate"
                                                            format="dd/MM/yyyy"
                                                            value={taskModel?.DueDate ? new Date(taskModel?.DueDate) : null}
                                                            onChange={e => handleChange('DueDate', e)}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitTask} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Task;