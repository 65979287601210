import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import ViewDataDialog from "components/dialoges/ViewDataDialog";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Typography } from "@mui/material";

const useStyles = makeStyles(componentStyles);

const ProductFiles = () => {
  const classes = useStyles();
  const [productFileList, setProductFileList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const [viewDialogData, setViewDialogData] = useState({
    labels: [],
    data: {}
  });
  const columns = [
    { id: 'FIleSize', numeric: false, disablePadding: true, label: 'Size', dataType: 'string' },
    { id: 'UnitPrice', numeric: false, disablePadding: false, label: 'Unit Price (₹)', dataType: 'string' },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    {
      id: 'Patta', numeric: false, disablePadding: false, label: 'Patta', dataType: 'string',
      Component: ({ row }) => {
        return (
          <Typography>{row?.Patta || "-"}</Typography>
        )
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let res = await dataService('get', 'ProductFileList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setProductFileList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    }

    fetchData()
  }, [page, searchText, order, orderBy, offset])



  const handleEdit = (row) => {
    history.push(`update-product-file/${row.ProductFileId}`)

  };

  const viewAction = (row) => {
    //Status, CreatedAt, UpdatedBy, BillingAddress, BillingCity, BillingState, BillingCountryId, BillingCountry, BillingZipCode, BillingEmail, BillingContactPhoneNumber, ShippingAddress, ShippingCity, ShippingState, ShippingCountry, ShippingCountryId, ShippingZipCode, ShippingContactPerson, ShippingEmail, ShippingContactPhoneNumber
    const labels = [
      { Name: 'FIleSize', DisplayName: 'Size' },
      { Name: 'UnitPrice', DisplayName: 'Unit Price' },
      { Name: 'CustomerName', DisplayName: 'Customer' },
      { Name: 'ProductName', DisplayName: 'Product' },
      { Name: 'Patta', DisplayName: 'Patta' },
      { Name: 'CreatedAt', DisplayName: 'Created At', DataType: 'date' },
    ];

    setOpen(true);
    setViewDialogData({
      labels: labels,
      data: row,
      title: `${row?.ProductName} Size Information`
    });
    //history.push(`update-productFile/${row.ProductFileId}`)

  };

  let actionList = [
    { label: 'View', icon: VisibilityIcon, iconColor: 'purple-500', action: viewAction },
    { label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit },
  ]


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >

        <CommonTable
          view="product-file"
          title="File Size List"
          columns={columns}
          rows={productFileList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>
      <ViewDataDialog open={open} onClose={setOpen} viewDialogData={viewDialogData} />
    </>
  );
};

export default ProductFiles;