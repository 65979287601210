import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// @mui/icons-material components
import Autocomplete from '@mui/material/Autocomplete';

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import { FormHelperText, InputAdornment } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Close } from "@mui/icons-material";
import { CustomButton } from "components/Common/CustomButton";
//import roles from "services/roles";

const useStyles = makeStyles(componentStyles);

function ProductFile() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    const [selectedProduct, setSelectedProduct] = useState({});

    //Then inside your component
    const { id } = useParams();
    const PageTitle = id ? `Update Product ${selectedProduct?.Name === undefined ? '' : selectedProduct?.Name}` : `Add Product ${selectedProduct?.Name === undefined ? '' : selectedProduct?.Name}`;

    // Usef defined states
    const [productFileModel, setProductFileModel] = useState({
        ProductId: 1,
        DimX: '',
        DimY: '',
        DimZ: '',
        UnitPrice: '',
        IsCheck: false,
        CustomerId: null,
        Patta : null
    });

    // Usef defined states
    const [productFileValidateModel, setProductFileValidateModel] = useState({
        DimX: { message: '', touched: false },
        DimY: { message: '', touched: false },
        UnitPrice: { message: '', touched: false },
        Patta: { message: '', touched: false },
        ProductId: { message: '', touched: true },
        IsValidationError: false
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [customerList, setCustomerList] = useState([]);
    const [productList, setProductList] = useState([]);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        const fetchData = async() => {
            await getCustomerList();
            await getProductList();
            if (id) {
              await  getProductFileData();
            }
        }

        const getProductFileData = async () => {
            let res = await dataService('get', `ProductFileGet/${id}`, '');
            if (res.status === 200) {
                let ProductFileData = res?.data
                if (ProductFileData) {
                    setProductFileModel({
                        ...productFileModel,
                        ProductId: ProductFileData?.ProductId,
                        DimX: ProductFileData?.DimX,
                        DimY: ProductFileData?.DimY,
                        DimZ: ProductFileData?.DimZ,
                        UnitPrice: ProductFileData?.UnitPrice,
                        Patta: ProductFileData?.Patta,
                        IsCheck: ProductFileData?.IsCheck ? ProductFileData?.IsCheck : false,
                        CustomerId: ProductFileData?.CustomerId
                    });
                }
    
            }
        }

        fetchData()
    }, [id])

   

    const getCustomerList = async () => {
        let res = await dataService('get', 'CommonList', `Query=customers`);
        if (res.status === 200) {
            res?.data?.push({ CustomerId: null, Name: 'All' })
            setCustomerList(res?.data);

        }
    }

    const getProductList = async () => {
        let res = await dataService('get', 'CommonList', `Query=products`);
        if (res.status === 200) {
            setProductList(res?.data);
            if (res?.data?.length > 0) {
                setProductFileModel({ ...productFileModel, ProductId: res?.data[0].ProductId });
            }
            setSelectedProduct(res?.data[0])
        }
    }


   

    const handleChange = (id, value) => {
        switch (id) {
            case 'DimX':
                setProductFileModel({ ...productFileModel, DimX: value });
                setProductFileValidateModel({ ...productFileValidateModel, DimX: { message: checkEmptyValidation(value, `${selectedProduct?.Name} Size`), touched: true } });
                break;
            case 'DimY':
                setProductFileModel({ ...productFileModel, DimY: value });
                setProductFileValidateModel({ ...productFileValidateModel, DimY: { message: checkEmptyValidation(value, `${selectedProduct?.Name} Size`), touched: true } });
                break;
            case 'DimZ':
                setProductFileModel({ ...productFileModel, DimZ: value });
                break;
            case 'UnitPrice':
                setProductFileModel({ ...productFileModel, UnitPrice: value });
                setProductFileValidateModel({ ...productFileValidateModel, UnitPrice: { message: checkEmptyValidation(value, `Unit Price`), touched: true } });
                break;
            case 'Patta':
                setProductFileModel({ ...productFileModel, Patta: value });
                setProductFileValidateModel({ ...productFileValidateModel, Patta: { message: checkEmptyValidation(value, `Patta`), touched: true } });
                break;
            case 'IsCheck':
                setProductFileModel({ ...productFileModel, IsCheck: value, CustomerId: !value ? null : productFileModel.CustomerId });
                break;
            case 'CustomerId':
                setProductFileModel({ ...productFileModel, CustomerId: value?.CustomerId });
                break;
            case 'ProductId':
                setProductFileModel({ ...productFileModel, ProductId: value?.ProductId });
                setProductFileValidateModel({ ...productFileValidateModel, ProductId: { message: checkEmptyValidation(value?.ProductId, 'Product'), touched: true } });
                setSelectedProduct(value);
                break;
            default:
                setProductFileModel({ ...productFileModel });
                break;
        }
    };

    const handleSubmitProductFile = async () => {

        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let res = await dataService('post', `ProductFileSave/${id ? id : 0}`, productFileModel);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: id ? `${selectedProduct?.Name} size updated successfully!` : 'ProductFile registered successfully!' });

                if (!id) {
                    setTimeout(() => {
                        history.push('product-files')
                    }, 2000);
                }

            } else {
                //setOpen(res.message);
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            DimX: { message: checkEmptyValidation(productFileModel.DimX, `${selectedProduct?.Name} Size`), touched: true },
            DimY: { message: checkEmptyValidation(productFileModel.DimY, `${selectedProduct?.Name} Size`), touched: true },
            UnitPrice: { message: checkEmptyValidation(productFileModel.UnitPrice, `Unit Price`), touched: true },
            Patta: { message: checkEmptyValidation(productFileModel.Patta, `Patta`), touched: true },
            ProductId: { message: checkEmptyValidation(productFileModel.ProductId.toString() === "0" ? "" : productFileModel.ProductId, 'Product'), touched: true }
        }
        if (validateErrors.DimX.message !== '' ||
            validateErrors.DimY.message !== '' ||
            validateErrors.UnitPrice.message !== '' ||
            validateErrors.Patta.message !== '' ||
            validateErrors.ProductId.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setProductFileValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }


    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>

                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <Box
                                    component={Typography}
                                    variant="h6"
                                    color={theme.palette.gray[600] + "!important"}
                                    paddingTop=".25rem"
                                    paddingBottom=".25rem"
                                    fontSize=".75rem!important"
                                    letterSpacing=".04em"
                                    marginBottom="1.5rem!important"
                                    classes={{ root: classes.typographyRootH6 }}
                                >
                                    {selectedProduct?.Name} Size Information
                                </Box>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12} lg={4}>
                                            <FormGroup>
                                                <FormLabel>Product</FormLabel>
                                                {productList?.length > 0 &&
                                                    <Autocomplete
                                                        id="tags-outlined"
                                                        value={productList?.find(c => Number(c.ProductId) === Number(productFileModel.ProductId))}
                                                        defaultValue={productList?.find(c => Number(c.ProductId) === Number(productFileModel.ProductId))}
                                                        options={productList}
                                                        onChange={(event, newValue) => {
                                                            handleChange('ProductId', newValue)
                                                        }}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.Name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select Product"
                                                                autoComplete="new-password"
                                                            />
                                                        )}
                                                    />
                                                }
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    {productList.length > 0 && productFileModel?.ProductId > 0 &&
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} lg={12}>
                                                    <FormGroup>
                                                        <div className="flex flex-wrap items-center mb-2">
                                                            <FormLabel className="mr-3 mb-0">{selectedProduct?.Name} Size</FormLabel>
                                                            <FormHelperText className="text-red-500">{(productFileValidateModel.DimX.message !== '' && productFileValidateModel.DimX.touched) || (productFileValidateModel.DimY.message !== '' && productFileValidateModel.DimY.touched) ? `(${selectedProduct?.Name} size is required)` : ''}</FormHelperText>
                                                        </div>
                                                        <div className="flex flex-wrap items-center justify-start ">
                                                            <div className="w-full md:w-auto">
                                                                <TextField
                                                                    variant="filled"
                                                                    component={Box}
                                                                    width="100%"
                                                                    marginBottom="1rem!important"
                                                                    autoComplete="off"
                                                                    value={productFileModel.DimX}
                                                                    onChange={e => handleChange('DimX', e.target.value)}
                                                                    type="number"
                                                                // onInput = {(e) =>{
                                                                //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                                                // }}
                                                                >
                                                                    <Box
                                                                        paddingLeft="0.75rem"
                                                                        paddingRight="0.75rem"
                                                                        component={FilledInput}
                                                                        autoComplete="off"
                                                                        type="number"
                                                                    />
                                                                </TextField>
                                                            </div>

                                                            <div className="mx-2 w-full text-center md:text-left md:w-auto">
                                                                <Close className="w-8 h-8 mb-4" />
                                                            </div>
                                                            <div className="md:mr-4 w-full md:w-auto">
                                                                <TextField
                                                                    variant="filled"
                                                                    component={Box}
                                                                    width="100%"
                                                                    marginBottom="1rem!important"
                                                                    autoComplete="off"
                                                                    value={productFileModel.DimY}
                                                                    type="number"
                                                                    onChange={e => handleChange('DimY', e.target.value)}
                                                                // onInput = {(e) =>{
                                                                //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                                                // }}
                                                                >
                                                                    <Box
                                                                        paddingLeft="0.75rem"
                                                                        paddingRight="0.75rem"
                                                                        component={FilledInput}
                                                                        autoComplete="off"
                                                                        type="number"
                                                                    />
                                                                </TextField>
                                                            </div>
                                                            <div className="w-full md:w-auto">
                                                                <TextField
                                                                    variant="filled"
                                                                    component={Box}
                                                                    width="100%"
                                                                    marginBottom="1rem!important"
                                                                    autoComplete="off"
                                                                    value={productFileModel.DimZ}
                                                                    onChange={e => handleChange('DimZ', e.target.value)}
                                                                    inputProps={{ maxLength: 12 }}
                                                                >
                                                                    <Box
                                                                        paddingLeft="0.75rem"
                                                                        paddingRight="0.75rem"
                                                                        component={FilledInput}
                                                                        autoComplete="off"
                                                                        type="text"
                                                                    />
                                                                </TextField>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} lg={3}>
                                                    <FormGroup>
                                                        <FormLabel>Unit Price</FormLabel>
                                                        <TextField
                                                            variant="filled"
                                                            component={Box}
                                                            width="100%"
                                                            marginBottom="1rem!important"
                                                            autoComplete="off"
                                                            value={productFileModel.UnitPrice}
                                                            type="number"
                                                            onChange={e => handleChange('UnitPrice', e.target.value)}
                                                            error={productFileValidateModel.UnitPrice.message !== '' && productFileValidateModel.UnitPrice.touched}
                                                            helperText={productFileValidateModel.UnitPrice.message !== '' && productFileValidateModel.UnitPrice.touched ? productFileValidateModel.UnitPrice.message : ''}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                                            }}
                                                        // onInput = {(e) =>{
                                                        //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                                        // }}
                                                        >

                                                            <Box
                                                                paddingLeft="0.75rem"
                                                                paddingRight="0.75rem"
                                                                component={FilledInput}
                                                                autoComplete="off"
                                                                type="number"
                                                            />
                                                        </TextField>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <FormGroup>
                                                        <FormLabel>Patta</FormLabel>
                                                        <TextField
                                                            variant="filled"
                                                            component={Box}
                                                            width="100%"
                                                            marginBottom="1rem!important"
                                                            autoComplete="off"
                                                            value={productFileModel.Patta}
                                                            type="number"
                                                            onChange={e => handleChange('Patta', e.target.value)}
                                                            error={productFileValidateModel.Patta.message !== '' && productFileValidateModel.Patta.touched}
                                                            helperText={productFileValidateModel.Patta.message !== '' && productFileValidateModel.Patta.touched ? productFileValidateModel.Patta.message : ''}
                                                        // onInput = {(e) =>{
                                                        //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                                        // }}
                                                        >

                                                            <Box
                                                                paddingLeft="0.75rem"
                                                                paddingRight="0.75rem"
                                                                component={FilledInput}
                                                                autoComplete="off"
                                                                type="number"
                                                            />
                                                        </TextField>
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} lg={6}>
                                                    <FormGroup>
                                                        <FormLabel>For Specific Customer Only?</FormLabel>
                                                        <FormControlLabel className="mt-2 -ml-1" control={<Switch checked={productFileModel.IsCheck} onChange={e => handleChange('IsCheck', e.target.checked)} name="IsCheck" />} />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} lg={6}>
                                                    <FormGroup>
                                                        <FormLabel>Customer</FormLabel>
                                                        {customerList?.length > 0 && productFileModel.IsCheck &&
                                                            <Autocomplete
                                                                id="tags-outlined"
                                                                value={customerList?.find(c => Number(c.CustomerId) === Number(productFileModel.CustomerId))}
                                                                defaultValue={customerList?.find(c => Number(c.CustomerId) === Number(productFileModel.CustomerId))}
                                                                options={customerList}
                                                                onChange={(event, newValue) => {
                                                                    handleChange('CustomerId', newValue)
                                                                }}
                                                                autoHighlight
                                                                getOptionLabel={(option) => option.Name}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder="Select Product"
                                                                        autoComplete="new-password"
                                                                    />
                                                                )}
                                                            />
                                                        }
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} lg={6}>
                                                    <Box
                                                        justifyContent="flex-end"
                                                        display="flex"
                                                        flexWrap="wrap"
                                                    >
                                                        <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitProductFile} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default ProductFile;