import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// @mui/icons-material components
import Autocomplete from '@mui/material/Autocomplete';

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { CustomButton } from "components/Common/CustomButton";
import { FormHelperText } from "@mui/material";
import { currentUser } from "services/user-service";
//import roles from "services/roles";

const statusData = [
    {
        Value : "Active",
        Name : "Active"
    },
    {
        Value : "InActive",
        Name : "InActive"
    }
]

const useStyles = makeStyles(componentStyles);

function HoldReason() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id } = useParams();
    const PageTitle = id ? `Update Hold Reason` : `Add Hold Reason`;

    // Usef defined states
    const [holdReasonModel, setHoleReasonModel] = useState({
        WorkCenterId: null,
        AssignUserId: null,
        ReasonOption: "",
        WorkCenterName : "",
        Status: null,
        UpdatedBy: currentUser()?.UserId
    });

    // Usef defined states
    const [holdReasonValidationSchema, setHoldReasonValidationSchema] = useState({
        WorkCenterId: { message: '', touched: true },
        ReasonOption: { message: '', touched: false },
        Status: { message: '', touched: true },
        IsValidationError: false
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [workCenterList, setWorkCenterList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [statusList] = useState(statusData || []);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        fetchData()
    }, [id])

    const fetchData = async() => {
        if (id) {
            getHoldReason();
        }
        else {
            await getWorkcenterList();
            await getUsersList();
        }
    }

    const getWorkcenterList = async () => {
        let res = await dataService('get', 'WorkCenterList', `searchText=&sortBy=Order&sortOrder=asc&page=1&offset=100`);
        if (res.status === 200) {
            setWorkCenterList(res?.data);

        }
    }

    const getUsersList = async () => {
        let res = await dataService('get', 'CommonList', `Query=UsersForTask`);
        if (res.status === 200) {
            setUsersList(res?.data);
        }
    }

    const getHoldReason = async () => {
        let res = await dataService('get', `HoldReasonGet/${id}`, '');
        if (res.status === 200) {
            let HoldReasonData = res?.data
            if (HoldReasonData) {
                setHoleReasonModel({
                    ...holdReasonModel,
                    ReasonOption: HoldReasonData?.ReasonOption,
                    WorkCenterId: HoldReasonData?.WorkCenterId,
                    WorkCenterName: HoldReasonData?.WorkCenterName,
                    Status: HoldReasonData?.Status,
                    AssignUserId: HoldReasonData?.AssignUserId,
                });
                await getWorkcenterList();
                await getUsersList();
            }

        }
    }

    const handleChange = (id, value) => {
        switch (id) {
            case 'ReasonOption':
                setHoleReasonModel({ ...holdReasonModel, ReasonOption: value });
                setHoldReasonValidationSchema({ ...holdReasonValidationSchema, ReasonOption: { message: checkEmptyValidation(value, `Reason Option Size`), touched: true } });
                break;
            case 'WorkCenterId':
                setHoleReasonModel({ ...holdReasonModel, WorkCenterId: value?.WorkCenterId, WorkCenterName : value?.Name });
                setHoldReasonValidationSchema({ ...holdReasonValidationSchema, WorkCenterId: { message: checkEmptyValidation(value?.WorkCenterId, 'WorkCenter'), touched: true } });
                break;
            case 'Status':
                setHoleReasonModel({ ...holdReasonModel, Status: value?.Value });
                setHoldReasonValidationSchema({ ...holdReasonValidationSchema, Status: { message: checkEmptyValidation(value?.Value, 'Status'), touched: true } });
                break;
            case 'AssignUserId':
                setHoleReasonModel({ ...holdReasonModel, AssignUserId: value ? value.UserId : null });
                break;
        }
    };

    const handleSubmit = async () => {

        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let res = await dataService('post', `HoldReasonSave/${id ? id : 0}`, holdReasonModel);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: id ? `Hold reason updated successfully!` : 'Hold reason created successfully!' });

                if (!id) {
                    setTimeout(() => {
                        history.push('hold-reasons')
                    }, 500);
                }

            } else {
                //setOpen(res.message);
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }

    const checkValidations = () => {

        let validateErrors = {
            ReasonOption: { message: checkEmptyValidation(holdReasonModel.ReasonOption, `Reason Option`), touched: true },
            WorkCenterId: { message: checkEmptyValidation(holdReasonModel.WorkCenterId === "0" ? "" : holdReasonModel.WorkCenterId, 'WorkCenter'), touched: true },
            Status: { message: checkEmptyValidation(holdReasonModel.Status, 'Status'), touched: true }
        }
        if (
            validateErrors.ReasonOption.message !== '' ||
            validateErrors.WorkCenterId.message !== '' || validateErrors.Status.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setHoldReasonValidationSchema(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>

                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Work Center</FormLabel>
                                                {workCenterList?.length > 0 &&
                                                    <Autocomplete
                                                        id="tags-outlined"
                                                        value={workCenterList?.find(c => Number(c.WorkCenterId) === Number(holdReasonModel.WorkCenterId)) || null}
                                                        // defaultValue={workCenterList?.find(c => c.WorkCenterId === holdReasonModel.WorkCenterId)}
                                                        options={workCenterList}
                                                        onChange={(event, newValue) => {
                                                            handleChange('WorkCenterId', newValue)
                                                        }}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.Name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select Work Center"
                                                                autoComplete="new-password"
                                                            />
                                                        )}
                                                    />
                                                }
                                                 <FormHelperText className="text-red-500">{holdReasonValidationSchema.WorkCenterId.message !== '' && holdReasonValidationSchema.WorkCenterId.touched ? holdReasonValidationSchema.WorkCenterId.message : ''}</FormHelperText>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Assign User</FormLabel>
                                                {usersList?.length > 0 &&
                                                    <Autocomplete
                                                        id="tags-outlined"
                                                        value={usersList?.find(c => Number(c.UserId) === Number(holdReasonModel.AssignUserId)) || null}
                                                        defaultValue={usersList?.find(c => Number(c.UserId) === Number(holdReasonModel.AssignUserId))}
                                                        options={usersList}
                                                        onChange={(event, newValue) => {
                                                            handleChange('AssignUserId', newValue)
                                                        }}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.Name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select User"
                                                                autoComplete="new-password"
                                                            />
                                                        )}
                                                    />
                                                }
                                            </FormGroup>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <div className="flex flex-wrap items-center mb-2">
                                                    <FormLabel className="mr-3 mb-0">Reason Option</FormLabel>
                                                </div>
                                                <div className="flex flex-wrap items-center justify-start ">
                                                    <div className="w-full ">
                                                        <TextField
                                                            variant="filled"
                                                            component={Box}
                                                            width="100%"
                                                            marginBottom="1rem!important"
                                                            autoComplete="off"
                                                            value={holdReasonModel.ReasonOption}
                                                            // className={classes1.errorTransform}
                                                            onChange={e => handleChange('ReasonOption', e.target.value)}
                                                            error={holdReasonValidationSchema.ReasonOption.message !== '' && holdReasonValidationSchema.ReasonOption.touched}
                                                            helperText={holdReasonValidationSchema.ReasonOption.message !== '' && holdReasonValidationSchema.ReasonOption.touched ? holdReasonValidationSchema.ReasonOption.message : ''}
                                                        // inputProps={{ maxLength: 12 }}
                                                        >
                                                            <Box
                                                                paddingLeft="0.75rem"
                                                                paddingRight="0.75rem"
                                                                component={FilledInput}
                                                                autoComplete="off"
                                                                type="text"
                                                            />
                                                        </TextField>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Status</FormLabel>
                                                {workCenterList?.length > 0 &&
                                                    <Autocomplete
                                                        id="tags-outlined"
                                                        value={statusData?.find(c => c.Value === holdReasonModel.Status) || null}
                                                        defaultValue={statusData?.find(c => c.Value === holdReasonModel.Status)}
                                                        options={statusList}
                                                        onChange={(event, newValue) => {    
                                                            handleChange('Status', newValue)
                                                        }}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.Name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select Status"
                                                            />
                                                        )}
                                                    />
                                                        }
                                                     <FormHelperText className="text-red-500">{holdReasonValidationSchema.Status.message !== '' && holdReasonValidationSchema.Status.touched ? holdReasonValidationSchema.Status.message : ''}</FormHelperText>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>

                                    
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmit} />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default HoldReason;