import React from "react";
import { Box, Button, Checkbox, DialogContent, Tooltip } from '@mui/material';
import { VpnKey } from '@mui/icons-material';
import moment from "moment";
import { currentUser } from "services/user-service";

function ViewOrderHeader(props) {
    const { orderData, handleShowPrice, showPrice } = props;

    return (
        <DialogContent>
            <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">General Information</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Product</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.ProductName}</dd>
                            <dt className="text-sm font-medium text-gray-500">Customer</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.CustomerName}</dd>
                            <dt className="text-sm font-medium text-gray-500">Client</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Client}</dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Order Date</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{moment(orderData?.Date).format('Do MMMM YYYY')}</dd>
                            <dt className="text-sm font-medium text-gray-500">Delivery Date</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{moment(orderData?.DeliveryDate).format('Do MMMM YYYY')}</dd>
                            <dt className="text-sm font-medium text-gray-500">Quantity</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Quantity}</dd>
                        </div>

                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">File Name</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.FileName}</dd>
                            <dt className="text-sm font-medium text-gray-500">File Size</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{orderData?.FileSize}</dd>
                            <dt className="text-sm font-medium text-gray-500">IsRepeat?</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.IsRepeat === 0 || null ? 'No' : 'Yes'}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-yellow-300 border-yellow-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Party Fabric</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Waterfall</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBWaterfall}</dd>
                            <dt className="text-sm font-medium text-gray-500">Waterfall Meter</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBWaterfallMtr ? orderData?.PBWaterfallMtr : '-'}</dd>
                            <dt className="text-sm font-medium text-gray-500">Main</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-yellow-300">{orderData?.PBMain}</dd>
                            <dt className="text-sm font-medium text-gray-500">Main Meter</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBMainMtr}</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Total</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBTotal}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.PBRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-blue-300 border-blue-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Cutting</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-7 sm:gap-1 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500"><strong>Waterfall</strong> Patta</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingPatta}</dd>
                            <dt className="text-sm font-medium text-gray-500">Tukda</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingTukda}</dd>
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-blue-300">Table   {orderData?.CuttingTable}</dt>
                            <dt className="text-sm font-medium text-gray-500">Fold</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingFold}</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-7 sm:gap-2 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500"><strong>Main</strong> Patta</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingMainPatta}</dd>
                            <dt className="text-sm font-medium text-gray-500">Tukda</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{orderData?.CuttingMainTukda}</dd>
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-blue-300">Table   {orderData?.CuttingMainTable}</dt>
                            <dt className="text-sm font-medium text-gray-500">Fold</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingMainFold}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className=""><Checkbox checked={orderData?.TagZigZag === 1 ? true : false} name="TagZigZag" color="primary" />ZigZag</span>
                            <span className=""><Checkbox checked={orderData?.TagInterlock === 1 ? true : false} name="TagInterlock" color="primary" />Interlock</span>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.CuttingRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="bg-red-300 border-red-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Binding</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-8">
                            <dt className="text-sm font-medium text-gray-500">Fabric Color</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsFabricColor}</dd>
                            <dt className="text-sm font-medium text-gray-500">Kaan</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsKaan}</dd>
                            <dt className="text-sm font-medium text-gray-500">Patti</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-red-300">{orderData?.BindingsPatti}</dd>
                            <dt className="text-sm font-medium text-gray-500">Hook</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.BindingsHook}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className=""><Checkbox checked={orderData?.BindingsDieCut === 1 ? true : false} name="TagZigZag" color="primary" />Die Cut</span>
                            <span className=""><Checkbox checked={orderData?.BindingsFoil === 1 ? true : false} name="TagInterlock" color="primary" />Foil</span>
                            <span className=""><Checkbox checked={orderData?.BindingsTagPrinted === 1 ? true : false} name="TagInterlock" color="primary" />Tag Printed</span>
                            <span className=""><Checkbox checked={orderData?.BindingsMDF === 1 ? true : false} name="TagInterlock" color="primary" />MDF</span>
                            <span className=""><Checkbox checked={orderData?.BindingsCappaBoard === 1 ? true : false} name="TagInterlock" color="primary" />Cappa Board</span>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.BindingsRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-green-300 border-green-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Tagging</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className="md:border-r border-green-300 pr-3"> <Checkbox checked={orderData?.TagLogo === 1 ? true : false} name="TagLogo" color="primary" />Logo</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagCollection === 1 ? true : false} name="TagCollection" color="primary" />Collection</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagDesign === 1 ? true : false} name="TagDesign" color="primary" />Design</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagShed === 1 ? true : false} name="TagShed" color="primary" />Shed</span>
                            <span className="md:border-r border-green-300 pr-3"> <Checkbox checked={orderData?.TagInstruction === 1 ? true : false} name="TagInstruction" color="primary" />Instruction</span>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagSymbol === 1 ? true : false} name="TagSymbol" color="primary" />Symbol</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagSrNo === 1 ? true : false} name="TagDesign" color="primary" />Sr No.</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TagWidth === 1 ? true : false} name="Width" color="primary" />Width ({orderData?.TagWidthValue})</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TaggingRubs === 1 ? true : false} name="Width" color="primary" />Rubs ({orderData?.TaggingRubsValue})</span>
                            <span className="md:border-r border-green-300 pr-3"><Checkbox checked={orderData?.TaggingGSM === 1 ? true : false} name="Width" color="primary" />Weight ({orderData?.TaggingGSMValue})</span>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.TagRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-indigo-300 border-indigo-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Photo Making</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Photo Size</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-indigo-300">{orderData?.PhotosSize}</dd>


                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">
                                Page
                                <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosPage?.includes(1) ? true : false} name="PhotosPage" color="primary" />1</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(2) ? true : false} name="PhotosPage" color="primary" />2</span>
                                <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosPage?.includes(3) ? true : false} name="PhotosPage" color="primary" />3</span>
                                <span><Checkbox checked={orderData?.PhotosPage?.includes(4) ? true : false} name="PhotosPage" color="primary" />4</span>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <span className="md:border-r border-indigo-300 pr-3"> <Checkbox checked={orderData?.PhotosLamination === 1 ? true : false} name="PhotosLamination" color="primary" />Lamination</span>
                            <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosColdPage === 1 ? true : false} name="PhotosColdPage" color="primary" />ColdPage</span>
                            <span className="md:border-r border-indigo-300 pr-3"><Checkbox checked={orderData?.PhotosMatt === 1 ? true : false} name="PhotosMatt" color="primary" />Matt</span>
                            <span className=""><Checkbox checked={orderData?.PhotosGlossy === 1 ? true : false} name="PhotosGlossy" color="primary" />Glossy</span>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.PhotosRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-purple-300 border-purple-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Screen Printing</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Top: {orderData?.ScreenTop}</dt>
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Bottom: {orderData?.ScreenBottom}</dt>
                            <dt className="text-sm font-medium text-gray-500 md:border-r border-purple-300">Patti: {orderData?.ScreenPatti}</dt>
                            <dt className="text-sm font-medium text-gray-500">Printed: {orderData?.ScreenPrinted} <Checkbox checked={orderData?.ScreenPrintedBit === 1 ? true : false} name="PhotosGlossy" color="primary" /></dt>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.ScreenRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-pink-300 border-pink-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Fittings</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Main Piece</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.FittingMainPiece}</dd>
                        </div>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{orderData?.FittingRemarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-red-500 border-red-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>


            <div className="bg-blue-500 border-blue-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Remarks</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Remarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            {currentUser()?.Role === 'SUPER_ADMIN' &&
                <div className="bg-purple-500 border-purple-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                    Price: {showPrice ? orderData?.UnitPrice : '****'}

                                    {!showPrice &&
                                        <Tooltip title="Verify to view price" placement="top" arrow>
                                            <Box className="bg-blue-500 text-white ml-4" aria-controls="simple-menu-1" aria-haspopup="true" onClick={handleShowPrice} size="small" component={Button}  >
                                                <VpnKey onClick={handleShowPrice} className="w-5 h-auto" />
                                            </Box>
                                        </Tooltip>
                                    }

                                </dd>

                            </div>
                        </dl>
                    </div>
                </div>
            }
        </DialogContent>
    );
};
export default ViewOrderHeader;