import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import {Alert as MuiAlert} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { updateSnackBarStore, store } from "../../generic/store";
import { useDispatch, useSelector } from 'react-redux';

export const showMessageSnackBar = (title, message, type, open, setOpen) => {
    store?.dispatch(updateSnackBarStore({
        title,
        message,
        type,
        open,
        setOpen
    }));
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function MessageSnackBar() {    
    const {  message, type, open } = useSelector(state => state?.snackBarStore?.snackbarData);
  // const [open, setOpen, type, message ] = props
  const classes = useStyles();
  const dispatch = useDispatch(); // to update store values

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    //setOpen(false);

    dispatch(updateSnackBarStore({
        title: '',
        message: '',
        type: '',
        open: false,
        setOpen: undefined
    }))
  };

  return (
    <div className={classes.root}>  
      <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={5000} onClose={handleClose}>
          {/* {(() => {
              switch(type) {
                case 'error':
                    return <Alert severity="error">This is an error message!</Alert>;
                default:
                 return 'foo';
                }
          })} */}
          {/* type must be from following list -- success, warning, info, success  */}

        <MuiAlert variant='filled' onClose={handleClose} severity={type} >
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}