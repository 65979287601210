import { DialogContent, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuBookOutlined, Close, CardGiftcardOutlined, BorderAllOutlined, Map } from '@mui/icons-material';
import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

// core components
import CardStats from "../Cards/CardStats.js";


import dashboardStyles from "assets/theme/views/admin/dashboard.js";
const useStyles = makeStyles(dashboardStyles);


function CardStatsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { onClose, open, message, viewDialogData, rows } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" open={open}>
        <div className={`bg-blue-600 border-blue-600 border-2 shadow flex flex-wrap items-center justify-between`}>
            <DialogTitle className="pb-0 w-full" >
                <div className="sm:grid sm:grid-cols-2 items-center">
                    <div className="text-gray-100 mb-2">
                        <span className="text-2xl">{viewDialogData.title}</span>
                    </div>
                </div>

            </DialogTitle>
            <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-5" onClick={handleClose} />
        </div>
        <DialogContent className="bg-gradient-to-r from-indigo-50 to-indigo-100">

            <Grid container justifyContent='center'>
                {rows?.map((rowData, index) => {
                    let icon =
                        rowData?.Name === 'File' ? MenuBookOutlined :
                            rowData?.Name === 'Header' ? CardGiftcardOutlined :
                                rowData?.Name === 'Shade' ? BorderAllOutlined :
                                    rowData?.Name === 'E-Catalogue' ? Map :
                                        MenuBookOutlined;

                    return (
                        <Grid item xl={3} lg={6} xs={12}>
                            <CardStats
                                subtitle={`${rowData?.Name}`}
                                title={`${rowData?.QuantityCount ? `Quantity - ${rowData?.QuantityCount}` : ''}`}
                                footer={`Records - ${rowData?.DataCount}`}
                                icon={icon}
                                color="bgInfo"
                            />
                        </Grid>)
                })
                }
            </Grid>
        </DialogContent>
    </Dialog>
);
};
export default CardStatsDialog;