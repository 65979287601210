import React, { Component } from 'react';
import Grid from "@mui/material/Grid";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, CardContent, CardHeader, FormLabel } from '@mui/material';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: grid
});

class DNDWorkCenters extends Component {
    constructor(props) { //// It's initialize objects
        super(props)

        
        //======================= This is common state value and control the component =======================// 
        this.state = {
            items: props.workcenters,
            selected: props.selectedWorkcenters, // show dynamic message in all pages
            setSelectedWorkcenters: props.setSelectedWorkcenters
        }

        //=========================================================//
    }
    // state = {
    //     items: getItems(10),
    //     selected: getItems(5, 10)
    // };

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (Number(source.droppableId) === Number(destination.droppableId)) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (Number(source.droppableId) === 'droppable2') {
                state = { selected: items };
                this.props.setSelectedWorkcenters(items);
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2,
            });

            //this.setState({...state, items: result.droppable, selected: result.droppable2, selectedWorkcenters:  result.droppable2});
            this.props.setSelectedWorkcenters(result.droppable2);
        }
    };

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Card>
                            <CardHeader className="bg-blue-500" subheader={<FormLabel className="text-white">Workcenters</FormLabel>} />
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <CardContent
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}>
                                        {this.state.items.map((item, index) => (
                                            <Draggable
                                                key={item.WorkCenterId}
                                                draggableId={item?.WorkCenterId?.toString()}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                    className="shadow bg-gray-100" 
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        {item.Name}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </CardContent>
                                )}
                            </Droppable>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card>
                            <CardHeader className="bg-blue-500" subheader={<FormLabel className="text-white">Selected Workcenters</FormLabel>} />
                            <Droppable droppableId="droppable2">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}>
                                        {this.state.selected.map((item, index) => (
                                            <Draggable
                                                key={item.WorkCenterId}
                                                draggableId={item?.WorkCenterId?.toString()}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                    className="shadow bg-gray-100"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        {item.Name}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Card>
                    </Grid>
                </Grid>
            </DragDropContext>
        );
    }
}

export default DNDWorkCenters;