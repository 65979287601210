import { DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { dataService } from 'services/data-service';
import { currentUser } from 'services/user-service';

function ViewGoodsEntryDialog(props) {
    const { onClose, open, GoodsEntryId } = props;

    const [goodsEntryModel, setGoodsEntryModel] = useState(null);
    const [relaodDocList] = useState(false);
    

    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
      fetchData()
    }, [relaodDocList]);

    const fetchData = async() => {
        if (GoodsEntryId) {
            await getGoodsEntryData();
        }
    }

    const getGoodsEntryData = async () => {
        let res = await dataService('get', `CustomerGoodsEntryGet`, `GoodsEntryId=${GoodsEntryId}&GoodsCustomerId=${currentUser()?.CustomerId}`);
        if (res.status === 200) {
            setGoodsEntryModel(res.data)
        }
    }

    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="xl" aria-labelledby="simple-dialog-title" open={open}>
            <div className="flex flex-wrap items-center justify-between">
                <DialogTitle id="form-dialog-title" className="pb-1 w-full">
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div>
                            <span className="text-2xl">Goods Entry Information</span><br />
                            <div className="text-lg text-gray-500 "> Goods Entry <span style={{ color: '#f68220' }}>#{goodsEntryModel?.GoodsEntryNumber}</span></div>
                        </div>
                        <div className="text-gray-900 flex flex-wrap justify-end">
                            <div className="mr-10">
                                <span className="text-sm flex flex-wrap justify-start items-center sm:grid sm:grid-cols-2">
                                    <strong>Customer: </strong>
                                    <span className="px-2 py-1 text-sm font-bold bg-blue-300 rounded ml-1">{currentUser()?.Name}</span>
                                </span>
                            </div>

                        </div>
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer absolute right-0 top-2" onClick={handleClose} />
            </div>

            <DialogContent>
                <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">General Information</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">LRNumber</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{goodsEntryModel?.GoodsEntryData?.LRNumbers}</dd>
                                <dt className="text-sm font-medium text-gray-500">Bundles</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{goodsEntryModel?.GoodsEntryData.Bundles}</dd>
                                <dt className="text-sm font-medium text-gray-500">Parcel</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{goodsEntryModel?.GoodsEntryData?.Parcel}</dd>
                            </div>

                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500 col-span-1">TagDetails</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300 col-span-1">{goodsEntryModel?.GoodsEntryData?.TagDetails}</dd>
                                <dt className="text-sm font-medium text-gray-500 col-span-1">Created Date</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-2">{moment(goodsEntryModel?.CreatedAt).format('Do MMMM YYYY')}</dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-12 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Transport</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-11">{goodsEntryModel?.GoodsEntryData?.Transport}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="bg-indigo-300 border-indigo-300 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-3 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">File List</h3>
                    </div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className={`px-4 py-5 sm:grid sm:grid-cols-5 sm:px-6 bg-blue-100`}>
                                    <dt className="text-sm text-blue-700 font-bold">File Name</dt>
                                    <dt className="text-sm text-blue-700 font-bold">Product</dt>
                                    <dt className="text-sm text-blue-700 font-bold">File Size</dt>
                                    <dt className="text-sm text-blue-700 font-bold">Client</dt>
                                    <dt className="text-sm text-blue-700 font-bold">Quantity</dt>
                                </div>
                                {goodsEntryModel?.GoodsEntryFiles?.map((label, index) => (
                                    <div key={index} className={`px-4 py-5 sm:grid sm:grid-cols-5 sm:px-6 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                        <dt className="text-sm font-medium text-gray-500">{label.FileNames}</dt>
                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                            {label?.ProductName}
                                        </dt>
                                       <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                            {label?.FileSize}
                                        </dt>
                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                            {label?.Client}
                                        </dt>
                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                            {label?.Quantity}
                                        </dt>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default ViewGoodsEntryDialog;