/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// core components
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { dataService } from 'services/data-service';
import { useHistory, useParams } from 'react-router-dom';
import { updatePreviousLocations } from 'generic/store';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import SalesReportTable from './components/SalesReportTable';
import { chartOptions } from 'variables/charts';
import { parseOptions } from 'variables/charts';
import { Chart } from 'chart.js';
import { Close } from '@mui/icons-material';
import dashboardStyles from 'assets/theme/views/admin/dashboard';
import { Bar, Line } from 'react-chartjs-2';
import { chartExample1 } from 'variables/charts';
import { chartExample2 } from 'variables/charts';
import { CustomButton } from 'components/Common/CustomButton';

const useStyles = makeStyles(componentStyles);
const useDashboardStyles = makeStyles(dashboardStyles);

const CustomerOrderReport = () => {
  const dispatch = useDispatch(); // to update store values

  const { customerId } = useParams();
  const classes = useStyles();
  const dashboardClasses = useDashboardStyles();

  const [customerList, setCustomerList] = useState([]);
  const history = useHistory();
  const [year, setYear] = useState(moment().year());
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [lineChartReportType] = useState('quantity');
  const [lineChartReportData, setLineCharReportData] = useState(null);

  const yearOptions = Array.from({ length: 10 }, (value, index) => 2022 + index);
  const theme = useTheme();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const columns = [
    {
      id: 'Month',
      numeric: false,
      disablePadding: true,
      label: 'Month',
      dataType: 'string',
      Component: ({ row }) => (
        <div>
          <Typography
            style={{
              cursor: row?.Quantity ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              if (row?.Quantity) {
                history.push(`/admin/report/daily-customer-order/${customerId}/${row.Year}/${moment()
                  .month(row?.Month)
                  .format('M')}`);
              }
            }}
          >
            {row?.Month}
          </Typography>
        </div>
      ),
    },
   
    {
      id: 'Quantity',
      numeric: false,
      disablePadding: true,
      label: 'Quantity',
      dataType: 'string',
    },
    {
      id: 'Amount',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
      dataType: 'string',
    },
  
  ];

  const viewAction = (row) => {
    history.push(`/admin/report/daily-customer-order/${customerId}/${row.Year}/${moment()
      .month(row?.Month)
      .format('M')}`);
  };

  useEffect(() => {
    fetchData()
  }, [ year]);

  const fetchData = async() => {
    dispatch(updatePreviousLocations(null));
    setIsLoading(true);
    let res = await dataService(
      'get',
      'CustomerReportSales',
      `Year=${year}&CustomerId=${customerId}`
      
    );
    setCustomerList(res?.data);
    generateMonthlyChartReport(res?.data?.data);
    setIsLoading(false);
  }

  const generateMonthlyChartReport = (monthlyReport) => {
    monthlyReport.sort(function (a, b) {
      return a.Month - b.Month;
    });
    let rptDataset = null;

    rptDataset = {
      label: 'Quantity',
      data: monthlyReport.map((rpt) => {
        return rpt.Quantity;
      }),
    };
    let reportData = {
      labels: monthlyReport.map((rpt) => {
        return rpt.Month;
      }),
      datasets: [rptDataset],
    };
    setLineCharReportData(reportData);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
          <SalesReportTable
          title={customerList?.CustomerName}
          columns={columns}
          rows={customerList?.data}
          actions={[]}
          loading={isLoading}
          selectedYear={year}
          setSelectedYear={setYear}
          viewAction={viewAction}
          reportType={'MONTHLY'}
          isChartVisible={true}
          setIsChartOpen={setIsOpen}
        />
       
       <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
        >
          <div
            style={{
              background: theme.palette.info.main,
              borderColor: theme.palette.info.main,
            }}
            className={`bg-blue-600 border-blue-600 border-2 shadow flex flex-wrap items-center justify-between`}
          >
            <DialogTitle className="pb-0 w-full">
              <div className="sm:grid sm:grid-cols-2 items-center">
                <div className="text-gray-100 mb-2">
                  <span className="text-2xl ">Yearly Sales Report</span>
                  <br />
                </div>
              </div>
            </DialogTitle>
            <Close
              className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2"
              onClick={handleClose}
            />
          </div>

          <DialogContent className="px-4 py-2">
            <Grid container>
              <Grid
                item
                xs={12}
                xl={7}
                component={Box}
                marginBottom="3rem!important"
                classes={{ root: dashboardClasses.gridItemRoot }}
              >
                <Card
                  classes={{
                    root:
                      dashboardClasses.cardRoot +
                      ' ' +
                      dashboardClasses.cardRootBgGradient,
                  }}
                >
                  <CardHeader
                    subheader={
                      <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs="auto">
                          <Box
                            component={Typography}
                            variant="h6"
                            letterSpacing=".0625rem"
                            marginBottom=".25rem!important"
                            className={dashboardClasses.textUppercase}
                          >
                            <ButtonGroup
                              size="small"
                              disableElevation
                              variant="contained"
                              color="primary"
                            >
                              <Button
                                classes={{
                                  root:
                                    lineChartReportType === 'quantity'
                                      ? ''
                                      : dashboardClasses.buttonRootUnselected,
                                }}
                              >
                                Quantity
                              </Button>
                            </ButtonGroup>
                          </Box>
                          <Box
                            component={Typography}
                            variant="h2"
                            marginBottom="0!important"
                          >
                            <Box
                              component="span"
                              color={theme.palette.info.main}
                            >
                              <span className="capitalize">
                                {lineChartReportType}
                              </span>{' '}
                              Report
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Select
                          value={year}
                          onChange={(event) => setYear(event.target.value)}
                          displayEmpty
                          className={`${dashboardClasses.selectEmpty} mr-2`}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {yearOptions.map((year) => {
                            return <MenuItem value={year}>{year}</MenuItem>
                          })}
                        </Select>
                      </Box>
                    </Grid>
                      </Grid>
                    }
                    classes={{ root: dashboardClasses.cardHeaderRoot }}
                  ></CardHeader>
                  <CardContent>
                    <Box position="relative" height="350px">
                      {lineChartReportData && (
                        <Line
                          data={lineChartReportData}
                          options={chartExample1.options}
                          getDatasetAtEvent={(e) => console.log(e)}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} xl={5}>
                <Card classes={{ root: dashboardClasses.cardRoot }}>
                  <CardHeader
                    title={
                      <Box component="span" color={theme.palette.gray[600]}>
                        Performane
                      </Box>
                    }
                    subheader={
                      <span className="capitalize">Total Quantity</span>
                    }
                    classes={{ root: dashboardClasses.cardHeaderRoot }}
                    titleTypographyProps={{
                      component: Box,
                      variant: 'h6',
                      letterSpacing: '.0625rem',
                      marginBottom: '.25rem!important',
                      classes: {
                        root: dashboardClasses.textUppercase,
                      },
                    }}
                    subheaderTypographyProps={{
                      component: Box,
                      variant: 'h2',
                      marginBottom: '0!important',
                      color: 'initial',
                    }}
                  ></CardHeader>
                  <CardContent>
                    <Box position="relative" height="350px">
                      {lineChartReportData && (
                        <Bar
                          data={lineChartReportData}
                          options={chartExample2.options}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButton className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" label="Cancel" onClick={handleClose} />
          </DialogActions>
        </Dialog>

      </Container>
    </>
  );
};

export default CustomerOrderReport;
