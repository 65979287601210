import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { Grid, TextField, Box, FilledInput, FormLabel, FormGroup, FormHelperText } from '@mui/material';
import { checkEmptyValidation } from 'services/validation-service';
import { CustomButton } from "components/Common/CustomButton";
import { Autocomplete } from '@mui/material';
import { dataService } from 'services/data-service';

function DeliveryFileQuantity(props) {
    const { onClose, open, fileData, addFileQuantityHandle, isEdit, productList, customerId } = props;
    const [dataModel, setDataModel] = useState({
        Quantity: fileData?.Quantity || null,
        ProductId: fileData?.ProductId || null,
        ProductFileId:  fileData?.ProductFileId || null,
        Client:  fileData?.Client || null,
    });

    // Usef defined states
    const [goodsEntryFileValidateModel, setGoodsEntryFileValidateModel] = useState({
        ProductId: { message: '', touched: false },
        ProductFileId: { message: '', touched: false },
        Client: { message: '', touched: false },
        Quantity: { message: '', touched: false },
        IsValidationError: false
    });

    const [productFileList, setProductFileList] = useState([]);

    const handleClose = () => {
        ///setConfirmData(null)
        onClose();
    };

    useEffect (() => {
        const fetchData = async() => {
            if(fileData?.ProductId){
                await getProductFileList(customerId, dataModel?.ProductId);
            }else{
                await getProductFileList(customerId, 1);
            }
        }
        fetchData()
    }, [])

    const handleSubmit = () => {
        if (!checkValidations()) {
            let selectedProduct = productList?.find(c => Number(c.ProductId) === Number(dataModel.ProductId));
            let selectedProductFile = productFileList?.find(c => Number(c.ProductFileId) === Number(dataModel.ProductFileId));

            let reqData = { ...fileData, 
                Quantity: dataModel?.Quantity,
                ProductName: selectedProduct ? selectedProduct?.Name : '', // dataModel?.ProductId,
                FileSize: selectedProductFile?.FileSize, //dataModel?.ProductFileId,
                ProductId: dataModel?.ProductId,
                ProductFileId: dataModel?.ProductFileId,
                Client: dataModel?.Client
            }
            addFileQuantityHandle(reqData);
        }
    }

    const handleChange = (evt, type, checkValidation) => {
        if (evt?.key === 'Enter') {
            handleSubmit();
        } else {
            setDataModel({ ...dataModel, [evt.target.name]: evt.target.value });
            setGoodsEntryFileValidateModel({ ...goodsEntryFileValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
        }
    }

    const handleSelectChange = (id, value) => {
        switch (id) {
            case 'ProductId':
                setDataModel({ ...dataModel, ProductId: value?.ProductId });
                getProductFileList(customerId, value?.ProductId);
                setGoodsEntryFileValidateModel({ ...goodsEntryFileValidateModel, 'ProductId': { message: checkEmptyValidation(value?.ProductId, 'Product'), touched: true } });
                break;
            case 'ProductFileId':
                setDataModel({ ...dataModel, ProductFileId: value?.ProductFileId });
                setGoodsEntryFileValidateModel({ ...goodsEntryFileValidateModel, 'ProductFileId': { message: checkEmptyValidation(value?.ProductFileId, 'File Size'), touched: true } });
                break;
        }
    }

    const checkValidations = () => {

        let validateErrors = {
            ProductId: { message: checkEmptyValidation(Number(dataModel.ProductId) === 0 || Number(dataModel.ProductId) === null ? '' : dataModel.ProductId, 'Product'), touched: true },
            ProductFileId: { message: checkEmptyValidation(dataModel.ProductFileId, 'File Size'), touched: true },
            Client: { message: checkEmptyValidation(dataModel.Client === '' ? '' : dataModel.Client, 'Client'), touched: true },
            Quantity: { message: checkEmptyValidation(dataModel.Quantity === '' ? '' : dataModel.Quantity, 'Quantity'), touched: true },
        }
        if (validateErrors.ProductId.message !== '' ||
            validateErrors.ProductFileId.message !== '' ||
            validateErrors.Client.message !== '' ||
            validateErrors.Quantity.message !== '') {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setGoodsEntryFileValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    const getProductFileList = async (customerIdVal, productIdVal) => {
        let res = await dataService('get', 'CommonList', `Query=productFilesByCustomers&Param1=${customerIdVal}&Param2=${productIdVal}`);
        if (res.status === 200) {

            if (res?.data?.length > 0) {
                let allProductFiles =  res?.data;
                allProductFiles?.push({ ProductFileId: null, FileSize: 'None' })
                setProductFileList(allProductFiles);
            }
            else {
                //setProductFileList([{ ProductFileId: null, FileSize: 'None' }]);
            }

        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={open}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <div className={`bg-green-500 border-green-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-4 text-xl">
                    <div className="text-white text-2xl">
                        Add Quantity For {fileData?.Name}
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormLabel>Product</FormLabel>
                            {productList?.length > 0 &&
                                <Autocomplete
                                    id="tags-outlined"
                                    value={productList?.find(c => Number(c.ProductId) === Number(dataModel.ProductId))}
                                    defaultValue={productList?.find(c => Number(c.ProductId) === Number(dataModel.ProductId))}
                                    options={productList}
                                    onChange={(event, newValue) => {
                                        handleSelectChange('ProductId', newValue)
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.Name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Product"
                                            autoComplete="new-password"
                                        />
                                    )}
                                />
                            }
                            <FormHelperText className="text-red-500">{goodsEntryFileValidateModel.ProductId.message !== '' && goodsEntryFileValidateModel.ProductId.touched ? goodsEntryFileValidateModel.ProductId.message : ''}</FormHelperText>
                        </FormGroup>

                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormLabel>File Size</FormLabel>
                            {productFileList?.length > 0 &&
                                <Autocomplete
                                    id="tags-outlined"
                                    value={productFileList?.find(c => Number(c.ProductFileId) === Number(dataModel.ProductFileId))}
                                    defaultValue={productFileList?.find(c => Number(c.ProductFileId) === Number(dataModel.ProductFileId))}
                                    options={productFileList}
                                    onChange={(event, newValue) => {
                                        handleSelectChange('ProductFileId', newValue)
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.FileSize}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Product"
                                            autoComplete="new-password"
                                        />
                                    )}
                                />
                            }
                            <FormHelperText className="text-red-500">{goodsEntryFileValidateModel.ProductFileId.message !== '' && goodsEntryFileValidateModel.ProductFileId.touched ? goodsEntryFileValidateModel.ProductFileId.message : ''}</FormHelperText>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormLabel>Client</FormLabel>
                            <TextField
                                variant="filled"
                                name="Client"
                                component={Box}
                                width="100%"
                                marginBottom="1rem!important"
                                value={dataModel.Client}
                                autoComplete="off"
                                onChange={e => handleChange(e)}
                                error={goodsEntryFileValidateModel.Client.message !== '' && goodsEntryFileValidateModel.Client.touched}
                                helperText={goodsEntryFileValidateModel.Client.message !== '' && goodsEntryFileValidateModel.Client.touched ? goodsEntryFileValidateModel.Client.message : ''}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormLabel>Quantity</FormLabel>
                            <TextField
                                variant="filled"
                                name="Quantity"
                                component={Box}
                                type="number"
                                width="100%"
                                marginBottom="1rem!important"
                                autoComplete="off"
                                value={dataModel.Quantity}
                                onChange={e => handleChange(e, null, true)}
                                onKeyDown={(e) => { if (e?.key === 'Enter') handleSubmit() }}
                                error={goodsEntryFileValidateModel.Quantity.message !== '' && goodsEntryFileValidateModel.Quantity.touched}
                                helperText={goodsEntryFileValidateModel.Quantity.message !== '' && goodsEntryFileValidateModel.Quantity.touched ? goodsEntryFileValidateModel.Quantity.message : ''}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <CustomButton autoFocus className='bg-green-500 text-white border-none mb-4' size="none" label={`${isEdit ? 'Edit' : 'Add'} Quantity`} onClick={handleSubmit} iconClassName="w-5 h-auto" />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default DeliveryFileQuantity;