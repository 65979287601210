import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
// @mui/icons-material components

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import { FormHelperText, MenuItem } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomButton } from "components/Common/CustomButton";
//import roles from "services/roles";

const useStyles = makeStyles(componentStyles);

function Machine() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id } = useParams();
    const PageTitle = id ? 'Update Machine' : 'Add Machine';

    // Usef defined states
    const [machineModel, setMachineModel] = useState({
        Name: '',
        WorkCenterId: "0",
        Default: false
    });

    // Usef defined states
    const [machineValidateModel, setMachineValidateModel] = useState({
        Name: { message: '', touched: false },
        WorkCenterId: { message: '', touched: true },
        IsValidationError: false
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value

    const [workCenterList, setWorkCenterList] = useState([]);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        fetchData()
    }, [id])

    const fetchData = async() => {
        await getWorkcenterList();
        if (id) {
            getMachineData();
        }
    }

    const getWorkcenterList = async () => {
        let res = await dataService('get', 'WorkCenterList', `searchText=&sortBy=Name&sortOrder=asc&page=1&offset=100`);
        if (res.status === 200) {
            setWorkCenterList(res?.data);
        }
    }


    const getMachineData = async () => {
        let res = await dataService('get', `MachineGet/${id}`, '');
        if (res.status === 200) {

            
            let MachineData = res?.data
            if (MachineData) {
                setMachineModel({
                    ...machineModel,
                    Name: MachineData?.Name,
                    WorkCenterId: MachineData?.WorkCenterId,
                    Default: MachineData?.Default
                });
            }

        }
    }

    const handleChange = (id, value) => {
        switch (id) {
            case 'Name':
                setMachineModel({ ...machineModel, Name: value });
                setMachineValidateModel({ ...machineValidateModel, Name: { message: checkEmptyValidation(value, 'Name'), touched: true } });
                break;
            case 'WorkCenterId':
                setMachineModel({ ...machineModel, WorkCenterId: value });
                setMachineValidateModel({ ...machineValidateModel, WorkCenterId: { message: checkEmptyValidation(value === "0" ? "" : value, 'Workcenter'), touched: true } });
                break;
            case 'Default':
                setMachineModel({ ...machineModel, Default: value });
                break;
        }
    };

    const handleSubmitMachine = async () => {
        
        if (!checkValidations()) {
            setShowSubmitLoading(true);
            let res = await dataService('post', `MachineSave/${id ? id : 0}`, machineModel);
            setShowSubmitLoading(false);
            if (res && (res.status === 200 || res.status === 304)) {
                setMessageDialoge({ show: true, type: 'success', message: id ? 'Machine updated successfully!' : 'Machine registered successfully!' });
        
                if (!id) {
                    setTimeout(() => {
                        history.push('machines')
                    }, 2000);
                }

            } else {
                //setOpen(res.message);
                setMessageDialoge({ show: true, type: 'error', message: res.message });
            }
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            Name: { message: checkEmptyValidation(machineModel.Name, 'Name'), touched: true },
            WorkCenterId: { message: checkEmptyValidation(machineModel.WorkCenterId.toString() === "0" ? "" : machineModel.WorkCenterId, 'WorkCenterId'), touched: true }
        }
        if (validateErrors.Name.message !== '' ||
            validateErrors.WorkCenterId.message !== ''
        ) {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        
        setMachineValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }


    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container >
                    <Grid
                        item
                        xs={12}
                        xl={12}
                        component={Box}
                        marginBottom="3rem"
                        classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
                    >
                        <Card
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent>
                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <Box
                                    component={Typography}
                                    variant="h6"
                                    color={theme.palette.gray[600] + "!important"}
                                    paddingTop=".25rem"
                                    paddingBottom=".25rem"
                                    fontSize=".75rem!important"
                                    letterSpacing=".04em"
                                    marginBottom="1.5rem!important"
                                    classes={{ root: classes.typographyRootH6 }}
                                >
                                    Machine Information
                                </Box>
                                <div className={classes.plLg4}>
                                    <Grid container>
                                        <Grid item xs={12} lg={5}>
                                            <FormGroup>
                                                <FormLabel>Name</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    component={Box}
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    value={machineModel.Name}
                                                    onChange={e => handleChange('Name', e.target.value)}
                                                    error={machineValidateModel.Name.message !== '' && machineValidateModel.Name.touched}
                                                    helperText={machineValidateModel.Name.message !== '' && machineValidateModel.Name.touched ? machineValidateModel.Name.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} lg={5}>
                                            <FormGroup>
                                                <FormLabel>Workcenter</FormLabel>
                                                <Select value={machineModel.WorkCenterId ? machineModel.WorkCenterId : "0"} onChange={e => handleChange('WorkCenterId', e.target.value)}>
                                                    
                                                    <MenuItem value="0">Select Workcenter</MenuItem>
                                                    {workCenterList?.map((workCenter, index) => {
                                                        return (<MenuItem value={workCenter.WorkCenterId}>{workCenter.Name}</MenuItem>)
                                                    })
                                                    }
                                                </Select>
                                                <FormHelperText className="text-red-500">{machineValidateModel.WorkCenterId.message !== '' && machineValidateModel.WorkCenterId.touched ? machineValidateModel.WorkCenterId.message : ''}</FormHelperText>
                                            </FormGroup>
                                            
                                        </Grid>
                                        
                                        <Grid item xs={12} lg={2}>
                                            <FormGroup>
                                                <FormLabel>Default</FormLabel>
                                                <FormControlLabel className="mt-2 -ml-1" control={<Switch checked={machineModel.Default} onChange={e => handleChange('Default', e.target.checked)} name="default" />} />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitMachine} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Machine;