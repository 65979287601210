import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, Receipt as ReceiptIcon } from '@mui/icons-material';
import { FormGroup, Grid, FormLabel, TextField, Box, FilledInput, Checkbox, FormControlLabel } from '@mui/material';
import { checkEmptyValidation } from 'services/validation-service';
import { dataService } from 'services/data-service';
import { CustomButton } from "components/Common/CustomButton";

function GenerateBillDialog(props) {
    const { onClose, open, DCDetail, isReload, setIsReload } = props;

    const [dataModel, setDataModel] = useState({
        BillNo: DCDetail?.BillNo,
        TransportDetail: DCDetail?.TransportDetail,
        isSendBillingSMS: true,
        isSendBillingEmail: true
    });

    const [dataValidateModel, setDataValidateModel] = useState({

        BillNo: { message: '', touched: false },
        IsValidationError: false
    });
    const handleConfirmEvent = async () => {
        //handleConfirm(confirmData?.action, confirmData?.rowData);
        
        let res = await dataService('post', `DeliveryChallanBillSave/${DCDetail?.DeliveryChallanId}`, dataModel);
        if (res && (res.status === 200 || res.status === 304)) 
        {
            setIsReload(!isReload);    
            handleClose();
        }
    
    };

    const handleClose = () => {
        ///setConfirmData(null)
        onClose();
    };

    const handleChange = (evt, type, checkValidation) => {
        setDataModel({ ...dataModel, [evt.target.name]: evt.target.value });
        if (checkValidation) {
            setDataValidateModel({ ...dataValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, 'Bill Number'), touched: true } });
        }
    }

    return (
        <Dialog
            open={open}
            disableBackdropClick
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <div className={`bg-green-500 border-green-500 border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className="px-4 py-4 text-xl">
                    <div className="text-white text-2xl">
                        Generate Bill
                    </div>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid className='flex flex-wrap items-center justify-start'>
                        <Grid item xs={12} lg={3}>
                            <FormGroup>
                                <FormLabel>Bill Number</FormLabel>
                                <TextField
                                    variant="filled"
                                    name="BillNo"
                                    component={Box}
                                    type="number"
                                    width="100%"
                                    marginBottom="1rem!important"
                                    autoComplete="off"
                                    value={dataModel.BillNo}
                                    onChange={e => handleChange(e, null, true)}
                                    error={dataValidateModel.BillNo.message !== '' && dataValidateModel.BillNo.touched}
                                    helperText={dataValidateModel.BillNo.message !== '' && dataValidateModel.BillNo.touched ? dataValidateModel.BillNo.message : ''}
                                >
                                    <Box
                                        paddingLeft="0.75rem"
                                        paddingRight="0.75rem"
                                        component={FilledInput}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </TextField>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <FormGroup className="mb-0">
                                <FormLabel>Transport Detail</FormLabel>
                                <TextField
                                    variant="filled"
                                    name="TransportDetail"
                                    component={Box}
                                    type="number"
                                    width="100%"
                                    marginBottom="1rem!important"
                                    autoComplete="off"
                                    multiline
                                    minRows={2}
                                    maxRows={6}
                                    value={dataModel.TransportDetail}
                                    onChange={e => handleChange(e, null, false)}
                                >
                                    <Box
                                        paddingLeft="0.75rem"
                                        paddingRight="0.75rem"
                                        component={FilledInput}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </TextField>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid className='flex flex-wrap items-center justify-start'>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormControlLabel
                                value={dataModel?.isSendBillingSMS}
                                className="text-sm"
                                name="isSendBillingSMS"
                                control={<Checkbox
                                    color="primary"
                                    className="py-1"
                                    checked={dataModel?.isSendBillingSMS}
                                    value={dataModel?.isSendBillingSMS}
                                    name="isSendBillingSMS"
                                    onChange={(e) => { setDataModel({ ...dataModel, isSendBillingSMS: e.target.checked }) }} />}
                                label="Send SMS"
                                labelPlacement="end" />

                        </FormGroup>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormControlLabel
                                value={dataModel?.isSendBillingEmail}
                                className="text-sm"
                                name="isSendBillingEmail"
                                control={<Checkbox
                                    color="primary"
                                    className="py-1"
                                    checked={dataModel?.isSendBillingEmail}
                                    value={dataModel?.isSendBillingEmail}
                                    name="isSendBillingEmail"
                                    onChange={(e) => { setDataModel({ ...dataModel, isSendBillingEmail: e.target.checked }) }} />}
                                label="Send Email"
                                labelPlacement="end" />

                        </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="justify-center">
                <CustomButton autoFocus className='bg-green-500 text-white border-none' size="none" label="Generate " onClick={handleConfirmEvent} icon={ReceiptIcon} iconClassName="w-5 h-auto" />
            </DialogActions>
        </Dialog>
    );
};
export default GenerateBillDialog;