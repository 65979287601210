import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';  
import { currentUser } from "services/user-service";
import {Edit as EditIcon, Delete as DeleteIcon, BorderClear} from "@mui/icons-material"
import { showVerificationDialog } from "components/dialoges/VerifiyOTPDialog";
import QRCodeDialog from "components/dialoges/QRCodeDialog";

const useStyles = makeStyles(componentStyles);

const Users = () => {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();
  
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);
  const [QRCodeURL, setQRCodeURL] = useState('');
  
  const columns = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name', dataType:'string' },
    { id: 'PhoneNumber', numeric: false, disablePadding: false, label: 'Phone Number', dataType:'string' },
    { id: 'Role', numeric: false, disablePadding: false, label: 'Role', dataType:'string'},
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType:'string' },
    { id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Registered On', dataType:'date' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let res = await dataService('get', 'UserList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setUserList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    };
    
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload]) 

 

  const handleEdit = (row) => {
    history.push(`update-user/${row.UserId}`)
  };

  
  const handleDelete = async (row) => {
    let res = await dataService('post', `UserDelete/${row.UserId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  const handleShowLoginQR = async (row) => {
    showVerificationDialog('Verify Authorization', true, () => {
    }, async () => {
      let res = await dataService('post', `checkAdminMultiFactorSecret/${row.UserId}`, null);
      if(res?.data){
        const url = `otpauth://totp/RajSamplerERP%20(${row?.Username})?secret=${res?.data?.MultiFactorSecret}`; // authentication user url and user name
        setQRCodeURL(url);
        setOpenQRCodeDialog(true);
      }
    })
    
  };


  let actionList = [
    {label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit},
    {label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true},
    {label: 'MFA QRCode', icon:  BorderClear, iconColor: 'black', action: handleShowLoginQR, Roles: ['SUPER_ADMIN']}
  ]

  
 // remove edit action if not super admin
if(currentUser()?.Role !== 'SUPER_ADMIN'){
  actionList = actionList.filter(ac => {
    return ac.label !== 'Edit'
  })

  actionList = actionList.filter(ac => {
    return ac.label !== 'Delete'
  })

  actionList = actionList.filter(ac => {
    return ac.label !== 'MFA QRCode'
  })
}
  

  return (
    <>
  
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        
          <CommonTable 
            view="user"
            title="Users List"
            columns={columns}
            rows={userList}
            page={page - 1}
            total={totalRecord}
            pagedata={setPage}
            setSearchText={setSearchText}
            order={order}
            orderBy={orderBy}
            orderData={setOrderData}
            orderByData={setOrderByData}
            //editAction={handleEdit}
            actions={actionList}
            setOffset={setOffset}
            offset={offset}
            loading={isLoading}

          />
        </Container>

        {QRCodeURL !== '' && openQRCodeDialog &&
          <QRCodeDialog openDialog={openQRCodeDialog} QRCodeURL={QRCodeURL} onClose={setOpenQRCodeDialog} title="MFA QRCode" description="Download Google Authenticator App and scan QRCode"/>
        } 
    </>
  );
};

export default Users;