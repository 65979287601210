import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import { currentUser } from "services/user-service";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material"
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import WorkCenterParallelDialog from "components/dialoges/WorkCenterParallel";

const useStyles = makeStyles(componentStyles);

const WorkCenters = () => {
  const classes = useStyles();
  const [workcenterList, setWorkCenterList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();
  const [isReload, setIsReload] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('Order');
  const [order, setOrderData] = useState('asc');
  const [offset, setOffset] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const [isParallelDialog, setIsParalledDialog] = useState({
    isVisible: false, data: null, extraData: null
  })

  const columns = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name', dataType: 'string' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status', dataType: 'string' },
    { id: 'Order', numeric: false, disablePadding: false, label: 'Order', dataType: 'string' },
    { id: 'WorkingDays', numeric: false, disablePadding: false, label: 'Work Days', dataType: 'string' },
    { id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Created At', dataType: 'date' },
  ];


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let res = await dataService('get', 'WorkCenterList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
      setWorkCenterList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    };
    fetchData();
  }, [page, searchText, order, orderBy, offset, isReload]);

  const handleEdit = (row) => {
    history.push(`update-workcenter/${row.WorkCenterId}`)
  };

  const handleDelete = async (row) => {
    let res = await dataService('post', `WorkCenterDelete/${row.WorkCenterId}`, null);
    if (res && (res.status === 200 || res.status === 304)) {
      setIsReload(!isReload);
    }
  };

  const getWorkCenterUpTo = (selectedId) => {
    return workcenterList.filter(workCenter => Number(workCenter.WorkCenterId) <= Number(selectedId)).sort((a, b) => Number(a.WorkCenterId) - Number(b.WorkCenterId))
  }

  const handleParallel = async (row) => {
    const filterData = getWorkCenterUpTo(row?.WorkCenterId)
    setIsParalledDialog({ data: filterData, isVisible: true, extraData: row })
  }

  let actionList = [
    { label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: handleEdit },
    { label: 'Delete', icon: DeleteIcon, iconColor: 'red-500', action: handleDelete, isConfirmAction: true },
    { label: 'Parallel', icon: VerticalSplitIcon, iconColor: 'blue-500', action: handleParallel }
  ]

  // remove edit action if not super admins
  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Edit'
    })

    actionList = actionList.filter(ac => {
      return ac.label !== 'Delete'
    })
  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >

        <CommonTable
          view="workcenter"
          title="Workcenter List"
          columns={columns}
          rows={workcenterList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          //editAction={handleEdit}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
        {isParallelDialog.isVisible &&
          <WorkCenterParallelDialog onClose={() => {
            setIsParalledDialog({ data: null, isVisible: false, extraData: null })
          }} open={isParallelDialog} />}
      </Container>
    </>
  );
};

export default WorkCenters;